import { Document, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { KEYS as REPORT_TRANSLATIONS } from "reports/translation/report.translation";
import { getLocaleDateTime, TRANSLATE_ONCE } from "utils/lang/translate";
import { getRenderSearchParams } from "./dataUtil";
import PdfPage from "reports/pdfComponents/PdfPage";
import { getAvailableSize, searchParamStyles } from "./styles";
import SearchComponent from "reports/pdfComponents/SearchComponent";

const GAP = 4;
const styles = StyleSheet.create({
  page: { paddingVertical: 24, paddingHorizontal: 36 },
  headerDate: { textAlign: "right", fontSize: 14 }, // generated date time stamp on top right corner
  docTitle: { textAlign: "center", fontSize: 18 },
  docSubtitle: {
    ...searchParamStyles.rowContainer,
    gap: GAP,
  },
  pageDiv: { position: "absolute", bottom: 12, right: 36, fontSize: 14 },
});

export const WorkorderMapViewPdf = (props) => {
  const { imageUrl, searchParams } = props;

  const {
    i18n: { language },
  } = useTranslation();

  const newSearchParams = getRenderSearchParams(searchParams, language);

  const { page, headerDate, docTitle, docSubtitle, pageDiv } = styles;
  return (
    <Document layout>
      <PdfPage orientation="landscape" style={page}>
        <View fixed>
          <Text style={headerDate}>
            {getLocaleDateTime(new Date(), language, "DATE")}
          </Text>
          <Text style={docTitle}>
            {TRANSLATE_ONCE(REPORT_TRANSLATIONS.TITLE.WORK_ORDER_MAP_VIEW)}
          </Text>
          <View style={{ marginTop: 10 }} />

          <SearchComponent
            pageSize="LETTER"
            orientation="landscape"
            data={newSearchParams}
          />
        </View>

        <Text
          style={pageDiv}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </PdfPage>
      <PdfPage orientation="landscape" style={page}>
        <View style={{ width: "100%", height: 450 }}>
          <Image src={imageUrl} style={{ objectFit: "contain" }} />
        </View>
        <Text
          style={pageDiv}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </PdfPage>
    </Document>
  );
};
