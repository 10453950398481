import React, { useState, useEffect } from "react";
import irisLogo from "assets/WelcomeIMG.png";
import { Button, FormControl, TextField } from "@material-ui/core";
import { AWS_PASSWORD_RULES, testAWSPassword } from "aws/utils";
import styles from "./RestCognitoPasswordPage.module.css";
import { Auth } from "aws-amplify";
import { useNotification } from "irisUI/global/notification/context/NotificationContext";

/**
 *
 * @param {boolean} isNeutral if true, render neutral style (-), not check mark or red cross
 * @param {boolean} goodCondition if true, green style (check mark) would be render, otherwise, red style (red cross) style would be rendered
 * @returns {string} class name that represent helper text list style
 */
const getHelperTextListStyle = (isNeutral, goodCondition) => {
  if (isNeutral) {
    return styles.neutral;
  } else if (goodCondition) {
    return styles.green;
  }
  return styles.red;
};

function RestCognitoPasswordPage(props) {
  /*================================================== states START ================================================== */
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword0, setNewPassword0] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  /*==================================================  states END  ================================================== */

  const { showNotification } = useNotification();
  const getConfirmPasswordHelperText = () => {
    const isNeutral = newPassword1.length === 0;
    const matched = isPasswordValid && newPassword0 === newPassword1;
    return (
      <span className={styles.cognitoPasswordHelperTextList}>
        <li className={getHelperTextListStyle(isNeutral, matched)}>
          {matched ? "Matched !" : "Not Match"}
        </li>
      </span>
    );
  };

  /**
   * @summary function that determines whehter or not the reset button should be disabled or not
   * @returns true if the reset button should be disabled, false otherwise
   */
  const shouldDisableResetButton = () => {
    const passwordsAreOkay = isPasswordValid && newPassword0 === newPassword1;
    return (
      !isSendingRequest &&
      passwordsAreOkay &&
      verificationCode.length === 6 &&
      email.length > 0
    );
  };

  /**
   * @summary callback function for the onChange props of the verification textfield
   * @returns {React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>}
   */
  const onVerificationCodeChanged = () => (event) => {
    const value = event.target.value.trim();
    /** regex for testing string contains only digits */
    const digitTest = /^[0-9]*$/;

    const isPurelyDigit = digitTest.test(value);

    // update (set state) only if digit string with max length = 6
    if (isPurelyDigit && value.length <= 6) {
      setVerificationCode(value);
    }
  };

  /**
   * @summary callback function for the onClick event of the reset button
   */
  const handleResetAwsPassword = () => {
    let isResetSuccessfully = false;
    setIsSendingRequest(true);
    Auth.forgotPasswordSubmit(email, verificationCode, newPassword0)
      .then((data) => {
        isResetSuccessfully = true;
      })
      .catch((err) => {
        showNotification("Error occurred", "top-center", "error");
        console.error(err);
      })
      .finally(() => {
        if (isResetSuccessfully) {
          props.history.replace("/login");
          showNotification(
            "Password reset \nSUCCESSFULLY",
            "top-center",
            "success"
          );
        } else {
          setIsSendingRequest(false);
        }
      });
  };

  // on each newPassword0 changes, check its validity and set state
  useEffect(() => {
    const { result } = testAWSPassword(newPassword0);
    setIsPasswordValid(result);
  }, [newPassword0]);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundImage: `url(${irisLogo})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className={styles.form}>
        <h3>Reset password</h3>
        <div className={styles.textFieldDiv}>
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            autoFocus
          />
        </div>
        <div className={styles.textFieldDiv}>
          <TextField
            fullWidth
            label="Verification Code"
            helperText="Sent to Your Email"
            value={verificationCode}
            onChange={onVerificationCodeChanged()}
          />
        </div>
        <div className={styles.textFieldDiv}>
          <CognitoPasswordTextField
            label="New Password"
            value={newPassword0}
            onChange={(event) => setNewPassword0(event.target.value)}
            fullWidth
          />
        </div>
        <div className={styles.textFieldDiv}>
          <TextField
            label="Confirm Password"
            type={"password"}
            disabled={!isPasswordValid}
            fullWidth
            value={newPassword1}
            onChange={(event) => setNewPassword1(event.target.value)}
            helperText={getConfirmPasswordHelperText()}
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              props.history.replace("/");
            }}
            disabled={isSendingRequest}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!shouldDisableResetButton()}
            onClick={handleResetAwsPassword}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RestCognitoPasswordPage;

/**
 * @summary text field for password input, with proper helper text set up
 * @param {import("@material-ui/core").TextFieldProps} props
 * @returns
 */
export const CognitoPasswordTextField = (props) => {
  const { value, onChange, ...restProps } = props;
  const getHelperText = () => {
    const password = value.trim();

    /** no char got input, there is no right or wrong */
    const isNeutral = password.length === 0;

    const {
      details: {
        hasNumber,
        hasLowercase,
        hasUppercase,
        isLongEnough,
        hasSpecialCharacter,
      },
    } = testAWSPassword(password);

    return (
      <span className={styles.cognitoPasswordHelperTextList}>
        <li className={getHelperTextListStyle(isNeutral, isLongEnough)}>
          {AWS_PASSWORD_RULES.AT_LEAST_EIGHT_CHARACTERS}
        </li>
        <li className={getHelperTextListStyle(isNeutral, hasNumber)}>
          {AWS_PASSWORD_RULES.MUST_HAVE_NUMERIC_CHARACTER}
        </li>
        <li className={getHelperTextListStyle(isNeutral, hasLowercase)}>
          {AWS_PASSWORD_RULES.MUST_HAVE_LOWERCASE_CHARACTER}
        </li>
        <li className={getHelperTextListStyle(isNeutral, hasUppercase)}>
          {AWS_PASSWORD_RULES.MUST_HAVE_UPPERCASE_CHARACTER}
        </li>
        <li className={getHelperTextListStyle(isNeutral, hasSpecialCharacter)}>
          {AWS_PASSWORD_RULES.MUST_HAVE_SPECIAL_SYMBOL}
        </li>
      </span>
    );
  };

  return (
    <TextField
      {...restProps}
      type="password"
      value={value}
      onChange={onChange}
      helperText={getHelperText()}
    />
  );
};
