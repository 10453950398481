import React, { useState, useCallback } from "react";
import { ImageModal } from "../irisUI/modals/imageModal/ImageModal";

/**
 *
 * @param {React.ReactNode} WrappedComponent component which use the image modal
 * @param {any} functionProps extra props passed to the wrapped component
 */
const withImageModal = (WrappedComponent, functionProps) => {
  const HocComponent = ({ ...rootProps }) => {
    const [imageSrc, setImageSrc] = useState("");
    const [openModal, setOpenModal] = useState(false);

    const handleModalClose = () => {
      setOpenModal(false);
    };

    const showImageModal = useCallback(
      (imageSrc) => {
        setImageSrc(imageSrc);
        setOpenModal(true);
      },
      [setImageSrc, setOpenModal]
    );

    const closeImageModal = useCallback(() => {
      setOpenModal(false);
    }, [setOpenModal]);
    return (
      <>
        <ImageModal
          imageSrc={imageSrc}
          open={openModal}
          onClose={handleModalClose}
          maxWidth={1280}
        />
        <WrappedComponent
          showImageModal={showImageModal}
          closImageModal={closeImageModal}
          propspassedfromhoc="propspassedfromhoc"
          {...rootProps}
        />
      </>
    );
  };

  HocComponent.propTypes = {};

  return HocComponent;
};

export default withImageModal;
