import DateUtils from "utils/dateUtils/DateUtils";
import {
  CREATE_LANG_KEY,
  getCommonTranslation,
  getLocaleDateTime,
} from "utils/lang/translate";
import i18n from "config/i18n";
import {
  DEFAULT_OPERANTS,
  DEFAULT_VALUE_TYPES,
  DYNAMONDB_OPERANTS_DATE,
  DYNAMONDB_OPERANTS_SELECTION,
} from "components/DynamonDBFilter/statics";
import { KEYS } from "../WorkorderTable.translate";
import { KEYS as DYNAMODB_FILTER_TRANSLATION_KEY } from "components/DynamonDBFilter/translation";

let allOperants = {
  ...DYNAMONDB_OPERANTS_DATE,
  ...DYNAMONDB_OPERANTS_SELECTION,
};

/**
 * @param {[DynamonDBFilter]} searchParams
 * @param {"en" | "fr" | "ja"} locale
 * @returns {[{searchTitle: string, translatedOperant: string, value0: string | string [], value1: string | string []}]}
 */
export function getRenderSearchParams(searchParams, locale) {
  console.log("searchParams", searchParams);
  const result = searchParams.reduce((acc, ele) => {
    let { key, operant, value0, value1, options, type } = ele;
    if (key === "region") {
      return acc;
    }
    const translateKey = KEYS[key.toUpperCase()];

    const translatedSearchTitle = i18n.t(translateKey);

    const translateOperantKey =
      DYNAMODB_FILTER_TRANSLATION_KEY.OPERANT[
        CREATE_LANG_KEY(allOperants[operant].title)
      ];
    const translatedOperant = i18n.t(translateOperantKey);
    if (!translatedSearchTitle) {
      return acc;
    }

    // localize data time value start
    if (type === "DATE" && DateUtils.isValid(value0)) {
      value0 = getLocaleDateTime(value0, locale, "DATE");
    }
    if (type === "DATE" && DateUtils.isValid(value1)) {
      value1 = getLocaleDateTime(value1, locale, "DATE");
    }
    // localize data time value end

    // translate selection's rendered value start
    if (
      Array.isArray(value0) &&
      operant === DEFAULT_OPERANTS.IN &&
      DEFAULT_VALUE_TYPES.SELECT === type &&
      options
    ) {
      const optionObj = options.reduce((acc, ele) => {
        const { key } = ele;
        acc[key] = ele;
        return acc;
      }, {});
      value0 = value0.map((ele) => optionObj[ele].title);
    }
    // translate selection's rendered value end

    // translate between value0 and value1 start
    if (operant === DEFAULT_OPERANTS.BETWEEN && value0 && value1) {
      value0 = `${getCommonTranslation("from")} ${value0}`;
      value1 = `${getCommonTranslation("to")} ${value1}`;
    } else {
      value0 = value0;
    }
    // translate between value0 and value1 end
    acc.push({
      searchTitle: translatedSearchTitle,
      translatedOperant,
      value0: value0,
      value1: value1,
    });
    return acc;
  }, []);

  return result;
}

/**
 *
 * @param {string} categoryName
 * @param {import("i18next").i18n} i18n
 * @returns
 */
export const getCategoryTitle = (categoryName, i18n) => {
  const { t, language } = i18n;
  const key = KEYS.CATEGORY[categoryName.split(" ").join("_").toUpperCase()];
  const tt = t(key);

  if (!tt) {
    switch (language) {
      case "en":
        return "Others ";
      case "fr":
        return "Autre ";
      case "ja":
        return "その他";
      default:
        return "";
    }
  }

  switch (language) {
    case "en":
      return tt + " Report";
    case "fr":
      return "Rapport de " + tt;
    case "ja":
      return tt + "レポート";
    default:
      return tt;
  }
};

/**
 *
 * @param {string} defectType
 * @param {import("i18next").i18n} i18n
 * @returns
 */
export const getDefectTitle = (defectType, i18n) => {
  const { t, language } = i18n;
  /** translation key for the defect itself */
  const defectsKey = CREATE_LANG_KEY(defectType);
  /** entire key that points to the right translation */
  const key = KEYS.W_O_TYPE[defectsKey];
  const value = t(key);

  if (!value) {
    switch (language) {
      case "en":
        return "Others: " + defectType;
      case "fr":
        return "Autre: " + defectType;
      case "ja":
        return "その他: " + defectType;
      default:
        return defectType;
    }
  }

  switch (language) {
    case "en":
      return value + " Report";
    case "fr":
      return "Rapport de " + value;
    case "ja":
      return value + " レポート";
    default:
      return value;
  }
};
