import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { KEYS as DYNAMODB_TRANSLATION_KEYS } from "components/DynamonDBFilter/translation";
import { KEYS as WORK_ORDER_FIELD_TRANSLATION_KEYS } from "components/table/WorkorderTable.translate";
import { WORK_ORDER_DATA_FIELDS_COMMON } from "components/table/dataModal/workOrderDataModal";
import {
  getAvailableSize,
  getRowStyle,
  ORIENTATIONS,
  PDF_PAGE_STYLE,
  tableHeaderStyle,
  TABLE_STYLES,
  searchParamStyles,
} from "components/table/file/styles";
import { TRANSLATE } from "utils/lang/translate";

const GAP = 4;
const styles = StyleSheet.create({
  page: { paddingVertical: 24, paddingHorizontal: 36 },
  headerDate: { textAlign: "right", fontSize: 14 }, // generated date time stamp on top right corner
  docTitle: { textAlign: "center", fontSize: 18 },
  docSubtitle: {
    ...searchParamStyles.rowContainer,
    gap: GAP,
  },
  pageDiv: { position: "absolute", bottom: 12, right: 36, fontSize: 14 },
});
"landscape" | "portrait";
/**
 *
 * @param {object} props
 * @param {string} props.pageSize ie: "LETTER", "A4" more from {@link https://github.com/diegomura/react-pdf/blob/master/packages/layout/src/page/getSize.js here}
 * @param {"landscape" | "portrait"} props.orientation
 * @param {string} props.title title to render
 * @param {string} props.value value to render
 * @param {number} props.gap gaps between each Search Component, used to calculate width of the component
 * @param {number} props.paramsPerRow number of component for each row, used to calculate width of the component
 * @returns
 */
function SearchComponent(props) {
  const { data } = props;
  const { pageSize, orientation } = props;

  const dtsCellWidth = 80;

  const { width: availableWidth, height } = getAvailableSize(
    pageSize,
    orientation
  );
  const cellWidth = (availableWidth - dtsCellWidth) / 4;
  const TRANSLATION_TYPES = {
    DATE: {
      FULL: "FULL",
      D_DATE: "D_DATE",
      DATE: "DATE",
    },
  };
  const headers0 = [
    {
      key: "searchTitle",
      label: TRANSLATE(DYNAMODB_TRANSLATION_KEYS.GENERAL_UI.FILTER),
      translationType: TRANSLATION_TYPES.DATE.FULL,
      style: {
        width: cellWidth,
      },
    },
    {
      key: "translatedOperant",
      label: TRANSLATE(DYNAMODB_TRANSLATION_KEYS.GENERAL_UI.OPERANT),
      translationType: TRANSLATION_TYPES.DATE.FULL,
      style: {
        width: cellWidth,
      },
    },
    {
      key: "value0",
      label: TRANSLATE(DYNAMODB_TRANSLATION_KEYS.GENERAL_UI.VALUE0),

      style: {
        width: cellWidth,
      },
    },
    {
      key: "value1",
      label: TRANSLATE(DYNAMODB_TRANSLATION_KEYS.GENERAL_UI.VALUE1),
      translationType: WORK_ORDER_FIELD_TRANSLATION_KEYS.CATEGORY,
      style: {
        width: cellWidth,
      },
    },
  ];

  return (
    <>
      <View fixed style={tableHeaderStyle}>
        {Object.values(headers0).map(({ label, style }, index) => (
          <Text key={index} style={style}>
            {label}
          </Text>
        ))}
      </View>
      {data.map((d, index) => (
        <View key={index} style={getRowStyle(index)}>
          {Object.values(headers0).map(
            ({ key: keyName, style, translationType }, index) => {
              let value = d[keyName];
              if (Array.isArray(value)) {
                value = value.join(", ");
              }
              return (
                <Text style={{ ...style, marginVertical: 2 }} key={index}>
                  {value}
                </Text>
              );
            }
          )}
        </View>
      ))}
    </>
  );
}

export default SearchComponent;
