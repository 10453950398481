import {
  DEFAULT_OPERANTS,
  DEFAULT_VALUE_TYPES,
} from "components/DynamonDBFilter/statics";

/**
 *
 * @param {[DynamonDBFilter]} params
 * @returns {TypeListWorkOrderFilter}
 */
export const getListWorkOrderFilter = (params) => {
  const copy = JSON.parse(JSON.stringify(params));
  /**@type {TypeListWorkOrderFilter} */
  const filter = copy.reduce((acc, ele) => {
    const { key, operant, value0, value1, type } = ele;

    if (
      operant === DEFAULT_OPERANTS.BETWEEN ||
      (operant === DEFAULT_OPERANTS.EQ && type === DEFAULT_VALUE_TYPES.DATE)
    ) {
      // maek "between" and "eq" operants into "between"
      acc[key] = { [DEFAULT_OPERANTS.BETWEEN]: [value0, value1] };
    } else {
      acc[key] = { [operant]: value0 };
    }
    return acc;
  }, {});

  return filter;
};

/**
 * return fitler param for /aip/workorder/ of iris-data server
 * @param {[DynamonDBFilter]} params
 * @returns {TypeListWorkOrderFilter}
 */
export const getWorkorderFilter = (params) => {
  const result = params.reduce((acc, ele) => {
    const { key, operant, type, value0, value1 } = ele;
    acc[key] = {};
    acc[key]["operant"] = operant;
    acc[key]["value0"] = value0;

    if (type === DEFAULT_VALUE_TYPES.DATE) {
      if (
        operant === DEFAULT_OPERANTS.BETWEEN ||
        operant === DEFAULT_OPERANTS.EQ
      ) {
        acc[key]["operant"] = DEFAULT_OPERANTS.BETWEEN;
        acc[key]["value1"] = value1;
      }
    } else if (type === DEFAULT_VALUE_TYPES.SELECT) {
      const str = value0.join(", ");
      acc[key]["value0"] = str;
    }

    return acc;
  }, {});

  return result;
};
