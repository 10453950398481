import { sorObjectKeys } from "./data/utilsObject";

/**
 * TODO this step should be done by server
 * make two dimension data from dynamodb to one dimension data
 * @param {object} data
 * @returns`
 * 
 * @example
  const data = {
      value0: {
          "S": "lucien"
      },
      value1: {
          "N": 20
      }
  }

  console.log(flatObject(data))
  {
    "value0": "lucien",
    "value1": 20
  } 
 */
export const flatObject = (data) => {
  const result = {};
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      // TODO support more types https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/HowItWorks.NamingRulesDataTypes.html
      // current consider "S" and "N" dynamodb type
      const strValue = element["S"];
      const numValue = element["N"];
      if (strValue !== undefined && strValue !== null) {
        result[key] = strValue;
      } else if (numValue !== undefined && numValue !== null) {
        result[key] = +numValue;
      }
    }
  }
  return sorObjectKeys(result);
};
