import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { HomePageContext } from "../components/MainContext";
import FullSizeWidgetHolder from "./FullSizeWidgetHolder";
import { AuthContext } from "../components/AuthContext";
import PrintIcon from "@material-ui/icons/Print";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "720px",
    width: "82%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "fixed",
    top: 15,
    right: 15,
    width: "40px",
    height: "40px",
    borderRadius: 0,
  },
  closeIcon: {
    color: "white",
    fontSize: 40,
  },
  printButton: {
    position: "fixed",
    top: 95,
    right: 15,
    width: "40px",
    height: "40px",
    borderRadius: 0,
  },
  printIcon: {
    color: "white",
    fontSize: 40,
  },
}));

export default function FullscreenModule({ moduleName }) {
  const classes = useStyles();
  const { setFullScreenModule } = useContext(HomePageContext);

  const { contextWidgetList, contextUsername, contextCity } =
    useContext(AuthContext);
  return (
    <div className={classes.root}>
      <IconButton
        className={classes.closeButton}
        onClick={() => setFullScreenModule("")}
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <IconButton
        className={classes.printButton}
        onClick={() => {
          // window.gtag(
          //   "event",
          //   `${contextCity.name}_${contextUsername}_print_${moduleName}`,
          //   {
          //     username: contextUsername,
          //     city: contextCity.name,
          //   }
          // );
          window.print();
        }}
      >
        <PrintIcon className={classes.printIcon} />
      </IconButton>
      <FullSizeWidgetHolder
        titlePosition={contextWidgetList[moduleName].titlePosition}
        titleSize={contextWidgetList[moduleName].titleSize}
        widgetUrl={contextWidgetList[moduleName].link}
        child={contextWidgetList[moduleName].child}
      />
    </div>
  );
}
