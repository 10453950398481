import { StyleSheet } from "@react-pdf/renderer";
import { PDF_PAGE_SIZES } from "./pdfPageSizes";

export const TABLE_STYLES = {
  headerBgColor: "#2980ba",
  oddRowBgColor: "#f5f5f5",
  evenRowBgColor: "#fff",
};

const PADDING_HORIZONTAL = 36;
const PADDING_VERTICAL = 36;
export const PDF_PAGE_STYLE = {
  paddingVertical: PADDING_VERTICAL,
  paddingHorizontal: PADDING_HORIZONTAL,
};

export const ORIENTATIONS = {
  LANDSCAPE: "landscape",
  PORTRAIT: "portrait",
};
export const getAvailableSize = (
  pageSize = "A4",
  orientation = ORIENTATIONS.LANDSCAPE,
  paddingHorizontal = PADDING_HORIZONTAL,
  paddingVertical = PADDING_VERTICAL
) => {
  let width = 0,
    height = 0;
  if (orientation === ORIENTATIONS.LANDSCAPE) {
    [height, width] = PDF_PAGE_SIZES[pageSize];
  } else {
    [width, height] = PDF_PAGE_SIZES[pageSize];
  }

  width -= 2 * paddingHorizontal;
  height -= 2 * paddingVertical;

  return { width, height };
};

export const tableHeaderStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  fontSize: 12,
  backgroundColor: TABLE_STYLES.headerBgColor,
  color: "#fff",
  paddingVertical: 5,
  paddingHorizontal: 5,
  fontStyle: "bold",
};

export const tableRowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  fontSize: 12,

  paddingVertical: 5,
  paddingHorizontal: 5,
};

export const getRowStyle = (index) => {
  return {
    ...tableRowStyle,
    backgroundColor:
      index % 2 === 0
        ? TABLE_STYLES.evenRowBgColor
        : TABLE_STYLES.oddRowBgColor,
  };
};

export const searchParamStyles = StyleSheet.create({
  rowContainer: {
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    fontSize: 14,
    flexWrap: "wrap",
  },
  container: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    paddingVertical: 2,
  },
  title: {
    marginBottom: 3,
    backgroundColor: TABLE_STYLES.headerBgColor,
    color: "#fff",
    padding: "4px 8px",
  },
  value: {
    padding: "4px 8px",
  },
});
