import ReactPDF, { Page, Text, StyleSheet, Font } from "@react-pdf/renderer";
import { ORIENTATIONS, PDF_PAGE_STYLE } from "components/table/file/styles";
import React from "react";

import robotoBlack from "assets/fonts/Roboto-Black.ttf";
import robotoBlackItalic from "assets/fonts/Roboto-BlackItalic.ttf";
import robotoBold from "assets/fonts/Roboto-Bold.ttf";
import robotoBoldItalic from "assets/fonts/Roboto-BoldItalic.ttf";
import robotoItalic from "assets/fonts/Roboto-Italic.ttf";
import robotoLight from "assets/fonts/Roboto-Light.ttf";
import robotoLightItalic from "assets/fonts/Roboto-LightItalic.ttf";
import robotoMedium from "assets/fonts/Roboto-Medium.ttf";
import robotoMediumItalic from "assets/fonts/Roboto-MediumItalic.ttf";
import robotoRegular from "assets/fonts/Roboto-Regular.ttf";
import robotoThin from "assets/fonts/Roboto-Thin.ttf";
import robotoThinItalic from "assets/fonts/Roboto-ThinItalic.ttf";

import jpRegular from "assets/fonts/nato_jp/NotoSansJP-Regular.otf";
import jpBold from "assets/fonts/nato_jp/NotoSansJP-Bold.otf";
import { LOCAL_STORAGE_KEY_REACT_PDF_RENDERER_CITY } from "libConfig/reactPDFRenderer";

const FONT_LOCALE = {
  JP: "jp",
  DEFAULT: "Roboto",
};
const CITY_LOCALE = {
  [FONT_LOCALE.JP]: ["nexco"],
};

const FONT_CONFIG_JP = {
  family: FONT_LOCALE.JP,
  fonts: [
    { src: jpRegular },
    { src: jpBold, fontStyle: "bold" },
    { src: jpRegular, fontStyle: "italic" },
    { src: jpRegular, fontStyle: "bold italic" },
  ],
};

const FONT_CONFIG_DEFAULT = {
  family: FONT_LOCALE.DEFAULT,
  fonts: [
    { src: robotoRegular },
    { src: robotoBold, fontStyle: "bold" },
    { src: robotoItalic, fontStyle: "italic" },
    { src: robotoBoldItalic, fontStyle: "bold italic" },
  ],
};

Font.register(FONT_CONFIG_JP);
Font.register(FONT_CONFIG_DEFAULT);

/**
 * set up basic page style. The param "region" is CRITICAL as it determines which font file the pdf page will
 * be used to render text. If it is not set up properly, special characters, ie: Japanese or Chinese, will not be displayed
 * properly on the pdf file.
 *
 * Note: the font file only determines whether the text will be displayed properly or not, it has nothing to do with translations.
 * @param {string} region
 * @returns
 */
const createPdfPageStyle = (region) => {
  let fontFamily = FONT_LOCALE.DEFAULT;
  Object.keys(CITY_LOCALE).forEach((key) => {
    const locals = CITY_LOCALE[key];
    if (locals.indexOf(region.toLowerCase()) > -1) {
      fontFamily = key;
    }
  });
  return StyleSheet.create({
    page: {
      ...PDF_PAGE_STYLE,
      fontSize: 12,
      fontFamily: fontFamily,
    },
    pagination: { position: "absolute", bottom: 12, right: 36, fontSize: 10 },
  });
};

/**
 * @summary A pdf component that has some default style set up and font family and font style set up
 *
 * @see PDF_PAGE_STYLE
 *
 * @param {object} props
 * @param {("landscape" | "portrait")} [props.orientation]
 * @param {string} [props.size]
 * @param {boolean} [props.pagination]
 * @param {ReactPDF.Styles} [props.style]
 * @param {JSX.Element} [props.children]
 * @returns
 */
export default function PdfPage(props) {
  const {
    orientation = ORIENTATIONS.LANDSCAPE,
    size = "LETTER",
    pagination = false,
    style = {},
    children,
  } = props;

  const cn = localStorage.getItem(LOCAL_STORAGE_KEY_REACT_PDF_RENDERER_CITY);

  const { page: pageStyle, pagination: paginationStyle } =
    createPdfPageStyle(cn);
  const mergedStyle = { ...pageStyle, ...style };

  return (
    <Page style={mergedStyle} orientation={orientation} size={size} pagination>
      {children}

      {pagination && (
        <Text
          style={paginationStyle}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      )}
    </Page>
  );
}
