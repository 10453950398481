import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { OPEN_WEATHER_API_KEY } from "../utils/env/keys";
import { CURRENT_WEATHER_REQUEST } from "../utils/requests";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { KEYS as WEATHER_TRANSLATION_KEYS } from "./Weather.translate";
import { getLocaleDateTime } from "utils/lang/translate";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  // container of both city info and time info
  cityTime: {
    marginRight: "3rem",
    "@media (max-width: 1250px)": {
      marginRight: "2rem",
    },
  },
  city: {
    fontSize: "1.3rem",
    fontWeight: 700,
    "@media (max-width: 1250px)": {
      fontSize: "1.2rem",
    },
  },
  time: {
    fontSize: "1rem",
    "@media (max-width: 1250px)": {
      fontSize: "0.9rem",
    },
  },
  // container of both temperature info and weather info
  weatherInfo: {},
  temperature: {
    fontSize: "1.5rem",
    fontWeight: 900,
    textAlign: "right",
    "@media (max-width: 1250px)": {
      fontSize: "1.3rem",
    },
  },
  weather: {
    fontSize: "1rem",
    textAlign: "right",
    "@media (max-width: 1250px)": {
      fontSize: "0.9rem",
    },
  },
  weatherIcon: {
    height: "100px",
    "@media (max-width: 1250px)": {
      height: "80px",
    },
  },
}));

/**
 * @typedef weatherInfoInterface
 * @property {string}  city
 * @property {TypeCityConfig} cityInfo
 * @property {string} condition
 * @property {number} dt
 * @property  {string} icon
 * @property  {number} temp
 */

/**
 *
 * @param {Object} props
 * @param {TypeCityConfig} props.city
 * @returns
 */
export default function PlaceHolder(props) {
  const { city } = props;

  /** @type {weatherInfoInterface} */
  const [weatherInfo, setWeatherInfo] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const classes = useStyles(visibility);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const cityCode = city?.code;
    let isSubscribed = false;
    if (cityCode) {
      let isSubscribed = true;
      CURRENT_WEATHER_REQUEST(cityCode, OPEN_WEATHER_API_KEY, language)
        .then((result) => {
          if (isSubscribed) {
            result["cityInfo"] = { ...city };
            setWeatherInfo(result);
            setVisibility(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return () => (isSubscribed = false);
  }, [city, language]);

  /**
   * @param {weatherInfoInterface} weatherInfo
   * @returns {string} degree string with either celcius(°C) or fahrenheit(°F) symbol, with proper conversion
   */
  const getDegreeString = (weatherInfo) => {
    const celsiusSymbol = "°C";
    const fahrenheitSymbol = "°F";

    const {
      cityInfo: { degree_preference },
      temp,
    } = weatherInfo;

    let result;
    if (degree_preference === "C") {
      result = Math.round(temp) + " " + celsiusSymbol;
    } else {
      // celcius to fahrenheit
      const fahrenheitDegree = (temp * 9) / 5 + 32;
      result = Math.round(fahrenheitDegree) + " " + fahrenheitSymbol;
    }
    return result;
  };

  return (
    <div className={classes.root}>
      <div className={classes.cityTime}>
        <div className={classes.city}>
          {visibility
            ? `${weatherInfo.cityInfo.clientName}, ${weatherInfo.cityInfo.province}`
            : "City, Province"}
        </div>
        <div className={classes.time}>
          {visibility
            ? t(WEATHER_TRANSLATION_KEYS.WEATHER_TIME, {
                time: getLocaleDateTime(weatherInfo.dt * 1000, language),
              })
            : t(WEATHER_TRANSLATION_KEYS.WEATHER_TIME, {
                time: "00:00 ---",
              })}
        </div>
      </div>
      <div className={classes.weatherInfo}>
        <div className={classes.temperature}>
          {visibility ? getDegreeString(weatherInfo) : "--"}
        </div>
        <div className={classes.weather}>
          {visibility ? weatherInfo.condition : "unknown"}
        </div>
      </div>
      <div>
        {visibility ? (
          <img
            src={`http://openweathermap.org/img/wn/${weatherInfo.icon}@2x.png`}
            alt={weatherInfo.condition}
            className={classes.weatherIcon}
          />
        ) : null}
      </div>
    </div>
  );
}
