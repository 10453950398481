export const ROOT_KEY = "REPORT_TRANSLATION";

export const TITLE = "TITLE";
export const REPORT_HEADERS = "REPORT_HEADERS";
export const KEYS = {
  [TITLE]: {
    WORK_ORDER: `${ROOT_KEY}.${TITLE}.WORK_ORDER`,
    WORK_ORDER_SUMMARY: `${ROOT_KEY}.${TITLE}.WORK_ORDER_SUMMARY`,
    WORK_ORDER_MAP_VIEW: `${ROOT_KEY}.${TITLE}.WORK_ORDER_MAP_VIEW`,
  },
  [REPORT_HEADERS]: {
    WORK_ORDER_STATUS: `${ROOT_KEY}.${REPORT_HEADERS}.WORK_ORDER_STATUS`,
    WORK_ORDER_OPENED: `${ROOT_KEY}.${REPORT_HEADERS}.WORK_ORDER_OPENED`,
    WORK_ORDER_CLOSED: `${ROOT_KEY}.${REPORT_HEADERS}.WORK_ORDER_CLOSED`,
    TOTAL: `${ROOT_KEY}.${REPORT_HEADERS}.TOTAL`,
    COMPLETED_PERCENT: `${ROOT_KEY}.${REPORT_HEADERS}.COMPLETED_PERCENT`,

    WORK_ORDER_COMPLIANCE: `${ROOT_KEY}.${REPORT_HEADERS}.WORK_ORDER_COMPLIANCE`,
    IN_COMPLIANCE: `${ROOT_KEY}.${REPORT_HEADERS}.IN_COMPLIANCE`,
    NON_COMPLIANCE: `${ROOT_KEY}.${REPORT_HEADERS}.NON_COMPLIANCE`,
    NON_COMPLETED_PERCENT: `${ROOT_KEY}.${REPORT_HEADERS}.NON_COMPLETED_PERCENT`,
    WORK_ORDER_OVER_DUE: `${ROOT_KEY}.${REPORT_HEADERS}.WORK_ORDER_OVER_DUE`,
    OVER_DUE: `${ROOT_KEY}.${REPORT_HEADERS}.OVER_DUE`,
  },
};

export const REPORT_TRANSLATIONS = {
  en: {
    [TITLE]: {
      WORK_ORDER: "Work Order",
      WORK_ORDER_SUMMARY: "Work Order Summary",
      WORK_ORDER_MAP_VIEW: "Work Order Map View",
    },
    [REPORT_HEADERS]: {
      // over all
      WORK_ORDER_STATUS: "Work Order Reports",
      WORK_ORDER_OPENED: "Work Orders Opened",
      WORK_ORDER_CLOSED: "Work Orders Closed",
      TOTAL: "Total",
      COMPLETED_PERCENT: "% Completed",
      // compliance
      WORK_ORDER_COMPLIANCE: "Work Order Compliance",
      IN_COMPLIANCE: "In Compliance",
      NON_COMPLIANCE: "Non-Compliance",
      NON_COMPLETED_PERCENT: "% Non-Compliance",
      // over due summary
      WORK_ORDER_OVER_DUE: "Work Orders Overdue",
      OVER_DUE: "Overdue",
    },
  },
  fr: {
    [TITLE]: {
      WORK_ORDER: "demande d'intervention ",
      WORK_ORDER_SUMMARY: "Résumé de la Requête de Travail",
      WORK_ORDER_MAP_VIEW: "Affichage de la Carte des Requête de Travail",
    },
    [REPORT_HEADERS]: {
      // over all
      WORK_ORDER_STATUS: "Statuts des demandes d'intervention",
      WORK_ORDER_OPENED: "Ouvert",
      WORK_ORDER_CLOSED: "Fini",
      TOTAL: "Total",
      COMPLETED_PERCENT: "% Complété",
      // compliance
      WORK_ORDER_COMPLIANCE: "Conformité  des demandes d'intervention",
      IN_COMPLIANCE: "conforme",
      NON_COMPLIANCE: "non conforme",
      NON_COMPLETED_PERCENT: "% Non-Conformité",
      // over due summary

      WORK_ORDER_OVER_DUE: "demande d'intevention en retard ",
      OVER_DUE: "En Retard",
    },
  },
  ja: {
    [TITLE]: {
      WORK_ORDER: "作業指示",
      WORK_ORDER_SUMMARY: "作業指示の概要",
      WORK_ORDER_MAP_VIEW: "作業指示マップ ビュー",
    },
    [REPORT_HEADERS]: {
      // over all
      WORK_ORDER_STATUS: "作業指示レポート",
      WORK_ORDER_OPENED: "オープンされた作業指示",
      WORK_ORDER_CLOSED: "クローズされた作業指示",
      TOTAL: "合計",
      COMPLETED_PERCENT: "%完了",
      // compliance
      WORK_ORDER_COMPLIANCE: "作業指示コンプライアンス",
      IN_COMPLIANCE: "順守して",
      NON_COMPLIANCE: "不遵守",
      NON_COMPLETED_PERCENT: "% 不遵守",
      // over due summary
      WORK_ORDER_OVER_DUE: "期限切れの作業指示書",
      OVER_DUE: "期限切れ",
    },
  },
};
