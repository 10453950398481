/**
 * @summary convert given search query into object
 *
 * @param {string} searchString url search query string
 * @returns {{
 *     startDate: string,
 *     endDate: string
 *     category: string,
 *     type: string,
 *     status: string,
 *     severity: string,
 *     serviceBy: string,
 *     assignedTo: string,
 *     workorder: string,
 *     ward: string,
 * }}
 */

export function getSearchParamObject(searchString) {
  const decodedSearchString = decodeURIComponent(searchString.substring(1));

  const components = decodedSearchString.split("&");

  const searchParamObj = components.reduce((acc, ele) => {
    const [key, value] = ele.split("=");
    acc[key] = value;
    return acc;
  }, {});

  return searchParamObj;
}

export function getSearchQueryFromObject(obj, prefix) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? getSearchQueryFromObject(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }
  return str.join("&");
}
