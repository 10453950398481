import React, { useMemo } from "react";
import styles from "./NotificationItem.module.css";
import { useTranslation } from "react-i18next";
import DateUtils from "utils/dateUtils/DateUtils";
import { Typography } from "@material-ui/core";
import NotificationIcon from "utils/theme/icon/NotificationIcon";
/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.message
 * @param {string} props.url
 * @param {string} props.isRead
 * @param {string} props.issuedDate
 * @param {(id: number) => {}} props.onClick
 * @param {[string]} props.tags
 * @returns
 */
function NotificationItem(props) {
  const { title, message, url, isRead, issuedDate, onClick, tags = [] } = props;
  const {
    i18n: { language },
  } = useTranslation();


  const timeAgo = useMemo(
    () => DateUtils.getLocalDate(issuedDate, false)
      .toLocaleDateString(language,
        {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hourCycle: "h12"
        }
      ) || "",
    [issuedDate, language]
  );

  return (
    <div className={styles.container} onClick={(event) => {
      event.preventDefault();
      onClick();
      if (url) {
        window.open(url, "_newtab");
      }
    }}>
      <div>
        <NotificationIcon tags={tags} size="lg" />
      </div>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <Typography variant="caption" className={styles.timeAgo}>
            {timeAgo}
          </Typography>
          <svg
            data-testid="unread-indicator"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24px"
            fill="none"
            height="24px"
          >
            {isRead === false && (
              <circle cx="50%" cy="50%" r="4" fill="#0052CC"></circle>
            )}
          </svg>
        </div>
        <Typography variant="body2" className={styles.message}>
          {url ? (
            <a
              href={url}
            >
              {message}
            </a>
          ) : (
            message
          )}
        </Typography>
      </div>
    </div>
  );
}

export default NotificationItem;
