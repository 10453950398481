import React, { useEffect, useRef, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";

export const COMPLIANCE_SUMMARY_FUNCTION_NAME = "listPatrolCompliances";
const LIST_PATROL_COMPLIANCE = gql`
  query QueryComplianceSummary(
    $filter: TablePatrolComplianceFilterInput
    $nextToken: String = ""
  ) {
    ${COMPLIANCE_SUMMARY_FUNCTION_NAME}(
      limit: 1000000
      filter: $filter
      nextToken: $nextToken
    ) {
      nextToken
      items {
        compliant
        date
        maintenance_class
        non_compliant
        patrol
        region
        section
      }
    }
  }
`;
export function useLazyListComplianceSummary() {
  const dataTempRef = useRef([]);
  const [allData, setAllData] = useState([]);

  const queryResult = useLazyQuery(LIST_PATROL_COMPLIANCE, {
    fetchPolicy: "no-cache",
    initialFetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  const [_, options] = queryResult;

  const { data } = options;

  useEffect(() => {
    if (data) {
      const { current: cachedData } = dataTempRef;
      const returnedData = data?.listPatrolCompliances?.items || [];

      const updatedCachedData = cachedData.concat(returnedData);

      if (data?.listPatrolCompliances?.nextToken) {
        dataTempRef.current = updatedCachedData;
      } else {
        const copy = JSON.parse(JSON.stringify(updatedCachedData));
        dataTempRef.current = [];
        setAllData(copy);
      }
    }
  }, [data, setAllData]);
  return { allData, queryResult };
}
