import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import DateUtils from "utils/dateUtils/DateUtils";
import { WORK_ORDER_DATA_FIELDS_COMMON } from "../dataModal/workOrderDataModal";
import {
  getCategoryTitle,
  getDefectTitle,
  getRenderSearchParams,
} from "./dataUtil";
import {
  getAvailableSize,
  getRowStyle,
  PDF_PAGE_STYLE,
  tableHeaderStyle,
  tableRowStyle,
  TABLE_STYLES,
} from "./styles";
import PdfPage from "reports/pdfComponents/PdfPage";
import {
  getCommonTranslation,
  getLocaleDateTime,
  TRANSLATE,
  TranslateDateTimeToLocale,
} from "utils/lang/translate";
import { useTranslation } from "react-i18next";
import { KEYS as REPORT_TRANSLATION_KEY_MAP } from "reports/translation/report.translation";

const PADDING_HORIZONTAL = 36;
const ORIENTATION = "landscape";

const PAGE_SIZE = "LETTER";

const { width: AVAILABLE_WIDTH, height: AVAILABLE_HEIGHT } = getAvailableSize(
  PAGE_SIZE,
  ORIENTATION,
  PADDING_HORIZONTAL
);

const styles = StyleSheet.create({
  page: PDF_PAGE_STYLE,
  headerDate: { textAlign: "right", fontSize: 14 }, // generated date time stamp on top right corner
  docTitle: { textAlign: "center", fontSize: 18 },
  docSubtitle: {
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    fontSize: 14,
  },
  pageDiv: { position: "absolute", bottom: 12, right: 36, fontSize: 14 },

  tableHeaderStyle: tableHeaderStyle,

  tableRowStyle: tableRowStyle,
});

const GAP = 4;

export const WorkOrderSummayPdf = (props) => {
  const { data, searchParams } = props;

  const { t, i18n } = useTranslation();
  const {
    page,
    headerDate,
    docTitle,
    docSubtitle,
    pageDiv,
    tableHeaderStyle,
    tableRowStyle,
  } = styles;

  const newSearchParams = getRenderSearchParams(searchParams, i18n.language);

  //   Work Order Status	Work Orders Opened	Work Orders Closed	Total	%Completed

  const { REPORT_HEADERS: headerTranslationMap } = REPORT_TRANSLATION_KEY_MAP;
  const workorderStatusTableHeader = [
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.OPEN_DATETIME_LOCAL,
      label: t(headerTranslationMap.WORK_ORDER_STATUS),
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.SERVICE_BY_LOCAL,
      label: t(headerTranslationMap.WORK_ORDER_OPENED),
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.DTS_LOCAL,
      label: t(headerTranslationMap.WORK_ORDER_CLOSED),
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.CATEGORY,
      label: t(headerTranslationMap.TOTAL),
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.W_O_TYPE,
      label: t(headerTranslationMap.COMPLETED_PERCENT),
    },
  ];

  //   Work Order Compliance(Closed)	# in Compliance	# Non-Compliance	Total	% Compliance	% Non-Compliance

  const workorderComplianceTableHeader = [
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.OPEN_DATETIME_LOCAL,
      label: t(headerTranslationMap.WORK_ORDER_COMPLIANCE),
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.SERVICE_BY_LOCAL,
      label: t(headerTranslationMap.IN_COMPLIANCE),
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.DTS_LOCAL,
      label: t(headerTranslationMap.NON_COMPLIANCE),
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.CATEGORY,
      label: t(headerTranslationMap.TOTAL),
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.W_O_TYPE,
      label: t(headerTranslationMap.COMPLETED_PERCENT),
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.W_O_TYPE,
      label: t(headerTranslationMap.NON_COMPLETED_PERCENT),
    },
  ];

  //   Work Orders Over-due 	Total	Over Due
  const workorderOverDueTableHeader = [
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.OPEN_DATETIME_LOCAL,
      label: t(headerTranslationMap.WORK_ORDER_OVER_DUE),
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.SERVICE_BY_LOCAL,
      label: t(headerTranslationMap.OVER_DUE),
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.DTS_LOCAL,
      label: t(headerTranslationMap.TOTAL),
    },
  ];
  const TITLE_CELL_WIDTH = 200;
  const title_cell_style = { width: TITLE_CELL_WIDTH };
  const workorder_status_cell_style = {
    width: (AVAILABLE_WIDTH - TITLE_CELL_WIDTH - 10) / 4,
    textAlign: "center",
  };
  const workorder_compliance_cell_style = {
    width: (AVAILABLE_WIDTH - TITLE_CELL_WIDTH - 10) / 5,
    textAlign: "center",
  };
  const workorder_overdue_cell_style = {
    width: (AVAILABLE_WIDTH - TITLE_CELL_WIDTH - 10) / 2,
    textAlign: "center",
  };
  // "image_url"

  let workOrderReportTableRowIndex = 0;
  let workOrderComplianceRowIndex = 0;
  let workOrderOverdueTableIndex = 0;
  return (
    <Document layout>
      <PdfPage orientation={ORIENTATION} size={PAGE_SIZE} pagination={true}>
        <View fixed>
          <Text style={headerDate}>
            {TranslateDateTimeToLocale(new Date(), "DATE")}
          </Text>
          <Text style={docTitle}>
            {t(REPORT_TRANSLATION_KEY_MAP.TITLE.WORK_ORDER_SUMMARY)}
          </Text>
          <View style={{ marginVertical: "10" }} />
        </View>
        <View fixed style={tableHeaderStyle}>
          {Object.values(workorderStatusTableHeader).map(
            ({ label, style }, index) => (
              <Text
                key={index}
                style={
                  index === 0 ? title_cell_style : workorder_status_cell_style
                }
              >
                {label}
              </Text>
            )
          )}
        </View>
        {Object.keys(data).map((categoryName, index) => {
          const a = getCategoryTitle(categoryName, i18n);
          const {
            report: { closed, completion, items, opened },
          } = data[categoryName];
          return (
            <React.Fragment key={index}>
              {Object.keys(data[categoryName]).map((defectType, index) => {
                if (defectType === "report") return null;
                const {
                  items,
                  report: { closed, completion, opened },
                } = data[categoryName][defectType];
                return (
                  <View
                    key={index}
                    style={{
                      ...getRowStyle(workOrderReportTableRowIndex++),
                      marginVertical: 2,
                    }}
                  >
                    <Text style={title_cell_style}>
                      {/* {`${categoryName} ${defectType} Report`} */}
                      {getDefectTitle(defectType, i18n)}
                    </Text>
                    <Text style={{ ...workorder_status_cell_style }}>
                      {opened.length}
                    </Text>
                    <Text style={{ ...workorder_status_cell_style }}>
                      {closed.length}
                    </Text>
                    <Text style={{ ...workorder_status_cell_style }}>
                      {items.length}
                    </Text>
                    <Text style={{ ...workorder_status_cell_style }}>
                      {completion}
                    </Text>
                  </View>
                );
              })}
            </React.Fragment>
          );
        })}
      </PdfPage>
      <PdfPage orientation={ORIENTATION} size={PAGE_SIZE} pagination={true}>
        <View fixed>
          <Text style={headerDate}>
            {getLocaleDateTime(new Date(), i18n.language, "DATE")}
          </Text>
          <Text style={docTitle}>
            {t(REPORT_TRANSLATION_KEY_MAP.TITLE.WORK_ORDER_SUMMARY)}
          </Text>

          <View style={{ marginVertical: "10" }} />
        </View>
        <View fixed style={tableHeaderStyle}>
          {Object.values(workorderComplianceTableHeader).map(
            ({ label, style }, index) => (
              <Text
                key={index}
                style={
                  index === 0
                    ? title_cell_style
                    : workorder_compliance_cell_style
                }
              >
                {label}
              </Text>
            )
          )}
        </View>
        {Object.keys(data).map((categoryName, index) => {
          const {
            report: {
              closed,
              completion,
              compliance,
              ignored,
              inCompleition,
              inCompliance,
              inspectedAfterServiceBy,
              inspectedBeforeServiceBy,
              items,
              opened,
              overDue,
              overduePercentage,
              stillHaveTime,
              stillHaveTimePercentage,
            },
          } = data[categoryName];

          //   worOrderComplianceRow += `${category} Report,${inspectedBeforeServiceBy.length},${inspectedAfterServiceBy.length},${closed.length},${compliance},${inCompliance}\n`;
          //   worOrderComplianceRow += `${category} ${defectType} report,${inspectedBeforeServiceBy.length},${inspectedAfterServiceBy.length},${closed.length},${compliance},${inCompliance}\n`;

          return (
            <React.Fragment key={index}>
              {Object.keys(data[categoryName]).map((defectType, index) => {
                if (defectType === "report") return null;
                const {
                  report: {
                    closed,
                    compliance,
                    inCompliance,
                    inspectedAfterServiceBy,
                    inspectedBeforeServiceBy,
                  },
                } = data[categoryName][defectType];
                return (
                  <View
                    key={index}
                    style={getRowStyle(workOrderComplianceRowIndex++)}
                  >
                    <Text style={title_cell_style}>
                      {getDefectTitle(defectType, i18n)}
                    </Text>
                    <Text style={workorder_compliance_cell_style}>
                      {inspectedBeforeServiceBy.length}
                    </Text>
                    <Text style={workorder_compliance_cell_style}>
                      {inspectedAfterServiceBy.length}
                    </Text>
                    <Text style={workorder_compliance_cell_style}>
                      {closed.length}
                    </Text>
                    <Text style={workorder_compliance_cell_style}>
                      {compliance}
                    </Text>
                    <Text style={workorder_compliance_cell_style}>
                      {inCompliance}
                    </Text>
                  </View>
                );
              })}
            </React.Fragment>
          );
        })}
      </PdfPage>

      <PdfPage orientation={ORIENTATION} size={PAGE_SIZE} pagination={true}>
        <View fixed>
          <Text style={headerDate}>
            {getLocaleDateTime(new Date(), i18n.language, "DATE")}
          </Text>
          <Text style={docTitle}>
            {t(REPORT_TRANSLATION_KEY_MAP.TITLE.WORK_ORDER_SUMMARY)}
          </Text>
          <View style={{ marginVertical: "10" }} />
        </View>
        <View fixed style={tableHeaderStyle}>
          {Object.values(workorderOverDueTableHeader).map(
            ({ label, style }, index) => (
              <Text
                key={index}
                style={
                  index === 0 ? title_cell_style : workorder_overdue_cell_style
                }
              >
                {label}
              </Text>
            )
          )}
        </View>
        {Object.keys(data).map((categoryName, index) => {
          const {
            report: {
              closed,
              completion,
              compliance,
              ignored,
              inCompleition,
              inCompliance,
              inspectedAfterServiceBy,
              inspectedBeforeServiceBy,
              items,
              opened,
              overDue,
              overduePercentage,
              stillHaveTime,
              stillHaveTimePercentage,
            },
          } = data[categoryName];

          //   workOrderOverDueRow += `${category} Report,${overDue.length},${items.length}\n`;
          //   workOrderOverDueRow += `${category} ${defectType} report,${overDue.length},${items.length}\n`;

          return (
            <React.Fragment key={index}>
              {Object.keys(data[categoryName]).map((defectType, index) => {
                if (defectType === "report") return null;
                const {
                  items,
                  report: { overDue },
                } = data[categoryName][defectType];
                return (
                  <View
                    key={index}
                    style={getRowStyle(workOrderOverdueTableIndex++)}
                  >
                    <Text style={title_cell_style}>
                      {getDefectTitle(defectType, i18n)}
                    </Text>
                    <Text style={workorder_overdue_cell_style}>
                      {overDue.length}
                    </Text>
                    <Text style={workorder_overdue_cell_style}>
                      {items.length}
                    </Text>
                  </View>
                );
              })}
            </React.Fragment>
          );
        })}
      </PdfPage>
    </Document>
  );
};
