import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Badge, Button, Menu, MenuItem } from "@material-ui/core";
import IrisCityLogo from "../assets/IrisCityLogo.png";
import { TOOL_BAR_HEIGHT } from "../utils/theme/constrains";
import { HomePageContext } from "../components/MainContext";
import { AuthContext } from "../components/AuthContext";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import Auth from "@aws-amplify/auth";
import useAuth, { getAWSToken, getAWSTOkenAsync } from "hook/useAuth";
import LanguaugeSelector from "../components/language/LanguageSelector";
import { getCommonTranslation } from "utils/lang/translate";

import Weather from "../components/Weather";
import IrisGoLogo from "../assets/IrisGoLogo.png";
import { KEYS as DASHBOARD_TRANSLATIONS } from "../components/Dashboard.translation";
import { useTranslation } from "react-i18next";
import { getDeviceDetailsForClient } from "utils/requests";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationPopoverNotificationPopover from "components/Popover/NotificationPopoverNotificationPopover";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appbar: {
    height: TOOL_BAR_HEIGHT,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
    zIndex: theme.zIndex.drawer + 1,
    overflow: "auto", // when screen size is under 1024px wide, header can be scrolled in x direction
    overflowY: "hidden", // disable scroll in y direction
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    height: TOOL_BAR_HEIGHT,
    minWidth: "1024px", // minimum width to display info precisely, including weather info, city info and device counts
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  siteLogo: {
    objectFit: "contain",
    height: TOOL_BAR_HEIGHT - 20,
    marginTop: "8px",
    "@media (max-width: 1250px)": {
      height: TOOL_BAR_HEIGHT - 40,
      marginTop: "5px",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuButton: {
    height: TOOL_BAR_HEIGHT,
    marginTop: "3px",
    borderRadius: 0,
  },
  menuText: {
    marginRight: 10,
    marginLeft: 10,
    marginTop: 3,
    maxWidth: 150,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  // container of weather info, city info and device counts
  infoContainer: {
    width: "70%",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 1470px)": {
      width: "65%",
    },
    "@media (max-width: 1250px)": {
      width: "64%",
    },
  },
  // device count section
  rightInfo: {
    display: "flex",
    alignItems: "center",
    marginRight: "2rem",
    "@media (max-width: 1250px)": {
      marginLeft: "1rem",
      marginRight: 0,
    },
  },
  irisGoLogo: {
    objectFit: "contain",
    height: "30px",
    "@media (max-width: 1250px)": {
      height: "20px",
    },
  },
  rightInfoText: {
    display: "inline",
    fontSize: "1.2rem",
    "@media (max-width: 1470px)": {
      fontSize: "1.1rem",
    },
    "@media (max-width: 1250px)": {
      fontSize: "0.9rem",
    },
  },
}));

export default function HomePageLayout(props) {
  const classes = useStyles();
  const [deviceCount, setDeviceCount] = useState(0);
  const { isDrawerOpened } = props;
  const [isNavMenuOpened, setIsNavMenuOpened] = useState(isDrawerOpened);
  const { showPreference, setShowPreference } = useContext(HomePageContext);

  const [awsToken, setAWSTOken] = useState(null);

  getAWSTOkenAsync().then((token) => {
    setAWSTOken(token);
  });

  const {
    user,
    logout,
    pageConfig: { city, dashboard: widgets, clientId },
  } = useAuth();
  const [menuAnchor, setMenuAnchor] = useState(false);
  const [notificationAnchor, setNotificationAncor] = useState(undefined);
  const [notificationBadgeStr, setNotificationBadgeStr] = useState("");

  const { t } = useTranslation();

  const toggleOpenDrawer = () => {
    const { toggleDrawer } = props;
    toggleDrawer();
  };

  useEffect(() => {
    setIsNavMenuOpened(isDrawerOpened);
  }, [isDrawerOpened]);
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleLogout = () => {
    setMenuAnchor(null);
    logout();
  };

  useEffect(() => {
    if (awsToken) {
      getDeviceDetailsForClient(awsToken, clientId, true)
        .then((deviceDetails) => {
          const deviceCount = deviceDetails.filter((device) => device.enabled);
          setDeviceCount(deviceCount.length);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [awsToken]);

  const handlePreference = async () => {
    // window.gtag(
    //   "event",
    //   `${contextCity.name}_${contextUsername}_open_preference`
    // );
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      alert("Error in signing out from AWS");
    } finally {
      setShowPreference(!showPreference);
      setMenuAnchor(null);
    }
  };

  const handleAdmin = () => {
    // window.gtag("event", `${contextCity.name}_${contextUsername}_open_admin`);
    props.history.push("/admin");
    setMenuAnchor(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={menuAnchor}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      open={menuAnchor}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handlePreference}>Preferences</MenuItem> */}
      <MenuItem onClick={handleLogout}>
        {getCommonTranslation("logout")}
      </MenuItem>
    </Menu>
  );

  const onBellIconClicked = (event) => {
    setNotificationAncor((prev) =>
      prev === undefined ? event.currentTarget : undefined
    );
    setNotificationBadgeStr("");
  };

  const updateNotificationBadgeStr = (number) => {
    if (number > 9) {
      setNotificationBadgeStr("9+");
    } else if (number > 0) {
      setNotificationBadgeStr(number);
    } else {
      setNotificationBadgeStr("");
    }
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appbar} elevation={1}>
        <Toolbar className={classes.toolbar}>
          <div style={{ display: "flex", alignItems: "stretch" }}>
            <Button onClick={toggleOpenDrawer}>
              {isNavMenuOpened ? <MenuOpenIcon /> : <ChevronRightIcon />}
            </Button>
            <img src={IrisCityLogo} className={classes.siteLogo} alt="IrisGO" />
          </div>

          <div className={classes.infoContainer}>
            <Weather city={city} />
            <div className={classes.rightInfo}>
              <img
                src={IrisGoLogo}
                className={classes.irisGoLogo}
                alt="irisGO™"
              />
              <div className={classes.rightInfoText}>
                {t(DASHBOARD_TRANSLATIONS.DEVICE_DEPLOYED, {
                  numOfDevices: deviceCount,
                })}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <LanguaugeSelector />
            <IconButton onClick={onBellIconClicked}>
              {notificationBadgeStr !== "" ? (
                <Badge badgeContent={notificationBadgeStr} color="secondary">
                  <NotificationsIcon fontSize="large" />
                </Badge>
              ) : (
                <NotificationsNoneIcon fontSize="large" />
              )}
            </IconButton>

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => setMenuAnchor(event.currentTarget)}
              color="inherit"
              className={classes.menuButton}
            >
              <Typography variant="h6" className={classes.menuText}>
                {user.username}
              </Typography>
              <MenuIcon />
            </IconButton>
            {renderMenu}
          </div>
        </Toolbar>
      </AppBar>

      <NotificationPopoverNotificationPopover
        userName={user.username}
        onClose={() => setNotificationAncor(undefined)}
        anchorEl={notificationAnchor}
        updateNumOfNewNotification={updateNotificationBadgeStr}
      />
      {props.children}
    </div>
  );
}
