import React, { useState, useContext, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { AuthContext } from "../components/AuthContext";
import RGL, { WidthProvider } from "react-grid-layout";
import WidgetHolder from "./WidgetHolder";
import Weather from "./Weather";
import Preference from "./Preference";
import FullscreenModule from "./FullscreenModule";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IrisGoLogo from "../assets/IrisGoLogo.png";
import { Typography } from "@material-ui/core";
import { IRISGO_USER_PROFILE_UPDATE_REQUEST } from "../utils/requests";
import { HomePageContext } from "./MainContext";
import { GIS_MAP_URL } from "../utils/env/urls";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import IrisModal from "./userPreference/IrisModal";
import useAuth from "hook/useAuth";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TOOL_BAR_HEIGHT } from "../utils/theme/constrains";
import { KEYS as DASHBOARD_TRANSLATIONS } from "./Dashboard.translation";
import { CREATE_LANG_KEY } from "utils/lang/translate";
import { useTranslation } from "react-i18next";

const GridLayout = WidthProvider(RGL);

/**
 * tab panel structure
 * @param {Object} props
 * @param {React.ReactElement} props.children tab panel content
 * @param {number} props.index current tab panel id
 * @param {number} props.value active tab panel id
 */
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
  },
  infoContainer: {
    padding: theme.spacing(1),
  },
  rightInfo: {
    float: "right",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-around",
  },
  button: {
    width: "300px",
    height: "35px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "16px",
  },
  irisGoLogo: {
    objectFit: "contain",
    height: "30px",
  },
  deploymentInfo: { justifyContent: "flex-end", alignItems: "flex-end" },
  preference: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tabs: {
    color: "#757575",
    "& .MuiTabs-indicator": {
      height: 0,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#ffff",
      backgroundColor: theme.palette.primary.main,
    },
  },
  tab: {
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
  iframe: {
    width: "100%",
    // 16px is 2 padding of page body
    height: `calc(100vh - 16px - ${TOOL_BAR_HEIGHT}px)`,
    border: 0,
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  const {
    user,
    pageConfig: { city, dashboard: widgets },
  } = useAuth();

  const {
    showPreference,
    setShowPreference,
    fullScreenModule,
    setFullScreenModule,
  } = useContext(HomePageContext);

  // set state to control the active tab
  const [activeTabId, setActiveTabId] = useState(0);

  // handle switch tab
  const handleChangeTab = (e, newValue) => {
    setActiveTabId(newValue);
  };

  const { t } = useTranslation();

  return (
    <div className={classes.main}>
      <Modal
        className={classes.preference}
        open={showPreference}
        onClose={() => setShowPreference(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showPreference}>
          <Preference />
        </Fade>
      </Modal>
      <Modal
        className={classes.preference}
        open={fullScreenModule !== ""}
        onClose={() => setFullScreenModule("")}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showPreference}>
          <FullscreenModule moduleName={fullScreenModule} />
        </Fade>
      </Modal>

      <AppBar position="static" color="default">
        <Tabs
          className={classes.tabs}
          value={activeTabId}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: { transition: "none" },
          }}
        >
          {widgets.map((widget, index) => (
            <Tab
              key={index}
              className={classes.tab}
              label={t(
                DASHBOARD_TRANSLATIONS.TAB_KEYS[CREATE_LANG_KEY(widget.key)]
              )}
            />
          ))}
        </Tabs>
      </AppBar>

      {widgets.map((widget, index) => {
        return (
          <TabPanel key={index} value={activeTabId} index={index}>
            <iframe
              className={classes.iframe}
              src={widget.link}
              title="Widget"
            ></iframe>
          </TabPanel>
        );
      })}
    </div>
  );
}
