/**
 * @summary function to filter out invalid params
 *
 * @param {[DynamonDBFilter]} param
 * @returns {[DynamonDBFilter]}
 */
export const filterSearchParams = (param) => {
  const copy = JSON.parse(JSON.stringify(param));
  let validParams = copy.filter((f) => {
    const validKey = f.key;
    const validOperant = f.operant;
    const value0 = f.value0;
    let isValid0 = value0 !== null && value0 !== undefined && value0 !== "";

    if (Array.isArray(value0)) {
      if (value0.length === 0) {
        isValid0 = false;
      }
    }

    // only thoes with key, operant and value0 set up are valid params
    return validKey && validOperant && isValid0;
  });

  return validParams;
};
