import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Radio from "@material-ui/core/Radio";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  getAssignees,
  LOCAL_MAPPING_FIELDS,
  STATUS,
  STATUS_VALUES,
  WORK_ORDER_DATA_FIELDS_COMMON,
} from "../dataModal/workOrderDataModal";
import TextField from "@material-ui/core/TextField";
import DateUtils from "utils/dateUtils/DateUtils";
import moment from "moment";

import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";

import { getDiffBetweenObjects } from "utils/data/utilsObject";
import { useNotification } from "irisUI/global/notification/context/NotificationContext";
import { AuthContext } from "components/AuthContext";
import useAuth from "hook/useAuth";
import { useUpdateWorkOrder } from "libs/AppoloClient/apis/workorder/hooks/useListWorkOrders/useMutatWorkOrders";
import { CREATE_LANG_KEY, TRANSLATE } from "utils/lang/translate";
import { useTranslation } from "react-i18next";
import {
  localeCancelLabelMap,
  localeDateTimeFormat,
  localeMap,
  localeUtilsMap,
} from "irisUI/DatePicker/localiation";
import { KEYS } from "config/dataTranslations/workOrderData.translation";
import { KEYS as WORK_ORDER_TABLE_TRANSLATION_KEY_MAP } from "../WorkorderTable.translate";
const TAG = "EditRowModal.js";

const VALUE_TYPE = {
  date: "date",
  text: "text",
  select: "select",
  number: "number",
  paragraph: "paragraph",
};

// keys
const assign_to = WORK_ORDER_DATA_FIELDS_COMMON.ASSIGN_TO;
const note = WORK_ORDER_DATA_FIELDS_COMMON.NOTE;
// const inspected_on = WORK_ORDER_DATA_FIELDS_COMMON.INSPECTED_ON;
const inspected_on = WORK_ORDER_DATA_FIELDS_COMMON.INSPECTED_ON_UTC;
const inspected_by = WORK_ORDER_DATA_FIELDS_COMMON.INSPECTED_BY;
const open_datetime_local = WORK_ORDER_DATA_FIELDS_COMMON.OPEN_DATETIME_LOCAL;
// const open_datetime = WORK_ORDER_DATA_FIELDS_COMMON.OPEN_DATETIME;
const open_datetime = WORK_ORDER_DATA_FIELDS_COMMON.OPEN_DATETIME_UTC;

const w_o_type = WORK_ORDER_DATA_FIELDS_COMMON.W_O_TYPE;
// const service_by = WORK_ORDER_DATA_FIELDS_COMMON.SERVICE_BY;
const service_by = WORK_ORDER_DATA_FIELDS_COMMON.SERVICE_BY_UTC;

const inspected_on_local = WORK_ORDER_DATA_FIELDS_COMMON.INSPECTED_ON_LOCAL;

const w_o_status = WORK_ORDER_DATA_FIELDS_COMMON.W_O_STATUS;
const w_o_id = WORK_ORDER_DATA_FIELDS_COMMON.W_O_ID;

/**
 *
 * @param {TypeWorkorderConfig} workorderConfig
 * @returns
 */
const getDataTemplate = (workorderConfig, cityName) => {
  const dataTemplate = {
    // show work order id for better debugging on DynamonDB
    // id: {
    //   title: "ID",
    //   keyName: "id",
    //   type: VALUE_TYPE.text,
    //   value: "",
    //   defaultValue: 0,
    //   readOnly: true,
    // },
    type: {
      title: "Type",
      keyName: "w_o_type",
      translationKey: KEYS.W_O_TYPE,
      type: VALUE_TYPE.text,
      value: "",
      defaultValue: 0,
      readOnly: true,
    },

    opened: {
      title: "Opened",
      keyName: open_datetime,
      translationKey: KEYS.OPEN_DATETIME_LOCAL,
      type: VALUE_TYPE.date,
      value: "",
      defaultValue: new Date(),
      readOnly: true,
      selections: [],
    },
    service_by: {
      title: "Service By",
      keyName: service_by,
      translationKey: KEYS.SERVICE_BY_LOCAL,
      type: VALUE_TYPE.date,
      value: "",
      defaultValue: new Date(),
      readOnly: true,
      selections: [],
    },
    status: {
      title: "Status",
      keyName: w_o_status,
      translationKey: KEYS.W_O_STATUS,
      type: VALUE_TYPE.select,
      value: "",
      defaultValue: 0,
      readOnly: false,
      selections: STATUS,
    },
    inspected_on: {
      title: "Inspected On",
      keyName: inspected_on,
      translationKey: KEYS.INSPECTED_ON_LOCAL,
      type: VALUE_TYPE.date,
      value: "",
      readOnly: false,
      selections: [],
    },
    inspected_by: {
      title: "Inspected By",
      keyName: inspected_by,
      translationKey: KEYS.INSPECTED_BY,
      type: VALUE_TYPE.text,
      readOnly: false,
    },

    workorder: {
      title: "Work Order",
      keyName: w_o_id,
      translationKey: KEYS.W_O_ID,
      type: VALUE_TYPE.text,
      value: "",
      defaultValue: "",
      readOnly: false,
    },
    assign_to: {
      title: "Assigned To",
      keyName: assign_to,
      translationKey: KEYS.ASSIGN_TO,
      type: VALUE_TYPE.select,
      value: "",
      defaultValue: 0,
      readOnly: false,
      selections: getAssignees(workorderConfig.assignees),
    },

    note: {
      title: "Note",
      keyName: note,
      translationKey: KEYS.NOTE,
      type: VALUE_TYPE.paragraph,
      value: "",
      defaultValue: "",
      readOnly: false,
      selections: [],
    },
  };

  // TODO move cutstom specific fields to server
  if (cityName.toLowerCase() === "egis") {
    const egisFields = {
      heading: {
        title: "Heading",
        keyName: "heading",
        translationKey: KEYS.HEADING,
        type: VALUE_TYPE.text,
        value: "",
        defaultValue: 0,
        readOnly: false,
      },
      lane: {
        title: "Lane",
        keyName: "lane",
        translationKey: KEYS.LANE,
        type: VALUE_TYPE.text,
        value: "",
        defaultValue: 0,
        readOnly: false,
      },
    };

    return { ...dataTemplate, ...egisFields };
  }

  return dataTemplate;
};
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    // maxWidth: 600,
    // maxHeight: 200
    width: 500,
    maxHeight: 800,
    overflowY: "scroll",
  },

  formlabel: {
    fontSize: 16,
  },
}));

export default function EditRowModal({
  openModal,
  closeModal,
  onEditData: handleEditData,
}) {
  const { modal, paper, formlabel: formLabelStyle } = useStyles();
  const [open, setOpen] = useState(false);

  const {
    pageConfig: {
      workorder,
      city: { timezone: cityTimezone, name: cityName },
    },
  } = useAuth();

  const { queryItems } = workorder;
  const dataTemplate = getDataTemplate(workorder, cityName);
  const timezone = cityTimezone;

  const {
    data: mutatedData,
    options: [updateWorkOrder, { loading }],
  } = useUpdateWorkOrder(timezone, queryItems);

  const [newData, setNewData] = useState({
    [w_o_type]: "",
    [open_datetime_local]: "",
    [inspected_on]: "",
    [inspected_by]: "",
    [w_o_status]: "",
    [w_o_id]: "",
    [assign_to]: "",
    [note]: "",
  });

  // ref for original data for comparison
  const dataRef = useRef();

  const { showNotification } = useNotification();

  const {
    i18n: { language: locale },
    t,
  } = useTranslation();

  useEffect(() => {
    const { open, data } = openModal;
    setOpen(open);
    if (data) {
      setNewData(data);
      dataRef.current = JSON.parse(JSON.stringify(data));
    }
  }, [openModal]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    if (typeof closeModal === "function") {
      closeModal();
    }
  };

  const onAddNewRowCancel = () => {
    if (typeof closeModal === "function") {
      closeModal();
    }
  };

  const saveNewData = () => {
    /**@type {Date | "" | "yyyy-MM-ddTHH:mm:ss.000Z" } */
    const inspectedOnLocal = newData[inspected_on];

    if (inspectedOnLocal) {
      newData[inspected_on] = new Date(inspectedOnLocal).toJSON();

      let inspectedOnLocalString = inspectedOnLocal;
      // date object
      if (inspectedOnLocal.toJSON) {
        inspectedOnLocalString = inspectedOnLocal.toJSON();
      }
      newData[inspected_on_local] = DateUtils.momentTimezoneGetyyyyMMddhhmmss(
        inspectedOnLocalString,
        timezone
      );
    } else {
      newData[inspected_on] = "";
      newData[inspected_on_local] = "";
    }

    const originalData = dataRef.current || {};
    const diff = getDiffBetweenObjects(originalData, newData);

    if (diff) {
      // if there is any updates on the newData object

      const matchDiff = Object.keys(diff).reduce((acc, key) => {
        if (
          Object.values(WORK_ORDER_DATA_FIELDS_COMMON).indexOf(key) > -1 &&
          Object.values(LOCAL_MAPPING_FIELDS).indexOf(key) < 0 // key-value pair for localization should be removed from this object these values should not be send to graphql server, otherwise raise exception
        ) {
          acc[key] = diff[key];
        }
        return acc;
      }, {});

      if (Object.keys(matchDiff).length === 0) {
        // no valid, updatable key-value pair found in the matchDiff object
        if (typeof closeModal === "function") {
          closeModal();
        }
      } else {
        const idField = WORK_ORDER_DATA_FIELDS_COMMON.ID;
        const regionField = WORK_ORDER_DATA_FIELDS_COMMON.REGION;
        // IMPORTANT: "id" and "region" are required
        matchDiff[idField] = newData[idField];
        matchDiff[regionField] = newData[regionField];
        handleUpdateWorkOrderRequest(matchDiff);
      }
    }
  };

  // const handleUpdateWorkOrderRequest = async (data) => {

  const handleUpdateWorkOrderRequest = (data) => {
    updateWorkOrder({
      variables: {
        input: data,
      },
    }).catch((error) => {
      console.error("error", error);
      showNotification("Error in updating work order", "top-right", "error");
    });
  };

  useEffect(() => {
    if (mutatedData) {
      handleEditData(mutatedData);
      closeModal();
      showNotification("Work order is UPDATED", "top-right", "success");
    }
  }, [mutatedData, handleEditData, closeModal, showNotification]);

  const updateNewData = (key, value) => {
    if (key === w_o_status) {
      if (value === STATUS_VALUES.CLOSED) {
        setNewData((prev) => ({
          ...prev,
          [inspected_on]: new Date(),
        }));
      } else {
        setNewData((prev) => ({
          ...prev,
          [inspected_on]: "",
        }));
      }
    }
    setNewData((prev) => ({ ...prev, [key]: value }));
  };

  const isNewDataValid = () => {
    if (!newData) return !newData;
    const { w_o_id = "", assign_to = "", w_o_status = "" } = newData;
    return w_o_status === "" || w_o_id === "" || assign_to === "";
  };

  /**
   * @summary get timestamp string for HTML5 input with "datetime-local" type
   *
   * @description for the value to be displayed properly in the input, it has to be
   * ISO format, ie "2021-01-01T16:00". However, it would be treated as local time, instead of
   * UTC time.
   *
   * Which means, "2021-01-01T16:00" is like "2021-01-01 04:00 pm"
   *
   * @param {Date | string} date
   * @returns a understandable timestamp string for HTML5 input with type "datetime-local"
   */
  const getLocalDateTimeString = (date) => {
    let temp;
    if (!date) {
      temp = moment().tz("America/Toronto").format("YYYY-MM-DDTHH:mm");
    } else {
      temp = moment(date).tz("America/Toronto").format("YYYY-MM-DDTHH:mm");
    }
    return temp;
  };

  /**
   *
   * @param {Date} value
   */
  const handleInsepctedDateChange = (value) => {
    const targetDateMilli = Date.parse(value);

    const openDateTimeMilli = Date.parse(newData[open_datetime]);

    const currentDateTimeMilli = Date.parse(new Date());

    if (targetDateMilli < openDateTimeMilli) {
      setNewData((prev) => ({
        ...prev,
        [inspected_on]: new Date(newData[open_datetime]),
      }));
    } else if (targetDateMilli > currentDateTimeMilli) {
      setNewData((prev) => ({
        ...prev,
        [inspected_on]: new Date(),
      }));
    } else {
      setNewData((prev) => ({
        ...prev,
        [inspected_on]: value,
      }));
    }
  };
  return (
    <div>
      <Modal
        aria-labelledby="add-new-row-modal-title"
        aria-describedby="add-new-row-modal-description"
        className={modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div>
            <Grid container className={paper}>
              {Object.keys(dataTemplate).map((key, index) => {
                const dataTempplate = dataTemplate[key];
                const {
                  title,
                  type,
                  keyName,
                  readOnly,
                  selections,
                  translationKey,
                } = dataTempplate;

                const translatedLabel = t(translationKey) || title;

                /**
                 * default value for input
                 */
                const originValue = newData[keyName];
                let itemsAlignment = "flex-start";
                if (type === VALUE_TYPE.select) {
                  itemsAlignment = "center";
                }
                return (
                  <Grid
                    item
                    container
                    key={index}
                    alignItems={itemsAlignment}
                    style={{ padding: "1em 0" }}
                  >
                    <Grid item xs={12}>
                      {type === VALUE_TYPE.select && (
                        <RenderSelections
                          labelName={title}
                          keyName={keyName}
                          defaultValue={originValue}
                          values={selections}
                          onChange={updateNewData}
                        />
                      )}

                      {/* numeric input fields */}
                      {type === VALUE_TYPE.number && (
                        <TextField
                          InputProps={{
                            style: {
                              fontSize: 20,
                            },
                          }}
                          label={translatedLabel}
                          fullWidth
                          type="number"
                          defaultValue={originValue}
                          onChange={(event) => {
                            const value = Number(event.target.value);
                            updateNewData(keyName, value);
                          }}
                        />
                      )}

                      {/* text input fields */}
                      {type === VALUE_TYPE.text && !readOnly && (
                        <TextField
                          InputLabelProps={{
                            style: {
                              fontSize: 22,
                              height: 22,
                            },
                          }}
                          InputProps={{
                            style: {
                              fontSize: 20,
                            },
                          }}
                          label={translatedLabel}
                          fullWidth
                          type="text"
                          value={newData[keyName]}
                          onChange={(event) =>
                            updateNewData(keyName, event.target.value)
                          }
                        />
                      )}

                      {/* read only inputs */}
                      {type === VALUE_TYPE.text && readOnly && (
                        <TextField
                          InputProps={{
                            style: {
                              fontSize: 20,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: 22,
                              height: 22,
                            },
                          }}
                          label={translatedLabel}
                          fullWidth
                          disabled
                          type="text"
                          defaultValue={t(
                            WORK_ORDER_TABLE_TRANSLATION_KEY_MAP[
                              keyName.toUpperCase()
                            ][CREATE_LANG_KEY(originValue)]
                          )}
                        />
                      )}

                      {/* text area */}
                      {type === VALUE_TYPE.paragraph && (
                        <FormControl fullWidth>
                          <FormLabel
                            className={formLabelStyle}
                            style={{ marginBottom: 5 }}
                          >
                            {translatedLabel}
                          </FormLabel>
                          <textarea
                            defaultValue={originValue}
                            style={{
                              width: "100%",
                              resize: "none",
                              height: 80,
                              fontSize: "1.375rem",
                            }}
                            onChange={(event) =>
                              updateNewData(keyName, event.target.value)
                            }
                          />
                        </FormControl>
                      )}
                      {type === VALUE_TYPE.date && (
                        <FormControl>
                          <FormLabel className={formLabelStyle}>
                            {translatedLabel}
                          </FormLabel>
                          <MuiPickersUtilsProvider
                            utils={localeUtilsMap[locale]}
                            locale={localeMap[locale]}
                          >
                            <DateTimePicker
                              disabled={
                                readOnly ||
                                (keyName === inspected_on &&
                                  newData[w_o_status] !== STATUS_VALUES.CLOSED)
                              }
                              value={
                                isNaN(Date.parse(newData[keyName]))
                                  ? new Date()
                                  : new Date(newData[keyName])
                              }
                              minDate={new Date(newData[open_datetime])}
                              maxDate={
                                keyName === service_by ? null : new Date()
                              }
                              format={localeDateTimeFormat[locale]}
                              cancelLabel={localeCancelLabelMap[locale]}
                              onChange={handleInsepctedDateChange}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item container justifyContent="flex-end" spacing={3}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<SaveIcon />}
                    onClick={onAddNewRowCancel}
                  >
                    {TRANSLATE("common:CANCEL")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={saveNewData}
                    disabled={loading}
                  >
                    {TRANSLATE("common:SAVE")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <div style={{ height: 4 }}>{loading && <LinearProgress />}</div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const RenderSelections = (props) => {
  const { labelName, keyName, defaultValue, values, onChange } = props;
  const [value, setValue] = useState(defaultValue);
  return (
    <FormControl>
      <FormLabel component="legend">
        {TRANSLATE(CREATE_LANG_KEY(labelName, "EDIT_ROW_MODEL.FORM_LABELS"))}
      </FormLabel>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        // defaultValue={defaultValue}
        value={value}
        onChange={(event, a) => {
          const value = event.target.value;
          setValue(value);
          onChange(keyName, value);
        }}
      >
        {values.map((ele, index) => (
          <FormControlLabel
            key={index}
            value={ele["value"]}
            control={
              <Radio
                color="primary"
                classes={{
                  root: "HHH",
                }}
              />
            }
            label={
              keyName === "w_o_status"
                ? TRANSLATE(
                    CREATE_LANG_KEY(ele["key"], "EDIT_ROW_MODEL.W_O_STATUS")
                  )
                : ele["key"]
            }
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
