import React, { useState, useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NotificationBanner from "./NotificationBanner";
import { useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

// https://blog.shahednasser.com/how-to-animate-components-entrance-and-exit-in-react/
// https://reactcommunity.org/react-transition-group/css-transition
const classNames = {
  enterActive: "animate__fadeInUp",
  exitActive: "animate__fadeOutUp",
};

const ANIMIATION_TIMEOUT = 750;

/**
 *
 * @param {object} props
 * @param {React.CSSProperties} [props.style]
 * @param {[TypeNotification]} [props.notifications]
 * @param {number} [props.maxNotifications] maximum banners to be display, default is **3**
 * @param {(id) => {}} [props.onNotificationClosed] to call backend API if needed
 * @param {import("./NotificationBanner").NotificationBannerProps} [props.bannerProps]
 * @returns
 */

export default function IrisNotificationBannerContainer(props) {
  const {
    style,
    maxNotifications = 3,
    onNotificationClosed,
    onNotificationRead,
    bannerProps,
    notifications: ns = [],
  } = props;

  const [notifications, setNotifications] = useState([]);
  const classes = useStyles();

  const onClose = useCallback(
    (id) => {
      const newNotifications = notifications.splice(0);
      const ind = newNotifications.findIndex((language) => language.id === id);
      if (ind !== -1) {
        newNotifications.splice(ind, 1);
        setNotifications(newNotifications);
      }
    },
    [notifications, setNotifications]
  );

  useEffect(() => {
    setNotifications(ns);
  }, [ns]);

  /*================================================== fade in one by one on mounted START ================================================== */
  const [maxNoti, setMaxNoti] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (maxNoti < maxNotifications) {
        setMaxNoti((prev) => prev + 1);
      }
    }, ANIMIATION_TIMEOUT);
  }, [maxNoti]);
  /*==================================================  fade in one by one on mounted END  ================================================== */

  const handleOnNotificationClosed = (id, read = false) => {
    onClose(id);
    if (read) {
      onNotificationRead(id)

    } else {
      onNotificationClosed(id);
    }

    /* ========== animation trick START ========== */
    // this makes sure that current UI is completedly animated out, and then next element's UI will be animated in
    // otherwise, both animated out and animated in will take place at the same time.
    setMaxNoti((prev) => {
      const newValue = prev - 1;
      if (newValue >= 0 && newValue <= maxNotifications) {
        return newValue;
      }
      return prev;
    });
    /* ===========  animation trick END  ========== */
  };

  if (Array.isArray(notifications)) {
    // for animation purpose,
    // do not put length > 1 as condition, otherwise, the last element will not fade out
    return (
      <TransitionGroup className={classes.root} style={style}>
        {notifications
          .slice(0, Math.min(maxNoti, maxNotifications))
          .map((notification) => (
            <CSSTransition
              key={notification.id} // IMPORTANT: use identifier on the object itself, rather than index of the object, otherwise, animation will be broken.
              className="animate__animated" // required by the aminate.css package, otherwise we have to prefix values on 'enterActive', 'exitActive', estra on classNames object
              classNames={classNames}
              timeout={ANIMIATION_TIMEOUT}
              unmountOnExit={true}
            >
              <div>
                <NotificationBanner
                  {...bannerProps}
                  type={notification.priority}
                  message={notification.message}
                  onClose={() => handleOnNotificationClosed(notification.id)}
                  onRead={() => handleOnNotificationClosed(notification.id, true)}
                  url={notification.link}
                  tags={notification.tags}
                  issuedDate={notification.issuedDate}
                />
              </div>
            </CSSTransition>
          ))}
      </TransitionGroup>
    );
  }

  return <div className={classes.root} style={style}></div>;
}
