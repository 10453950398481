import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import useDebounce from "hook/useDebounce";
import { DatePicker } from "irisUI/DatePicker/DatePicker";
import DateRangePicker from "irisUI/DatePicker/DateRangePicker";
import DateUtils from "utils/dateUtils/DateUtils";
import { KEYS } from "./translation";
import { useTranslation } from "react-i18next";
import { DEFAULT_OPERANTS, DEFAULT_VALUE_TYPES } from "./statics";

const INPUT_VALUE_IDS = {
  VALUE0: "VALUE0",
  VALUE1: "VALUE1",
};

const useStyle = makeStyles({
  container: {
    display: "flex",
    width: 1080,
    gap: "10px",
    alignItems: "flex-end",
  },
  root: {
    width: 180,
    "& .MuiFormControl-marginNormal": {
      marginBottom: 0,
    },
  },
  root1: {
    width: 220,
    "& .MuiFormControl-marginNormal": {
      marginBottom: 0,
    },
  },
  root2: {
    width: 600,
    "& .MuiFormControl-marginNormal": {
      marginBottom: 0,
    },
  },
  deleteIcon: {
    "&.MuiButtonBase-root": {
      padding: 6,
    },
  },
});
/**
 * React element that renders UIs for single dynamondb selection.
 *
 * The UIs includs
 * - key selection
 * - operant selection, ie: equal, not equal, begins with etc
 * - value 0
 * - value 1 if selected key (ie: open_date) and operant (ie: between) support query with 2 values
 * @param {Object} props
 * @param {[DynamonDBFilterType]} props.filterFields
 * @param {DynamonDBFilter} props.filterObj filter object that used for two-way binding.
 * @param {Object} props.operants object that is used to quicky id which operant is selected and retrieve its key, title and value(s).
 * @param {(value: DynamonDBFilter) => {}} props.onValueUpdate callback function which passed the updated filter objct to parent component
 * @param {Object & React.Attributes} props.timezone timezone in string format which is used to conver date object (in local timezone) to the target timezone, then to the ISO 8061 UTC format.
 * @param {() =>{}} props.onRemove callback function which handles removal functionality, in parent component.
 */
export default function FilterFields(props) {
  const {
    filterObj,
    filterFields,
    operants,
    onValueUpdate,
    timezone,
    onRemove,
    isRemovable,
  } = props;

  const {
    key,
    value0,
    value1,
    operant,
    type,
    options,
    renderValue0,
    renderValue1,
  } = filterObj;

  const classes = useStyle();

  const [values, setValues] = useState([value0, value1]);
  /** flag to indicate whether to show 1 or 2 input fields for current filter */
  const [isBetween, setIsBetween] = useState(
    operant === DEFAULT_OPERANTS.BETWEEN
  );

  const [filterType, setFilterType] = useState(type || "");

  const { t } = useTranslation();

  // util function for perfornamce purpose
  const debounce = useDebounce((value0, value1) => {
    const copy = { ...filterObj };
    if (value0 !== null && value0 !== undefined) {
      // any value for value0
      copy.value0 = value0;
    }
    if (value1 !== null && value1 !== undefined) {
      // any value for value1
      copy.value1 = value1;
    }
    // speical hanlding for DATE type input
    if (copy.type === DEFAULT_VALUE_TYPES.DATE) {
      const [startDateString, endDateString] = DateUtils.momentGetUTCDateRange(
        value0,
        value1,
        timezone
      );
      // make all value in UTC string, start of the date
      copy.renderValue0 = value0;
      copy.renderValue1 = value1;
      copy.value0 = startDateString;
      copy.value1 = endDateString;

      if (copy.operant === DEFAULT_OPERANTS.EQ) {
        const [startDateString, endDateString] =
          DateUtils.momentGetUTCDateRange(value0, value0, timezone);
        // force the filter object have two values
        // when date type's operant is marked as "equal"
        // need two values as it will be ultimately converted to
        // "between" to do the query
        copy.value1 = endDateString;
      }
    }
    onValueUpdate(copy);
  }, 300);

  /**
   * callback for updating a search field
   * @param {event} _
   * @param {string} keyValue
   */
  const handleFilterKeyUpdate = (_, keyValue) => {
    const copy = JSON.parse(JSON.stringify(filterObj));
    // null when user click close button, make key invalid
    if (keyValue === null) {
      copy.key = "";
    }
    const target = filterFields.find((field) => field.title === keyValue);
    if (target) {
      const { key, type, operants, options } = target;
      copy.key = key;
      copy.type = type;
      copy.operants = operants;
      copy.options = options;
      if (target.operants[copy.operant] === undefined) {
        const k = Object.keys(copy.operants)[0];
        copy.operant = copy.operants[k].key;
      }
      if (
        target.type === DEFAULT_VALUE_TYPES.SELECT ||
        target.type === DEFAULT_VALUE_TYPES.TEXT
      ) {
        copy.options = target.options;
        copy.value0 = "";
        copy.value1 = null;
      }
      if (
        (copy.operant === DEFAULT_OPERANTS.BETWEEN ||
          copy.operant === DEFAULT_OPERANTS.EQ) &&
        copy.type === DEFAULT_VALUE_TYPES.DATE
      ) {
        const now = new Date();
        const [startDateString, endDateString] =
          DateUtils.momentGetUTCDateRange(now, now, timezone);

        if (!DateUtils.isValid(copy.value0)) {
          copy.value0 = startDateString;
        }
        copy.value1 = endDateString;
      }
      setFilterType(target.type);
    }
    onValueUpdate(copy);
  };

  /**
   * callback for selection of operant.
   *
   * This affects mostly on filters that have type as 'DATE', as for 'TEXT' and 'SELECTION', they share the same operant as 'is',
   * but for 'DATE', there are more of them and the speical one is 'between' which takes two values.
   *
   * So, when for 'DATE', when operation is change to 'between', both start date and end date will reset to current date.
   * for the rest, 'before', 'after', 'on', which takes only one value, the value will be reset to null so that the user
   * have to select a new value from the calendar.
   *
   * The same behavior does not apply to 'between' is becuase after setting to 'between', the UI component will be replace by DateRangePicker.js
   * which does not allow invalid date value input.
   *
   * @param {event} _
   * @param {string} value operation value @see DYNAMONDB_OPERANTS
   * @returns
   */
  const handleUpdateOperant = (_, value) => {
    const target = Object.values(operants).find(
      (operant) => operant.title === value
    );
    if (!target) return;
    const key = target.key;
    const isBetween = target.key === DEFAULT_OPERANTS.BETWEEN;
    const isEqual = target.key === DEFAULT_OPERANTS.EQ;
    setIsBetween(isBetween);
    let copy = {
      ...filterObj,
      operant: key,
      renderValue0: null,
      value0: null,
      renderValue1: null,
      value1: null,
    };

    if (filterType === DEFAULT_VALUE_TYPES.DATE) {
      const renderValue0 = new Date();
      const renderValue1 = new Date();
      const [startDateUTC, endDateUTC] = DateUtils.momentGetUTCDateRange(
        renderValue0,
        renderValue0,
        timezone
      );

      if (isBetween) {
        // NOTE: have to set value for the DateRnagePciker, otherwise, the isBetween method will cause crashes
        copy = {
          ...copy,
          renderValue0,
          value0: startDateUTC,
          renderValue1,
          value1: endDateUTC,
        };
      }
    }

    onValueUpdate(copy);
  };
  /**
   * callback that passed to INPUT element
   * @param {Event} event input event from the element
   * @param {string} id indentifier indicate which values, within the filterObject, should be updated
   */
  const handleUpdateValues = (id) => (event) => {
    const value = event.target.value;
    switch (id) {
      case INPUT_VALUE_IDS.VALUE0:
        setValues((prev) => [value, prev[1]]);
        debounce(value, null);
        break;
      case INPUT_VALUE_IDS.VALUE1:
        setValues((prev) => [prev[0], value]);
        debounce(null, value);
        break;

      default:
        break;
    }
  };

  /**
   * callback passed to date range picker from which selected start date and end date are retrieved.
   * @param {Date} d0 start date object
   * @param {Date} d1 end date object
   */
  const handleDateRangeUpdate = (d0, d1) => {
    debounce(d0, d1);
  };

  const handleUpdateValue = (_, title) => {
    const target = options.find((option) => option.title === title);
    if (target) {
      filterObj.value0 = target.key;
      onValueUpdate({ ...filterObj });
    }
  };
  const handleUpdateMutiOptionValues = (_, values) => {
    const keysNames = [];

    values.map((value) => {
      const target = options.find((option) => option.title === value);
      if (target) {
        keysNames.push(target.key);
      }
    });
    onValueUpdate({ ...filterObj, value0: keysNames });
  };

  /**
   * get translated option title to render, for translation purpose.
   * @returns option (translated) title
   */
  const getSelectionValue = () => {
    const target = options.find((option) => value0 === option.key);
    return target?.title || "";
  };

  /**
   * get translated option titles to render, for translation purpose.
   * @returns option (translated) title
   */
  const getSelectionValues = () => {
    const titles = [];
    if (Array.isArray(value0)) {
      value0.forEach((value) => {
        const target = options.find((option) => option.key === value);
        if (target) {
          titles.push(target.title);
        }
      });
    }
    return titles;
  };

  useEffect(() => {
    setValues([value0, value1]);
  }, [value0, value1]);

  useEffect(() => {
    setIsBetween(operant === DEFAULT_OPERANTS.BETWEEN);
  }, [operant]);

  const filter = filterFields.find((field) => field.key === key);
  const renderTitle = filter?.title || "";

  const renderOperant = operants[operant]?.title || "equal to";

  const isSingleOption =
    filterType === DEFAULT_VALUE_TYPES.SELECT && filterObj.operant === "eq";
  const isMultiOptions =
    filterType === DEFAULT_VALUE_TYPES.SELECT && filterObj.operant === "in";
  return (
    <div className={classes.container}>
      <IconButton
        disabled={isRemovable}
        aria-label="delete"
        className={classes.deleteIcon}
        onClick={() => onRemove()}
      >
        <DeleteIcon />
      </IconButton>
      <Autocomplete
        disableClearable={true}
        freeSolo
        className={classes.root}
        options={filterFields.map((option) => option.title)}
        value={renderTitle}
        onChange={handleFilterKeyUpdate}
        renderInput={(params) => (
          <TextField {...params} placeholder="Filter" margin="normal" />
        )}
      />
      <Autocomplete
        className={classes.root1}
        disableClearable={true}
        options={Object.values(operants).map((option) => option.title)}
        value={renderOperant}
        onChange={handleUpdateOperant}
        renderInput={(params) => (
          <TextField {...params} placeholder="operant" margin="normal" />
        )}
      />
      {filterType === "TEXT" && (
        <div style={{ flex: 1 }}>
          <TextField
            value={values[0] || ""}
            onChange={handleUpdateValues(INPUT_VALUE_IDS.VALUE0)}
          />
          {isBetween && (
            <TextField
              style={{ marginLeft: 10 }}
              value={values[1] || ""}
              onChange={handleUpdateValues(INPUT_VALUE_IDS.VALUE1)}
            />
          )}
        </div>
      )}
      {/* render two date pickers */}
      {filterType === DEFAULT_VALUE_TYPES.DATE && isBetween && (
        <DateRangePicker
          style={{
            width: 330,
            margin: 0,
            padding: 0,
            display: "flex",
            justifyContent: "space-between",
          }}
          datePickerStyle={{ width: 160 }}
          dateWrapperStyle={{ margin: 0 }}
          updateDateRange={handleDateRangeUpdate}
          startDate={renderValue0}
          endDate={renderValue1}
        />
      )}
      {/* render one date picker */}
      {filterType === DEFAULT_VALUE_TYPES.DATE && !isBetween && (
        <DatePicker
          style={{ width: 200 }}
          editable={false}
          value={renderValue0}
          onChange={(value) => {
            debounce(value, null);
          }}
        />
      )}
      {/* render ui for single selection */}
      {isSingleOption && (
        <Autocomplete
          className={classes.root1}
          freeSolo
          disableClearable={true}
          options={options.map((option) => option.title)}
          value={getSelectionValue()} // important for translation purpose
          onChange={handleUpdateValue}
          renderInput={(params) => <TextField {...params} margin="normal" />}
        />
      )}
      {/* render ui for multi selections */}
      {isMultiOptions && (
        <Autocomplete
          multiple
          className={classes.root2}
          options={options.map((option) => option.title)}
          value={getSelectionValues()} // important for translation purpose
          onChange={handleUpdateMutiOptionValues}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} margin="normal" />}
        />
      )}
    </div>
  );
}
