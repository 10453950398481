import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TextField } from "@material-ui/core";
import useInput from "customHooks/useInput";
import { AWS_PASSWORD_MIN_LENGTH, testAWSPassword } from "aws/utils";
import LoadingButton from "irisUI/loadingButton/LoadingButton";
import Auth from "@aws-amplify/auth";
import { CognitoPasswordTextField } from "pages/RestCognitoPassword/RestCognitoPasswordPage";
import { useNotification } from "irisUI/global/notification/context/NotificationContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageDiv: {
    width: 500,
    padding: "1em",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    verticalAlign: "middle",
    border: "10px solid #efefef",
    backgroundColor: "#fff",
    transition: ".3s ease",
    boxShadow: `inset 1px 1px 3px rgba(0, 0, 0, .3),
		  1px 1px 2px rgba(0, 0, 0, .2),
		  40px 35px 70px -10px rgba(0, 0, 0, .5),
		  -20px -20px 100px -10px rgba(255, 255, 255, .8)`,

    "& > *": {
      margin: "10px 0",
    },
  },
}));

export const ResetPasswordModal = (props) => {
  const { open: showModal, onClose: handleModalClose, tempCognitoUser } = props;

  /*================================================== states START ================================================== */
  const [password0, changePassword0, resetPassword0] = useInput("");
  const [password1, changePassword1, resetPassword1] = useInput("");
  const [open, setOpen] = useState(false);
  const [isResetingPassword, setIsResettingPassword] = useState(false);
  /*==================================================  states END  ================================================== */

  const { showNotification } = useNotification();
  const classes = useStyles();

  const handleClose = () => {
    if (typeof handleModalClose === "function") {
      handleModalClose();
    }
  };

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  const isPasswordValid = () => {
    const password = password0.trim();
    const { result } = testAWSPassword(password);

    return result;
  };

  const shouldDisabled = () => {
    const pass0 = password0.trim();
    const pass1 = password1.trim();

    const samelength =
      pass0.length >= AWS_PASSWORD_MIN_LENGTH && pass0 === pass1;

    const { result } = testAWSPassword(pass0);

    return !samelength || !result;
  };

  const handleRestPassword = async () => {
    try {
      setIsResettingPassword(true);
      const user = await Auth.completeNewPassword(
        tempCognitoUser,
        password0.trim()
      );
      showNotification("Password is reset", "top-center", "success");
      resetPassword0();
      resetPassword1();
      props.onClose();
    } catch (error) {
      console.error(error);
      showNotification("Error in reset password", "top-center", "error");
    } finally {
      setIsResettingPassword(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.imageDiv}>
            <h3>Reset password</h3>
            <CognitoPasswordTextField
              placeholder="New Password"
              type="password"
              value={password0}
              onChange={changePassword0}
              autoFocus
            />
            <TextField
              placeholder="Confirm New Password"
              type="password"
              disabled={!isPasswordValid()}
              value={password1}
              onChange={changePassword1}
              helperText={
                password1.trim().length > 0 &&
                password1.trim() === password0.trim()
                  ? "Matched"
                  : password1.trim().length === 0
                  ? ""
                  : "Mismatched"
              }
            />
            <LoadingButton
              style={{ width: 120, marginLeft: "auto" }}
              variant="contained"
              color="primary"
              isLoading={isResetingPassword}
              disabled={shouldDisabled()}
              onClick={handleRestPassword}
            >
              Reset
            </LoadingButton>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
