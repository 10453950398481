import { Transition, CSSTransition } from "react-transition-group";
import { useState, useRef, useEffect, useCallback } from "react";
import { TOOL_BAR_HEIGHT } from "utils/theme/constrains";
import NotificationItem from "./NotificationItem";
import {
  getNotification,
  NOTIFICATIONS_STATES,
  updateNotificationStatus,
} from "utils/apis/notificationAPI";
import { getCommonTranslation } from "utils/lang/translate";
import styles from "./NotificationPopoverNotificationPopover.module.css";
import { Typography } from "@material-ui/core";

const duration = 300;

let notificationIntervalId;
const NOTIFICATION_INTERVAL = 15 * 60 * 1000; // 15 minutes

/**
 *
 * @param {object} props
 * @param {string} props.userName
 * @param {React.ReactHTMLElement} props.anchorEl
 * @param {() => {}} props.updateNumOfNewNotification
 * @param {(num: number) => {}} props.updateNumOfNewNotification
 * @returns
 */
function NotificationPopoverNotificationPopover(props) {
  const { userName, anchorEl, onClose, updateNumOfNewNotification } = props;
  const [inProp, setInProp] = useState(false);
  const nodeRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const containerRef = useRef(null);
  const [anchorPosition, setAnchorPosition] = useState({
    right: 0,
    top: TOOL_BAR_HEIGHT,
  });

  useEffect(() => {
    setInProp(anchorEl !== undefined);
    const position = anchorEl?.getBoundingClientRect();
    if (position) {
      const right = window.innerWidth - position.left - position.width;
      const top = position.bottom + position.top;
      setAnchorPosition({ top: top, right: right });
    }
  }, [anchorEl]);


  useEffect(() => {
    const clickFn = (e) => {
      if (
        !containerRef.current?.contains(e.target) &&
        typeof onClose === "function"
      ) {
        onClose();
      }
    };

    if (anchorEl) {
      document.addEventListener("click", clickFn);
    } else {
      document.removeEventListener("click", clickFn);
    }

    return () => {
      document.removeEventListener("click", clickFn);
    };
  }, [inProp]);

  const updateNotification = useCallback(
    (id) => () => {
      const target = notifications.find((ele) => ele.id === id);
      if (target) {
        target.isRead = true;
      }
      setNotifications([...notifications]);
      updateNotificationStatus(userName, [target.id], NOTIFICATIONS_STATES.READ).catch(
        (error) => {
          console.error(
            `Error in update notifications ${target.id} as READ: ${error}`
          );
        }
      );
    },
    [notifications, setNotifications]
  );

  useEffect(() => {
    const fetchUserNotification = (userName) => {
      const endDate = new Date().toJSON();
      const startDateTimeStamp =
        new Date().getTime() - 1000 * 60 * 60 * 23 * 30;
      const startDate = new Date(startDateTimeStamp).toJSON();
      getNotification(userName, startDate, endDate, "ALL")
        .then((result) => {

          let unreadCount = 0
          const notifications = result.map(notification => {
            if (notification.read_status !== NOTIFICATIONS_STATES.READ) {
              notification["isRead"] = false
              unreadCount++
            }
            return notification
          })
          setNotifications(notifications);

          if (typeof updateNumOfNewNotification === "function") {
            updateNumOfNewNotification(unreadCount);
          }
        })
        .catch((error) => {
          console.error(
            `Error in fetching user[${userName}] notification: ${error}`
          );
        });
    };

    fetchUserNotification(userName);

    notificationIntervalId = setInterval(
      fetchUserNotification,
      NOTIFICATION_INTERVAL,
      userName
    );

    return () => {
      if (notificationIntervalId) {
        clearInterval(notificationIntervalId);
      }
    };
  }, []);

  const hasNotification = notifications.length > 0;

  /** @type {React.CSSProperties}} */
  const wrapperStyle = {
    position: "fixed",
    top: anchorPosition.top,
    right: anchorPosition.right,
    zIndex: 100,
    backgroundColor: "#F0F0F0",
    "max-height": `calc(100vh - ${TOOL_BAR_HEIGHT}px)`,
    overflowY: "auto",
  };

  if (!hasNotification) {
    return (
      <CSSTransition
        nodeRef={nodeRef}
        in={inProp}
        timeout={duration}
        unmountOnExit={true}
        className="animate__animated"
        classNames={{
          enterActive: styles.notificationPanelFadeInDown,
          exitActive: styles.notificationPanelFadeOutUp,
        }}
      >
        <div
          ref={nodeRef}
          style={{
            ...wrapperStyle,
            backgroundColor: "#E0E0E0",
          }}
        >
          <div
            ref={containerRef}
            style={{
              position: "relative",
              width: 540,
              padding: "2em 1em",
            }}
            className={hasNotification ? "" : styles.center}
          >
            <Typography variant="h6">
              {getCommonTranslation("NO_NOTIFICATION")}
            </Typography>
          </div>
        </div>
      </CSSTransition>
    );
  }

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={inProp}
      timeout={duration}
      unmountOnExit={true}
      className="animate__animated"
      classNames={{
        enterActive: styles.notificationPanelFadeInDown,
        exitActive: styles.notificationPanelFadeOutUp,
      }}
    >
      <div
        ref={nodeRef}
        style={{
          ...wrapperStyle,
        }}
      >
        <div
          ref={containerRef}
          style={{
            position: "relative",
            width: 540,
            padding: "2em 1em",
          }}
        >
          {notifications.map((ele, index) => (
            <NotificationItem
              key={index}
              title={ele.subject}
              message={ele.message}
              url={ele.link}
              onClick={updateNotification(ele.id)}
              isRead={ele.isRead}
              issuedDate={ele.issuedDate}
              tags={ele.tags}
            />
          ))}
        </div>
      </div>
    </CSSTransition>
  );
}

export default NotificationPopoverNotificationPopover;
