import moment from "moment";
import DateUtils from "utils/dateUtils/DateUtils";
import { QUERY_WORKORDER_URL } from "utils/env/urls";

export const REPORT_TYPES = {
  ROAD_SECTION_REPORT: "Road Section Report",
  PATROL_COMPLIANCE_SUMMARY: "Patrol Compliance Summary",
  RAODS_PATROL_SUMMARY: "Roads Patrol Summary",
  ROAD_PATROLLED_LIST: "Road Patrolled List",
};

const GET_REPORT_URL = process.env.REACT_APP_PATROL_REPORT_API_GATEWAY_URL;
const rootURL = QUERY_WORKORDER_URL;

export const getPatrolSummary = async (
  startDate,
  endDate,
  region,
  nextToken = ""
) => {
  var graphql = JSON.stringify({
    query: `query MyQuery {\n  listRoadPatrolSummaries(filter: {date: {between: ["${startDate}", "${endDate}"]}, region: {eq: "${region}"}}, limit: 10000, nextToken: "${nextToken}") {\n    nextToken\n    items {\n      to\n      section_id\n      region\n      name\n      from\n      date\n      class\n    }\n  }\n}\n`,
    variables: {},
  });
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": "da2-kjdoywj2wzgojmlxsmkmktje3q",
    },
    body: graphql,
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(rootURL, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      if (response.status === 401) {
        error = new Error("access_denied__error_message");
      } else if (response.status === 404) {
        error = new Error("page_not_found_error_message");
      } else if (response.status >= 500) {
        error = new Error("server_error_message");
      } else {
        error = new Error("general_error_message");
      }
      reject(error);
    }
  });
};

/**
 *
 * @param {string} region name of city / region
 * @param {string} timezone timezone of city / region
 * @param {string} startDate start datetime string, in UTC format
 * @param {string} endDate end datetime string, in UTC format
 * @param {Boolean} [isCheck] flag to indicate whehter cheking existence of a report, if false and found non exist, lambda would generate one.
 * @returns aws response, indicating whehter pdf report file is ready(existed) or not, and a url point to the ready or incoming pdf url
 */
export const getRoadPatrolledListReport = async (
  region,
  timezone,
  startDate,
  endDate,
  isCheck = true
) => {
  const url = new URL(GET_REPORT_URL);
  url.searchParams.append("region", region);
  url.searchParams.append("timezone", timezone);
  url.searchParams.append("start_time", startDate);
  url.searchParams.append("end_time", endDate);
  url.searchParams.append("check", isCheck);
  return new Promise(async (resolve, reject) => {
    const response = await fetch(url);
    const { status } = response;
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      responseJson.isPending = status === 202;
      resolve(responseJson);
    } else {
      if (response.status === 401) {
        error = new Error("access_denied__error_message");
      } else if (response.status === 404) {
        error = new Error("page_not_found_error_message");
      } else if (response.status >= 500) {
        error = new Error("server_error_message");
      } else {
        error = new Error("general_error_message");
      }
      reject(error);
    }
  });
};
