import React, { useState } from "react";
// import makeStyles from "@material-ui/core/styles/makeStyles";
import CssBaseline from "@material-ui/core/CssBaseline";

import HomePageLayout from "../layout/HomePageLayout";
import { PageBody } from "../layout/PageBody";
// const useStyles = makeStyles((theme) => ({}));

export default function AdminConsole(props) {
  const [openDrawer, setOpenDrawer] = useState(true);

  const toggleDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };

  return (
    <div>
      <CssBaseline />
      <HomePageLayout
        {...props}
        toggleDrawer={toggleDrawer}
        isDrawerOpened={openDrawer}
      >
        <PageBody openDrawer={openDrawer} />
      </HomePageLayout>
    </div>
  );
}
