import i18next from "../../config/i18n";
import moment from "moment";
import { Translation } from "react-i18next";
import DateUtils from "utils/dateUtils/DateUtils";
import i18n from "../../config/i18n";

/**
 * Sends back a component that will listen to languge changes
 * @param {*} key The key that matches the *.translate.json
 * @param {*} txt Fall back text if key is missing.
 * @returns
 */
export const TRANSLATE = (key, txt = "") => {
  return (
    <Translation>
      {(t, { i18n }) => {
        return key && i18n.exists(key) ? t(key) : txt;
      }}
    </Translation>
  );
};

/**
 *
 * @param {Date | string | number} dateTimeValue timestamp in either `string`, `date` or `number` format.
 * @param {("LT"|"LTS"|"L"|"LL"|"LLL"|"LLLL")|[string]} formatString pick one of the following
 * * `LT` -> `HH:mm`
 * * `LTS` -> `HH:mm:ss`
 * * `L` -> `DD/MM/YYYY`
 * * `LL` -> `D MMMM YYYY`
 * * `LLL` -> `D MMMM YYYY HH:mm`
 * * `LLLL` -> `dddd D MMMM YYYY HH:mm`
 * * any format that is defined {@link https://momentjs.com/docs/#/parsing/string-format/ here}
 * * or any format combos listed above and defined {@link https://momentjs.com/docs/#/parsing/string-format/ here}
 * @returns
 *
 * @example
 * // demo for English
 * const LT = TranslateTime("2022-10-21T15:03:13.522Z", "LT") // 11:03 AM
 * const LTS = TranslateTime("2022-10-21T15:03:13.522Z", "LTS") // 11:03:13 AM
 * const L = TranslateTime("2022-10-21T15:03:13.522Z", "L") // 10/21/2022
 * const LL = TranslateTime("2022-10-21T15:03:13.522Z", "LL") // October 21, 2022
 * const LLL = TranslateTime("2022-10-21T15:03:13.522Z", "LLL") // October 21, 2022 11:03 AM
 * const LLLL = TranslateTime("2022-10-21T15:03:13.522Z", "LLLL") // Friday, October 21, 2022 11:03 AM
 * const other = TranslateTime("2022-10-21T15:03:13.522Z", ["D MMM YYYY", "LTS"]) // 21 Oct 2022 11:03:13 AM
 * const combo = TranslateTime("2022-10-21T15:03:13.522Z", ["D MMMM YYYY", "LTS"]) // 21 October 2022 11:03:13 AM
 */
export const TranslateTime = (dateTimeValue, formatString = "LLLL") => {
  if (dateTimeValue === "") {
    // allow empty time string
    return "";
  } else if (!DateUtils.isValid(dateTimeValue)) {
    return `invalid time - ${dateTimeValue}`;
  }
  return (
    <Translation>
      {(t, i18n) => {
        moment.locale(i18n.lng);
        // if pass in format combos for further customization
        if (Array.isArray(formatString)) {
          const combos = formatString.map((format) =>
            moment(dateTimeValue).format(format)
          );

          const str = combos.join(" ");
          return str;
        }
        return moment(dateTimeValue).format(formatString);
      }}
    </Translation>
  );
};

export const DATE_TIME_FORMATS = {
  fr: {
    /** mar. 30 août 2022 11:47 */
    FULL: "ddd D MMMM YYYY, HH:mm",
    /**  mar. 30 août 2022 */
    D_DATE: "ddd D MMMM YYYY",
    /** 30 août 2022 */
    DATE: "D MMMM YYYY",
  },
  /** Tue Aug 30 2022 11:47 AM*/
  EN: "ddd., MMM D, YYYY, hh:mm A",

  en: {
    /** Tue Aug 30 2022 11:47 AM*/
    FULL: "ddd., MMM D, YYYY, hh:mm A",
    /**  Tue Aug 30 2022 */
    D_DATE: "ddd., MMM D, YYYY",
    /** Aug 30 2022 */
    DATE: "MMM D, YYYY",
  },
  ja: {
    // 2023年4月27日 (木) 15:11
    FULL: "YYYY年M月D日 (ddd) HH:mm",
    // 2023年4月27日 (木) 15:11
    D_DATE: "YYYY年M月D日 (ddd) HH:mm",
    // 2023年4月27日 (木) 15:11
    DATE: "YYYY年M月D日 (ddd) HH:mm",
  },
};

/**
 *
 * @param {String | number | Date} dateTimeValue
 * @param {"FULL" | "D_DATE" | "DATE"} [type]
 * * `FULL` -> `Tue Aug 30 2022 11:47 AM`
 * * `D_DATE` -> `Tue Aug 30 2022`
 * * `DATE` -> `Aug 30 2022`
 * @returns
 */
export const TranslateDateTimeToLocale = (dateTimeValue, type = "FULL") => {
  if (dateTimeValue === "") {
    // allow empty time string
    return "";
  } else if (!DateUtils.isValid(dateTimeValue)) {
    return `invalid time - ${dateTimeValue}`;
  }
  return (
    <Translation>
      {(t, i18n) => {
        const { lng } = i18n;
        moment.locale(lng);
        const f = DATE_TIME_FORMATS[lng][type];
        return moment(dateTimeValue).format(f);
      }}
    </Translation>
  );
};

/**
 *
 * @param {String | number | Date} dateTimeValue
 * @param {"en" | "fr" | "ja"} locale
 * @param {"FULL" | "D_DATE" | "DATE"} [type]
 * * `FULL` -> `Tue Aug 30 2022 11:47 AM`
 * * `D_DATE` -> `Tue Aug 30 2022`
 * * `DATE` -> `Aug 30 2022`
 * @returns
 */
export const getLocaleDateTime = (
  dateTimeValue,
  locale = "en",
  type = "FULL"
) => {
  if (dateTimeValue === "") {
    // allow empty time string
    return "";
  } else if (!DateUtils.isValid(dateTimeValue)) {
    console.error(`invalid time - ${dateTimeValue}`);
    return "";
  }
  moment.locale(locale);
  const f = DATE_TIME_FORMATS[locale][type];
  const value = moment(dateTimeValue).format(f);
  return value;
};

/**
 * Used to create standardized key from string
 * @param {string} str To be turned into key
 * @param {string} ns The name space to be added
 * @returns
 */
export const CREATE_LANG_KEY = (str, ns = "") => {
  if (typeof str !== "string") {
    // due to historical data, value coud be string or number
    return str;
  }
  let key = str
    .trim()
    .replace(/[\W_]+/g, "_")
    .toUpperCase();

  // incase a key is started by a number, ie: 911_SOME_KEY,
  // convert it as SOME_KEY_911 instead as cannot set up key
  // which starts with a number
  const regex = new RegExp(/[0-9]/);
  if (regex.test(key.charAt(0))) {
    const elements = key.split("_");
    let target = elements[0];
    let count = elements.length;
    while (elements.length > 0 && regex.test(target)) {
      const temp = elements.shift();
      elements.push(temp);
      target = elements[0];
      count--;
      if (count <= 0) {
        break;
      }
    }
    key = elements.join("_");
  }
  if (ns !== "") {
    return ns + "." + key;
  }
  return key;
};

/**
 *
 * get translation for common strings, ie: header title, button text, etc
 * @param {string} key
 * @returns
 */
export const getCommonTranslation = (key) => {
  const k = CREATE_LANG_KEY(key);
  // return t("common:" + k);
  return i18next.t("common:" + k);
};

/**
 * This call is not binded to component changes
 * @param {*} key The key that matches the *.translate.json
 * @param {*} txt Fall back text if key is missing.
 */
export const TRANSLATE_ONCE = (key, txt = "") =>
  key && i18next.exists(key) ? i18next.t(key) : txt;

/**
 * Used to compare two string with regards to locale
 * Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
 * @param {*} a str1
 * @param {*} b str2
 * @param {*} keyBase Base key for translation look up.
 * @returns Number comparison
 */
export const compareTranslated = (a,b,keyBase) =>
{
  const translatedA = TRANSLATE_ONCE(CREATE_LANG_KEY(a,keyBase ))
  const translatedB = TRANSLATE_ONCE(CREATE_LANG_KEY(b, keyBase))
  return translatedA.localeCompare(translatedB, i18n.language)
}