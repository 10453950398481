import React, { useMemo } from "react";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./NotificationBanner.module.css";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import NotificationIcon,
{ CATEGORY_CS_SUPPORT_TECHNICAL, CATEGORY_MARKETING_PRODUCT, CATEGORY_SALES } from "utils/theme/icon/NotificationIcon";
import { NOTIFICATIONS_CS_SUPPORT_TECHNICAL, NOTIFICATIONS_MARKETING_PRODUCT, NOTIFICATIONS_SALES } from "utils/theme/notifications";
import DateUtils from "utils/dateUtils/DateUtils";
import { useTranslation } from "react-i18next";

/**
 * @typedef NotificationBannerProps
 * @property {boolean} [withIcon] default **false**
 * @property {("filled" | "standard" | "outlined")} [variant] default **"standard"**
 * @property {string} [message]
 * @property {("promotion" | "downt_time" | "technical")} [type]
 * @property {String} [url]
 * @property {() => {}} [onClose]
 * @property {React.CSSProperties} [style]
 * @property {("small" | "medium")} closeIconSize
 * @property {[string]} tags
 */



export const useNotificationStyles = makeStyles({
  marketingProduct: {
    backgroundColor: NOTIFICATIONS_MARKETING_PRODUCT
  },
  sales: {
    backgroundColor: NOTIFICATIONS_SALES
  },
  technical: {
    backgroundColor: NOTIFICATIONS_CS_SUPPORT_TECHNICAL
  },
});
/**
 *
 * @param {NotificationBannerProps} props
 * @returns
 */
export default function NotificationBanner(props) {
  const {
    withIcon = false,
    variant = "standard",
    message,
    type,
    url,
    onClose,
    style,
    closeIconSize = "small",
    tags = [],
    issuedDate = "",
    onRead
  } = props;

  const {
    i18n: { language },
  } = useTranslation();
  const classes = useNotificationStyles();

  const getColor = (tags) => {
    let color = classes.marketingProduct;
    if (Array.isArray(tags) && tags.length > 0) {
      switch (tags[0]) {
        case CATEGORY_MARKETING_PRODUCT: color = classes.marketingProduct; break;
        case CATEGORY_SALES: color = classes.sales; break;
        case CATEGORY_CS_SUPPORT_TECHNICAL: color = classes.technical; break;
        default:
          color = classes.marketingProduct
      }
    }

    return color
  }

  const timeAgo = useMemo(
    () => DateUtils.getLocalDate(issuedDate, false)
      .toLocaleDateString(language,
        { weekday: "short", year: "numeric", month: "short", day: "numeric" }
      ) || "",
    [issuedDate, language]
  );



  const onLinkClicked = (event) => {
    event.preventDefault();
    window.open(url, "_blank");
    if (typeof onRead === "function") {
      onRead();
    }
  };
  return (
    <Alert
      style={style}
      icon={withIcon}
      variant={variant}
      className={getColor(tags)}
      severity={"default"}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size={closeIconSize}
          onClick={() => {
            if (typeof onClose === "function") {
              onClose();
            }
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      <div className={styles.container}>
        <div>
          <NotificationIcon tags={tags} size="sm" />
        </div>
        <Grid container>
          <Grid item xs={12} style={{ fontSize: "10px" }}> {timeAgo}</Grid>
          <Grid item xs={12}>
            <div className={`${styles.messageDateContainer}`}>
              <div>
                {url ? (
                  <Typography variant="body2" className={`${styles.truncate} ${styles.whiteText}`}>
                    <a
                      className={`${styles.truncate} ${styles.link}`}
                      href={url}
                      target="_blank"
                      onClick={onLinkClicked}
                    >
                      {message}
                    </a>
                  </Typography>
                ) : (
                  <Typography variant="body2" className={`${styles.truncate} ${styles.whiteText}`} >
                    {message}
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
        </Grid>


      </div>
    </Alert>
  );
}
