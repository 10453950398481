import React, { useEffect, useState } from "react";
import WorkorderTable from "../../components/table/WorkorderTable";
import { Button, ButtonGroup } from "@material-ui/core";
import WorkorderMap from "components/maps/workorderMap/WorkorderMap";
import DateUtils from "utils/dateUtils/DateUtils";
import useAuth from "hook/useAuth";
import { useTranslation } from "react-i18next";
import DynamonDBFilter from "components/DynamonDBFilter/DynamonDBFilter";
import { KEYS } from "config/dataTranslations/workOrderData.translation";
import { KEYS as DBTRANSLATION_KEYS } from "components/DynamonDBFilter/translation";
import { KEYS as WORKORDER_TALBE_KEYS } from "components/table/WorkorderTable.translate";
import { CREATE_LANG_KEY, compareTranslated } from "utils/lang/translate";
import {
  IGNORED_FILTERS,
  DATE_FIELDS,
  DYNAMONDB_OPERANTS,
  DYNAMONDB_OPERANTS_DATE,
  DEFAULT_VALUE_TYPES,
  FILTERS_SELECT,
  DYNAMONDB_OPERANTS_SELECTION,
  UNTRANSLATED_FIELS,
} from "components/DynamonDBFilter/statics";
import { sortObjectByValueField } from "utils/data/utilsObject";
import {
  getSessionDynamodbFilter,
  setSessionDynamodbFilter,
} from "utils/sessionStorageUtils/utils";

/**
 * get operants base on data type
 * @param {import("i18next").TFunction} t
 * @param {"DATE"} [type]
 * @returns object of sorted operant values
 */
const getOperants = (t, type) => {
  let allOperants;
  switch (type) {
    case DEFAULT_VALUE_TYPES.DATE:
      allOperants = DYNAMONDB_OPERANTS_DATE;
      break;
    case DEFAULT_VALUE_TYPES.SELECT:
      allOperants = DYNAMONDB_OPERANTS_SELECTION;
      break;
    default:
      allOperants = DYNAMONDB_OPERANTS;
      break;
  }
  let operants = JSON.parse(JSON.stringify(allOperants));
  operants = sortObjectByValueField(operants, "title");
  Object.values(operants).forEach((value) => {
    const translationKey = CREATE_LANG_KEY(value.title);
    value.title = t(DBTRANSLATION_KEYS.OPERANT[translationKey]);
  });

  return operants;
};

function WorkorderPage() {
  const [dynamonDBFilters, setDynamonDBFilters] = useState(null);

  const [workorderData, setWorkorderData] = useState([]);

  const [activeIndex, setIndex] = useState(0);

  const {
    pageConfig: {
      city: { name, timezone, latitude, longitude },
      workorder,
    },
  } = useAuth();

  const { searchFields } = workorder;

  const { t , i18n} = useTranslation();

  const operants = getOperants(t);
  const dateOperants = getOperants(t, DEFAULT_VALUE_TYPES.DATE);
  const selectionOperats = getOperants(t, DEFAULT_VALUE_TYPES.SELECT);
  const filterFields = searchFields
    .reduce((acc, ele) => {
      if (IGNORED_FILTERS.indexOf(ele.toLocaleLowerCase()) > -1) return acc;
      // set up for search fields whose values should be a selection instead of text field input
      if (FILTERS_SELECT.indexOf(ele) > -1) {
        const shouldNotTranslate = UNTRANSLATED_FIELS.indexOf(ele) > -1;
        const options = workorder[ele].map((optionObj) => {
          const { title } = optionObj;
          let translatedTitle = title;
          if (!shouldNotTranslate) {
            const translateKey = CREATE_LANG_KEY(title);
            translatedTitle = t(
              WORKORDER_TALBE_KEYS[ele.toUpperCase()][translateKey]
            );
          }
          return {
            ...optionObj,
            title: translatedTitle,
          };
        });
        options.sort((a,b)=>a.title.localeCompare(b.title,i18n.language))
        const a = {
          key: ele,
          title: t(KEYS[CREATE_LANG_KEY(ele)]),
          type: DEFAULT_VALUE_TYPES.SELECT,
          operants: selectionOperats,
          options: options,
        };
        acc.push(a);
        return acc;
      }
      const type =
        DATE_FIELDS.indexOf(ele) > -1
          ? DEFAULT_VALUE_TYPES.DATE
          : DEFAULT_VALUE_TYPES.TEXT;
      const a = {
        key: ele,
        title: t(KEYS[CREATE_LANG_KEY(ele)]),
        type,
        operants: type === DEFAULT_VALUE_TYPES.DATE ? dateOperants : operants,
      };
      acc.push(a);
      return acc;
    }, [])
    .filter((ele) => ele.key !== "region")
    .sort((a, b) => {
      const titleA = a.title;
      const titleB = b.title;

      if (titleA > titleB) return 1;
      else if (titleA < titleB) return -1;
      return 0;
    });

  const handleFilter = (filters) => {
    // show work order table
    setIndex(0);
    let temp = JSON.parse(JSON.stringify(filters));
    setDynamonDBFilters(temp);
    setSessionDynamodbFilter(temp);
  };

  // set inital query params when onMount
  useEffect(() => {
    const now = new Date();
    const threeMonthsBefore = new Date(new Date().setMonth(now.getMonth() - 3));

    const [startDateStr, endDateStr] = DateUtils.momentGetUTCDateRange(
      threeMonthsBefore,
      now,
      timezone
    );

    const cachedFilter = getSessionDynamodbFilter();
    /** @type {[DynamonDBFilter]} */
    let initialSearchParams = [];

    if (Array.isArray(cachedFilter)) {
      initialSearchParams = cachedFilter;
    }

    setDynamonDBFilters(initialSearchParams);
  }, [name, timezone]);

  const titles = [t("common:TABLE"), t("common:MAP")];
  return (
    <div>
      <DynamonDBFilter
        timezone={timezone}
        filterFields={filterFields}
        onFilter={handleFilter}
        searchParams={dynamonDBFilters}
      />

      <div style={{ marginBlock: "1rem" }}></div>

      {/* <div> */}
      <ButtonGroup color="primary">
        {titles.map((title, index) => (
          <Button
            variant={index === activeIndex ? "contained" : ""}
            key={index}
            onClick={() => setIndex(index)}
          >
            {title}
          </Button>
        ))}
      </ButtonGroup>
      {/* </div> */}
      {/* </AppBar> */}
      <div style={{ minHeight: 400, maxHeight: 800 }}>
        {activeIndex === 0 && (
          <WorkorderTable
            searchParams={dynamonDBFilters}
            onDataUpdated={setWorkorderData}
          />
        )}
        {activeIndex === 1 && (
          <WorkorderMap
            data={workorderData}
            searchParams={dynamonDBFilters}
            mapCenter={[longitude, latitude]}
          />
        )}
      </div>
    </div>
  );
}

export default WorkorderPage;
