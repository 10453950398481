import React from "react";
import { Document, Text, View } from "@react-pdf/renderer";
import PdfPage from "reports/pdfComponents/PdfPage";
import { PCI_TITLE_TEMPLATE } from "./dataTemplate";
import { styles, DEFAULTS as defaultStyles, DEFAULTS } from "./reportStyles";

const groupObject = (objectList, key) => {
  const copy = JSON.parse(JSON.stringify(objectList));
  const values = Object.values(copy);
  const result = values.reduce((acc, ele) => {
    const newKey = ele[key];
    if (!acc[newKey]) {
      acc[newKey] = [];
    }
    acc[newKey].push({ ...ele });
    return acc;
  }, {});
  return result;
};

const ROW_TYPES = {
  SUB_TOTAL: "TYPE__SUB_TOTAL",
  TOTAL: "TYPE__TOTAL",
};

const ROWS_PER_PAGE = 36;

const RenderDataCell = (props) => {
  const {
    prefix: data_prefix,
    suffix: data_suffix,
    data,
    style: parentCellStyle,
    ignorePrefixSuffix = false,
    isLastSection,
  } = props;

  if (
    ignorePrefixSuffix ||
    (data_prefix === undefined && data_suffix === undefined)
  ) {
    return (
      <View style={parentCellStyle}>
        <Text style={styles.cellText}>{data}</Text>
      </View>
    );
  }
  if (data_prefix) {
    return (
      <View style={parentCellStyle}>
        <Text style={styles.cellText}>{data_prefix}</Text>
        <Text style={styles.cellText}>{data}</Text>
      </View>
    );
  } else if (data_suffix) {
    return (
      <View style={parentCellStyle}>
        <Text style={styles.cellText}>{data}</Text>
        <Text style={styles.cellText}>{data_suffix}</Text>
      </View>
    );
  }
};

const NexcoPCIReport = ({ data }) => {
  const TOTAL_TITLE_KEY = "DP_Defect_"; // where save title of total / subtotal
  const TOTAL_VALUE_KEY = "LB_Defec00"; // where save value of total / subtotal
  const subTotalKey = "direction"; // key's value which is to be caculated for subtotal

  // const tKey = "㎡";
  /*================================================== for total or sub total object (row) START ================================================== */
  const TOTAL_ROW_TEMPLATE = Object.keys(data[0][0]).reduce((acc, ele) => {
    acc[ele] = "";
    return acc;
  }, {});

  /*==================================================  for total or sub total object (row) END  ================================================== */

  const dateSets = data.map((dataSet) => {
    const groupDataObj = groupObject(dataSet, subTotalKey);
    let total = 0;

    for (const key in groupDataObj) {
      let element;
      if (Object.hasOwnProperty.call(groupDataObj, key)) {
        // const totalKey = key + "り" + tKey + "合計";
        const totalKey = key + "り合計";
        element = groupDataObj[key];

        const sectionalSubtitle = element.reduce((acc, ele) => {
          return acc + ele.LB_Defec00;
        }, 0);
        total += sectionalSubtitle;

        const temp = {
          ...TOTAL_ROW_TEMPLATE,
          TYPE: ROW_TYPES.SUB_TOTAL,
          [TOTAL_TITLE_KEY]: totalKey,
          [TOTAL_VALUE_KEY]: Math.round(sectionalSubtitle * 100) / 100,
        };
        element.push(temp);
      }
    }
    const allRows = Object.values(groupDataObj).reduce((acc, ele) => {
      acc = [...acc, ...ele];
      return acc;
    }, []);
    const totalRow = {
      ...TOTAL_ROW_TEMPLATE,
      TYPE: ROW_TYPES.TOTAL,
      [TOTAL_TITLE_KEY]: "合計",
      [TOTAL_VALUE_KEY]: total.toFixed(2),
    };

    allRows.push(totalRow);

    const pageData = allRows.reduce((acc, ele, index) => {
      const i = Math.trunc(index / ROWS_PER_PAGE);

      if (!acc[i]) {
        acc[i] = [];
      }
      acc[i].push(ele);
      return acc;
    }, []);
    groupDataObj["totalRow"] = totalRow;
    groupDataObj["paginatedDataSets"] = pageData;
    groupDataObj["allRows"] = allRows;

    return groupDataObj;
  });
  const PCIHeader = () => {
    return (
      <>
        <View style={{ marginBottom: 8 }}>
          <Text>舗装補修工</Text>
        </View>
        {/* render header */}
        <View style={styles.headerRow}>
          {Object.entries(PCI_TITLE_TEMPLATE).map(
            (entry, parentCellIndex, allEntries) => {
              const [key, value] = entry;
              const {
                width: parentWidth,
                title: paretnCellTitle,
                data_prefix,
                data_suffix,
                data_key,
                render_sub_titles,
              } = value;

              const subTitles = value?.subs || [];
              const isFirstCell = parentCellIndex === 0;
              const isLastCell = parentCellIndex === allEntries.length - 1;
              if (subTitles.length > 0 && render_sub_titles) {
                const subWidth = parentWidth / subTitles.length;

                return (
                  <View
                    key={parentCellIndex}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderLeft: isFirstCell ? "none" : defaultStyles.BORDER,
                      width: parentWidth,
                    }}
                  >
                    <View>
                      <Text style={styles.cellTextRow}>{paretnCellTitle}</Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      {subTitles.map((subTitle, index) => {
                        const isFirstCell = index === 0;
                        const sutTitleCellStyle = {
                          ...styles.singelCellView,
                          width: isFirstCell ? subWidth - 3 : subWidth - 2, // play with these value CAREFULLY
                          borderTop: defaultStyles.BORDER,
                          borderLeft: isFirstCell ? "none" : DEFAULTS.BORDER,
                        };

                        return (
                          <View key={index} style={sutTitleCellStyle}>
                            <Text style={styles.cellText}>
                              {subTitle.title}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              } else {
                // render cells that has not sub cells
                const parentCellStyle = {
                  ...styles.singelCellView,
                  borderLeft: isFirstCell ? "none" : defaultStyles.BORDER,
                  width: parentWidth,
                };
                if (!isLastCell) {
                  // parentCellStyle.borderRight = "none";
                }
                return (
                  <View key={parentCellIndex} style={parentCellStyle}>
                    <Text style={styles.cellText}>{paretnCellTitle}</Text>
                  </View>
                );
              }
            }
          )}
        </View>
      </>
    );
  };
  return (
    <Document>
      {dateSets.map((dateSet, index) => {
        return dateSet.paginatedDataSets.map(
          (paginatedData, sectionRowIndex, sectionRowsArr) => {
            // render section data row by row
            let rowStyles = { ...styles.row };

            if (!(sectionRowIndex === sectionRowsArr.length - 1)) {
              rowStyles.borderBottom = "none";
            }

            return (
              // render row's data cell by cell
              <PdfPage
                key={sectionRowIndex}
                style={styles.body}
                size="A4"
                orientation="portrait"
              >
                <PCIHeader />
                {paginatedData.map((rowData, index, dataInOnePage) => {
                  const isSubtotalRow = rowData.TYPE === ROW_TYPES.SUB_TOTAL;
                  const isLastRowOfPage = dataInOnePage.length - 1 === index;

                  const isTotalRow = rowData.TYPE === ROW_TYPES.TOTAL;
                  if (isTotalRow) {
                    rowStyles = { ...styles.row, border: "none" };
                  }
                  return (
                    <View key={index} style={rowStyles}>
                      {Object.values(PCI_TITLE_TEMPLATE).map(
                        (value, parentCellIndex, values) => {
                          const {
                            width: parentWidth,
                            data_prefix,
                            data_suffix,
                            data_key,
                          } = value;
                          const subTitles = value?.subs || [];
                          parentCellIndex === values.length - 1;

                          const cellStyle = {
                            ...styles.singelCellView,
                            borderLeft:
                              parentCellIndex === 0
                                ? "none"
                                : "1px solid black",
                          };

                          if (!data_key) {
                            // data key(s) exist(s) in the objects in subs array
                            // data sample
                            // subs: [
                            //   {  data_prefix: undefined,  data_suffix: undefined,  title: "自",  data_key: "ic_zi",},
                            //   {  data_prefix: undefined,  data_suffix: undefined,  title: "至",  data_key: "ic_zhi",}
                            // ],
                            return subTitles.map((subTitle, index) => {
                              const { data_prefix, data_suffix, data_key } =
                                subTitle;
                              const dataValue = rowData[data_key];
                              let dataCellStyle = {
                                ...cellStyle,
                                width: parentWidth / subTitles.length,
                              };

                              if (isLastRowOfPage) {
                                dataCellStyle.borderBottom = DEFAULTS.BORDER;
                              }
                              if (isSubtotalRow && dataValue) {
                                dataCellStyle.backgroundColor =
                                  "rgb(253, 233, 217)";
                              } else if (isSubtotalRow && dataValue) {
                                dataCellStyle.backgroundColor =
                                  "rgb(218, 238, 243)";
                              }
                              if (isTotalRow) {
                                dataCellStyle = {
                                  ...styles.singelCellView,
                                  width: parentWidth / subTitles.length,
                                  borderLeft: "none",
                                };

                                if (
                                  dataValue !== "" &&
                                  dataValue !== undefined
                                ) {
                                  dataCellStyle = { ...dataCellStyle };
                                  dataCellStyle.borderLeft =
                                    defaultStyles.BORDER;
                                  dataCellStyle.borderBottom =
                                    defaultStyles.BORDER;
                                  dataCellStyle.borderRight =
                                    defaultStyles.BORDER;
                                  dataCellStyle.backgroundColor =
                                    "rgb(235, 241, 222)";
                                }
                              }
                              return (
                                <RenderDataCell
                                  ignorePrefixSuffix={
                                    isSubtotalRow || isTotalRow
                                  }
                                  isLastSection={isTotalRow}
                                  key={index}
                                  prefix={data_prefix}
                                  suffix={data_suffix}
                                  data={dataValue}
                                  style={dataCellStyle}
                                />
                              );
                            });
                          } else {
                            const dataValue = rowData[data_key];

                            let dataCellStyle = {
                              ...cellStyle,
                              width: parentWidth,
                            };
                            if (isLastRowOfPage) {
                              dataCellStyle.borderBottom = DEFAULTS.BORDER;
                            }
                            if (isSubtotalRow && dataValue) {
                              dataCellStyle.backgroundColor =
                                "rgb(253, 233, 217)";
                            } else if (isSubtotalRow && dataValue) {
                              dataCellStyle.backgroundColor =
                                "rgb(218, 238, 243)";
                            }
                            if (isTotalRow) {
                              dataCellStyle = {
                                ...styles.singelCellView,
                                width: parentWidth,
                                borderLeft: "none",
                              };

                              if (dataValue !== "" && dataValue !== undefined) {
                                dataCellStyle = { ...dataCellStyle };
                                dataCellStyle.borderLeft = defaultStyles.BORDER;
                                dataCellStyle.borderBottom =
                                  defaultStyles.BORDER;
                                dataCellStyle.borderRight =
                                  defaultStyles.BORDER;
                                dataCellStyle.backgroundColor =
                                  "rgb(235, 241, 222)";
                              }
                            }

                            return (
                              <RenderDataCell
                                ignorePrefixSuffix={isSubtotalRow || isTotalRow}
                                isLastSection={isTotalRow}
                                key={parentCellIndex}
                                prefix={data_prefix}
                                suffix={data_suffix}
                                data={dataValue}
                                style={dataCellStyle}
                              />
                            );
                          }
                        }
                      )}
                    </View>
                  );
                })}

                <Text
                  style={{
                    position: "absolute",
                    bottom: 12,
                    right: 36,
                  }}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                  fixed
                />
              </PdfPage>
            );
          }
        );
      })}
    </Document>
  );
};

export default NexcoPCIReport;
