const SPECIAL_CHAR_REGEX = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?~]/;

/**
 *
 * @param {string} inputValue
 * @param {string} replaceValue
 * @param {[string]} replaceValue default to  /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?~]/
 */
const replaceSpecialChars = (
  inputValue,
  replaceValue,
  regex = SPECIAL_CHAR_REGEX
) => {
  let outputValue = inputValue;
  let match;
  do {
    match = regex.exec(outputValue);
    if (match) {
      const char = match[0];
      outputValue = outputValue.replace(char, replaceValue);
    }
  } while (match);

  return outputValue;
};

/**
 * remove special chars (but not hpyens) from a client's name
 * @param {string} value
 * @returns client name with consecutive spaces replaced by a singl space
 * 
 * @example 
 * 
 * const data = [
        ["Vaughan", "Vaughan"],
        ["SPLICE", "SPLICE"],
        ["TNS", "TNS"],
        ["EGIS", "EGIS"],
        ["CMTI", "CMTI"],
        ["Durham", "Durham"],
        ["Brantford", "Brantford"],
        ["NEXCO", "NEXCO"],
        ["Mota-Engil", "Mota-Engil"],
        ["ESRI-Italy", "ESRI-Italy"],
        ["Greeley", "Greeley"],
        ["Centennial", "Centennial"],
        ["Coppell", "Coppell"],
        ["Quinte_West", "Quinte West"],
        ["RanchoNexo", "RanchoNexo"],
        ["Saugeen_Shores", "Saugeen Shores"],
        ["Maple_Ridge", "Maple Ridge"],
        ["Applus+_Ventures", "Applus Ventures"],
    ];

    data.forEach((d) => {
        const [nameInDb, displayName] = d;
        const newName = remapClientName(nameInDb, "-");
        const isEqual = newName === displayName;
        if (isEqual) {
            console.log(
            `TRUE: [value, expectedValue]: [${nameInDb} converted to ${newName} === ${displayName}]`
            );
        } else {
            throw new Error(
            `FALSE: [value, expectedValue]: [${nameInDb} converted to ${newName} !== ${displayName}]`
            );
        }
    });
 */
export const remapClientName = (value) => {
  // regex without hypyen
  const sepRegex = /[`!@#$%^&*()_+=[\]{};':"\\|,.<>\/?~]/;
  const result = replaceSpecialChars(value, " ", sepRegex);
  // deal with consecutive spaces
  // https://stackoverflow.com/questions/31845255/javascript-get-index-of-first-special-regex-character-in-a-string
  return result.replace(/\s\s+/g, " ");
};
