/**
 * @description get difference between two objects
 * @param {object} oldObj 
 * @param {object} newObj 
 * 
 * @example 
    const target = {
        name:"lucien",
        age:20
    }

    const newObject = {
        name:"lucien",
        age:30, // modified 
        weight: 40 // added
    }

    // const diff = {age: 30, weight: 40}
    const diff = getDiffBetweenObjects(target, newObject)
 * @returns {Object | null} a valid object if there is difference between the 2nd object and the 1st object, null otherwise
 */
export const getDiffBetweenObjects = (oldObj, newObj) => {
  const diff = {};
  for (const key in newObj) {
    if (Object.hasOwnProperty.call(newObj, key)) {
      const element = newObj[key];
      const targetElement = oldObj[key];

      if (JSON.stringify(element) !== JSON.stringify(targetElement)) {
        diff[key] = element;
      }
    }
  }
  if (Object.keys(diff) === 0) return null;
  return diff;
};

/**
 *
 * @param {Array} arr
 * @param {number} n
 * @returns {Array}
 * 
 * @example
 const example = [1, 2, 3, 4, 5, 6, 7]
 cosnt result = splitIntoChunks(example, 3) // [[1, 2, 3], [4, 5, 6], [7]] 
 */
export const splitIntoChunks = (arr, n) => {
  if (n < 0) {
    return arr;
  }
  const copy = JSON.parse(JSON.stringify(arr));

  const result = [];
  for (let i = 0; i < copy.length; i = i + n) {
    const temp = arr.slice(i, i + n);
    result.push(temp);
  }
  return result;
};

/**
 * @summary sort an array of objects by values corresponding to the given key
 * @param {[object]} obj
 * @param {string} key value, object[key], would be used to sort
 * @param {boolean} [acc]
 * @returns {[object]} sorted objects
 */
export const sortObjectByField = (obj, key, acc = true) => {
  obj.sort((a, b) => {
    const fieldA = a[key];
    const fieldB = b[key];
    if (fieldA < fieldB) {
      return acc ? -1 : 1;
    } else if (fieldA > fieldB) {
      return acc ? 1 : -1;
    }
    return 0;
  });

  return obj;
};

export const sortObjectByValueField = (obj, key, acc = true) => {
  const copy = JSON.parse(JSON.stringify(obj));

  const values = Object.values(copy);
  values.sort((a, b) => {
    const fieldA = a[key];
    const fieldB = b[key];
    if (fieldA < fieldB) {
      return acc ? -1 : 1;
    } else if (fieldA > fieldB) {
      return acc ? 1 : -1;
    }
    return 0;
  });

  const sortedObject = values.reduce((acc, ele) => {
    acc[ele.key] = ele;
    return acc;
  }, {});
  return sortedObject;
};

/**
 * sort object by key name
 * @param {object} obj
 * @returns sorted object
 * @example 
 *  const data0 = {
        value1: 0,
        value0: 1,
        value2: 2
    }
    sorObjectKeys(data0)
    {
      "value0": 1,
      "value1": 0,
      "value2": 2
    } 
    const result1 = sorObjectKeys(data, false)
    sorObjectKeys(data1)
    {
      "value2": 2,
      "value1": 0,
      "value0": 1
    } 
 */
export function sorObjectKeys(obj, asc = true) {
  let keys = Object.keys(obj).sort();
  if (!asc) {
    keys.reverse();
  }
  return keys.reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});
}
