import { MenuItem, Select } from "@material-ui/core";
import { SUPPORTED_LANG } from "../../config/i18n";
import { useTranslation } from "react-i18next";

export default function LanguaugeSelector(props) {
  const { i18n } = useTranslation();
  return (
    <Select
      label="lang"
      value={i18n.language}
      onChange={(ent) => i18n.changeLanguage(ent.target.value)}
    >
      {SUPPORTED_LANG.map((lng, index) => (
        <MenuItem key={index} value={lng.value}>
          {lng.label}
        </MenuItem>
      ))}
    </Select>
  );
}
