import React, { useCallback, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import FilterFields from "./FilterFields";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { KEYS } from "./translation";
import {
  sortObjectByField,
  sortObjectByValueField,
} from "utils/data/utilsObject";
import { DEFAULT_OPERANTS, DEFAULT_VALUE_TYPES } from "./statics";
import useAuth from "hook/useAuth";

/**
 *
 * A component that handles the CRUD of fitler ui elements.
 *
 * @param {object} props
 * @param {object} props.timezone timezone use to convert timestamp
 * @param {[DynamonDBFilterType]} props.filterFields array of ALL filter type objects to render a single filter element.
 * @param {() => {}} props.onFilter callback function to handle when a new fitler ui element is added by user.
 * @param {[DynamonDBFilter]} props.searchParams array of SELECTED filter type object to render all selected fitler elements.
 * @returns
 */
export default function DynamonDBFilter(props) {
  const { timezone, filterFields, onFilter, searchParams } = props;
  const { t } = useTranslation();

  const { defectCategories } = useAuth()

  /**@type {[[DynamonDBFilter]]} */
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [unselectedFilters, setUnselectedFilters] = useState([]);


  const getValidOptionsOnCategoryUpdate = (workOrderCategory, workOrderField) => {
    const updatedWOTypeField = { ...workOrderField }
    if (workOrderCategory && workOrderCategory?.value0 && workOrderCategory?.value0.length > 0) {
      const defectTypes = workOrderCategory.value0.reduce((acc, category) => {
        const defectTypes = defectCategories.get(category)
        if (defectTypes) {
          acc.push(...defectTypes)
        }
        return acc
      }, []).map(defect => defect.name)
      //  Have to use orignal filter options otherwise translation does not apply.
      updatedWOTypeField.options = filterFields.find((f) => f.key === "w_o_type").options.filter(option=> defectTypes.includes(option.key))
      updatedWOTypeField.value0 = (workOrderField.value0) ? workOrderField.value0.filter(defect => defectTypes.indexOf(defect) > -1) : ''
    } else {
      updatedWOTypeField.options = filterFields.find((f) => f.key === "w_o_type").options
    }
    return updatedWOTypeField
  }

  /**
   * callback passed to FilterFields element, which passes updated filter object to this element.
   */
  const handleFilterUpdate = useCallback(
    (filterObject) => {
      const { id } = filterObject;
      const index = selectedFilters.findIndex((ele) => ele.id === id);

      const workOrderCategoryIndex = selectedFilters.findIndex((f) => f.key === "category")
      const workOrderFieldIndex = selectedFilters.findIndex((f) => f.key === "w_o_type")
      const workOrderField = (filterObject.key === "w_o_type") ? filterObject : selectedFilters[workOrderFieldIndex]
      const workOrderCategory = (filterObject.key === "category") ? filterObject : selectedFilters[workOrderCategoryIndex]

      let updatedWOTypeField = null
      if (workOrderField) {
        //  Update model value for options otherwise disconnect with UI and model
        updatedWOTypeField = getValidOptionsOnCategoryUpdate(workOrderCategory, workOrderField)
      }

      setSelectedFilters((prev) => {
        if (updatedWOTypeField !== null) {
          // If work order field is being updated or added.
          if (filterObject.key === "w_o_type") {
            filterObject = updatedWOTypeField
          }
          // If work category field gets updated and work order field exists.
          else if (workOrderFieldIndex > -1) {
            prev[workOrderFieldIndex] = updatedWOTypeField
          }
        }

        prev[index] = { ...filterObject };
        return [...prev];
      });
    },
    [selectedFilters]
  );

  // listen passed in searchParams to update the filters so that
  // their ui element will be rendered.
  useEffect(() => {
    if (Array.isArray(searchParams) && searchParams.length > 0) {
      setSelectedFilters(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    const currentFilterKeys = selectedFilters.map((filter) => filter.key);
    const remainFilters = filterFields.filter(
      (filter) => currentFilterKeys.indexOf(filter.key) < 0
    );
    setUnselectedFilters(remainFilters);
  }, [selectedFilters, filterFields]);

  return (
    <React.Fragment>
      <Typography
        style={{
          marginTop: "0.5rem",
        }}
        variant="h5"
      >
        {t("common:FILTERS")}
      </Typography>
      {selectedFilters
        .filter((f) => f.key !== "region")
        .map((ele) => {
          // operants attached to each fitlerField
          const target = filterFields.find((a) => a.key === ele.key);
          const { operants, options } = target;
          if (ele.type === DEFAULT_VALUE_TYPES.SELECT) {
            const workOrderCategory = selectedFilters.find((f) => f.key === "category")
            if (ele.key === 'w_o_type' && workOrderCategory) {
              //  Set initial value for options if catergory is set.
              const { options } = getValidOptionsOnCategoryUpdate(workOrderCategory, ele)
              ele['options'] = options
            } else {
              ele['options'] = options
            }
          }

          // options for current selected, contains itself + those unselected
          let newFilters = [target, ...unselectedFilters];
          newFilters = sortObjectByField(newFilters, "key");
          const sortedOperants = sortObjectByValueField(operants, "title");
          return (
            <FilterFields
              key={ele.id}
              filterObj={ele}
              timezone={timezone}
              onValueUpdate={handleFilterUpdate}
              onRemove={() => {
                const filtered = selectedFilters.filter((m) => m.id !== ele.id);
                const workOrderField = filtered.find((f) => f.key === "w_o_type")
                //  Set w_o_type back to initial if catergory is removed.
                if (ele.key === 'category' && workOrderField){
                  workOrderField.options = filterFields.find((f) => f.key === "w_o_type").options
                }

                setSelectedFilters(filtered);
              }}
              filterFields={newFilters}
              operants={sortedOperants}
              isRemovable={!(selectedFilters.length > 1)} // default one, region, plus this one (iteself)
            />
          );
        })}
      <Button
        color="primary"
        style={{
          marginTop: 8,
        }}
        variant="outlined"
        disabled={unselectedFilters.length < 1} // when no more available filters to be selected
        onClick={() => {
          const { key, type } = unselectedFilters[0];
          const isDateType = type === DEFAULT_VALUE_TYPES.DATE;
          const filter = {
            key: key,
            operant: isDateType ? DEFAULT_OPERANTS.EQ : DEFAULT_OPERANTS.IN,
            type: type,
            value0: null,
            renderValue0: null, // for date picker, passing null will let it have empty input, while undefine will cause it to define a value for us, which defaults to current date.
            value1: null,
            renderValue1: null,
          };
          setSelectedFilters((pre) =>
            pre.concat({ id: new Date().getTime(), ...filter })
          );
        }}
      >
        {t(KEYS.GENERAL_UI.ADD)}
      </Button>
      <Button
        color="primary"
        style={{
          marginTop: 8,
          display: "block",
        }}
        variant="contained"
        onClick={() => {
          onFilter(selectedFilters);
        }}
      >
        {t(KEYS.GENERAL_UI.FILTER)}
      </Button>
    </React.Fragment>
  );
}
