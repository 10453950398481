// Dashboard Module

// Components for use with all modules
import React, { Component } from "react";
import { AuthContext } from "../../components/AuthContext";
import {
  copyElementToClipboard,
  savePdf,
  printPdf,
} from "../../utils/sharetools";
import { IRISGO_DOWNLOAD_CSV_URL } from "../../utils/env/urls";
import loading_icon from "../shared_assets/loading.gif";

// Api for getting label details
import { getLabel } from "../../utils/requests";

// Date picker component
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Sub-components
import DailyAlert from "./components/dailyalert/DailyAlert";
import ViewIssue from "./components/viewissue/ViewIssue";
import RenderForPdf from "./components/renderforpdf/RenderForPdf";

// Styles
import "./London_DailyAlerts.css";
import download_icon from "../shared_assets/download.png";
import { TextField } from "@material-ui/core";

// London Daily Alerts - Component class

export const csvToArray = (str, delimiter = ",") => {
  let array = str.split("\n").map(function (line) {
    return line.split(delimiter);
  });

  return array;
};
class London_DailyAlerts extends Component {
  static contextType = AuthContext;

  constructor() {
    super();
    this.state = {
      csv: null,
      focus: null,
      updateCount: 0,
      START_DATE: new Date(), // absolute min date for start date picker
      END_DATE: new Date(), // absolute max date for end date picker
      startDate: new Date(),
      endDate: new Date(),
      filter: "EM",
      searchKeyWords: "",
    };

    // current issue being viewed
    this.focusItem = React.createRef();

    // current issue being printed
    this.printIndex = 0;

    // bind functions for use as props in elements
    this.chooseFocus = this.chooseFocus.bind(this);
    this.forceUpdate = this.forceUpdate.bind(this);
    this.shareItem = this.shareItem.bind(this);
    this.printItem = this.printItem.bind(this);
    this.downloadItem = this.downloadItem.bind(this);
  }

  // initial start and end dates to today (this will change during loading)
  setStartDate(date) {
    this.setState({ startDate: date });
  }
  setEndDate(date) {
    this.setState({ endDate: date });
  }

  // load the csv file
  loadParamsAsync = async () => {
    // get the focus item specified in the URL
    let params = new URLSearchParams(window.location.search);
    var id = params.get("id");

    // download the csv file to be used as a data source
    fetch(`${IRISGO_DOWNLOAD_CSV_URL}?key=${this.module_name}`)
      .then((r) => r.text())
      .then(async (text) => {
        // convert the csv to JSON
        // var arr = csvToJson.fieldDelimiter(",").csvStringToJson(text);
        var arr = csvToArray(text, ",");

        // looking for the max date in the CSV file, and getting the resolution of each item
        var maxDateMillis = new Date().getTime();
        var minDateMillis = maxDateMillis;

        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];
          const timeInMillis = Date.parse(item.date_collected);
          item.TrueDate = timeInMillis;
          if (timeInMillis > maxDateMillis) {
            maxDateMillis = timeInMillis;
          }
          if (timeInMillis < minDateMillis) {
            minDateMillis = timeInMillis;
          }
          await getLabel(item.label_id, this.contextToken)
            .then((result) => {
              if (result?.resolution != "" && result?.resolution != null) {
                item.Resolution = result.resolution;
                item.Comments = result.comments;
              }
            })
            .catch((error) => {
              // alert(`Error getting label: ${error}`);
              item.label_id = "";
              this.forceUpdate();
            });
        }

        // set the start and end dates to the max date in the CSV file
        this.setState({
          csv: arr,
          START_DATE: minDateMillis,
          startDate: new Date(minDateMillis),
          END_DATE: maxDateMillis,
          endDate: new Date(maxDateMillis),
        });

        // if the id param was set, open to that item
        if (id) {
          let index = arr.findIndex((item) => item.label_id == id);
          if (index < 0 || index == null) {
            alert("This item does not exist");
          } else {
            this.setState({ focus: index });
          }
        }
      })

      .catch((e) => {
        alert("An error occured: " + e);
      });
  };

  // code to run after component is mounted
  componentDidMount() {
    // get the auth context variables
    const {
      contextUserID,
      contextToken,
      contextPreference,
      contextCity,
      setContextPreference,
      contextWidgetList,
      contextAuthStr,
    } = this.context;

    // store into local variables
    this.contextUserID = contextUserID;
    this.contextToken = contextToken;
    this.contextPreference = contextPreference;
    this.contextCity = contextCity;
    this.setContextPreference = setContextPreference;
    this.contextWidgetList = contextWidgetList;
    this.contextAuthStr = contextAuthStr;
    this.module_name = this.contextAuthStr.module_name;
    this.first_name = this.contextAuthStr.first_name;
    this.last_name = this.contextAuthStr.last_name;
    this.initials =
      this.contextAuthStr.first_name.substring(0, 1) +
      this.contextAuthStr.last_name.substring(0, 1);

    // load the CSV file
    this.loadParamsAsync();
  }

  // set the focus on the indicated item
  chooseFocus = (item) => {
    this.setState({ focus: item });
  };

  // create an email containing details from the selected item
  shareItem = (index) => {
    let item = this.state.csv[index];
    let url = "mailto:";
    url +=
      "?subject=" +
      encodeURIComponent(
        "Issue #" +
          item.datapoint_id +
          " - Class " +
          item.mms_class +
          " - " +
          item.mms_damage_typ
      );
    let body = "";
    body +=
      encodeURIComponent("Issue #" + item.datapoint_id) + "%0D%0A" + "%0D%0A";
    body +=
      encodeURIComponent("Damage Type: " + item.mms_damage_typ) + "%0D%0A";
    body += encodeURIComponent("Class " + item.mms_class + " road") + "%0D%0A";
    body +=
      encodeURIComponent(
        ": " + item.date_collected + " at " + item.timestamp
      ) + "%0D%0A";
    body += encodeURIComponent("Repair By " + item.mms_due_display) + "%0D%0A";
    body +=
      encodeURIComponent("Service Window: " + item.mms_due_display) + "%0D%0A";
    body += encodeURIComponent("Location: " + item.Address) + "%0D%0A";
    body += encodeURIComponent("GPS: " + item.long + "," + item.lat) + "%0D%0A";
    body += encodeURIComponent("Device: " + item.device) + "%0D%0A" + "%0D%0A";
    body += encodeURIComponent("Comments: ") + "%0D%0A";

    url += "&body=" + body;
    window.open(url, "_blank");
  };

  // download the indicated issue as a pdf file
  downloadItem = (item) => {
    this.printIndex = item;
    let contents = RenderForPdf(
      this.state.csv[this.printIndex],
      this.contextUserID,
      this.contextToken
    );
    savePdf(contents, "alert.pdf");
  };

  downloadAllItems = () => {
    let contents = RenderForPdf(
      // this.state.csv,
      this.getFilteredItems(),
      this.contextUserID,
      this.contextToken
    );
    savePdf(contents, "alert.pdf");
  };

  // print the indicated issue as a pdf file
  printItem = (item) => {
    this.printIndex = item;
    let contents = RenderForPdf(
      this.state.csv[this.printIndex],
      this.contextUserID,
      this.contextToken
    );
    printPdf(contents);
  };

  // force a refresh
  forceUpdate = () => {
    this.setState({ updateCount: this.state.updateCount + 1 });
  };

  /**
   * @summary filtered out the cvs items based on key in search data (damage type name) and selected issue type
   * @returns {[object]}
   */
  getFilteredItems = () => {
    let { csv: items, searchKeyWords } = this.state;

    if (searchKeyWords) {
      items = items.filter((item) =>
        item.mms_damage_typ
          .toLowerCase()
          .startsWith(this.state.searchKeyWords.toLowerCase())
      );
    }
    return items;
  };
  // render the component
  render() {
    // show loading screen until the csv file is fetched
    if (!this.state.csv) {
      return (
        <div className="loading-icon">
          <img src={loading_icon} />
        </div>
      );

      // if not issue is selected, display the list
    } else if (this.state.focus === null) {
      let items = this.getFilteredItems();
      return (
        <div className="module-wrapper">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div className="mms-header">MMS Patrol Report</div>
            <div
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "auto",
                  }}
                >
                  <div style={{ width: 50, marginBottom: 5 }}>Start:</div>
                  <div>
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={(date) => this.setStartDate(date)}
                      maxDate={this.state.endDate} // limitted by selected end date
                      minDate={this.state.START_DATE} // limited by absolute START DATE
                      // value={this.state.startDate}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "auto",
                  }}
                >
                  <div style={{ width: 50 }}>End:</div>
                  <div>
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={(date) => this.setEndDate(date)}
                      minDate={this.state.startDate} // limited by selected start date
                      maxDate={this.state.END_DATE} // limited by absolute END DATE
                      // value={this.state.endDate}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ textAlign: "right", marginRight: 15 }}>
              <TextField
                placeholder="Search"
                type="search"
                onChange={(event) =>
                  this.setState({ searchKeyWords: event.target.value })
                }
              />
              <select
                value={this.state.filter}
                style={{ fontSize: "1.25em" }}
                onChange={(event) => {
                  this.setState({ filter: event.target.value });
                }}
              >
                <option value="EM">Open Issues</option>
                <option value="REV">Resolved Issues</option>
                <option value="IG">Ignored Issues</option>
                <option value="all">All Issues</option>
              </select>
            </div>
            <div
              style={{
                fontFamily: "Lato, sans-serif",
                fontSize: " 1.1em",
                textAlign: "right",
                margin: "0 5px",
                paddingRight: "5px",
                cursor: "pointer",
              }}
              onClick={this.downloadAllItems}
            >
              Download All
              <img
                src={download_icon}
                style={{ height: 20, paddingRight: 8, marginLeft: "0.5rem" }}
              />
            </div>
          </div>
          {/* mms_damage_typ */}
          <div className="mms-report-wrapper">
            <div style={{ display: "flex", flexDirection: "column" }}>
              {items.map((item, index) => {
                if (item.label_id === "" || item.mms_class === "0") return null;
                // no label id
                else if (
                  item.TrueDate < this.state.startDate ||
                  item.TrueDate > this.state.endDate
                )
                  return null;
                else if (
                  !(item.Resolution === "" && this.state.filter === "EM") &&
                  item.Resolution !== this.state.filter &&
                  this.state.filter !== "all"
                )
                  return null;
                return (
                  <DailyAlert
                    key={index}
                    Image={item.image + this.contextToken}
                    Data={item}
                    UpdateCount={this.state.updateCount}
                    ChooseFocus={this.chooseFocus}
                    ShareItem={this.shareItem}
                    PrintItem={this.printItem}
                    DownloadItem={this.downloadItem}
                    ForceUpdate={this.forceUpdate}
                    Index={index}
                  />
                );
              })}
            </div>
          </div>
        </div>
      );

      // if an issue is selected, view that issue
    } else {
      let item = this.state.csv[this.state.focus];
      return (
        <ViewIssue
          Issue={item}
          UserID={this.contextUserID}
          ChooseFocus={this.chooseFocus}
          ContextToken={this.contextToken}
          Initials={this.initials}
        />
      );
    }
  }
}

export default London_DailyAlerts;
