/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Jan 11, 2021
 *
 * Description: This component is designed for an administrator who can
 * create a user with user's first name, last name, email address, and assign
 * at least one widget for the new user to use on his own dashboard.
 */

import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { DialogTitle, TextField } from "@material-ui/core";
import { createNewUser } from "../../utils/requests";
import { AuthContext } from "../AuthContext";

const FIRST_NAME = "firstName";
const LAST_NAME = "lastName";
const EMAIL = "email";

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
}
const useStyles = makeStyles((theme) => ({
  root: {
    height: "530px",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),

    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(100vh - 64px)",
  },
  textField: {
    margin: "0.5rem",
  },
  settingRow: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  saveButton: {
    marginTop: "30px",
  },
  weatherOptions: {
    width: "215px",
  },
}));

export default function CreateNewUser(props) {
  const { contextToken, contextCity } = useContext(AuthContext);
  const classes = useStyles();
  const {
    existedUsers,
    handleCreateUser: onHandleCreateUser,
    openModal,
  } = props;
  const [widgetList, setWidgetList] = useState(props.widgetList);
  const [userInfo, setUserInfo] = useState({
    [FIRST_NAME]: "",
    [LAST_NAME]: "",
    [EMAIL]: "",
  });

  // reference for a setTimeOut function
  // see useEffect which consider duplicate user name
  // for more details
  const timeoutFunc = useRef(null);

  /**
   * @summary a callback function pass to Input elements
   *
   * @description a callback function pass to Input element, including
   * first name, last name and email input
   *
   * @param {String} key property name for userInfo object
   * @param {String} value value name for userInfo[key]
   */
  const updateUserInfo = (key, value) => {
    const updateUser = { ...userInfo };
    updateUser[key] = value;
    setUserInfo(updateUser);
  };

  /**
   * @summary a callback function passed to onClick property of the create button
   */
  const onCreate = () => {
    const { firstName, lastName } = userInfo;

    const excludedWidgets = widgetList.filter((widget) => !widget.enabled);
    const excludedWidgetIds = [];
    for (const widget of excludedWidgets) {
      excludedWidgetIds.push(widget.id);
    }
    const data = {
      username: `${firstName}_${lastName}`,
      city: contextCity.id,
      email: userInfo.email,
      // layout: excludedWidgetIds.toString(),
    };

    for (let i = 0; i < excludedWidgetIds.length; i++) {
      data[`disabled_widgets[${i}]widget_id`] = excludedWidgetIds[i];
    }

    createNewUser(data, contextToken)
      .then((result) => {
        if (result.success) {
          const newUser = {
            userName: `${userInfo.firstName}_${userInfo.lastName}`,
            enabled: true,
            modules: widgetList,
          };
          onHandleCreateUser(newUser);
        }
      })
      .catch((error) => {
        alert(`Error in creating user: ${error.message}`);
      });
  };

  /**
   * @summary a callback function passed to onClick property of the create button
   */
  const onCancel = () => {
    onHandleCreateUser();
  };

  /**
   * @summary a callback function pass to the onChange property of the Checkbox component
   *
   * @description Each widget of the widgetList corresponses to a single checkbox element,
   * when on of them is toggle, ths callback method should be called with the index of the
   * widget that has been toggled.
   *
   * The checked state of the checkbox component determines
   * whether a widget is enabled for the upcoming user or not.
   *
   * @param {Event} event
   * @param {Number} index index of the widget within the widgetList
   */
  const toggleModule = (event, index) => {
    const checked = event.target.checked;
    const updatedModuleList = [...widgetList];
    updatedModuleList[index].enabled = checked;
    setWidgetList(updatedModuleList);
  };

  /**
   * @summary one of determinant that toggle the disabled state of the create button
   *
   * @description Definition of valid user input is
   * the input first name, last name and email address are not empty AND at least
   * one widget among the widgetList is enabled.
   */
  const areUserInputInvalid = () => {
    if (userInfo === null) return false;
    const result0 = widgetList.filter(
      (widget) => widget.enabled && widget.enabled === true
    );

    const { firstName, lastName, email } = userInfo;

    const result1 =
      firstName &&
      lastName &&
      email &&
      firstName.trim().length !== 0 &&
      lastName.trim().length !== 0 &&
      validateEmail(email);
    return result0.length > 0 && result1;
  };

  /**
   * set "enabled" proerty to false for each element of the
   * passed widgetList so that all checkboxes would be uncheced
   * when this compoent is mounted
   */
  useEffect(() => {
    setWidgetList(
      props.widgetList.map((widget) => ({ ...widget, enabled: false }))
    );
  }, [props.widgetList]);

  /**
   * @description
   * As user(administrator) typing in a new user name, check the given name against the given
   * existedUsers array.
   *
   * Each check triggerred by a key event would have 500ms delay. If an imcoming key event is fired
   * less than 500ms, the previous timeout function would be clear and a new timeout function would
   * be arranged due to this new key event.
   *
   * This mechanism improves UX by fixing an issue, say user hit a duplicate name and an auto generate one is assigned
   * to him. If he decided to modify the auto generated name by hitting backspace, it would not work properly
   * if the modified name is also found is a duplicate, the same auto generated name would be assigned.
   *
   * In this case, user has to select all the text and remove all of them together.
   */
  useEffect(() => {
    // clear previous setTimeout function
    clearTimeout(timeoutFunc.current);
    timeoutFunc.current = setTimeout(() => {
      if (userInfo) {
        const { firstName, lastName } = userInfo;
        const name = firstName + "_" + lastName;
        const result = existedUsers.indexOf(name.toLowerCase()) > -1;
        if (result) {
          let i = 0;
          let autoGenerateName;
          let isAutoGenerateNameStillExisted;
          // key generating a user name by appending an increasing index
          // next to the first name until the genreated name is found
          // completely new.
          do {
            autoGenerateName = firstName + i + "_" + lastName;
            isAutoGenerateNameStillExisted =
              existedUsers.indexOf(autoGenerateName.toLowerCase()) > -1;
            i++;
          } while (isAutoGenerateNameStillExisted);
          openModal(
            "User name conflicts",
            `<b>${name}</b> has been assigned<br /> auto generated user name <b style = "color: red;font-style: italic">${autoGenerateName}</b> is assigned instead`
          );
          const [fName, lName] = autoGenerateName.split("_");
          setUserInfo({ ...userInfo, firstName: fName, lastName: lName });
        }
      }
    }, 500);
  }, [userInfo, existedUsers, setUserInfo]);

  return (
    <div className={classes.root}>
      {widgetList && (
        <>
          <DialogTitle>Create user</DialogTitle>
          <DialogContent>
            <DialogContent dividers>
              <div>
                <TextField
                  autoFocus
                  className={classes.textField}
                  placeholder="First name"
                  onChange={(event) =>
                    updateUserInfo(FIRST_NAME, event.target.value)
                  }
                  value={userInfo[FIRST_NAME]}
                />
              </div>
              <div>
                <TextField
                  className={classes.textField}
                  placeholder="Last name"
                  value={userInfo[LAST_NAME]}
                  onChange={(event) =>
                    updateUserInfo(LAST_NAME, event.target.value)
                  }
                />
              </div>
              <div>
                <TextField
                  className={classes.textField}
                  placeholder="Email"
                  InputProps
                  onChange={(event) =>
                    updateUserInfo(EMAIL, event.target.value)
                  }
                />
              </div>
            </DialogContent>
            {widgetList.map((widget, index) => (
              <Grid container key={index} className={classes.settingRow}>
                <Grid item>
                  <Typography variant="subtitle2">{widget.title}</Typography>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={widget.enabled}
                        onChange={(event) => toggleModule(event, index, "as")}
                        color="primary"
                      />
                    }
                    label={<Typography variant="subtitle2">Enable</Typography>}
                  />
                </Grid>
              </Grid>
            ))}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={onCancel}
              variant="outlined"
              color="secondary"
              className={classes.saveButton}
            >
              Cancel
            </Button>
            <Button
              onClick={onCreate}
              variant="contained"
              color="primary"
              className={classes.saveButton}
              disabled={!areUserInputInvalid()}
            >
              Create
            </Button>
          </DialogActions>
        </>
      )}
    </div>
  );
}

/**
 * Change Log:
 *
 * Change Date: Jan 11, 2021
 *
 * Description: component is created and add documentation.
 */

/**
 * Change Log:
 *
 * Change Date: Jan 25, 2021
 *
 * Description:
 * update: change the duplicated user name mechanism.
 * Before, when a user name is found duplicate, show warning text on the component title,
 * indicating that user should choose another name.
 *
 * Now, a popup would show and indicate that the given name has existed, an auto generated
 * user name would replace the given user name as a valid user name for the upcoming user.
 */
