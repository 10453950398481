import React from "react";
import { MainContextProvider } from "./MainContext";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalUI from "irisUI/global/GlobalUI";
import IRISThemeProvider from "utils/theme/theme";
import { AuthProvider } from "hook/useAuth";

import GraphQLContextProvider from "libs/AppoloClient/context/GraphQLContext";

function RootProvider(props) {
  return (
    <IRISThemeProvider>
      <GlobalUI>
        <MainContextProvider>
          <Router>
            {/* AuthProvider uses both useHistory and useLocation, it MUST be wrapped by BrowserRouter */}
            <AuthProvider>
              <GraphQLContextProvider>{props.children}</GraphQLContextProvider>
            </AuthProvider>
          </Router>
        </MainContextProvider>
      </GlobalUI>
    </IRISThemeProvider>
  );
}

export default RootProvider;
