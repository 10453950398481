import React, { useEffect, useRef, useState } from "react";
import { loadModules, setDefaultOptions } from "esri-loader";
import { pdf } from "@react-pdf/renderer";
import RoadSectionReport from "../../reports/RoadSectionReport";
import { Button } from "@material-ui/core";
import { getRoadPatrolledListReport } from "../../utils/apis/reportAPI/reportAPI";
import PatrolComplianceSummary from "../../reports/PatrolComplianceSummary";
import PatrollSummary from "../../reports/PatrolSummary";
import RoadPatrolledList from "../../reports/RoadPatrolledList";
import withLoader from "../../HOC/withLoader";
import DateRangePicker from "../../irisUI/DatePicker/DateRangePicker";
import DateUtils from "../../utils/dateUtils/DateUtils";
import {
  getSearchParamObject,
  getSearchQueryFromObject,
} from "routes/data/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { getWorkOrder } from "api/workorderAPI/workorderAPI";
import useAuth, { getAWSTOkenAsync, getAWSToken } from "hook/useAuth";
import moment from "moment";
import { useAlert } from "irisUI/global/alert/context/AlertContext";
import { getListWorkOrderFilter } from "libs/AppoloClient/apis/workorder/hooks/useListWorkOrders/utils";
import { queryAll } from "libs/AppoloClient/utils";
import {
  COMPLIANCE_SUMMARY_FUNCTION_NAME,
  useLazyListComplianceSummary,
} from "libs/AppoloClient/apis/complianceSummary/roadSectionReport/hooks/useListComplianceSummary";
import { getRoadSectionData, getWorkOrderData } from "utils/requests";
import { sortObjectByField } from "utils/data/utilsObject";
setDefaultOptions({ version: "4.20" });

const REPORT_TYPES = {
  COMPLIANCE_SUMMARY_REPORT: "compliance_summary_report",
  ROADS_PATROLLED_REPORT: "roads_patrolled_report",
  ROAD_SECTION_REPORT: "road_section_report",
};

/**
 * predefined keys, used on the online hosted feature layer, by the data team
 */
const RENDER_FEATURE_ATTRIBUTES = {
  GIS_ID: "GIS_ID",
  FULL_NAME: "FULLNAME",
  CLASS: "ROADCLASS",
  WARD: "Ward",
  FROM: "FROMRIGHT",
  TO: "TORIGHT",
  BLOCK: "BLOCK",
};
function getReportFields(feature) {
  return {
    "GIS ID": feature.graphic.attributes[RENDER_FEATURE_ATTRIBUTES.GIS_ID],
    "Road Name":
      feature.graphic.attributes[RENDER_FEATURE_ATTRIBUTES.FULL_NAME], // good
    Class: feature.graphic.attributes[RENDER_FEATURE_ATTRIBUTES.CLASS],
    Ward: feature.graphic.attributes[RENDER_FEATURE_ATTRIBUTES.WARD],
    From: feature.graphic.attributes[RENDER_FEATURE_ATTRIBUTES.FROM], // good
    To: feature.graphic.attributes[RENDER_FEATURE_ATTRIBUTES.TO], // good
    Block: feature.graphic.attributes[RENDER_FEATURE_ATTRIBUTES.BLOCK], // good
  };
}

/**
 * get the where clause for the feature layer to query a feature
 *
 * @param {string | number} gisId
 * @returns
 */
const getIdWhereClause = (gisId) => {
  const paraType = typeof gisId;
  if (paraType === "string") {
    // ie: 'a123'
    if (isNaN(gisId)) {
      return `GIS_ID LIKE '${gisId}'`;
    } else {
      // ie: '123'
      return `GIS_ID = ${gisId}`;
    }
  } else if (paraType === "number") {
    return `GIS_ID = ${gisId}`;
  }
  return "undetermined gis_id";
};
const ReportPage = React.memo((props) => {
  const { setShowLoader: isLoading } = props;

  const {
    pageConfig: {
      patrolReport: { layer_url: mapLink, reports },
      workorder: { queryItems },
      city: { timezone, name, logo_url, clientName },
    },
  } = useAuth();

  const complianceReport = reports.find(
    (report) => report.key === REPORT_TYPES.COMPLIANCE_SUMMARY_REPORT
  );
  const roadsPatrolledReport = reports.find(
    (report) => report.key === REPORT_TYPES.ROADS_PATROLLED_REPORT
  );

  const { showAlert } = useAlert();

  const {
    allData: allComplianceData,
    queryResult: [fetchComplianceReport],
  } = useLazyListComplianceSummary(timezone);

  const [datesState, setDatesState] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  /** state object that contains params for calling api gate which would check whehter report of the given params is available  */
  const [awaitRemotePDF, setAwaitAWSObj] = useState(null);

  const {
    go,
    push,
    location: { pathname, search, state },
  } = useHistory();

  /*================================================== refs START ================================================== */
  const mapContainerRef = useRef();
  const selectedFeatureRef = useRef();
  const loadedLayerRef = useRef();
  const mapViewRef = useRef();
  const datesRef = useRef({
    startDate: new Date(),
    endDate: new Date(),
  });

  const dateRangePickerRef = useRef();

  const btns = useRef();

  const fullScreenshotRef = useRef();
  /*==================================================  refs END  ================================================== */

  let highlightCallback;

  const exportReportAction = {
    title: "Export report",
    id: "export-report",
  };

  /**
   *
   * @summary converts react-pdf renendered dom element to data and show pdf content on a new tab
   * @param {} pdfCOntent react-pdf renendered dom element
   */
  const showPdfContent = async (pdfCOntent) => {
    const data = await pdf(pdfCOntent).toBlob();
    window.open(URL.createObjectURL(data), "_blank");
  };

  const preparePatrolComlianceSummaryPdf = async () => {
    const { startDate, endDate } = datesRef.current;

    const sd = DateUtils.getyyyyMMdd(startDate);
    const ed = DateUtils.getyyyyMMdd(endDate);

    try {
      isLoading(true);

      let items = await queryAll(
        fetchComplianceReport,
        fetchComplianceReport,
        COMPLIANCE_SUMMARY_FUNCTION_NAME,
        {
          date: {
            between: [sd, ed],
          },
        }
      );

      if (items.length > 0) {
        const content = (
          <PatrolComplianceSummary
            data={items}
            dateRange={{ startDate: sd, endDate: ed }}
            imageUrl={fullScreenshotRef.current}
          />
        );
        await showPdfContent(content);
      } else {
        alert("No data");
      }
    } catch (error) {
      isLoading(false);
      const message = error.message || "UNKNOWN ERROR";
      showAlert(message);
    } finally {
      isLoading(false);
    }
  };

  // const preparePatrolSummary = async () => {
  //   const { startDate, endDate } = datesRef.current;

  //   const sd = DateUtils.getyyyyMMdd(startDate);
  //   const ed = DateUtils.getyyyyMMdd(endDate);

  //   try {
  //     isLoading(true);
  //     let items = [];
  //     let nextToken = "";
  //     do {
  //       const result = await getPatrolSummary(sd, ed, name, nextToken);
  //       items = items.concat(
  //         result?.data?.listRoadPatrolSummaries?.items || []
  //       );
  //       nextToken = result?.data?.listRoadPatrolSummaries?.nextToken;
  //     } while (nextToken);
  //     if (items.length > 0) {
  //       const content = (
  //         <PatrollSummary
  //           data={items}
  //           dateRange={{ startDate: sd, endDate: ed }}
  //         />
  //       );

  //       await showPdfContent(content);
  //     } else {
  //       alert("No data");
  //     }
  //   } catch (error) {
  //     isLoading(false);
  //   } finally {
  //     isLoading(false);
  //   }
  // };

  /**
   * @summary onclik callback function for Roads Patrolled button
   */
  const prepareRoadPatrolledList = async () => {
    const { startDate, endDate } = datesRef.current;

    const startDateUTC = moment(startDate).tz(timezone).startOf("day").toJSON();
    const endDateUTC = moment(endDate).tz(timezone).endOf("day").toJSON();
    try {
      isLoading(true);
      const response = await getRoadPatrolledListReport(
        name,
        timezone,
        startDateUTC,
        endDateUTC,
        false
      );

      const { is_ready: isReady, message, url, isPending } = response;
      if (url && isReady) {
        window.open(url);
      } else if (isPending) {
        // alert("Report is being generated, the process takes up to 2 minutes");
        setAwaitAWSObj({
          start_time: startDateUTC,
          end_time: endDateUTC,
        });
      } else {
        alert(message);
      }
    } catch (error) {
      isLoading(false);
    } finally {
      isLoading(false);
    }
  };

  useEffect(() => {
    // lazy load the required ArcGIS API for JavaScript modules and CSS

    const onMapViewClick = (event) => {
      if (highlightCallback) {
        highlightCallback.remove();
        highlightCallback = null;
      }
      mapViewRef.current.hitTest(event).then(function (response) {
        selectedFeatureRef.current = response.results[0];
        const gis_id = selectedFeatureRef?.current?.graphic?.attributes?.GIS_ID;
        updateUrlPath(gis_id);
      });
    };

    const loadReportPdf = (gisId) => {
      // alert(report);

      isLoading(true);
      mapViewRef.current
        .takeScreenshot()
        .then(async function (screenshot) {
          const { startDate, endDate } = datesRef.current;

          const [startDateUTC, endDateUTC] = DateUtils.momentGetUTCDateRange(
            startDate,
            endDate,
            timezone
          );

          const sd = DateUtils.getyyyyMMdd(startDate);
          const ed = DateUtils.getyyyyMMdd(endDate);
          let sectionInfo = getReportFields(selectedFeatureRef.current);

          const title = {};
          let items = [];
          try {
            isLoading(true);
            const token = await getAWSTOkenAsync();
            const params = {
              client_name: {
                operant: "eq",
                value0: name,
              },
              gis_id: {
                operant: "eq",
                value0: gisId,
              },
              start_time: {
                operant: "between",
                value0: startDateUTC,
                value1: endDateUTC,
              },
            };
            items = await getRoadSectionData(token, params);
            items = sortObjectByField(items, "date");
            items = items.map((ele) => {
              const copy = { ...ele };
              copy.start_time = DateUtils.momentTimezoneGetyyyyMMddhhmmss(
                copy.start_time,
                timezone
              );

              copy.end_time = DateUtils.momentTimezoneGetyyyyMMddhhmmss(
                copy.end_time,
                timezone
              );
              copy.weather_date = DateUtils.momentTimezoneGetyyyyMMddhhmmss(
                copy.weather_date,
                timezone
              );
              return copy;
            });

            const workOrders = await getWorkOrderData(
              token,
              {
                region: {
                  operant: "eq",
                  value0: name,
                },
                open_datetime: {
                  operant: "between",
                  value0: startDateUTC,
                  value1: endDateUTC,
                },
                gis_id: {
                  operant: "eq",
                  value0: gisId,
                },
              },
              timezone
            );

            if (items.length > 0) {
              const element = items[0];

              const { agency_id = "", material = "", region = "" } = element;
              title.Region = region;

              sectionInfo = { ...sectionInfo, ...title };

              const content = (
                <RoadSectionReport
                  cityName={clientName}
                  sectionInfo={sectionInfo}
                  mapImageSrc={screenshot.dataUrl}
                  dateRange={{
                    startDate: sd,
                    endDate: ed,
                  }}
                  data={items}
                  workOrderData={workOrders}
                  logo={logo_url}
                />
              );
              await showPdfContent(content);
            } else {
              alert("No data");
              isLoading(false);
            }
          } catch (error) {
            console.error(error);
          } finally {
            isLoading(false);
          }
        })
        .catch((e) => {
          console.error(`e`, e);
          alert("Error in taking snap shot from map");
          isLoading(false);
        });
    };

    const onPopupActionKeysClick = (event) => {
      if (event.action.id === "export-report") {
        const gisId =
          selectedFeatureRef.current?.graphic?.attributes?.GIS_ID || null;
        if (!gisId) {
          alert("Cannot find gis id for current feature");
          return;
        }
        const { current: view } = mapViewRef;

        // zoom to highlighted feature
        view
          .goTo({
            extent: selectedFeatureRef.current.graphic.geometry.extent,
            zoom: 18,
          })
          .then(() => {
            setTimeout(() => {
              loadReportPdf(gisId);
            }, 500);
          });

        // close popup
        // view.popup.close();

        // as popup is closed, feature would be dehilighted
        // make it hilighted
        highlightCallback = loadedLayerRef.current.highlight([
          selectedFeatureRef.current.graphic.attributes.GIS_ID,
        ]);
        // loadReportPdf();
      }
    };

    let popupActionKeysListener;

    const popupTemplate = {
      // autocasts as new PopupTemplate()
      title: `{${RENDER_FEATURE_ATTRIBUTES.FULL_NAME}}`,

      content: [
        {
          type: "fields",
          fieldInfos: [
            {
              fieldName: "FID",
              label: "Field ID",
              places: 2,
            },
            {
              fieldName: `${RENDER_FEATURE_ATTRIBUTES.GIS_ID}`,
              label: "GIS ID",
              places: 2,
            },
            {
              fieldName: `${RENDER_FEATURE_ATTRIBUTES.FULL_NAME}`,
              label: "Street Name",
            },
            {
              fieldName: `${RENDER_FEATURE_ATTRIBUTES.CLASS}`,
              label: "Class",
            },
          ],
        },
      ],
    };
    const roadSectionReport = reports.find(
      (report) => report.key === REPORT_TYPES.ROAD_SECTION_REPORT
    );

    if (roadSectionReport && roadSectionReport.enabled) {
      popupTemplate.actions = [exportReportAction];
    }
    const onLayerViewIsDisplayed = (featureLayer) => async () => {
      const { current: mapView } = mapViewRef;

      // when layer is dispaly, take full screenshot first
      try {
        const { dataUrl } = await mapView.takeScreenshot();
        fullScreenshotRef.current = dataUrl;
      } catch (error) {
        alert("Error in taking snapshot");
      }

      const gisId = selectedFeatureRef.current;
      if (!gisId) return;

      if (!mapView) return;
      featureLayer
        .queryFeatures({
          where: getIdWhereClause(gisId), // where clause of the query
          outFields: ["*"], // defined array of attributes of the return features, ["*"] means keep all
          returnGeometry: true, // make this true if the mapview need to open a popup of the feature, otherwise, the popup won't show next to the feature
        })
        .then(function (response) {
          // returns a feature set with features containing the following attributes
          // defined in outFields of the query
          const { features: queriedFeatures } = response;

          // at least one feature is queried
          const firstFeature = queriedFeatures[0];

          // do nothing if no feature was found
          if (!firstFeature) return;

          // update reference
          selectedFeatureRef.current = { graphic: firstFeature };

          // zoom map to the feature and open predefiend popup
          mapView.goTo(firstFeature).then(() => {
            mapView.popup.open({
              features: queriedFeatures,
            });
          });
        });
    };

    let mapViewOnClickListener;
    if (mapLink) {
      loadModules(
        [
          "esri/Map",
          "esri/views/MapView",
          "esri/layers/FeatureLayer",
          "esri/core/watchUtils",
          "esri/widgets/Legend",
          "esri/widgets/Expand",
          "esri/widgets/BasemapGallery",
          "esri/widgets/Search",
        ],
        {
          css: true,
        }
      ).then(
        async ([
          Map,
          MapView,
          FeatureLayer,
          watchUtils,
          Legend,
          Expand,
          BasemapGallery,
          Search,
        ]) => {
          const layer = new FeatureLayer({
            url: mapLink,
            outFields: ["*"],
            popupTemplate: popupTemplate,
          });
          const map = new Map({
            basemap: "topo",
            layers: [layer],
          });

          const view = new MapView({
            map: map,
            container: mapContainerRef.current,
            // hide zoom widget
            // https://developers.arcgis.com/javascript/latest/sample-code/view-disable-navigation/
            ui: {
              components: ["attribution"],
            },
          });

          mapViewRef.current = view;

          layer.load().then(function (loadedLayer) {
            // Set the view extent to the data extent
            view.extent = loadedLayer.fullExtent;
          });

          view.whenLayerView(layer).then(function (layerView) {
            loadedLayerRef.current = layerView;

            /**
             * cuase in method onLayerViewIsDisplayed, it would take a screenshot
             * in case the layer is too big, give it a second to rendered the map,
             * then call this function to process
             */
            setTimeout(() => {
              return watchUtils.whenFalseOnce(
                layerView,
                "updating",
                onLayerViewIsDisplayed(layer)
              );
            }, 1000);
          });

          mapViewOnClickListener = view.on("click", onMapViewClick);

          view.ui.add(dateRangePickerRef.current, "top-left");
          view.ui.add(btns.current, "top-left");

          popupActionKeysListener = view.popup.on(
            "trigger-action",
            onPopupActionKeysClick
          );

          const searchComponent = new Search({ view: view });

          view.ui.add(searchComponent, "top-right");

          /*================================================== show legend START ================================================== */

          const legend = new Legend({
            view: view,
            container: document.createElement("div"),
            layerInfos: [
              {
                layer: layer,
              },
            ],
          });

          view.ui.add(legend, "bottom-left");

          /*==================================================  show legend END  ================================================== */

          /*================================================== show base map selector START ================================================== */
          const basemapGallery = new BasemapGallery({
            view: view,
            container: document.createElement("div"),
          });

          const bgExpand = new Expand({
            view: view,
            content: basemapGallery,
          });
          view.ui.add(bgExpand, "top-right");
          /*==================================================  show base map selector END  ================================================== */

          return () => {
            if (view) {
              view.container = null;
            }
          };
        }
      );
    }

    return () => {
      if (mapViewOnClickListener) {
        mapViewOnClickListener.remove();
      }

      if (popupActionKeysListener) {
        popupActionKeysListener.remove();
      }
    };
  }, [mapLink, isLoading]);

  // watch changes of the search query
  useEffect(() => {
    if (search) {
      const searchParams = getSearchParamObject(search);

      if (!searchParams) return;

      const {
        startDate: startDateMilli,
        endDate: endDateMilli,
        gisId,
      } = searchParams;

      const { current: selectedFeature } = selectedFeatureRef;
      if (!selectedFeature) {
        selectedFeatureRef.current = gisId;
      }

      if (startDateMilli && endDateMilli) {
        const startDate = new Date(+startDateMilli);
        const endDate = new Date(+endDateMilli);

        if (DateUtils.isValid(startDate) && DateUtils.isValid(endDate)) {
          setDatesState({
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          });
          datesRef.current = {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          };
        }
      }
    }
  }, [search]);

  // if theawaitRemotePDF is set, which means that the pdf is being generated remotely (pdf)
  // within 2 minutes, check whehter it is avaiable in every 3 seconds
  useEffect(() => {
    const INFO = "useEffect CHECKING road patrolled report against AWS";
    if (!awaitRemotePDF) return;
    isLoading(true);
    const { start_time, end_time } = awaitRemotePDF;

    const intervalStartTime = new Date().getTime();
    const TIME_OUT_THRESHOLD = 2 * 60 * 1000;

    let awsInterval;
    /**
     * hide loading animation, nullify awaitAWSObj object, and cancel interval
     */
    const cleanUp = () => {
      isLoading(false);
      setAwaitAWSObj(null);
      if (awsInterval) {
        clearInterval(awsInterval);
      }
    };
    awsInterval = setInterval(async () => {
      const now = new Date().getTime();
      const isTimeout = now - intervalStartTime >= TIME_OUT_THRESHOLD;
      // if time out, stop the periodical check
      if (isTimeout) {
        cleanUp();
        alert("Generating pdf file time out, please try later");
      } else {
        await getRoadPatrolledListReport(
          name,
          timezone,
          start_time,
          end_time
        ).then((response) => {
          const { is_ready: isReady, url } = response;
          if (isReady) {
            cleanUp();
            // show windows default confirm window
            // const confirmDownload = window.confirm("Report is ready");
            // if (confirmDownload) {
            window.open(url);
            // }
          }
        });
      }
    }, 3000);

    return () => {
      if (awsInterval) {
        clearInterval(awsInterval);
      }
    };
  }, [awaitRemotePDF, isLoading, name, timezone]);

  const updateUrlPath = (gisId) => {
    const { startDate, endDate } = datesRef.current;

    if (!startDate || !endDate) return;
    const copiedStartDate = new Date(startDate);
    const copiedEndDate = new Date(endDate);

    if (
      !DateUtils.isValid(copiedStartDate) ||
      !DateUtils.isValid(copiedEndDate)
    )
      return;
    copiedStartDate.setHours(0);
    copiedStartDate.setMinutes(0);
    copiedStartDate.setSeconds(0);
    copiedStartDate.setMilliseconds(0);
    const copiedStartDateMilli = Date.parse(copiedStartDate);
    copiedEndDate.setHours(0);
    copiedEndDate.setMinutes(0);
    copiedEndDate.setSeconds(0);
    copiedEndDate.setMilliseconds(0);
    const copiedEndDateMilli = Date.parse(copiedEndDate);

    let query = "";

    if (gisId) {
      query = getSearchQueryFromObject({
        startDate: copiedStartDateMilli,
        endDate: copiedEndDateMilli,
        gisId,
      });
    } else {
      query = getSearchQueryFromObject({
        startDate: copiedStartDateMilli,
        endDate: copiedEndDateMilli,
      });
    }

    push(pathname + "?" + query);
  };

  return (
    <div>
      <div
        className="webmap"
        style={{ height: "92vh" }}
        ref={mapContainerRef}
      />
      <div ref={dateRangePickerRef} style={{ background: "#fff" }}>
        <DateRangePicker
          startDate={datesState.startDate}
          endDate={datesState.endDate}
          stack={true}
          updateDateRange={(startDate, endDate) => {
            datesRef.current = {
              startDate: new Date(startDate),
              endDate: new Date(endDate),
            };
            setDatesState({
              startDate: new Date(startDate),
              endDate: new Date(endDate),
            });

            updateUrlPath();
          }}
        />
      </div>
      <div
        ref={btns}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
          background: "#fff",
          padding: "0.375em 0.5em",
        }}
      >
        {complianceReport && complianceReport.enabled && (
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "0.375em 0" }}
            fullWidth
            onClick={preparePatrolComlianceSummaryPdf}
          >
            Compliance Summary
          </Button>
        )}
        {/* <Button
          variant="contained"
          color="primary"
          style={{ margin: "0.375em 0" }}
          fullWidth
          onClick={preparePatrolSummary}
        >
          Patrol Summary
        </Button> */}
        {roadsPatrolledReport && roadsPatrolledReport?.enabled && (
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "0.375em 0" }}
            fullWidth
            onClick={prepareRoadPatrolledList}
          >
            Roads Patrolled
          </Button>
        )}
      </div>
    </div>
  );
});

export default withLoader(ReportPage);
