import * as XLSX from "xlsx";

const sortObject = (obj, asc = true) => {
  let keys = Object.keys(obj).sort();
  if (!asc) {
    keys.reverse();
  }
  return keys.reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});
};
const groupArrayOfObject = (values, categoryName) => {
  const result = values.reduce((acc, ele) => {
    const copy = JSON.parse(JSON.stringify(ele));
    const keyName = ele[categoryName];
    const values = acc[keyName];
    if (!values) {
      acc[keyName] = [copy];
    } else {
      acc[keyName] = [...values, copy];
    }
    return acc;
  }, {});
  return sortObject(result);
};

// LB_Defect_ // width
// DP_Defect_ // length
// LB_Defec00 // total

/**
 *
 * @param {TypeNexcoPCIData[]} data
 * @param {TypeNexcoPCIEXCELConfig} config
 * @returns
 */
const getPages = (data, config) => {
  const {
    paginationKey: PAGINATION_KEY,
    groupKey: GROUP_KEY_ON_SAME_PAGE,
    dynamicKey: DYNAMIC_KEY,
    valueWidthKey: VALUE_WIDTH_KEY,
    valueLengthKey: VALUE_LENGTH_KEY,
    valueTotakKey: VALUE_TOTAL_KEY,
    subtitleTitle: SUBTITLE_TITLE,
    totalTitle: TOTAL_TITLE,
    keyMap: KEY_MAP,
  } = config;

  // flat {key0: obj [], key1: obj[]} => {key00: ele, key01: ele} for fast search for an object with a key
  const keyMapObjects = Object.values(KEY_MAP).reduce((acc, ele) => {
    ele.forEach((element) => {
      acc[element.key] = element;
    });
    return acc;
  }, {});

  const groupedData = groupArrayOfObject(data, "FULLNAME");

  const KM_MARKER_TO = "KM_Marker_to";

  KEY_MAP.pre.reduce((acc, ele) => {
    acc.push(ele.title);
    return acc;
  }, []);
  const preColumns = KEY_MAP.pre.reduce((acc, ele) => {
    acc.push(ele.title);
    return acc;
  }, []);

  const suffColumns = KEY_MAP.suff.reduce((acc, ele) => {
    acc.push(ele.title);
    return acc;
  }, []);

  /**keys that must have value */
  const fixKeys = [...preColumns, ...suffColumns];

  const pages = Object.entries(groupedData).reduce((acc, entry) => {
    const [key, data] = entry;
    const newData = data.map((datum, index) => {
      const copy = JSON.parse(JSON.stringify(datum));
      // copy[KM_MARKER_TO] = "~";
      const temp = {};
      Object.values(KEY_MAP).forEach((arr) => {
        arr.forEach((ele) => {
          const { key, title } = ele;
          temp[title] = copy[key];
        });
      });
      const categoryTitle = copy[DYNAMIC_KEY];
      if (categoryTitle) {
        // as found some category is null
        temp[`${categoryTitle} (W)`] = copy[VALUE_WIDTH_KEY];
        temp[`${categoryTitle} (L)`] = copy[VALUE_LENGTH_KEY];
        temp[`${categoryTitle} (数 量)`] = copy[VALUE_TOTAL_KEY];
      }
      return temp;
    });

    const groupedTitle = keyMapObjects[GROUP_KEY_ON_SAME_PAGE].title;

    const groupedData = groupArrayOfObject(newData, groupedTitle);
    console.log("sxData", groupedData, fixKeys, GROUP_KEY_ON_SAME_PAGE);

    const page = { title: key, dataSets: Object.values(groupedData) };
    acc.push(page);
    return acc;
  }, []);

  pages.forEach((page) => {
    const { title, dataSets } = page;

    const sectionData = [];

    let newKeySet = [];
    const sectionSubTotalData = [];
    /*================================================== flat dataSets [[], []] into [] START ================================================== */
    dataSets.forEach((dataSet, index) => {
      const isLastSection = index === dataSets.length - 1;

      let defectTypeColumnNames = [];
      /*================================================== get all defect types' keys START ================================================== */

      dataSet.forEach((datum) => {
        Object.keys(datum).forEach((key) => {
          const isFixedKey = fixKeys.includes(key);
          if (!isFixedKey) {
            // key that points to a defect type
            defectTypeColumnNames.push(key);
          }
        });
      });

      const set = new Set(defectTypeColumnNames);
      defectTypeColumnNames = [...set].sort();

      /*==================================================  get all defect types' keys END  ================================================== */

      newKeySet = [...preColumns, ...defectTypeColumnNames, ...suffColumns];

      const TEMP = newKeySet.reduce((acc, key) => {
        acc[key] = undefined;
        return acc;
      }, {});

      const sectionTotalData = { ...TEMP };
      sectionTotalData[preColumns[preColumns.length - 1]] = SUBTITLE_TITLE;

      /*================================================== fill data with extra keys and assign 'undefined' as value START ================================================== */
      const newSectionData = dataSet.map((datum, index) => {
        const copy = { ...TEMP };
        newKeySet.forEach((columKey) => {
          if (defectTypeColumnNames.includes(columKey) && datum[columKey]) {
            const tempTotal = sectionTotalData[columKey] || 0;
            sectionTotalData[columKey] = tempTotal + datum[columKey];
          }
          copy[columKey] = datum[columKey];
        });
        return copy;
      });

      /*==================================================  fill data with extra keys and assign 'undefined' as value END  ================================================== */
      sectionData.push(...newSectionData, sectionTotalData);
      sectionSubTotalData.push({ ...sectionTotalData });

      /*================================================== handle page total START  ================================================== */
      if (isLastSection) {
        const pageTotal = { ...TEMP };
        pageTotal[
          preColumns[preColumns.length - 1]
        ] = `${TOTAL_TITLE} (${title})`;
        sectionSubTotalData.forEach((subTotal) => {
          defectTypeColumnNames.forEach((key) => {
            const v = subTotal[key] || 0;
            const current = pageTotal[key] || 0;
            pageTotal[key] = v + current;
          });
        });
        sectionData.push(pageTotal);
      }
      /*================================================== handle page total END  ================================================== */

      // handle subtitle
    });
    /*================================================== flat dataSets [[], []] into [] END ================================================== */

    page.sectionSubTotalData = sectionSubTotalData;
    page.dataSet = sectionData;
  });

  return pages;
};
/**
 *
 * @param {TypeNexcoPCIData[]} data
 * @param {TypeNexcoPCIEXCELConfig} config
 */
export function nexcoPCITemplateCSSV(data, config) {
  const { pageTitlePrefix } = config;
  const pages = getPages(data, config);
  console.log("pages", pages);
  // csv file
  const workbook = XLSX.utils.book_new();

  pages.forEach((page) => {
    const { title, dataSet } = page;
    const TITLE_TOP = [
      `${pageTitlePrefix}（${title}）`,
      "",
      "",
      "",
      "",
      "",
      "A2-1\nA2-1-2(Y)",
      "A1-1-2(Y)",
      "A1-2-2(Y)",
      "B1-1\nB1-1-2(Y)",
      "C1-1\nC1-1-2(Y)",
      "C2-1\nC2-1-2(Y)",
    ];
    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.aoa_to_sheet([TITLE_TOP]);
    // XLSX.utils.sheet_add_json(worksheet, rows, { origin: -1 });

    XLSX.utils.sheet_add_json(worksheet, dataSet, { origin: -1 });
    // XLSX.utils.sheet_add_json(worksheet, data, { origin: -1 });
    XLSX.utils.book_append_sheet(workbook, worksheet, title);
    worksheet["!cols"] = [{ wch: 20 }];
  });

  XLSX.writeFile(workbook, `NEXCO_PCI_${new Date().toLocaleString()}.xlsx`, {
    compression: true,
  });
}
