import React from "react";
import ReactDOM from "react-dom";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { awsconfig } from "./aws/aws-export";
import { Auth } from "aws-amplify";
import "./config/i18n";
import "animate.css";

// setup locales for moment
// so that it could use it later on
import fr from "moment/locale/fr";
import ja from "moment/locale/ja";
import moment from "moment";
moment.locale("fr", fr);
moment.locale("ja", ja);

Auth.configure(awsconfig);

// dynamically load worker
// if (process.env.NODE_ENV === "development") {
//   const { worker } = require("test/mocks/browser");
//   worker.start();
// }

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
