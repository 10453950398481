import React, { useState, useEffect } from "react";
import locale from "date-fns/locale/en-CA";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  localeUtilsMap,
  localeMap,
  localeFormatMap,
  localeCancelLabelMap,
} from "./localiation";

export function DatePicker({
  value,
  maxValue,
  minValue,
  readOnly,
  editable = true,
  label,
  onChange: handleDateUpdate,
  onDateCleared,
  style,
  ...props
}) {
  const {
    i18n: { language },
  } = useTranslation();
  const [locale, setLocale] = useState(language);

  const [dateValue, setDateValue] = useState(value);
  const handleDateChange = (date) => {
    setDateValue(date);
    if (typeof handleDateUpdate === "function") {
      handleDateUpdate(new Date(date));
    }
  };

  const handleDateCleared = () => {
    setDateValue(null);
    if (typeof onDateCleared === "function") {
      onDateCleared();
    }
  };

  useEffect(() => {
    setDateValue(value);
  }, [value]);

  useEffect(() => {
    setLocale(language);
  }, [language]);

  return (
    <div style={style}>
      <MuiPickersUtilsProvider
        utils={localeUtilsMap[locale]}
        locale={localeMap[locale]}
      >
        <KeyboardDatePicker
          label={label}
          format={localeFormatMap[locale]}
          cancelLabel={localeCancelLabelMap[locale]}
          value={dateValue}
          onChange={handleDateChange}
          maxDate={maxValue}
          minDate={minValue}
          disabled={readOnly}
          readOnly={readOnly}
          inputProps={{
            readOnly: !editable,
          }}
          InputAdornmentProps={{
            position: "start",
          }}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={handleDateCleared}>
                <CloseIcon />
              </IconButton>
            ),
          }}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
