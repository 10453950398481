import { gql, useMutation } from "@apollo/client";
import { localizeData } from "components/table/dataModal/workOrderDataModal";
import { useEffect, useMemo, useState } from "react";

/**
 * @param {string []} queryItems array of key names whose value should be return by the query.
 */
const getUpdateWorkOrderGQL = (queryItems) => {
  return gql`
    mutation UpdateWorkOrder($input: UpdateWorkOrderInput!) {
      updateWorkOrder(input: $input) {
        ${queryItems}
      }
    }
  `;
};

/**
 * @param {string} timezone timezone of the city or region, used to localize its work order
 * @param {string []} queryItems array of key names whose value should be return by the query.
 */
export const useUpdateWorkOrder = (timezone, queryItems) => {
  const [data, setData] = useState();
  const UPDATE_WORKORDER = getUpdateWorkOrderGQL(queryItems);

  const useMutationResult = useMutation(UPDATE_WORKORDER);
  const [_, { data: returnedData }] = useMutationResult;

  useEffect(() => {
    const unlocalizedData = returnedData?.updateWorkOrder;
    if (returnedData) {
      setData(localizeData(unlocalizedData, timezone));
    }
  }, [returnedData, timezone]);

  return { data, options: useMutationResult };
};

/**
 * @param {string} region region / city name of the work order
 * @param {string []} queryItems array of key names whose value should be return by the query.
 */
export const useDeleteWorkOrder = (region) => {
  const REMOVE_WORK_ORDER_GQL = gql`
    mutation DeleteWorkOrder($id: String!) {
      deleteWorkOrder(input: { region: "${region}", id: $id }) {
        id
      }
    }
  `;
  return useMutation(REMOVE_WORK_ORDER_GQL);
};
