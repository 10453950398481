import React, { useEffect, useState } from "react";
import clsx from "clsx";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import locale from "date-fns/locale/en-US";
import isWithinInterval from "date-fns/isWithinInterval";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { FormControl, IconButton, makeStyles } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import {
  localeUtilsMap,
  localeMap,
  localeFormatMap,
  localeCancelLabelMap,
} from "./localiation";
import { getCommonTranslation } from "utils/lang/translate";
import DateUtils from "utils/dateUtils/DateUtils";

export function setToStartOfDate(date) {
  const temp = new Date(date);
  temp.setHours(0);
  temp.setMinutes(0);
  temp.setSeconds(0);
  temp.setMilliseconds(0);
  return temp;
}

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

const DATE_RANGE_SOURCE = {
  from: "from",
  to: "to",
};

const useStyles = makeStyles((theme) => ({
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

const stackStyle = { display: "flex", flexDirection: "column" };

/**
 *
 * @param {object} props
 * @param {Date} props.startDate
 * @param {Date} props.endDate
 * @param {boolean} props.stack
 * @param {React.CSSProperties} props.style
 * @param {React.CSSProperties} props.datePickerStyle
 * @param {React.CSSProperties} props.dateWrapperStyle
 * @param {(date0, date1) => {}} props.updateDateRange
 * @param {number} [props.maxDate]
 * @param {number} [props.minDate]
 * @returns
 */
function DateRangePicker(props) {
  const {
    startDate,
    endDate,
    stack = false,
    style = {},
    datePickerStyle = {},
    dateWrapperStyle = { margin: "0.5rem 0.375rem" },
    minDate,
    maxDate,
  } = props;

  const { updateDateRange, selectedDate: date } = props;
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState({
    startDate: startDate || new Date(),
    endDate: endDate || new Date(),
  });

  const {
    i18n: { language },
  } = useTranslation();
  const [locale, setLocale] = useState(language);

  useEffect(() => {
    if (DateUtils.isValid(startDate)) {
      setSelectedDate((prev) => ({ ...prev, startDate: new Date(startDate) }));
    }
    if (DateUtils.isValid(endDate)) {
      setSelectedDate((prev) => ({ ...prev, endDate: new Date(endDate) }));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setLocale(language);
  }, [language]);

  /**
   * @summary a callback function passed in both date picker
   *
   * @description this method would be called once the user has selected a date from either of the
   * date picker. The source varaibel tells whehter the date is selected from the start date picker
   * or the end date picker so that the corresponding value would be updated properly
   *
   * @param {Date} date
   * @param {DATE_RANGE_SOURCE} source
   */
  const handleDateChange = (date, source) => {
    const isFrom = source === DATE_RANGE_SOURCE.from;
    const copy = new Date(date);
    const { startDate, endDate } = selectedDate;
    const startCopy = new Date(startDate);
    const endCopy = new Date(endDate);
    if (isFrom) {
      // date is selected from start date picker
      setSelectedDate((current) => ({
        ...current,
        startDate: copy,
      }));
    } else {
      // date is selecte dfrom end date picker
      setSelectedDate((current) => ({
        ...current,
        endDate: copy,
      }));
    }

    // send update dates to parent component, withDatePicker.js
    if (typeof updateDateRange === "function") {
      if (isFrom) {
        updateDateRange(copy, endCopy);
      } else {
        updateDateRange(startCopy, copy);
      }
    }
  };

  /**
   /**
   * @summary the overwritten, customized version of the renderWrappedWeekDay of the DatePicker
   *
   * @description by overwriting this method, the dates between the selected start date and end date
   *  would be highlighted, a small touch that notifies the user that the date range he has selected
   * 
   * @param {Date} date date of the month, from date 1 to last date of the month
   * @param {Date} currentDate // the user selected date
   * @param {Boolean} dayInCurrentMonth // whehter or not date is in the current month
   * @param {DATE_RANGE_SOURCE} source  // the date picker is for start date or for end date
   * @returns 
   */
  const renderWrappedWeekDay = (
    date,
    currentDate,
    dayInCurrentMonth,
    source
  ) => {
    const { startDate, endDate } = selectedDate;

    const isStartDate = source === DATE_RANGE_SOURCE.from;
    let start, end;

    if (isStartDate) {
      start = new Date(currentDate);
      end = new Date(endDate);
    } else {
      start = new Date(startDate);
      end = new Date(currentDate);
    }

    start = setToStartOfDate(start);
    end = setToStartOfDate(end);

    let dateClone = new Date(date);

    let dayIsBetween;

    // determine whehter or not the day is within the selected
    // start date and the end date or not
    if (isStartDate) {
      dayIsBetween = isWithinInterval(dateClone, {
        start: currentDate,
        end,
      });
    } else {
      dayIsBetween = isWithinInterval(dateClone, {
        start,
        end: currentDate,
      });
    }

    let isFirstDay, isLastDay;

    isFirstDay = isSameDay(dateClone, start);
    isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    // render disabled days if date are after today or
    // dates are before the selected start date

    // as this method is overwritten, this part is mandatory
    // as the maxDate property does not disabled the future dates any more
    if (
      date.getTime() > maxDate || // dates are after today
      (date.getTime() < start.getTime() && !isStartDate) || // dates are before the start time
      (isStartDate && date.getTime() > end.getTime()) // start date picker and dates are after the selected end
    ) {
      return (
        <div
          className={wrapperClassName}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <IconButton className={dayClassName} disabled>
            <span> {format(dateClone, "d")} </span>
          </IconButton>
        </div>
      );
    }

    // render the date UIs with custom style
    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    );
  };

  // style
  let wrapperStyle = style;
  if (stack) {
    wrapperStyle = { ...wrapperStyle, ...stackStyle };
  }
  return (
    <div style={wrapperStyle}>
      <FormControl style={dateWrapperStyle}>
        <MuiPickersUtilsProvider
          utils={localeUtilsMap[locale]}
          locale={localeMap[locale]}
        >
          <KeyboardDatePicker
            style={datePickerStyle}
            label={getCommonTranslation("from")}
            format={localeFormatMap[locale]}
            cancelLabel={localeCancelLabelMap[locale]}
            value={selectedDate.startDate}
            onChange={(value) =>
              handleDateChange(value, DATE_RANGE_SOURCE.from)
            }
            renderDay={(date, currentDate, dayInCurrentMonth) =>
              renderWrappedWeekDay(
                date,
                currentDate,
                dayInCurrentMonth,
                DATE_RANGE_SOURCE.from
              )
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      <FormControl style={dateWrapperStyle}>
        <MuiPickersUtilsProvider
          utils={localeUtilsMap[locale]}
          locale={localeMap[locale]}
        >
          <KeyboardDatePicker
            style={datePickerStyle}
            label={getCommonTranslation("to")}
            format={localeFormatMap[locale]}
            cancelLabel={localeCancelLabelMap[locale]}
            value={selectedDate.endDate}
            onChange={handleDateChange}
            renderDay={(date, currentDate, dayInCurrentMonth) =>
              renderWrappedWeekDay(
                date,
                currentDate,
                dayInCurrentMonth,
                DATE_RANGE_SOURCE.to
              )
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </div>
  );
}

export default DateRangePicker;
