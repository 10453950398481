import { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import ArchiveIcon from "@material-ui/icons/Archive";
import demoImage from "./data/report_demo_image.jpg";
import { WorkorderPdfView } from "./file/WorkorderPdfView";
import { pdf } from "@react-pdf/renderer";
import LoadingButton from "irisUI/loadingButton/LoadingButton";
import {
  STRING_SET_FIELDS,
  WORK_ORDER_DATA_FIELDS_COMMON,
} from "./dataModal/workOrderDataModal";
import SendIcon from "@material-ui/icons/Send";
import { useConfirm } from "irisUI/global/confirm/context/ConfirmContext";
import {
  CREATE_LANG_KEY,
  TRANSLATE,
  TRANSLATE_ONCE,
} from "utils/lang/translate";
import { KEYS as WORK_ORDER_TABLE_TRANSLATIONS_MAP } from "./WorkorderTable.translate";
import useAuth from "hook/useAuth";
import { useTranslation } from "react-i18next";
import { KEYS as WORK_ORDER_DATA_KEYS } from "config/dataTranslations/workOrderData.translation";
export function RowDetailsView({
  rowData,
  handleReadRow,
  showImageModal,
  onRemoveRow: removeRow,
  onEditBtnClick: handleEditBtnClick,
}) {
  const { t } = useTranslation();
  const {
    getArcgisAccessToken,
    pageConfig: {
      workorder: { interested_fields },
      city: { logo_url },
    },
  } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [anchor, setAnchor] = useState(undefined);

  const { isConfirmed } = useConfirm();

  const closeMenu = () => {
    setAnchor(undefined);
  };

  const openMenu = (e) => {
    setAnchor(e.currentTarget);
  };

  const exportRowDetailPdf = async (arcgisToken) => {
    const content = (
      <WorkorderPdfView
        data={[rowData]}
        arcgisToken={arcgisToken}
        interestedFields={interested_fields}
        logo={logo_url}
      />
    );
    try {
      setIsLoading(true);
      const data = await pdf(content).toBlob();

      window.open(URL.createObjectURL(data), "_blank");
    } catch (error) {
      console.error(`error in generating pdf file for data`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendRowDataViaEmail = () => {
    // const datapoint_id = rowData[WORK_ORDER_DATA_FIELDS.DATAPOINT_ID];
    const road_class = rowData[WORK_ORDER_DATA_FIELDS_COMMON.CLASS] || "N / A";
    const defect_type = rowData[WORK_ORDER_DATA_FIELDS_COMMON.W_O_TYPE];
    const collect_date_time_local =
      rowData[WORK_ORDER_DATA_FIELDS_COMMON.OPEN_DATETIME_LOCAL];

    const service_by_local =
      rowData[WORK_ORDER_DATA_FIELDS_COMMON.SERVICE_BY_LOCAL];
    const address = rowData[WORK_ORDER_DATA_FIELDS_COMMON.ADDRESS];
    const longitude = rowData[WORK_ORDER_DATA_FIELDS_COMMON.LON];
    const latitude = rowData[WORK_ORDER_DATA_FIELDS_COMMON.LAT];
    const note = rowData[WORK_ORDER_DATA_FIELDS_COMMON.NOTE];
    const imageURL = rowData[WORK_ORDER_DATA_FIELDS_COMMON.IMAGE_URL];

    const workOrderId = rowData[WORK_ORDER_DATA_FIELDS_COMMON.W_O_ID];

    const linebreakString = "%0D%0A";
    let url = "mailto:";
    url +=
      "?subject=" +
      encodeURIComponent(
        // "Work Order #" +
        TRANSLATE_ONCE(
          WORK_ORDER_TABLE_TRANSLATIONS_MAP.TABLE_HEADERS.WORK_ORDER_ID
        ) +
          ": " +
          workOrderId
      );
    let body = "";
    body +=
      t(WORK_ORDER_TABLE_TRANSLATIONS_MAP.TABLE_HEADERS.WORK_ORDER_ID) +
      encodeURIComponent(": " + workOrderId) +
      linebreakString;
    body +=
      encodeURIComponent(
        t(WORK_ORDER_DATA_KEYS.W_O_TYPE) +
          ": " +
          t(
            WORK_ORDER_TABLE_TRANSLATIONS_MAP.W_O_TYPE[
              CREATE_LANG_KEY(defect_type)
            ]
          )
      ) + linebreakString;
    body +=
      encodeURIComponent(
        t(
          WORK_ORDER_TABLE_TRANSLATIONS_MAP.WORK_ORDER_EMAIL_FIELDS.ROAD_CLASS
        ) +
          ": " +
          road_class
      ) + linebreakString;
    body +=
      encodeURIComponent(
        t(
          WORK_ORDER_TABLE_TRANSLATIONS_MAP.WORK_ORDER_EMAIL_FIELDS
            .ISSUE_DATE_TIME
        ) +
          ": " +
          collect_date_time_local
      ) + linebreakString;
    body +=
      encodeURIComponent(
        t(WORK_ORDER_TABLE_TRANSLATIONS_MAP.WORK_ORDER_EMAIL_FIELDS.REPAIR_BY) +
          ": " +
          service_by_local
      ) + linebreakString;

    body +=
      encodeURIComponent(
        t(WORK_ORDER_TABLE_TRANSLATIONS_MAP.WORK_ORDER_EMAIL_FIELDS.LOCATION) +
          ": " +
          address
      ) + linebreakString;
    body +=
      encodeURIComponent("GPS: " + latitude + ", " + longitude) +
      linebreakString;

    body +=
      encodeURIComponent(
        t(WORK_ORDER_TABLE_TRANSLATIONS_MAP.WORK_ORDER_EMAIL_FIELDS.COMMENTS) +
          ": " +
          note
      ) + linebreakString;
    body += encodeURIComponent("URL: " + imageURL) + linebreakString;

    url += "&body=" + body;
    window.open(url, "_blank");
  };

  const renderDetails = (detailFields, data) => {
    return (
      <Grid container alignItems="flex-start" style={{ padding: "1em" }}>
        {detailFields.map((detailField, key) => {
          const { fieldName, label, fullWidth } = detailField;
          const value = data[fieldName];
          const translatedLabel = t(
            WORK_ORDER_DATA_KEYS[CREATE_LANG_KEY(label)]
          );
          // As requested in item https://irisradgroup.atlassian.net/browse/DT-957 
          if(translatedLabel === '')
            return
          // TDOD attachment remove this hardcode keycheck
          if (
            Array.isArray(value) &&
            value.length > 0 &&
            Object.values(STRING_SET_FIELDS).indexOf(fieldName) > -1
          ) {
            return (
              <Grid item container xs={10} key={key}>
                <Grid item xs={3}>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    {translatedLabel}:
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ marginLeft: "1rem" }}>
                  {value.map((url, index) => (
                    <a
                      style={{ display: "block" }}
                      key={index}
                      target="_blank"
                      href={url}
                      onClick={async (event) => {
                        event.preventDefault();
                        const token = await getArcgisAccessToken();
                        if (token) {
                          const newUrl = `${url}?token=${token}`;
                          window.open(newUrl, "blank");
                        } else {
                          alert("arcgis token is expried, please try again");
                        }
                      }}
                    >
                      {`${translatedLabel}: ${++index}`}
                    </a>
                  ))}
                </Grid>
              </Grid>
            );
          }
          if (fullWidth) {
            return (
              <Grid item container xs={10} key={key}>
                <Grid item xs={3}>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    {translatedLabel}
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ marginLeft: "1rem" }}>
                  <Typography variant="h6" style={{ fontSize: "1rem" }}>
                    {value}
                  </Typography>
                </Grid>
              </Grid>
            );
          }

          return (
            <Grid item container xs={6} key={key} alignItems="center">
              <Grid item xs={5}>
                <Typography
                  variant="h6"
                  style={{
                    textAlign: "right",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  {translatedLabel}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ marginLeft: "1rem" }}>
                <Typography variant="h6" style={{ fontSize: "1rem" }}>
                  {value}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
        <Grid container>
          <Grid
            item
            container
            // xs={7}
            style={{ marginLeft: "auto" }}
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  isConfirmed("Are you sure to remove this work order?").then(
                    (confirmed) => {
                      if (confirmed && typeof removeRow === "function") {
                        removeRow(rowData);
                      }
                    }
                  );
                }}
              >
                {TRANSLATE("common:DELETE")}
              </Button>
            </Grid>
            {/* <Button onClick={openMenu}>menu</Button> */}
            <Grid item>
              <LoadingButton
                variant="contained"
                color="primary"
                isLoading={isLoading}
                startIcon={<MoreVertIcon />}
                onClick={openMenu}
              >
                {TRANSLATE("common:MORE")}
              </LoadingButton>
            </Grid>
            <Menu
              open={anchor !== undefined}
              anchorEl={anchor}
              onClose={closeMenu}
              anchorOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  closeMenu();
                  handleSendRowDataViaEmail();
                }}
              >
                <ListItemIcon style={{ minWidth: 36 }}>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary={TRANSLATE("common:SEND_EMAIL")} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  closeMenu();
                  handleEditBtnClick(rowData);
                }}
              >
                <ListItemIcon style={{ minWidth: 36 }}>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary={TRANSLATE("common:EDIT")} />
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  closeMenu();
                  const token = await getArcgisAccessToken();
                  exportRowDetailPdf(token);
                }}
              >
                <ListItemIcon style={{ minWidth: 36 }}>
                  <ArchiveIcon />
                </ListItemIcon>
                <ListItemText primary={TRANSLATE("common:DOWNLOAD")} />
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  let imageUrl = rowData.image_url;
  if (!imageUrl || imageUrl === "") {
    imageUrl = demoImage;
  }

  // CLASS: { fieldName: "class", label: "Class" },
  // FROM: { fieldName: "from", label: "From" },
  // ROAD_ID: { fieldName: "road_id", label: "Road ID" },
  // ROAD_NAME: { fieldName: "road_name", label: "Road Name" },
  // ROAD_REGION: { fieldName: "Road Region", label: "Road Region" },
  // TO: { fieldName: "to", label: "To" },
  // WARD: { fieldName: "ward", label: "Ward" },

  const d = [
    {
      fieldName: WORK_ORDER_DATA_FIELDS_COMMON.LAT,
      label: "Lat",
    },
    {
      fieldName: WORK_ORDER_DATA_FIELDS_COMMON.LON,
      label: "Lon",
    },
    {
      fieldName: WORK_ORDER_DATA_FIELDS_COMMON.DATAPOINT_ID,
      label: "Data Point ID",
    },
    {
      fieldName: WORK_ORDER_DATA_FIELDS_COMMON.LABEL_ID,
      label: "Label ID",
    },

    {
      fieldName: WORK_ORDER_DATA_FIELDS_COMMON.ISSUED_BY,
      label: "Issued By",
    },

    ...interested_fields,
    {
      fieldName: WORK_ORDER_DATA_FIELDS_COMMON.ADDRESS,
      label: "Address",
      fullWidth: true,
    },
    {
      fieldName: WORK_ORDER_DATA_FIELDS_COMMON.NOTE,
      label: "Note",
      fullWidth: true,
    },
    {
      fieldName: WORK_ORDER_DATA_FIELDS_COMMON.DESCRIPTION,
      label: "Description",
      fullWidth: true,
    },
    {
      fieldName: WORK_ORDER_DATA_FIELDS_COMMON.ATTACHMENTS,
      label: "Attachments",
      fullWidth: true,
    },
  ];

  // set the row as read when component mounts
  useEffect(() => {
    handleReadRow(rowData.id);
  }, [handleReadRow, rowData.id]);

  return (
    <Grid
      container
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        padding: "2em 1em",
        width: "100%",
      }}
    >
      <Grid container>
        <Typography variant="h4">
          {TRANSLATE("common:WORK_ORDER_DETAILS")}
        </Typography>
      </Grid>
      <Grid container style={{ margin: "1rem" }}>
        <Grid
          item
          container
          md={6}
          xs={12}
          // style={{ backgroundColor: "lightblue" }}
        >
          {renderDetails(d, rowData)}
        </Grid>
        <Grid
          item
          container
          md={6}
          xs={12}
          // style={{ backgroundColor: "pink" }}
          alignItems="center"
        >
          <Grid item container style={{ padding: "1em" }}>
            <div style={{ width: "100%" }}>
              <img
                onClick={() => showImageModal(imageUrl)}
                src={imageUrl}
                alt="data point"
                style={{ width: "100%", maxHeight: 400, objectFit: "contain" }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
