import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { QUERY_WORKORDER_URL, WORK_OREDER_API_KEY } from "utils/env/urls";

const client = new ApolloClient({
  uri: QUERY_WORKORDER_URL,
  headers: {
    "x-api-key": WORK_OREDER_API_KEY,
  },
  cache: new InMemoryCache(),
});

export default function GraphQLContextProvider(props) {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
