import AssessmentIcon from "@material-ui/icons/Assessment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Dashboard from "components/Dashboard";
import WorkorderPage from "pages/WorkOrder/WorkorderPage";
import ReportPage from "pages/reportPage/ReportPage";
import PCIPage from "pages/PCIPage/PCIPage";

export const PROTECTED_ROUTES = {
  dashboardPage: {
    key: "widgets",
    name: "Dashboard",
    path: "/dashboard",
    IconComponent: <DashboardIcon />,
    component: Dashboard,
  },
  workOrder: {
    key: "w_o_profile",
    name: "Work Order",
    path: "/work-order",
    IconComponent: <AssessmentIcon />,
    component: WorkorderPage,
  },
  reportPage: {
    key: "patrol_report",
    name: "Reports",
    path: "/reports",
    IconComponent: <LibraryBooksIcon />,
    component: ReportPage,
  },
  pciPage: {
    key: "pci_report",
    name: "PCI Report",
    path: "/pci-report",
    IconComponent: <LibraryBooksIcon />,
    component: PCIPage,
  },
};
