import React, { useState, useEffect, useRef, useCallback } from "react";

import MaterialTable, { MTableToolbar } from "material-table";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import {
  CSV_REPORT_FIELDS,
  DEFAULT_VALUE,
  WORK_ORDER_DATA_FIELDS_COMMON,
} from "components/table/dataModal/workOrderDataModal";

import xlsx from "json-as-xlsx";

import { RowDetailsView } from "./RowDetailsView";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import EditRowModal from "./tableModals/EditRowModal";
import withImageModal from "../../HOC/withImageModal";
import GetAppIcon from "@material-ui/icons/GetApp";
import DateUtils from "../../utils/dateUtils/DateUtils";
import { pdf } from "@react-pdf/renderer";
import LoadingButton from "irisUI/loadingButton/LoadingButton";
import { WorkorderPdfSimplifiedView } from "./file/WorkorderPdfSimplifiedView";
import {
  getCategoryTitle,
  getDefectTitle,
  getRenderSearchParams,
} from "./file/dataUtil";
import { getWorkorderDataSummary } from "./dataModal/workOrderDataModal";
import { WorkOrderSummayPdf } from "./file/WorkOrderSummayPdf";
import useAuth, { getAWSTOkenAsync } from "hook/useAuth";
import { getWorkorderFilter } from "libs/AppoloClient/apis/workorder/hooks/useListWorkOrders/utils";

import { useAlert } from "irisUI/global/alert/context/AlertContext";
import {
  CREATE_LANG_KEY,
  DATE_TIME_FORMATS,
  getCommonTranslation,
  getLocaleDateTime,
  TRANSLATE,
  TranslateTime,
  compareTranslated
} from "../../utils/lang/translate";
import { FR as talbeLocalizationFrench } from "./localization/fr";
import { useTranslation } from "react-i18next";
import { KEYS as REPORT_TRANSLATIONS_MAP } from "reports/translation/report.translation";
import { KEYS } from "config/dataTranslations/workOrderData.translation";
import { KEYS as WORK_ORDER_TABLE_TRANSLATION_KEY_MAP } from "./WorkorderTable.translate";
import i18n from "config/i18n";
import { useDeleteWorkOrder } from "libs/AppoloClient/apis/workorder/hooks/useListWorkOrders/useMutatWorkOrders";
import {
  DEFAULT_OPERANTS,
  DEFAULT_VALUE_TYPES,
} from "components/DynamonDBFilter/statics";
import { filterSearchParams } from "components/DynamonDBFilter/utils";
import { getWorkOrderData } from "utils/requests";

const TABLE_LOCALIZATION_MAP = {
  fr: talbeLocalizationFrench,
};

function WOTable(props) {
  const { searchParams, showImageModal, updatedData, onDataUpdated } = props; // from hoc
  const {
    pageConfig: {
      workorder: { queryItems, interested_fields },
      city: { name: cityName, timezone },
    },
  } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [localization, setLocalization] = useState(
    TABLE_LOCALIZATION_MAP[language]
  );

  // comment out due to no deletion on front end
  const [deleteWorkOrder] = useDeleteWorkOrder(cityName);





  const columns = [
    {
      title: TRANSLATE("WORK_ORDER_TABLE.TABLE_HEADERS.IMAGE_URL"),
      field: WORK_ORDER_DATA_FIELDS_COMMON.IMAGE_URL,
      cellStyle: {
        padding: "5px",
      },
      editable: "never",
      width: 120,
      render: (rowData) => {
        return (
          <img
            src={rowData[WORK_ORDER_DATA_FIELDS_COMMON.IMAGE_URL]}
            alt={rowData.road_name}
            style={{ width: "100px", height: "auto" }}
          />
        );
      },
    },
    {
      title: TRANSLATE("WORK_ORDER_TABLE.TABLE_HEADERS.OPENED"),
      field: WORK_ORDER_DATA_FIELDS_COMMON.OPEN_DATETIME_LOCAL,
      cellStyle: {
        backgroundColor: "#039be5",
        color: "#FFF",
        padding: "10px",
      },
      headerStyle: {
        backgroundColor: "#039be5",
      },
      editable: "never",
      width: 120,
      render: (rowData) =>
        TranslateTime(
          rowData[WORK_ORDER_DATA_FIELDS_COMMON.OPEN_DATETIME_LOCAL],
          DATE_TIME_FORMATS[language]["FULL"]
        ),
      defaultSort: "desc",
    },
    {
      title: TRANSLATE("WORK_ORDER_TABLE.TABLE_HEADERS.SERVICE_BY"),
      field: WORK_ORDER_DATA_FIELDS_COMMON.SERVICE_BY_LOCAL,
      type: "date",
      cellStyle: {
        padding: "10px",
      },
      width: 140,
      render: (rowData) =>
        TranslateTime(
          rowData[WORK_ORDER_DATA_FIELDS_COMMON.SERVICE_BY_LOCAL],
          DATE_TIME_FORMATS[language]["FULL"]
        ),
    },
    {
      title: TRANSLATE("WORK_ORDER_TABLE.TABLE_HEADERS.DTS"),
      field: WORK_ORDER_DATA_FIELDS_COMMON.DTS_LOCAL,
      cellStyle: {
        padding: "10px",
      },
      width: 160,
    },
    {
      title: TRANSLATE("WORK_ORDER_TABLE.TABLE_HEADERS.CATEGORY"),
      field: WORK_ORDER_DATA_FIELDS_COMMON.CATEGORY,
      cellStyle: {
        padding: "10px",
      },
      customSort: (a, b) => compareTranslated(a.category, b.category, "WORK_ORDER_TABLE.CATEGORY"),
      width: 120,
      render: (rowData) => {
        return TRANSLATE(
          CREATE_LANG_KEY(rowData.category, "WORK_ORDER_TABLE.CATEGORY"),
          rowData.category
        );
      },
    },
    {
      title: TRANSLATE("WORK_ORDER_TABLE.TABLE_HEADERS.TYPE"),
      field: WORK_ORDER_DATA_FIELDS_COMMON.W_O_TYPE,
      cellStyle: {
        padding: "10px",
      },
      customSort: (a, b) => compareTranslated(a.w_o_type, b.w_o_type, "WORK_ORDER_TABLE.W_O_TYPE"),
      width: 180,
      render: (rowData) => {
        return TRANSLATE(
          CREATE_LANG_KEY(rowData.w_o_type, "WORK_ORDER_TABLE.W_O_TYPE"),
          rowData.w_o_type
        );
      },
    },
    // { title: "Description", field: "description" },
    {
      title: TRANSLATE("WORK_ORDER_TABLE.TABLE_HEADERS.SEVERITY"),
      field: WORK_ORDER_DATA_FIELDS_COMMON.SEVERITY,
      cellStyle: {
        padding: "10px",
      },
      width: 120,
    },
    {
      title: TRANSLATE("WORK_ORDER_TABLE.TABLE_HEADERS.STATUS"),
      field: WORK_ORDER_DATA_FIELDS_COMMON.W_O_STATUS,
      cellStyle: {
        padding: "10px",
      },
      customSort: (a, b) => compareTranslated(a.w_o_status, b.w_o_status, "WORK_ORDER_TABLE.W_O_STATUS"),
      width: 100,
      render: (rowData) =>
        TRANSLATE(
          "WORK_ORDER_TABLE.W_O_STATUS." +
          rowData[WORK_ORDER_DATA_FIELDS_COMMON.W_O_STATUS].toUpperCase()
        ),
    },
    {
      title: TRANSLATE("WORK_ORDER_TABLE.TABLE_HEADERS.REGION"),
      field: WORK_ORDER_DATA_FIELDS_COMMON.ROAD_REGION,
      editable: "never",
      cellStyle: {
        padding: "10px",
      },
      customSort: (a, b) => a.road_region.localeCompare(b.road_region, i18n.language),
      sorting: false,
      width: 100,
    },
    {
      title: TRANSLATE("WORK_ORDER_TABLE.TABLE_HEADERS.WORK_ORDER_ID"),
      field: WORK_ORDER_DATA_FIELDS_COMMON.W_O_ID,
      cellStyle: {
        padding: "10px",
      },
      width: 180,
    },
    {
      title: TRANSLATE("WORK_ORDER_TABLE.TABLE_HEADERS.ASSIGNED_TO"),
      field: "assign_to",
      customSort: (a, b) => a.assign_to.localeCompare(b.assign_to, i18n.language),
      cellStyle: {
        padding: "10px",
      },
      // width: 180,
    },
  ];

  const [data, setData] = useState([]);

  // state to store the read rows
  const readDataRef = useRef([]);
  // local storage key name for read rows
  const readRowsLocalStorageKey = "irisCityWorkOrderReadRows";

  const [isPreparingPDF, setIsPreparingPDF] = useState(false);

  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const { showAlert } = useAlert();

  const handleExportButtonClick = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClosed = () => {
    setPopoverAnchor(null);
  };

  // set row as read in both state and local storage
  const handleReadRow = (rowId) => {
    // set state of read row
    readDataRef.current = readDataRef.current.concat(rowId);

    // get data of read rows from local storage and then add new row id
    const readRowsLocal = localStorage.getItem(readRowsLocalStorageKey);

    let readRows;

    // when no data stored in local storage
    if (readRowsLocal === null) {
      readRows = [rowId];
    }
    // add new row id to existing data
    else {
      // try catch in case the local storage value cannot be manipulated as an array
      try {
        readRows = JSON.parse(readRowsLocal);
        // add when existing data doesn't have the same row id
        if (readRows.find((oneRow) => oneRow === rowId) === undefined) {
          readRows.push(rowId);
        } else {
          return;
        }
      } catch (err) {
        console.error("error message from setting localStorage", err);
        readRows = [rowId];
      }
    }

    localStorage.setItem(readRowsLocalStorageKey, JSON.stringify(readRows));
  };

  // set state of read rows with local storage data when component mounts
  useEffect(() => {
    const readRowsLocal = localStorage.getItem(readRowsLocalStorageKey);
    if (readRowsLocal !== null) {
      // try catch in case the local storage value cannot be manipulated as an array
      try {
        const readRows = JSON.parse(readRowsLocal);
        if (Array.isArray(readRows)) {
          readDataRef.current = readRows;
        }
      } catch (err) {
        console.error("error message from reading localStorage", err);
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async (searchParams) => {
      const validParams = filterSearchParams(searchParams);
      validParams.push({
        id: new Date().getTime(), // value of the id dees not matter here, for format integrity only
        operant: "eq",
        key: "region",
        value0: cityName,
        type: DEFAULT_VALUE_TYPES.TEXT,
      });

      const { serviceBy } = validParams;
      const params = { ...validParams };
      const serviceByString = isNaN(Date.parse(serviceBy))
        ? ""
        : DateUtils.getyyyyMMdd(new Date(serviceBy));
      params.serviceBy = serviceByString;
      setIsLoading(true);

      const { startDate, endDate, region = cityName, ...restParams } = params;

      const workOrderFilter = getWorkorderFilter(validParams);
      try {
        // for UX, the one from getAWSToken() works 99% of the cases
        // this one is in case user refresh the page and the
        // token should be used right await
        const token = await getAWSTOkenAsync();
        const data = await getWorkOrderData(token, workOrderFilter, timezone);
        setData(data);
      } catch (error) {
        alert("Error in getting work order");
        console.error(`error in getting work order`, error);
      } finally {
        setIsLoading(false);
      }
    };
    searchParams && fetchData(searchParams);
  }, [searchParams, cityName, timezone]);

  useEffect(() => {
    onDataUpdated(data);
  }, [data, onDataUpdated]);

  const removeRow = async (oldData) => {
    if (oldData.region === "TELUS") {
      alert("For demo purpose, the work order cannot be deleted");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const { id } = oldData;
    try {
      const deleteResult = await deleteWorkOrder({
        variables: {
          id: id,
        },
      });

      if (deleteResult?.data?.deleteWorkOrder.id) {
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        onDataUpdated();
      }
      const dataDelete = [...data];
      const index = oldData.tableData.id;
      dataDelete.splice(index, 1);
      setData([...dataDelete]);
      onDataUpdated();
    } catch (error) {
      showAlert("Error in deleting work order: " + error.message || "");
      console.error(`Error in deleting work order`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const addRow = (newData) => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        setData([...data, newData]);
        setIsLoading(false);

        resolve();
      }, 1000);
    });
  };

  useEffect(() => {
    if (updatedData) {
      setData((prev) => {
        const dataUpdate = [...prev];

        const idField = WORK_ORDER_DATA_FIELDS_COMMON.ID;
        const index = dataUpdate.findIndex(
          (datum) => datum[idField] === updatedData[idField]
        );
        const oldData = dataUpdate[index];

        // sync updates local varialbes, ie: inspected_time_local, etc
        const merged = { ...oldData, ...updatedData };
        dataUpdate[index] = merged;

        return dataUpdate;
      });
    }
  }, [updatedData]);

  // change table's locale when language is updated.
  useEffect(() => {
    setLocalization(TABLE_LOCALIZATION_MAP[language]);
  }, [language]);

  const handleExportPdf = async () => {
    setPopoverAnchor(null);

    const pdfContentView = (
      <WorkorderPdfSimplifiedView data={data} searchParams={searchParams} />
    );

    try {
      setIsLoading(true);
      const pdfContent = await pdf(pdfContentView).toBlob();
      window.open(URL.createObjectURL(pdfContent), "_blank");
    } catch (error) {
      alert("Error in converting pdf content");
      console.error("Error in converting pdf content", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleExportSummaryPdf = async () => {
    setPopoverAnchor(null);

    const pdfContentView = (
      <WorkOrderSummayPdf
        data={getWorkorderDataSummary(data, language)}
        searchParams={searchParams}
      />
    );
    try {
      setIsLoading(true);
      const pdfContent = await pdf(pdfContentView).toBlob();
      window.open(URL.createObjectURL(pdfContent), "_blank");
    } catch (error) {
      alert("Error in converting pdf content");
      console.error("Error in converting pdf content", error);
    } finally {
      // setIsPreparingPDF(false);
      setIsLoading(false);
    }
  };

  const handleExportCSV = () => {
    setPopoverAnchor(null);

    /**
     * object to translate datas
     * key matches key in a single data
     * value represent the translation type
     */
    const translationMap = {
      category: WORK_ORDER_TABLE_TRANSLATION_KEY_MAP.CATEGORY,
      w_o_status: WORK_ORDER_TABLE_TRANSLATION_KEY_MAP.W_O_STATUS,
      w_o_type: WORK_ORDER_TABLE_TRANSLATION_KEY_MAP.W_O_TYPE,
      heading: WORK_ORDER_TABLE_TRANSLATION_KEY_MAP.HEADING,
      open_datetime_local: "Date",
      inspected_on_local: "Date",
      service_by_local: "Date",
    };

    const translatedData = data.map((d) => {
      const copy = JSON.parse(JSON.stringify(d));
      Object.keys(translationMap).forEach((key) => {
        const value = translationMap[key];
        if (value === "Date") {
          copy[key] =
            getLocaleDateTime(copy[key], language, "FULL") || DEFAULT_VALUE;
        } else {
          if (copy[key]) {
            const k = value[CREATE_LANG_KEY(copy[key])];
            if (k !== "" && k !== null && k !== undefined) {
              copy[key] = t(k);
            }
          }
        }
      });
      return copy;
    });

    const csvFileName = `${cityName}-${t(
      REPORT_TRANSLATIONS_MAP.TITLE.WORK_ORDER
    )} ${getLocaleDateTime(new Date(), language)}`;

    const citySpecifiKeys = interested_fields;
    /** all keys in a single data */
    const allKeys = [
      ...Object.values(CSV_REPORT_FIELDS),
      ...citySpecifiKeys.map((ele) => ele.fieldName),
    ];

    /**
     * key is the key name where points to actual value
     * value is the translated title for header
     */
    const keyTitleMap = allKeys.reduce((acc, ele) => {
      acc[ele] = t(KEYS[ele.toUpperCase()]);
      return acc;
    }, {});

    /**
     * key value that value has been localized, not the key, yet
     */
    const localizedSearchParams = getRenderSearchParams(searchParams, language);
    let kvs = [];
    localizedSearchParams.forEach((ele) => {
      const { searchTitle, value } = ele;
      kvs.push(`${searchTitle}: ${value}`);
    });

    const sheetTitle = kvs.join(" ");
    const xlsxContent = Object.entries(keyTitleMap).map((entry) => {
      const [key, title] = entry;
      return { label: title, value: (row) => row[key] };
    });
    let cData = [
      {
        sheet: "data",
        columns: xlsxContent,
        content: translatedData,
      },
    ];
    let settings = {
      fileName: csvFileName + " " + sheetTitle, // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      // RTL: true // Display the columns from right-to-left (the default value is false)
    };

    xlsx(cData, settings);
  };
  const handleExportSummaryCSV = () => {
    setPopoverAnchor(null);
    // const csvFileName = `Work Order Summary ${DateUtils.getyyyyMMddHHmmss(
    //   new Date()
    // )}`;

    const csvFileName = `${t(
      REPORT_TRANSLATIONS_MAP.TITLE.WORK_ORDER_SUMMARY
    )} ${getLocaleDateTime(new Date(), language)}`;

    JSONtoReportXlsx(
      getWorkorderDataSummary(data),
      csvFileName,
      getRenderSearchParams(searchParams, language),
      i18n
    );
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        {data && data.length > 0 && (
          <>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleExportButtonClick}
              endIcon={<GetAppIcon />}
              style={{ marginLeft: "auto" }}
              isLoading={isLoading}
            >
              {TRANSLATE("common:EXPORT")}
            </LoadingButton>
            <Menu
              open={popoverAnchor !== null}
              anchorEl={popoverAnchor}
              onClose={handlePopoverClosed}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleExportCSV}>
                <ListItemIcon style={{ minWidth: 36 }}>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${t(
                    REPORT_TRANSLATIONS_MAP.TITLE.WORK_ORDER
                  )} (xlsx)`}
                />
              </MenuItem>
              <MenuItem onClick={handleExportPdf}>
                <ListItemIcon style={{ minWidth: 36 }}>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${t(
                    REPORT_TRANSLATIONS_MAP.TITLE.WORK_ORDER
                  )} (pdf)`}
                />
              </MenuItem>
              <MenuItem onClick={handleExportSummaryCSV}>
                <ListItemIcon style={{ minWidth: 36 }}>
                  <AssignmentTurnedInIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${t(
                    REPORT_TRANSLATIONS_MAP.TITLE.WORK_ORDER_SUMMARY
                  )} (xlsx)`}
                />
              </MenuItem>
              <MenuItem onClick={handleExportSummaryPdf}>
                <ListItemIcon style={{ minWidth: 36 }}>
                  <AssignmentTurnedInIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${t(
                    REPORT_TRANSLATIONS_MAP.TITLE.WORK_ORDER_SUMMARY
                  )} (pdf)`}
                />
              </MenuItem>
            </Menu>
          </>
        )}
      </div>
      <MaterialTable
        isLoading={isLoading}
        title={t(REPORT_TRANSLATIONS_MAP.TITLE.WORK_ORDER)}
        columns={columns}
        data={data}
        onRowClick={(event, rowData, togglePanel) => {
          togglePanel();
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ backgroundColor: "#e8eaf5", paddingBottom: "1rem" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        options={{
          // maxBodyHeight: 500,
          pageSize: 20, // default number of rows per page
          pageSizeOptions: [5, 10, 20], // rows selection options, 5 rows per page, 10 rows per page, 20 rows per page
          toolbar: false, // remove table title
          search: false,
          sorting: true,
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
            // position: "sticky",
            // top: 0
            fontSize: "1rem",
            padding: 10,
          },
          rowStyle: (rowData) => {
            const readRowIds = readDataRef.current;
            return {
              fontSize: "1rem",
              padding: 10,
              backgroundColor:
                readRowIds.indexOf(rowData.id) !== -1 ? "#fff" : "#e8eaf5",
            };
          },
        }}
        detailPanel={(rowData) => {
          return (
            <RowDetailsView
              rowData={rowData}
              handleReadRow={handleReadRow}
              showImageModal={showImageModal}
              onRemoveRow={removeRow}
              onEditBtnClick={props.handleRowEditBtnClick}
            />
          );
        }}
        localization={localization}
      />
    </div>
  );
}

const MemorizedTable = React.memo(WOTable);
function TableContainer(props) {
  const [openEditRowModal, setOpenEditRowModal] = useState({
    open: false,
    data: null,
  });

  const [updatedData, setUpdatedData] = useState(null);
  const updateRow = useCallback((newData) => {
    setUpdatedData(newData);
  }, []);

  const closeModal = useCallback(
    () => setOpenEditRowModal({ open: false, data: null }),
    []
  );

  const handleRowEditBtnClick = useCallback((rowData) => {
    setOpenEditRowModal({ open: true, data: rowData });
  }, []);

  return (
    <>
      <MemorizedTable
        {...props}
        handleRowEditBtnClick={handleRowEditBtnClick}
        updatedData={updatedData}
      />
      <EditRowModal
        openModal={openEditRowModal}
        closeModal={closeModal}
        onEditData={updateRow}
      />
    </>
  );
}

const JSONtoReportXlsx = (JSONData, reportFileName, searchParams, i18n) => {
  let kvs = [];
  searchParams.forEach((ele) => {
    const { searchTitle, value } = ele;
    // translate the keys
    kvs.push(`${searchTitle}: ${value}`);
  });

  /** localize suffix string */
  const sheetTitleSuffix = kvs.join(" ");

  const fileName = `${reportFileName} ${sheetTitleSuffix}`;

  //1st loop is to extract each row

  const statusSheetTitle = i18n.t(
    REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.WORK_ORDER_STATUS
  );
  const statusRowHeadersColumns = [
    {
      label: statusSheetTitle,
      value: (row) => {
        const { category, defectTitle } = row;
        if (defectTitle) return defectTitle;
        return getCategoryTitle(category, i18n);
      },
    },
    {
      label: i18n.t(REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.WORK_ORDER_OPENED),
      value: (row) => {
        return row.opened.length;
      },
    },
    {
      label: i18n.t(REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.WORK_ORDER_CLOSED),
      value: (row) => row.closed.length,
    },
    {
      label: i18n.t(REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.TOTAL),
      value: (row) => row.items.length,
    },
    {
      label: i18n.t(REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.COMPLETED_PERCENT),
      value: (row) => row.completion,
    },
  ];

  const statusRowContent = [];

  const workOrderComplianceSheetTitle = i18n.t(
    REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.WORK_ORDER_COMPLIANCE
  );
  const workOrderComplianceColumns = [
    {
      label: workOrderComplianceSheetTitle,
      value: (row) => {
        const { category, defectTitle } = row;
        if (defectTitle) return defectTitle;
        return getCategoryTitle(category, i18n);
      },
    },
    {
      label:
        "# " + i18n.t(REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.IN_COMPLIANCE),
      value: (row) => row.inspectedBeforeServiceBy.length,
    },
    {
      label:
        "# " + i18n.t(REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.NON_COMPLIANCE),
      value: (row) => row.inspectedAfterServiceBy.length,
    },
    {
      label: i18n.t(REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.TOTAL),
      value: (row) => row.closed.length,
    },
    {
      label: i18n.t(REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.COMPLETED_PERCENT),
      value: (row) => row.compliance,
    },
    {
      label: i18n.t(
        REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.NON_COMPLETED_PERCENT
      ),
      value: (row) => row.inCompliance,
    },
  ];

  const worOrderComplianceContent = [];

  const workOrderOverdueSheetTitle = i18n.t(
    REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.WORK_ORDER_OVER_DUE
  );
  const workOrderOverDueColumns = [
    {
      label: workOrderOverdueSheetTitle,
      value: (row) => {
        const { category, defectTitle } = row;
        if (defectTitle) return defectTitle;
        return getCategoryTitle(category, i18n);
      },
    },
    {
      label: "# " + i18n.t(REPORT_TRANSLATIONS_MAP.REPORT_HEADERS.OVER_DUE),
      value: (row) => row.overDue.length,
    },
    {
      label: "Total",
      value: (row) => row.items.length,
    },
  ];

  const workOrderOverDueContents = [];

  for (const category in JSONData) {
    if (Object.hasOwnProperty.call(JSONData, category)) {
      const element = JSONData[category];
      // statusRowContent.push({ ...element.report, category });
      // worOrderComplianceContent.push({ ...element.report, category });
      // workOrderOverDueContents.push({ ...element.report, category });
      for (const defectType in element) {
        if (Object.hasOwnProperty.call(element, defectType)) {
          if (defectType !== "report") {
            const type = element[defectType];

            const defectTitle = getDefectTitle(defectType, i18n);

            statusRowContent.push({
              ...type.report,
              defectTitle,
              items: type.items,
            });
            worOrderComplianceContent.push({
              ...type.report,
              defectTitle,
              items: type.items,
            });
            workOrderOverDueContents.push({
              ...type.report,
              defectTitle,
              items: type.items,
            });
          }
        }
      }
    }
  }

  const data = [
    {
      sheet: statusSheetTitle.substring(0, 18),
      columns: statusRowHeadersColumns,
      content: statusRowContent,
    },
    {
      sheet: workOrderComplianceSheetTitle.substring(0, 18),
      columns: workOrderComplianceColumns,
      content: worOrderComplianceContent,
    },
    {
      sheet: workOrderOverdueSheetTitle.substring(0, 18),
      columns: workOrderOverDueColumns,
      content: workOrderOverDueContents,
    },
  ];

  let settings = {
    fileName: fileName, // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
  };

  xlsx(data, settings);
};
export default withImageModal(TableContainer);
