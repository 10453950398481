import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-US";
import jpLocale from "date-fns/locale/ja";

class FrLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "d MMM yyyy", { locale: this.locale });
  }
}

export const localeUtilsMap = {
  en: DateFnsUtils,
  fr: FrLocalizedUtils,
  ja: DateFnsUtils,
};

export const localeMap = {
  en: enLocale,
  fr: frLocale,
  ja: jpLocale,
};

export const localeFormatMap = {
  en: "MMM d, yyyy",
  fr: "d MMM yyyy",
  ja: "yyyy MMM d",
};

export const localeDateTimeFormat = {
  en: "MMM d, yyyy hh:mm a",
  fr: "d MMM yyyy HH:mm",
  ja: "MMM d, yyyy hh:mm a",
};

export const localeCancelLabelMap = {
  en: "Cancel",
  fr: "Annuler",
  ja: "キャンセル",
};
