import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Langauge Files
import COMMON from "../components/language/Common.translation.json";
import EDIT_ROW_MODEL from "../components/table/tableModals/EditRowModal.translate.json";
import {
  ROOT_KEY as WORK_ORDER_TRANSLATION_ROOT_KEY,
  WORK_ORDER_TABLE,
} from "../components/table/WorkorderTable.translate.js";
import {
  ROOT_KEY as REPORT_TRANSLATION_ROOT_KEY,
  REPORT_TRANSLATIONS,
} from "reports/translation/report.translation";
import {
  ROOT_KEY as WORK_ORDER_DATA_TRANSLATION_KEY,
  WORK_ORDER_DATA_TRANSLATIONS,
} from "./dataTranslations/workOrderData.translation";
import {
  ROOT_KEY as WEATHER_COMPONENT_TRANSLATION_KEY,
  WEATHER_COMPONENT_TRANSLATIONS,
} from "components/Weather.translate";
import {
  ROOT_KEY as DASHBOARD_COMPONENT_TRANSLATION_KEY,
  DASHBOARD_COMPONENT_DICTS,
} from "components/Dashboard.translation";
import {
  ROOT_KEY as DYNAMONDB_FILTER_KEY,
  DYNAMONDB_FILTER_TRANSLATIONS,
} from "components/DynamonDBFilter/translation";

export const SUPPORTED_LANG = [
  { label: "English", value: "en" },
  { label: "Français", value: "fr" },
  { label: "日本語", value: "ja" },
];

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18ne xt-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    supportedLngs: SUPPORTED_LANG.map((lng) => lng.value),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        common: COMMON.en,
        translation: {
          [WEATHER_COMPONENT_TRANSLATION_KEY]:
            WEATHER_COMPONENT_TRANSLATIONS.en,
          [DASHBOARD_COMPONENT_TRANSLATION_KEY]: DASHBOARD_COMPONENT_DICTS.en,
          [WORK_ORDER_TRANSLATION_ROOT_KEY]: WORK_ORDER_TABLE.en,
          [REPORT_TRANSLATION_ROOT_KEY]: REPORT_TRANSLATIONS.en,
          [WORK_ORDER_DATA_TRANSLATION_KEY]: WORK_ORDER_DATA_TRANSLATIONS.en,
          [DYNAMONDB_FILTER_KEY]: DYNAMONDB_FILTER_TRANSLATIONS.en,
          EDIT_ROW_MODEL: EDIT_ROW_MODEL.en,
        },
      },
      fr: {
        common: COMMON.fr,
        translation: {
          [WEATHER_COMPONENT_TRANSLATION_KEY]:
            WEATHER_COMPONENT_TRANSLATIONS.fr,
          [DASHBOARD_COMPONENT_TRANSLATION_KEY]: DASHBOARD_COMPONENT_DICTS.fr,
          [WORK_ORDER_TRANSLATION_ROOT_KEY]: WORK_ORDER_TABLE.fr,
          [REPORT_TRANSLATION_ROOT_KEY]: REPORT_TRANSLATIONS.fr,
          [WORK_ORDER_DATA_TRANSLATION_KEY]: WORK_ORDER_DATA_TRANSLATIONS.fr,
          [DYNAMONDB_FILTER_KEY]: DYNAMONDB_FILTER_TRANSLATIONS.fr,
          EDIT_ROW_MODEL: EDIT_ROW_MODEL.fr,
        },
      },
      ja: {
        common: COMMON.ja,
        translation: {
          [WEATHER_COMPONENT_TRANSLATION_KEY]:
            WEATHER_COMPONENT_TRANSLATIONS.ja,
          [DASHBOARD_COMPONENT_TRANSLATION_KEY]: DASHBOARD_COMPONENT_DICTS.ja,
          [WORK_ORDER_TRANSLATION_ROOT_KEY]: WORK_ORDER_TABLE.ja,
          [REPORT_TRANSLATION_ROOT_KEY]: REPORT_TRANSLATIONS.ja,
          [WORK_ORDER_DATA_TRANSLATION_KEY]: WORK_ORDER_DATA_TRANSLATIONS.ja,
          [DYNAMONDB_FILTER_KEY]: DYNAMONDB_FILTER_TRANSLATIONS.ja,
          EDIT_ROW_MODEL: EDIT_ROW_MODEL.ja,
        },
      },
    },

    // for language dector https://github.com/i18next/i18next-browser-languageDetector
    detection: {
      order: ["cookie", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
  });

export default i18n;
