import React from "react";
import { Document, View, Text, Image } from "@react-pdf/renderer";
import PdfPage from "./pdfComponents/PdfPage";
import PdfPageHeader from "./pdfComponents/PdfPageHeader";
import LineBreak from "./pdfComponents/LineBreak";
import {
  getAvailableSize,
  getRowStyle,
  ORIENTATIONS,
  tableHeaderStyle,
  tableRowStyle,
} from "components/table/file/styles";

const { width: availableWidth } = getAvailableSize(
  "LETTER",
  ORIENTATIONS.LANDSCAPE
);
const cellStyle = { width: (availableWidth - 10) / 7 };

export default function PatrollSummary({ data: summaryData, dateRange }) {
  const {
    startDate = new Date().toISOString(),
    endDate = new Date().toISOString(),
  } = dateRange;
  // const patrolSummary = summaryData;
  // "to": 10,
  // "section_id": 10,
  // "region": "Montreal",
  // "name": "Lucien",
  // "from": 10,
  // "date": "2021-10-02",
  // "class": 10
  return (
    <Document>
      <PdfPage pagination={true}>
        <PdfPageHeader
          logoUrl="https://surveymonkey-assets.s3.amazonaws.com/survey/164524031/aee40dac-27bf-4cb9-9668-89aae37b459b.jpg"
          pageHeader="City of Vaughan"
          title="Roads Patrolled Summary"
          subtitle={`From ${startDate} to ${endDate}`}
          reportDate={`Report date: ${new Date().toJSON().split("T")[0]}`}
        />
        <View style={{ ...tableHeaderStyle, width: availableWidth }} fixed>
          <Text style={cellStyle}>Road name</Text>
          <Text style={cellStyle}>From</Text>
          <Text style={cellStyle}>To</Text>
          <Text style={cellStyle}>Class</Text>
          <Text style={cellStyle}>Section ID</Text>
          <Text style={cellStyle}>Region</Text>
          <Text style={cellStyle}>Last</Text>
        </View>

        {summaryData.map((d, index) => {
          const {
            name: road_name,
            from,
            to,
            class: roadClass,
            section_id,
            region,
            date: last_patrol,
          } = d;
          return (
            <View key={index} style={getRowStyle(index)}>
              <Text style={cellStyle}>{road_name}</Text>

              <Text style={cellStyle}>{from}</Text>
              <Text style={cellStyle}>{to}</Text>
              <Text style={cellStyle}>{roadClass}</Text>
              <Text style={cellStyle}>{section_id}</Text>
              <Text style={cellStyle}>{region}</Text>
              <Text style={cellStyle}>{last_patrol}</Text>
            </View>
          );
        })}
      </PdfPage>
    </Document>
  );
}
