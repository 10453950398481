export const DEFAULT_OPERANTS = {
  EQ: "eq",
  BETWEEN: "between",
  IN: "in",
};

export const DEFAULT_VALUE_TYPES = {
  DATE: "DATE",
  TEXT: "TEXT",
  SELECT: "SELECT",
};

export const DYNAMONDB_OPERANTS = {
  in: { key: "in", title: "matches" },
};

export const DYNAMONDB_OPERANTS_DATE = {
  eq: { key: DEFAULT_OPERANTS.EQ, title: "equal" },
  lt: { key: "lt", title: "less than" },
  gt: { key: "gt", title: "greater than" },
  between: { key: DEFAULT_OPERANTS.BETWEEN, title: "between" },
};
export const DYNAMONDB_OPERANTS_SELECTION = {
  in: { key: "in", title: "matches" },
};

/** fitlers that should not be used for filtering */
export const IGNORED_FILTERS = [
  "dts",
  "image_url",
  "map_image_url",
  "open_date",
  "open_time",
  "status", // due to translation issue, ie: opened vs ouvre
];

/**
 * fiels' values that should not be translated.
 */
export const UNTRANSLATED_FIELS = ["assign_to"];

// filters whose value should be selection dropdown
export const FILTERS_SELECT = [
  "assign_to",
  "category",
  "heading",
  "w_o_status",
  "w_o_type",
];

/** filters whose values, in string format, should be treated (handled) as date object */
export const DATE_FIELDS = [
  "service_by",
  "open_datetime",
  "inspected_on",
  "open_date",
];
