export const ROOT_KEY = "WORK_ORDER_TABLE";
const TABLE_HEADERS = "TABLE_HEADERS";
const W_O_STATUS = "W_O_STATUS";
const CATEGORY = "CATEGORY";
const WORK_ORDER_EMAIL_FIELDS = "WORK_ORDER_EMAIL_FIELDS";
const HEADING = "HEADING";
const W_O_TYPE = "W_O_TYPE";

export const KEYS = {
  [TABLE_HEADERS]: {
    OPENED: `${ROOT_KEY}.${TABLE_HEADERS}.OPENED`,
    SERVICE_BY: `${ROOT_KEY}.${TABLE_HEADERS}.SERVICE_BY`,
    DTS: `${ROOT_KEY}.${TABLE_HEADERS}.DTS`,
    CATEGORY: `${ROOT_KEY}.${TABLE_HEADERS}.CATEGORY`,
    TYPE: `${ROOT_KEY}.${TABLE_HEADERS}.TYPE`,
    SEVERITY: `${ROOT_KEY}.${TABLE_HEADERS}.SEVERITY`,
    STATUS: `${ROOT_KEY}.${TABLE_HEADERS}.STATUS`,
    REGION: `${ROOT_KEY}.${TABLE_HEADERS}.REGION`,
    WORK_ORDER_ID: `${ROOT_KEY}.${TABLE_HEADERS}.WORK_ORDER_ID`,
    WORK_ORDER: `${ROOT_KEY}.${TABLE_HEADERS}.WORK_ORDER`,
    ASSIGNED_TO: `${ROOT_KEY}.${TABLE_HEADERS}.ASSIGNED_TO`,
    ASSIGN_TO: `${ROOT_KEY}.${TABLE_HEADERS}.ASSIGN_TO`,
    LANE: `${ROOT_KEY}.${TABLE_HEADERS}.LANE`,
    INSPECTED_ON: `${ROOT_KEY}.${TABLE_HEADERS}.INSPECTED_ON`,
    INSPECTED_BY: `${ROOT_KEY}.${TABLE_HEADERS}.INSPECTED_BY`,
    UPON_COMPLETION_SUBMIT_TO: `${ROOT_KEY}.${TABLE_HEADERS}.UPON_COMPLETION_SUBMIT_TO`,
  },
  [W_O_STATUS]: {
    OPENED: `${ROOT_KEY}.${W_O_STATUS}.OPENED`,
    CLOSED: `${ROOT_KEY}.${W_O_STATUS}.CLOSED`,
    IGNORED: `${ROOT_KEY}.${W_O_STATUS}.IGNORED`,
  },
  [HEADING]: {
    N: `${ROOT_KEY}.${HEADING}.N`,
    E: `${ROOT_KEY}.${HEADING}.E`,
    S: `${ROOT_KEY}.${HEADING}.S`,
    W: `${ROOT_KEY}.${HEADING}.W`,
    NE: `${ROOT_KEY}.${HEADING}.NE`,
    SE: `${ROOT_KEY}.${HEADING}.SE`,
    NW: `${ROOT_KEY}.${HEADING}.NW`,
    SW: `${ROOT_KEY}.${HEADING}.SW`,
    // for EGIS
    1: `${ROOT_KEY}.${HEADING}.1`,
    2: `${ROOT_KEY}.${HEADING}.2`,
  },
  [CATEGORY]: {
    BARRIER: `${ROOT_KEY}.${CATEGORY}.BARRIER`,
    BRIDGE: `${ROOT_KEY}.${CATEGORY}.BRIDGE`,
    DRAINAGE: `${ROOT_KEY}.${CATEGORY}.DRAINAGE`,
    OTHER_ADD_NOTES_: `${ROOT_KEY}.${CATEGORY}.OTHER_ADD_NOTES_`,
    ROAD_ACCIDENT: `${ROOT_KEY}.${CATEGORY}.ROAD_ACCIDENT`,
    ROAD_CONDITION_CHANGE: `${ROOT_KEY}.${CATEGORY}.ROAD_CONDITION_CHANGE`,
    ROADS_STANDARD: `${ROOT_KEY}.${CATEGORY}.ROADS_STANDARD`,
    ROADWAY: `${ROOT_KEY}.${CATEGORY}.ROADWAY`,
    SIDEWALK: `${ROOT_KEY}.${CATEGORY}.SIDEWALK`,
    SIGN: `${ROOT_KEY}.${CATEGORY}.SIGN`,
    WEATHER: `${ROOT_KEY}.${CATEGORY}.WEATHER`,
  },
  [WORK_ORDER_EMAIL_FIELDS]: {
    DAMAGE_TYPE: `${ROOT_KEY}.${WORK_ORDER_EMAIL_FIELDS}.DAMAGE_TYPE`,
    ROAD_CLASS: `${ROOT_KEY}.${WORK_ORDER_EMAIL_FIELDS}.ROAD_CLASS`,
    ISSUE_DATE_TIME: `${ROOT_KEY}.${WORK_ORDER_EMAIL_FIELDS}.ISSUE_DATE_TIME`,
    REPAIR_BY: `${ROOT_KEY}.${WORK_ORDER_EMAIL_FIELDS}.REPAIR_BY`,
    LOCATION: `${ROOT_KEY}.${WORK_ORDER_EMAIL_FIELDS}.LOCATION`,
    COMMENTS: `${ROOT_KEY}.${WORK_ORDER_EMAIL_FIELDS}.COMMENTS`,
  },
  [W_O_TYPE]: {
    ABANDONED_APPLIANCE: `${ROOT_KEY}.${W_O_TYPE}.ABANDONED_APPLIANCE`,
    CABLE_POST_GUIDRAIL: `${ROOT_KEY}.${W_O_TYPE}.CABLE_POST_GUIDRAIL`,
    CHECKERBOARD: `${ROOT_KEY}.${W_O_TYPE}.CHECKERBOARD`,
    DAMAGED_ELECTRICAL_BOX: `${ROOT_KEY}.${W_O_TYPE}.DAMAGED_ELECTRICAL_BOX`,
    DAMAGED_PEDESTAL: `${ROOT_KEY}.${W_O_TYPE}.DAMAGED_PEDESTAL`,
    DAMAGED_SIGN: `${ROOT_KEY}.${W_O_TYPE}.DAMAGED_SIGN`,
    EDGE_CRACKING_MEDIUM_SEVERITY: `${ROOT_KEY}.${W_O_TYPE}.EDGE_CRACKING_MEDIUM_SEVERITY`,
    GENERAL: `${ROOT_KEY}.${W_O_TYPE}.GENERAL`,
    GRAFFITI_NORMAL: `${ROOT_KEY}.${W_O_TYPE}.GRAFFITI_NORMAL`,
    GUARDRAIL_DAMAGED: `${ROOT_KEY}.${W_O_TYPE}.GUARDRAIL_DAMAGED`,
    LONGITUDINAL_CRACK_OUTSIDE_OF_WHEEL_TRACK: `${ROOT_KEY}.${W_O_TYPE}.LONGITUDINAL_CRACK_OUTSIDE_OF_WHEEL_TRACK`,
    LONGITUDINAL_CRACK_WHEEL_TRACK: `${ROOT_KEY}.${W_O_TYPE}.LONGITUDINAL_CRACK_WHEEL_TRACK`,
    NETWORK_CRACKS_WHEEL_TRACK: `${ROOT_KEY}.${W_O_TYPE}.NETWORK_CRACKS_WHEEL_TRACK`,
    NETWORK_CRACKS_OUTSIDE_OF_WHEEL_TRACK: `${ROOT_KEY}.${W_O_TYPE}.NETWORK_CRACKS_OUTSIDE_OF_WHEEL_TRACK`,
    OTHER_ADD_NOTE_: `${ROOT_KEY}.${W_O_TYPE}.OTHER_ADD_NOTE_`,
    PAVEMENT_MARKING: `${ROOT_KEY}.${W_O_TYPE}.PAVEMENT_MARKING`,
    POTHOLE_PAVED_SHOULDER: `${ROOT_KEY}.${W_O_TYPE}.POTHOLE_PAVED_SHOULDER`,
    POTHOLE_NON_PAVED_SURFACE: `${ROOT_KEY}.${W_O_TYPE}.POTHOLE_NON_PAVED_SURFACE`,
    REFLECTOR_DAMAGED: `${ROOT_KEY}.${W_O_TYPE}.REFLECTOR_DAMAGED`,
    ROAD_MARKINGS_DEFECT: `${ROOT_KEY}.${W_O_TYPE}.ROAD_MARKINGS_DEFECT`,
    SHOES_ON_POWERLINE: `${ROOT_KEY}.${W_O_TYPE}.SHOES_ON_POWERLINE`,
    SHOPPING_CARTS: `${ROOT_KEY}.${W_O_TYPE}.SHOPPING_CARTS`,
    SHOULDER_DROP_OFF: `${ROOT_KEY}.${W_O_TYPE}.SHOULDER_DROP_OFF`,
    SHOVING_MEDIUM_SEVERITY: `${ROOT_KEY}.${W_O_TYPE}.SHOVING_MEDIUM_SEVERITY`,
    SIGNAGE_ILLEGIBLE: `${ROOT_KEY}.${W_O_TYPE}.SIGNAGE_ILLEGIBLE`,
    SIGNAGE_MISSING: `${ROOT_KEY}.${W_O_TYPE}.SIGNAGE_MISSING`,
    STOP: `${ROOT_KEY}.${W_O_TYPE}.STOP`,
    STOP_AHEAD: `${ROOT_KEY}.${W_O_TYPE}.STOP_AHEAD`,
    TRAFFIC_SIGNAL_AHEAD_NEW: `${ROOT_KEY}.${W_O_TYPE}.TRAFFIC_SIGNAL_AHEAD_NEW`,
    TRANSVERSE_CRACKING: `${ROOT_KEY}.${W_O_TYPE}.TRANSVERSE_CRACKING`,
    TRASH_ACCUMULATION_ROADWAY: `${ROOT_KEY}.${W_O_TYPE}.TRASH_ACCUMULATION_ROADWAY`,
    TRASH_ACCUMULATION_SIDEWALK: `${ROOT_KEY}.${W_O_TYPE}.TRASH_ACCUMULATION_SIDEWALK`,
    TWO_WAY_TRAFFIC_AHEAD: `${ROOT_KEY}.${W_O_TYPE}.TWO_WAY_TRAFFIC_AHEAD`,
    WEATHERING_RAVELING_MEDIUM_SEVERITY: `${ROOT_KEY}.${W_O_TYPE}.WEATHERING_RAVELING_MEDIUM_SEVERITY`,
    YIELD: `${ROOT_KEY}.${W_O_TYPE}.YIELD`,
    TRANSVERSE_CRACKING: `${ROOT_KEY}.${W_O_TYPE}.TRANSVERSE_CRACKING`,
    LONGITUDINAL_CRACK_WHEEL_TRACK: `${ROOT_KEY}.${W_O_TYPE}.LONGITUDINAL_CRACK_WHEEL_TRACK`,
    LONGITUDINAL_CRACK_OUTSIDE_OF_WHEEL_TRACK: `${ROOT_KEY}.${W_O_TYPE}.LONGITUDINAL_CRACK_OUTSIDE_OF_WHEEL_TRACK`,
    GRID_CRACKS: `${ROOT_KEY}.${W_O_TYPE}.GRID_CRACKS`,
    JOINT_CRACKS: `${ROOT_KEY}.${W_O_TYPE}.JOINT_CRACKS`,
    CRACKS_FROM_SOIL_MOVEMENT: `${ROOT_KEY}.${W_O_TYPE}.CRACKS_FROM_SOIL_MOVEMENT`,
    NETWORK_CRACKS_WHEEL_TRACK: `${ROOT_KEY}.${W_O_TYPE}.NETWORK_CRACKS_WHEEL_TRACK`,
    NETWORK_CRACKS_OUTSIDE_OF_WHEEL_TRACK: `${ROOT_KEY}.${W_O_TYPE}.NETWORK_CRACKS_OUTSIDE_OF_WHEEL_TRACK`,
    CIRCULAR_NETWORK_CRACKS: `${ROOT_KEY}.${W_O_TYPE}.CIRCULAR_NETWORK_CRACKS`,
    ANGLED_CRACK: `${ROOT_KEY}.${W_O_TYPE}.ANGLED_CRACK`,
    OBLIQUE_CRACK: `${ROOT_KEY}.${W_O_TYPE}.OBLIQUE_CRACK`,
    DEBRIS: `${ROOT_KEY}.${W_O_TYPE}.DEBRIS`,
    POTHOLE_PAVED_SURFACE: `${ROOT_KEY}.${W_O_TYPE}.POTHOLE_PAVED_SURFACE`,
    POTHOLE_NON_PAVED_SURFACE: `${ROOT_KEY}.${W_O_TYPE}.POTHOLE_NON_PAVED_SURFACE`,
    SURFACE_DISCONTINUITIES: `${ROOT_KEY}.${W_O_TYPE}.SURFACE_DISCONTINUITIES`,
    DAMAGED_ELECTRICAL_BOX: `${ROOT_KEY}.${W_O_TYPE}.DAMAGED_ELECTRICAL_BOX`,
    DAMAGED_PEDESTAL: `${ROOT_KEY}.${W_O_TYPE}.DAMAGED_PEDESTAL`,
    DAMAGED_SIGN: `${ROOT_KEY}.${W_O_TYPE}.DAMAGED_SIGN`,
    ILLEGIBLE_SIGN: `${ROOT_KEY}.${W_O_TYPE}.ILLEGIBLE_SIGN`,
    MISSING_SIGN: `${ROOT_KEY}.${W_O_TYPE}.MISSING_SIGN`,
    OBSCURED_SIGN: `${ROOT_KEY}.${W_O_TYPE}.OBSCURED_SIGN`,
    IMPROPERLY_ORIENTED_SIGN: `${ROOT_KEY}.${W_O_TYPE}.IMPROPERLY_ORIENTED_SIGN`,
    LUMINAIRE: `${ROOT_KEY}.${W_O_TYPE}.LUMINAIRE`,
    OVERGROWN_VEGETATION: `${ROOT_KEY}.${W_O_TYPE}.OVERGROWN_VEGETATION`,
    GUARDRAIL_MARKER_HIDDEN: `${ROOT_KEY}.${W_O_TYPE}.GUARDRAIL_MARKER_HIDDEN`,
    GUARDRAIL_MARKER_TILT: `${ROOT_KEY}.${W_O_TYPE}.GUARDRAIL_MARKER_TILT`,
    CATCH_BASIN_BLOCK: `${ROOT_KEY}.${W_O_TYPE}.CATCH_BASIN_BLOCK`,
    SPEED_SIGNS_RB_1: `${ROOT_KEY}.${W_O_TYPE}.SPEED_SIGNS_RB_1`,
    CABLE_AND_POST_GUARDRAIL: `${ROOT_KEY}.${W_O_TYPE}.CABLE_AND_POST_GUARDRAIL`,
    RAMP: `${ROOT_KEY}.${W_O_TYPE}.RAMP`,

    RURAL_SIGN_911: `${ROOT_KEY}.${W_O_TYPE}.RURAL_SIGN_911`,
    ANIMAL_PICKUP_DEAD_DEER: `${ROOT_KEY}.${W_O_TYPE}.ANIMAL_PICKUP_DEAD_DEER`,
    ASPHALT_REPAIR: `${ROOT_KEY}.${W_O_TYPE}.ASPHALT_REPAIR`,
    BARE_DRY: `${ROOT_KEY}.${W_O_TYPE}.BARE_DRY`,
    BLVD_CONTRACT_GRASS_CUTTING: `${ROOT_KEY}.${W_O_TYPE}.BLVD_CONTRACT_GRASS_CUTTING`,
    BLVD_REPAIR_INTERLOCK_GRASS: `${ROOT_KEY}.${W_O_TYPE}.BLVD_REPAIR_INTERLOCK_GRASS`,
    BRIDGE_DECK_SPALL: `${ROOT_KEY}.${W_O_TYPE}.BRIDGE_DECK_SPALL`,
    CABLE_GUIDERAIL: `${ROOT_KEY}.${W_O_TYPE}.CABLE_GUIDERAIL`,
    CATCH_BASIN: `${ROOT_KEY}.${W_O_TYPE}.CATCH_BASIN`,
    CONCRETE_BARRIER: `${ROOT_KEY}.${W_O_TYPE}.CONCRETE_BARRIER`,
    CRACKS: `${ROOT_KEY}.${W_O_TYPE}.CRACKS`,
    CULVERT: `${ROOT_KEY}.${W_O_TYPE}.CULVERT`,
    CURB_AND_GUTTER: `${ROOT_KEY}.${W_O_TYPE}.CURB_AND_GUTTER`,
    CURB_SIDEWALK_REPAIR: `${ROOT_KEY}.${W_O_TYPE}.CURB_SIDEWALK_REPAIR`,
    DAMP_WET: `${ROOT_KEY}.${W_O_TYPE}.DAMP_WET`,
    DITCH_INLET: `${ROOT_KEY}.${W_O_TYPE}.DITCH_INLET`,
    FENCE: `${ROOT_KEY}.${W_O_TYPE}.FENCE`,
    FLOODING: `${ROOT_KEY}.${W_O_TYPE}.FLOODING`,
    GRADING_REQUIRED: `${ROOT_KEY}.${W_O_TYPE}.GRADING_REQUIRED`,
    GRAFFITI: `${ROOT_KEY}.${W_O_TYPE}.GRAFFITI`,
    ILLEGAL_DUMPING: `${ROOT_KEY}.${W_O_TYPE}.ILLEGAL_DUMPING`,
    MANHOLE: `${ROOT_KEY}.${W_O_TYPE}.MANHOLE`,
    OTHER_ADD_NOTES_: `${ROOT_KEY}.${W_O_TYPE}.OTHER_ADD_NOTES_`,
    POTHOLE_SHOULDER: `${ROOT_KEY}.${W_O_TYPE}.POTHOLE_SHOULDER`,
    REGULATORY_WARNING_SIGN_SEC_11: `${ROOT_KEY}.${W_O_TYPE}.REGULATORY_WARNING_SIGN_SEC_11`,
    REGULATORY_WARNING_SIGN_SEC_12: `${ROOT_KEY}.${W_O_TYPE}.REGULATORY_WARNING_SIGN_SEC_12`,
    ROAD_ACCIDENT: `${ROOT_KEY}.${W_O_TYPE}.ROAD_ACCIDENT`,
    ROAD_CLOSURE: `${ROOT_KEY}.${W_O_TYPE}.ROAD_CLOSURE`,
    ROAD_ICY: `${ROOT_KEY}.${W_O_TYPE}.ROAD_ICY`,
    ROAD_SLUSHY: `${ROOT_KEY}.${W_O_TYPE}.ROAD_SLUSHY`,
    ROAD_SNOW_COVERAGE_BY_CM: `${ROOT_KEY}.${W_O_TYPE}.ROAD_SNOW_COVERAGE_BY_CM`,
    ROAD_SNOW_DRIFTING: `${ROOT_KEY}.${W_O_TYPE}.ROAD_SNOW_DRIFTING`,
    ROAD_SNOW_PACKED: `${ROOT_KEY}.${W_O_TYPE}.ROAD_SNOW_PACKED`,
    SHOES_ON_POWERLINE: `${ROOT_KEY}.${W_O_TYPE}.SHOES_ON_POWERLINE`,
    SHOULDER_DROP_OFF: `${ROOT_KEY}.${W_O_TYPE}.SHOULDER_DROP_OFF`,
    SITE_RESTORATION: `${ROOT_KEY}.${W_O_TYPE}.SITE_RESTORATION`,
    SNOW_ICE_ACCUMULATION: `${ROOT_KEY}.${W_O_TYPE}.SNOW_ICE_ACCUMULATION`,
    STEEL_BEAM_GUIDERAIL: `${ROOT_KEY}.${W_O_TYPE}.STEEL_BEAM_GUIDERAIL`,
    STREET_SWEEPING: `${ROOT_KEY}.${W_O_TYPE}.STREET_SWEEPING`,
    WATER_PONDING: `${ROOT_KEY}.${W_O_TYPE}.WATER_PONDING`,
    WATER_TRUCK: `${ROOT_KEY}.${W_O_TYPE}.WATER_TRUCK`,
    WHEEL_TRACK_BARE: `${ROOT_KEY}.${W_O_TYPE}.WHEEL_TRACK_BARE`,

    // suggested by EGIS
    POLICE_SIGNALISATION: `${ROOT_KEY}.${W_O_TYPE}.POLICE_SIGNALISATION`,
    INDICATION_SIGNAGE: `${ROOT_KEY}.${W_O_TYPE}.INDICATION_SIGNAGE`,
    LIMITATION_SIGNAGE: `${ROOT_KEY}.${W_O_TYPE}.LIMITATION_SIGNAGE`,
    PROHIBITIONS_SIGNAGE: `${ROOT_KEY}.${W_O_TYPE}.PROHIBITIONS_SIGNAGE`,
    OBLIGATION_SIGNALING: `${ROOT_KEY}.${W_O_TYPE}.OBLIGATION_SIGNALING`,
    DIRECTIONAL_SIGNAGE: `${ROOT_KEY}.${W_O_TYPE}.DIRECTIONAL_SIGNAGE`,
    TOURIST_SIGNAGE: `${ROOT_KEY}.${W_O_TYPE}.TOURIST_SIGNAGE`,
    // New Keys
    BICYCLE_LANE_PLOWING_REQUIRED: `${ROOT_KEY}.${W_O_TYPE}.BICYCLE_LANE_PLOWING_REQUIRED`,
    BICYCLE_LANE_SALT_REQUIRED: `${ROOT_KEY}.${W_O_TYPE}.BICYCLE_LANE_SALT_REQUIRED`,
    BLACK_GARBAGE_BAG_MULTI_RESIDENCE_CHOOSE_BAGS_: `${ROOT_KEY}.${W_O_TYPE}.BLACK_GARBAGE_BAG_MULTI_RESIDENCE_CHOOSE_BAGS_`,
    BLACK_GARBAGE_BAG_SINGLE_RESIDENCE_CHOOSE_BAGS_: `${ROOT_KEY}.${W_O_TYPE}.BLACK_GARBAGE_BAG_SINGLE_RESIDENCE_CHOOSE_BAGS_`,
    BLUE_BIN_LARGE_SIZE_CHOOSE_BINS_: `${ROOT_KEY}.${W_O_TYPE}.BLUE_BIN_LARGE_SIZE_CHOOSE_BINS_`,
    BLUE_BIN_MEDIUM_SIZE_CHOOSE_BINS_: `${ROOT_KEY}.${W_O_TYPE}.BLUE_BIN_MEDIUM_SIZE_CHOOSE_BINS_`,
    BLUE_BIN_SMALL_SIZE_CHOOSE_BINS_: `${ROOT_KEY}.${W_O_TYPE}.BLUE_BIN_SMALL_SIZE_CHOOSE_BINS_`,
    BLVD_REPAIR_INTERLOCK_GRASS: `${ROOT_KEY}.${W_O_TYPE}.BLVD_REPAIR_INTERLOCK_GRASS`,
    BULK_ITEM_SET_OUT_APPLIANCE: `${ROOT_KEY}.${W_O_TYPE}.BULK_ITEM_SET_OUT_APPLIANCE`,
    BULK_ITEM_SET_OUT_FURNITURE_DEPRECATED: `${ROOT_KEY}.${W_O_TYPE}.BULK_ITEM_SET_OUT_FURNITURE_DEPRECATED`,
    BULK_ITEM_SET_OUT_MATTRESS_WITH_BED_BUG_COVER: `${ROOT_KEY}.${W_O_TYPE}.BULK_ITEM_SET_OUT_MATTRESS_WITH_BED_BUG_COVER`,
    BULK_ITEM_SET_OUT_MATTRESS_WITHOUT_BED_BUG_COVER: `${ROOT_KEY}.${W_O_TYPE}.BULK_ITEM_SET_OUT_MATTRESS_WITHOUT_BED_BUG_COVER`,
    BULK_ITEM_SET_OUT_OTHER: `${ROOT_KEY}.${W_O_TYPE}.BULK_ITEM_SET_OUT_OTHER`,
    BUS_SHELTER_GOOD_CONDITION: `${ROOT_KEY}.${W_O_TYPE}.BUS_SHELTER_GOOD_CONDITION`,
    BUS_SHELTER_TRASH_ACCUMULATION: `${ROOT_KEY}.${W_O_TYPE}.BUS_SHELTER_TRASH_ACCUMULATION`,
    BUS_SHELTER_VANDALIZED_DAMAGED: `${ROOT_KEY}.${W_O_TYPE}.BUS_SHELTER_VANDALIZED_DAMAGED`,
    CONCRETE_BARRIER_DAMAGE: `${ROOT_KEY}.${W_O_TYPE}.CONCRETE_BARRIER_DAMAGE`,
    CONCRETE_OPEN_TOP_LITTER_DAMAGED_VANDALIZED: `${ROOT_KEY}.${W_O_TYPE}.CONCRETE_OPEN_TOP_LITTER_DAMAGED_VANDALIZED`,
    CONCRETE_OPEN_TOP_LITTER_FULL: `${ROOT_KEY}.${W_O_TYPE}.CONCRETE_OPEN_TOP_LITTER_FULL`,
    CONCRETE_OPEN_TOP_LITTER_REGULAR: `${ROOT_KEY}.${W_O_TYPE}.CONCRETE_OPEN_TOP_LITTER_REGULAR`,
    CURVE_SIGN_WITH_ADVISORY_SPEED_TAB: `${ROOT_KEY}.${W_O_TYPE}.CURVE_SIGN_WITH_ADVISORY_SPEED_TAB`,
    DO_NOT_ENTER: `${ROOT_KEY}.${W_O_TYPE}.DO_NOT_ENTER`,
    DUAL_STREAM: `${ROOT_KEY}.${W_O_TYPE}.DUAL_STREAM`,
    FLAGGED_SET_OUT: `${ROOT_KEY}.${W_O_TYPE}.FLAGGED_SET_OUT`,
    GARBAGE_BIN_LARGE: `${ROOT_KEY}.${W_O_TYPE}.GARBAGE_BIN_LARGE`,
    GARBAGE_BIN_MEDIUM: `${ROOT_KEY}.${W_O_TYPE}.GARBAGE_BIN_MEDIUM`,
    GARBAGE_BIN_SMALL: `${ROOT_KEY}.${W_O_TYPE}.GARBAGE_BIN_SMALL`,
    GARBAGE_PAD: `${ROOT_KEY}.${W_O_TYPE}.GARBAGE_PAD`,
    GRAFFITI_HATE_RACIST: `${ROOT_KEY}.${W_O_TYPE}.GRAFFITI_HATE_RACIST`,
    GREEN_CART_LARGE_SIZE_CHOOSE_BINS_: `${ROOT_KEY}.${W_O_TYPE}.GREEN_CART_LARGE_SIZE_CHOOSE_BINS_`,
    GREEN_CART_MEDIUM_SIZE_CHOOSE_BINS_: `${ROOT_KEY}.${W_O_TYPE}.GREEN_CART_MEDIUM_SIZE_CHOOSE_BINS_`,
    GREEN_CART_SMALL_SIZE_MIHI_CHOOSE_BINS_: `${ROOT_KEY}.${W_O_TYPE}.GREEN_CART_SMALL_SIZE_MIHI_CHOOSE_BINS_`,
    GUARDRAIL_MARKER_NORMAL: `${ROOT_KEY}.${W_O_TYPE}.GUARDRAIL_MARKER_NORMAL`,
    HEAVY_UNDERGROWTH_18_INCHES_: `${ROOT_KEY}.${W_O_TYPE}.HEAVY_UNDERGROWTH_18_INCHES_`,
    LEAF_CONTAINERS: `${ROOT_KEY}.${W_O_TYPE}.LEAF_CONTAINERS`,
    LITTER_JUBILEE_DAMAGED_VANDALIZED: `${ROOT_KEY}.${W_O_TYPE}.LITTER_JUBILEE_DAMAGED_VANDALIZED`,
    LITTER_JUBILEE_FULL: `${ROOT_KEY}.${W_O_TYPE}.LITTER_JUBILEE_FULL`,
    LITTER_JUBILEE_RECYCLING: `${ROOT_KEY}.${W_O_TYPE}.LITTER_JUBILEE_RECYCLING`,
    LITTER_JUBILEE_REGULAR: `${ROOT_KEY}.${W_O_TYPE}.LITTER_JUBILEE_REGULAR`,
    LOAD_RESTRICTED_BRIDGE: `${ROOT_KEY}.${W_O_TYPE}.LOAD_RESTRICTED_BRIDGE`,
    LOW_BRIDGE: `${ROOT_KEY}.${W_O_TYPE}.LOW_BRIDGE`,
    LOW_BRIDGE_AHEAD: `${ROOT_KEY}.${W_O_TYPE}.LOW_BRIDGE_AHEAD`,
    ONE_WAY: `${ROOT_KEY}.${W_O_TYPE}.ONE_WAY`,
    OPEN_DOME: `${ROOT_KEY}.${W_O_TYPE}.OPEN_DOME`,
    PAPER_BAG_CHOOSE_BAGS_: `${ROOT_KEY}.${W_O_TYPE}.PAPER_BAG_CHOOSE_BAGS_`,
    PATIO_COMMERCIAL_ACTIVITY: `${ROOT_KEY}.${W_O_TYPE}.PATIO_COMMERCIAL_ACTIVITY`,
    RECYCLING_BAG_BLUE_CHOOSE_BAGS_: `${ROOT_KEY}.${W_O_TYPE}.RECYCLING_BAG_BLUE_CHOOSE_BAGS_`,
    REFLECTOR_MISSING: `${ROOT_KEY}.${W_O_TYPE}.REFLECTOR_MISSING`,
    REFLECTOR_OBSCURED: `${ROOT_KEY}.${W_O_TYPE}.REFLECTOR_OBSCURED`,
    REPAIRED_DEFECTS_CRACK: `${ROOT_KEY}.${W_O_TYPE}.REPAIRED_DEFECTS_CRACK`,
    REPAIRED_DEFECTS_PAVEMENT: `${ROOT_KEY}.${W_O_TYPE}.REPAIRED_DEFECTS_PAVEMENT`,
    ROAD_PLOWING_REQUIRED: `${ROOT_KEY}.${W_O_TYPE}.ROAD_PLOWING_REQUIRED`,
    ROAD_SALT_REQUIRED: `${ROOT_KEY}.${W_O_TYPE}.ROAD_SALT_REQUIRED`,
    SCHOOL_ZONE_SPEED_LIMIT: `${ROOT_KEY}.${W_O_TYPE}.SCHOOL_ZONE_SPEED_LIMIT`,
    SHARED_BIKE_STATION: `${ROOT_KEY}.${W_O_TYPE}.SHARED_BIKE_STATION`,
    SHOPPING_CART: `${ROOT_KEY}.${W_O_TYPE}.SHOPPING_CART`,
    SIDEWALK_PLOWING_REQUIRED: `${ROOT_KEY}.${W_O_TYPE}.SIDEWALK_PLOWING_REQUIRED`,
    SIDEWALK_SALT_REQUIRED: `${ROOT_KEY}.${W_O_TYPE}.SIDEWALK_SALT_REQUIRED`,
    SOLAR_POWERED_SIGN_FIXED: `${ROOT_KEY}.${W_O_TYPE}.SOLAR_POWERED_SIGN_FIXED`,
    SOLAR_POWERED_SIGN_MOBILE: `${ROOT_KEY}.${W_O_TYPE}.SOLAR_POWERED_SIGN_MOBILE`,
    STOP_AHEAD_NEW: `${ROOT_KEY}.${W_O_TYPE}.STOP_AHEAD_NEW`,
    SURFACE_DISCONTINUITIES_DEPRECATED: `${ROOT_KEY}.${W_O_TYPE}.SURFACE_DISCONTINUITIES_DEPRECATED`,
    TRANSIT_LITTER_BINS: `${ROOT_KEY}.${W_O_TYPE}.TRANSIT_LITTER_BINS`,
    TRASH_ACCUMULATION_INTERSECTION: `${ROOT_KEY}.${W_O_TYPE}.TRASH_ACCUMULATION_INTERSECTION`,
    TWIST_TOP: `${ROOT_KEY}.${W_O_TYPE}.TWIST_TOP`,
    VEHICLE_BLOCKING_BICYCLE_LANE: `${ROOT_KEY}.${W_O_TYPE}.VEHICLE_BLOCKING_BICYCLE_LANE`,
    VEHICLE_BLOCKING_ROAD: `${ROOT_KEY}.${W_O_TYPE}.VEHICLE_BLOCKING_ROAD`,
    WIRE_BASKET: `${ROOT_KEY}.${W_O_TYPE}.WIRE_BASKET`,
    WRONG_WAY: `${ROOT_KEY}.${W_O_TYPE}.WRONG_WAY`,
    YELLOW_BIN: `${ROOT_KEY}.${W_O_TYPE}.YELLOW_BIN`,
    YIELD_AHEAD: `${ROOT_KEY}.${W_O_TYPE}.YIELD_AHEAD`,
    YIELD_AHEAD_NEW: `${ROOT_KEY}.${W_O_TYPE}.YIELD_AHEAD_NEW`,
    ZUM_BIN: `${ROOT_KEY}.${W_O_TYPE}.ZUM_BIN`,
  },
};

export const WORK_ORDER_TABLE = {
  en: {
    [TABLE_HEADERS]: {
      OPENED: "Opened",
      SERVICE_BY: "Service By",
      DTS: "DTS",
      CATEGORY: "Category",
      TYPE: "Type",
      SEVERITY: "Severity",
      STATUS: "Status",
      REGION: "Region",
      WORK_ORDER_ID: "Work Order ID",
      WORK_ORDER: "Work Order",
      ASSIGNED_TO: "Assigned To",
      ASSIGN_TO: "Assigned To",
      LANE: "Lane",
      INSPECTED_ON: "Inspected On",
      INSPECTED_BY: "Inspected By",
      UPON_COMPLETION_SUBMIT_TO: "Upon Completion Submit To",
    },
    [W_O_STATUS]: {
      OPENED: "Opened",
      CLOSED: "Closed",
      IGNORED: "Ignored",
    },
    [HEADING]: {
      N: "N",
      E: "E",
      S: "S",
      W: "W",
      NE: "E",
      SE: "SE",
      NW: "NW",
      SW: "SW",
      // for EGIS
      1: "1",
      2: "2",
    },
    [CATEGORY]: {
      BARRIER: "Barrier",
      BRIDGE: "Bridge",
      DRAINAGE: "Drainage",
      OTHER_ADD_NOTES_: "Other (Add Notes)",
      ROAD_ACCIDENT: "Road Accident",
      ROAD_CONDITION_CHANGE: "Road Condition Change",
      ROADS_STANDARD: "Roads Standard",
      ROADWAY: "Roadway",
      SIDEWALK: "Sidewalk",
      SIGN: "Sign",
      WEATHER: "Weather",
    },
    [WORK_ORDER_EMAIL_FIELDS]: {
      DAMAGE_TYPE: "Damage Type",
      ROAD_CLASS: "Road Class",
      ISSUE_DATE_TIME: "Issue Date/Time",
      REPAIR_BY: "Repair By",
      LOCATION: "Location",
      COMMENTS: "Comments",
    },
    [W_O_TYPE]: {
      ABANDONED_APPLIANCE: "Abandoned appliance",
      CABLE_POST_GUIDRAIL: "Cable & Post Guidrail",
      CHECKERBOARD: "Checkerboard",
      EDGE_CRACKING_MEDIUM_SEVERITY: "Edge Cracking - Medium Severity",
      GENERAL: "General",
      GRAFFITI_NORMAL: "Graffiti - Normal",
      GUARDRAIL_DAMAGED: "Guardrail-Damaged",
      OTHER_ADD_NOTE_: "Other (Add Note)",
      PAVEMENT_MARKING: "Pavement Marking",
      POTHOLE_PAVED_SHOULDER: "Pothole Paved Shoulder",
      REFLECTOR_DAMAGED: "Reflector- Damaged",
      ROAD_MARKINGS_DEFECT: "Road Markings Defect",
      SHOPPING_CARTS: "Shopping Carts",
      SHOVING_MEDIUM_SEVERITY: "Shoving - Medium Severity",
      SIGNAGE_ILLEGIBLE: "Signage Illegible",
      SIGNAGE_MISSING: "Signage Missing",
      STOP: "Stop",
      STOP_AHEAD: "Stop Ahead",
      TRAFFIC_SIGNAL_AHEAD_NEW: "Traffic Signal Ahead, New",
      TRASH_ACCUMULATION_ROADWAY: "Trash Accumulation - Roadway",
      TRASH_ACCUMULATION_SIDEWALK: "Trash Accumulation - Sidewalk",
      TWO_WAY_TRAFFIC_AHEAD: "Two-Way Traffic Ahead",
      WEATHERING_RAVELING_MEDIUM_SEVERITY:
        "Weathering / Raveling - Medium Severity",
      YIELD: "Yield",
      TRANSVERSE_CRACKING: "Transverse Cracking",
      LONGITUDINAL_CRACK_WHEEL_TRACK: "Longitudinal Crack-Wheel Track",
      LONGITUDINAL_CRACK_OUTSIDE_OF_WHEEL_TRACK:
        "Longitudinal Crack-Outside of Wheel Track",
      GRID_CRACKS: "Grid Cracks",
      JOINT_CRACKS: "Joint Cracks",
      CRACKS_FROM_SOIL_MOVEMENT: "Cracks from Soil Movement",
      NETWORK_CRACKS_WHEEL_TRACK: "Network Cracks- Wheel Track",
      NETWORK_CRACKS_OUTSIDE_OF_WHEEL_TRACK:
        "Network Cracks-Outside of Wheel Track",
      CIRCULAR_NETWORK_CRACKS: "Circular Network Cracks",
      ANGLED_CRACK: "Angled Crack",
      OBLIQUE_CRACK: "Oblique Crack",
      DEBRIS: "Debris",
      POTHOLE_PAVED_SURFACE: "Pothole Paved Surface",
      POTHOLE_NON_PAVED_SURFACE: "Pothole Non-Paved Surface",
      SURFACE_DISCONTINUITIES: "Surface Discontinuities",
      DAMAGED_ELECTRICAL_BOX: "Damaged Electrical Box",
      DAMAGED_PEDESTAL: "Damaged Pedestal",
      DAMAGED_SIGN: "Damaged Sign",
      ILLEGIBLE_SIGN: "Illegible Sign",
      MISSING_SIGN: "Missing Sign",
      OBSCURED_SIGN: "Obscured Sign",
      IMPROPERLY_ORIENTED_SIGN: "Improperly Oriented Sign",
      LUMINAIRE: "Luminaire",
      OVERGROWN_VEGETATION: "Overgrown Vegetation",
      GUARDRAIL_MARKER_HIDDEN: "Guardrail Marker - Hidden",
      GUARDRAIL_MARKER_TILT: "Guardrail Marker - Tilt",
      CATCH_BASIN_BLOCK: "Catch Basin Block",
      SPEED_SIGNS_RB_1: "Speed Signs - Rb-1",
      CABLE_AND_POST_GUARDRAIL: "Cable and post guardrail",
      RAMP: "Ramp",
      RURAL_SIGN_911: "911 Rural Sign",
      ANIMAL_PICKUP_DEAD_DEER: "Animal Pickup / Dead Deer",
      ASPHALT_REPAIR: "Asphalt Repair",
      BARE_DRY: "Bare & Dry",
      BLVD_CONTRACT_GRASS_CUTTING: "Blvd Contract Grass Cutting",
      BLVD_REPAIR_INTERLOCK_GRASS: "Blvd Repair - Interlock/Grass",
      BRIDGE_DECK_SPALL: "Bridge Deck Spall",
      CABLE_GUIDERAIL: "Cable Guiderail",
      CATCH_BASIN: "Catch Basin",
      CONCRETE_BARRIER: "Concrete Barrier",
      CRACKS: "Cracks",
      CULVERT: "Culvert",
      CURB_AND_GUTTER: "Curb and Gutter",
      CURB_SIDEWALK_REPAIR: "Curb/Sidewalk Repair",
      DAMP_WET: "Damp / Wet",
      DITCH_INLET: "Ditch Inlet",
      FENCE: "Fence",
      FLOODING: "Flooding",
      GRADING_REQUIRED: "Grading Required",
      GRAFFITI: "Graffiti",
      ILLEGAL_DUMPING: "Illegal Dumping",
      MANHOLE: "Manhole",
      OTHER_ADD_NOTES_: "Other (Add Notes)",
      POTHOLE_SHOULDER: "Pothole Shoulder",
      REGULATORY_WARNING_SIGN_SEC_11: "Regulatory/Warning Sign Sec 11",
      REGULATORY_WARNING_SIGN_SEC_12: "Regulatory/Warning Sign Sec 12",
      ROAD_ACCIDENT: "Road Accident",
      ROAD_CLOSURE: "Road Closure",
      ROAD_ICY: "Road ICY",
      ROAD_SLUSHY: "Road Slushy",
      ROAD_SNOW_COVERAGE_BY_CM: "Road Snow Coverage By CM",
      ROAD_SNOW_DRIFTING: "Road Snow Drifting",
      ROAD_SNOW_PACKED: "Road Snow Packed",
      SHOES_ON_POWERLINE: "Shoes on Powerline",
      SHOULDER_DROP_OFF: "Shoulder Drop-Off",
      SITE_RESTORATION: "Site Restoration",
      SNOW_ICE_ACCUMULATION: "Snow/Ice Accumulation",
      STEEL_BEAM_GUIDERAIL: "Steel Beam Guiderail",
      STREET_SWEEPING: "Street Sweeping",
      WATER_PONDING: "Water Ponding",
      WATER_TRUCK: "Water Truck",
      WHEEL_TRACK_BARE: "Wheel Track Bare",
      POLICE_SIGNALISATION: "Police signalisation",
      INDICATION_SIGNAGE: "Indication signage",
      LIMITATION_SIGNAGE: "Limitation signage",
      PROHIBITIONS_SIGNAGE: "Prohibitions signage",
      OBLIGATION_SIGNALING: "Obligation signaling",
      DIRECTIONAL_SIGNAGE: "Directional signage",
      TOURIST_SIGNAGE: "Tourist signage",
      BICYCLE_LANE_PLOWING_REQUIRED: "Bicycle Lane Plowing Required",
      BICYCLE_LANE_SALT_REQUIRED: "Bicycle Lane Salt Required",
      BLACK_GARBAGE_BAG_MULTI_RESIDENCE_CHOOSE_BAGS_:
        "Black Garbage Bag - Multi residence (Choose # bags)",
      BLACK_GARBAGE_BAG_SINGLE_RESIDENCE_CHOOSE_BAGS_:
        "Black Garbage Bag - Single residence (Choose # bags)",
      BLUE_BIN_LARGE_SIZE_CHOOSE_BINS_: "Blue Bin - Large size (Choose # bins)",
      BLUE_BIN_MEDIUM_SIZE_CHOOSE_BINS_:
        "Blue Bin - Medium size (Choose # bins)",
      BLUE_BIN_SMALL_SIZE_CHOOSE_BINS_: "Blue Bin - Small size (Choose # bins)",
      BULK_ITEM_SET_OUT_APPLIANCE: "Bulk Item Set Out - Appliance",
      BULK_ITEM_SET_OUT_FURNITURE_DEPRECATED:
        "Bulk Item Set Out - Furniture -- deprecated",
      BULK_ITEM_SET_OUT_MATTRESS_WITH_BED_BUG_COVER:
        "Bulk Item Set Out - Mattress with Bed Bug Cover",
      BULK_ITEM_SET_OUT_MATTRESS_WITHOUT_BED_BUG_COVER:
        "Bulk Item Set Out - Mattress without Bed Bug Cover",
      BULK_ITEM_SET_OUT_OTHER: "Bulk Item Set Out - Other",
      BUS_SHELTER_GOOD_CONDITION: "Bus Shelter - Good condition",
      BUS_SHELTER_TRASH_ACCUMULATION: "Bus Shelter - Trash Accumulation",
      BUS_SHELTER_VANDALIZED_DAMAGED: "Bus Shelter - Vandalized/Damaged",
      CONCRETE_BARRIER_DAMAGE: "Concrete Barrier Damage",
      CONCRETE_OPEN_TOP_LITTER_DAMAGED_VANDALIZED:
        "Concrete Open Top Litter - Damaged/ Vandalized",
      CONCRETE_OPEN_TOP_LITTER_FULL: "Concrete Open Top Litter - Full",
      CONCRETE_OPEN_TOP_LITTER_REGULAR: "Concrete Open Top Litter - Regular",
      CURVE_SIGN_WITH_ADVISORY_SPEED_TAB: "Curve sign with advisory speed tab",
      DO_NOT_ENTER: "Do Not Enter",
      DUAL_STREAM: "Dual Stream",
      FLAGGED_SET_OUT: "Flagged Set Out",
      GARBAGE_BIN_LARGE: "Garbage Bin - Large",
      GARBAGE_BIN_MEDIUM: "Garbage Bin - Medium",
      GARBAGE_BIN_SMALL: "Garbage Bin - Small",
      GARBAGE_PAD: "Garbage Pad",
      GRAFFITI_HATE_RACIST: "Graffiti - Hate/Racist",
      GREEN_CART_LARGE_SIZE_CHOOSE_BINS_:
        "Green Cart - Large size (Choose # bins)",
      GREEN_CART_MEDIUM_SIZE_CHOOSE_BINS_:
        "Green Cart - Medium size (Choose # bins)",
      GREEN_CART_SMALL_SIZE_MIHI_CHOOSE_BINS_:
        "Green Cart - Small size (MiHi) (Choose # bins)",
      GUARDRAIL_MARKER_NORMAL: "Guardrail Marker - Normal",
      HEAVY_UNDERGROWTH_18_INCHES_: "Heavy Undergrowth (18 inches)",
      LEAF_CONTAINERS: "Leaf Containers",
      LITTER_JUBILEE_DAMAGED_VANDALIZED: "Litter Jubilee - Damaged/ Vandalized",
      LITTER_JUBILEE_FULL: "Litter Jubilee - Full",
      LITTER_JUBILEE_RECYCLING: "Litter Jubilee - Recycling",
      LITTER_JUBILEE_REGULAR: "Litter Jubilee - Regular",
      LOAD_RESTRICTED_BRIDGE: "Load Restricted Bridge",
      LOW_BRIDGE: "Low Bridge",
      LOW_BRIDGE_AHEAD: "Low Bridge Ahead",
      ONE_WAY: "One Way",
      OPEN_DOME: "Open Dome",
      PAPER_BAG_CHOOSE_BAGS_: "Paper Bag (Choose # bags)",
      PATIO_COMMERCIAL_ACTIVITY: "Patio Commercial Activity",
      RECYCLING_BAG_BLUE_CHOOSE_BAGS_: "Recycling Bag - Blue (Choose # bags)",
      REFLECTOR_MISSING: "Reflector-Missing",
      REFLECTOR_OBSCURED: "Reflector-Obscured",
      REPAIRED_DEFECTS_CRACK: "Repaired Defects - Crack",
      REPAIRED_DEFECTS_PAVEMENT: "Repaired Defects - Pavement",
      ROAD_PLOWING_REQUIRED: "Road Plowing Required",
      ROAD_SALT_REQUIRED: "Road Salt Required",
      SCHOOL_ZONE_SPEED_LIMIT: "School Zone Speed Limit",
      SHARED_BIKE_STATION: "Shared Bike Station",
      SHOPPING_CART: "Shopping Cart",
      SIDEWALK_PLOWING_REQUIRED: "Sidewalk Plowing Required",
      SIDEWALK_SALT_REQUIRED: "Sidewalk Salt Required",
      SOLAR_POWERED_SIGN_FIXED: "Solar Powered Sign - Fixed",
      SOLAR_POWERED_SIGN_MOBILE: "Solar Powered Sign - Mobile",
      STOP_AHEAD_NEW: "Stop Ahead, New",
      SURFACE_DISCONTINUITIES_DEPRECATED:
        "Surface Discontinuities -- deprecated",
      TRANSIT_LITTER_BINS: "Transit Litter Bins",
      TRASH_ACCUMULATION_INTERSECTION: "Trash Accumulation - Intersection",
      TWIST_TOP: "Twist Top",
      VEHICLE_BLOCKING_BICYCLE_LANE: "Vehicle Blocking Bicycle Lane",
      VEHICLE_BLOCKING_ROAD: "Vehicle Blocking Road",
      WIRE_BASKET: "Wire Basket",
      WRONG_WAY: "Wrong Way",
      YELLOW_BIN: "Yellow Bin",
      YIELD_AHEAD: "Yield Ahead",
      YIELD_AHEAD_NEW: "Yield Ahead, New",
      ZUM_BIN: "Zum Bin",
    },
  },
  fr: {
    [TABLE_HEADERS]: {
      OPENED: "Ouvert",
      SERVICE_BY: "Service le",
      DTS: "DTS",
      CATEGORY: "Catégorie",
      TYPE: "Type",
      SEVERITY: "Gravité",
      STATUS: "Statut",
      REGION: "Région",
      WORK_ORDER_ID: "ID de la Requête de Travail",
      WORK_ORDER: "demande d'intervention ",
      ASSIGNED_TO: "La personne responsable",
      ASSIGN_TO: "La personne responsable",
      LANE: "voie",
      INSPECTED_ON: "Inspecté à",
      INSPECTED_BY: "Inspecté par",
      UPON_COMPLETION_SUBMIT_TO: "Une fois terminé Soumettre à",
    },
    [W_O_STATUS]: {
      OPENED: "Ouvert",
      CLOSED: "Fermé",
      IGNORED: "Ignoré",
    },
    [HEADING]: {
      N: "NORD",
      E: "EST",
      S: "SUD",
      W: "OUEST",
      NE: "NORD-EST",
      SE: "SUD-EST",
      NW: "NORD-OUEST",
      SW: "SUD-OUEST",
      // for EGIS
      1: "1",
      2: "2",
    },
    [CATEGORY]: {
      BARRIER: "Barrière",
      BRIDGE: "Pont",
      DRAINAGE: "Drainage",
      OTHER_ADD_NOTES_: "Autre (ajouter des notes)",
      ROAD_ACCIDENT: "Accident de la route",
      ROAD_CONDITION_CHANGE: "Changement de l'état de la route",
      ROADS_STANDARD: "Norme des routes",
      ROADWAY: "Route",
      SIDEWALK: "Trottoir",
      SIGN: "Panneau",
      WEATHER: "Météo",
    },
    [WORK_ORDER_EMAIL_FIELDS]: {
      DAMAGE_TYPE: "Type de Dégât",
      ROAD_CLASS: "CLASSE DE ROUTE",
      ISSUE_DATE_TIME: "Date de Publication",
      REPAIR_BY: "Réparé Par",
      LOCATION: "LOCALISATION",
      COMMENTS: "Commentaire",
    },
    [W_O_TYPE]: {
      ABANDONED_APPLIANCE: "APPAREIL ABANDONNE",
      CABLE_POST_GUIDRAIL: "Cable & Post Guidrail",
      CHECKERBOARD: "DAMIER",
      EDGE_CRACKING_MEDIUM_SEVERITY: "FISSURE DE RIVE - SEVERITE MOYENNE",
      GENERAL: "Général",
      GRAFFITI_NORMAL: "Graffiti - Normal",
      GUARDRAIL_DAMAGED: "GARDE-CORPS ENDOMMAGEE",
      OTHER_ADD_NOTE_: "AUTRE NOTE",
      PAVEMENT_MARKING: "SIGNALISATION HORIZONTALE",
      POTHOLE_PAVED_SHOULDER: "Pothole Paved Shoulder", // TBD
      REFLECTOR_DAMAGED: "REFLECTEUR ENDOMMAGE",
      ROAD_MARKINGS_DEFECT: "DEFAUT DE SIGNALISATION HORIZONTALE",
      SHOPPING_CARTS: "Paniers d'achat",
      SHOVING_MEDIUM_SEVERITY: "Bousculer – severite moyenne",
      SIGNAGE_ILLEGIBLE: "SIGNALISATION ILLLISIBLE",
      SIGNAGE_MISSING: "SIGNALISATION MANQUANTE",
      STOP: "Arrêt",
      STOP_AHEAD: "Arrêt à venir",
      TRAFFIC_SIGNAL_AHEAD_NEW: "Feu de circulation à venir, nouveau",
      TRASH_ACCUMULATION_ROADWAY: "ZONE D'ACCUMULATION DE DECHETS",
      TRASH_ACCUMULATION_SIDEWALK: "Accumulation de déchets - Trottoir",
      TWO_WAY_TRAFFIC_AHEAD: "Trafic bidirectionnel à venir",
      WEATHERING_RAVELING_MEDIUM_SEVERITY:
        "Altération/effilochage – Severite moyenne",
      YIELD: "Yield",
      TRANSVERSE_CRACKING: "Fissure transversale",
      LONGITUDINAL_CRACK_WHEEL_TRACK:
        "Fissure longitudinale dans les bandes de roulement",
      LONGITUDINAL_CRACK_OUTSIDE_OF_WHEEL_TRACK:
        "Fissure longitudinale hors  aux bandes de roulement",
      GRID_CRACKS: "Faïençage",
      JOINT_CRACKS: "Fissure de joint",
      CRACKS_FROM_SOIL_MOVEMENT: "fissure de tassement",
      NETWORK_CRACKS_WHEEL_TRACK: "Faïençage dans les bandes de roulement",
      NETWORK_CRACKS_OUTSIDE_OF_WHEEL_TRACK:
        "Faïençage non spécifique aux bandes de roulement",
      CIRCULAR_NETWORK_CRACKS: "Faïençage circulaire",
      ANGLED_CRACK: "Cassure d'Angle",
      OBLIQUE_CRACK: "Fissure Oblique",
      DEBRIS: "Débris",
      POTHOLE_PAVED_SURFACE: "Surface Pavée Nid-de-Poule",
      POTHOLE_NON_PAVED_SURFACE: "Nid de Poule Surface non Pavée",
      SURFACE_DISCONTINUITIES: "Discontinuités de Surface",
      DAMAGED_ELECTRICAL_BOX: "COFFRET ELECTRIQUE ENDOMMAGE",
      DAMAGED_PEDESTAL: "PIED OU SUPPORT ENDOMMAGE",
      DAMAGED_SIGN: "Panneau Endommagé",
      ILLEGIBLE_SIGN: "Panneau Illisible",
      MISSING_SIGN: "Panneau de Signalisation Manquant",
      OBSCURED_SIGN: "Signalisation Masquée",
      IMPROPERLY_ORIENTED_SIGN: "défaut d'orientation de la Signalisation",
      LUMINAIRE: "Luminaire",
      OVERGROWN_VEGETATION: "Végétation Envahissante",
      GUARDRAIL_MARKER_HIDDEN: "Garde-corps- caché",
      GUARDRAIL_MARKER_TILT: "Garde-corps- incliné",
      CATCH_BASIN_BLOCK: "Bloc puisard",
      SPEED_SIGNS_RB_1: "Panneau de vitesse - Rb-1",
      CABLE_AND_POST_GUARDRAIL: "Glissières de sécurité",
      RAMP: "RAMPE",
      RURAL_SIGN_911: "Panneau rural 911",
      ANIMAL_PICKUP_DEAD_DEER: "RAMASSAGE D ANIMAUX SAUVAGES MORTS",
      ASPHALT_REPAIR: "Réparation d'asphalte",
      BARE_DRY: "Nu et sec",
      BLVD_CONTRACT_GRASS_CUTTING: "Blvd Contrat de coupe de gazon",
      BLVD_REPAIR_INTERLOCK_GRASS: "Réparation de boulevard - Pavé/Herbe",
      BRIDGE_DECK_SPALL: "Éclatement du tablier du pont",
      CABLE_GUIDERAIL: "Rail de guidage de câble",
      CATCH_BASIN: "AVALOIR",
      CONCRETE_BARRIER: "GLISSIERE BETON",
      CRACKS: "Fissures",
      CULVERT: "Ponceau",
      CURB_AND_GUTTER: "Bordure et caniveau",
      CURB_SIDEWALK_REPAIR: "Réparation de bordure/trottoir",
      DAMP_WET: "Humide / Mouillé",
      DITCH_INLET: "Entrée de fossé",
      FENCE: "Clôture",
      FLOODING: "Inondation",
      GRADING_REQUIRED: "Classement requis",
      GRAFFITI: "Graffiti",
      ILLEGAL_DUMPING: "Déversement illégal",
      MANHOLE: "regard",
      OTHER_ADD_NOTES_: "Autre (ajouter des notes)",
      POTHOLE_SHOULDER: "Épaule nid-de-poule",
      REGULATORY_WARNING_SIGN_SEC_11:
        "Panneau de réglementation / d'avertissement Sec 11",
      REGULATORY_WARNING_SIGN_SEC_12:
        "Panneau de réglementation / d'avertissement Sec 12",
      ROAD_ACCIDENT: "Accident de route",
      ROAD_CLOSURE: "Fermeture de route",
      ROAD_ICY: "Route glacée",
      ROAD_SLUSHY: "Boue de route",
      ROAD_SNOW_COVERAGE_BY_CM: "Couverture de neige sur les routes par CM",
      ROAD_SNOW_DRIFTING: "ROUTE ENNEIGEE GLISSANTE",
      ROAD_SNOW_PACKED: "Route enneigée",
      SHOES_ON_POWERLINE: "Chaussures sur Ligne électrique",
      SHOULDER_DROP_OFF: "Chute d'épaule",
      SITE_RESTORATION: "Restauration de sites",
      SNOW_ICE_ACCUMULATION: "CONGERE",
      STEEL_BEAM_GUIDERAIL: "Rail de Guidage de Poutre en Acier",
      STREET_SWEEPING: "Balayage des rues",
      WATER_PONDING: "FLAQUE D'EAU",
      WATER_TRUCK: "CAMION CITERNE D'EAU",
      WHEEL_TRACK_BARE: "TRACE DE PNEU",
      POLICE_SIGNALISATION: "SIGNALISATION DE POLICE",
      INDICATION_SIGNAGE: "SIGNALISATION D'INDICATION",
      LIMITATION_SIGNAGE: "SIGNALISATION DE LIMITATION",
      PROHIBITIONS_SIGNAGE: "SIGNALISATION D'INTERDICTION",
      OBLIGATION_SIGNALING: "SIGNALISATION D'OBLIGATION",
      DIRECTIONAL_SIGNAGE: "SIGNALISATION DIRECTIONNELLE",
      TOURIST_SIGNAGE: "SIGNALISATION TOURISTIQUE",
      BICYCLE_LANE_PLOWING_REQUIRED: "Déneigement de piste cyclable requis",
      BICYCLE_LANE_SALT_REQUIRED: "Épandage de sel sur piste cyclable requis",
      BLACK_GARBAGE_BAG_MULTI_RESIDENCE_CHOOSE_BAGS_:
        "Sac poubelle noir - Résidence collective (Choisir # sacs)",
      BLACK_GARBAGE_BAG_SINGLE_RESIDENCE_CHOOSE_BAGS_:
        "Sac poubelle noir - Résidence individuelle (Choisir # sacs)",
      BLUE_BIN_LARGE_SIZE_CHOOSE_BINS_:
        "Bac bleu - Grande Taille (Choisir # bacs)",
      BLUE_BIN_MEDIUM_SIZE_CHOOSE_BINS_:
        "Bac bleu - Taille Moyenne (Choisir # bacs)",
      BLUE_BIN_SMALL_SIZE_CHOOSE_BINS_:
        "Bac bleu - Petite Taille (Choisir # bacs)",
      BULK_ITEM_SET_OUT_APPLIANCE:
        "Encombrant à sortir - Appareil électroménager",
      BULK_ITEM_SET_OUT_FURNITURE_DEPRECATED:
        "Encombrant à sortir - Meuble -- obsolète",
      BULK_ITEM_SET_OUT_MATTRESS_WITH_BED_BUG_COVER:
        "Encombrant à sortir - Matelas avec housse anti-punaises",
      BULK_ITEM_SET_OUT_MATTRESS_WITHOUT_BED_BUG_COVER:
        "Encombrant à sortir - Matelas sans housse anti-punaises",
      BULK_ITEM_SET_OUT_OTHER: "Encombrant à sortir - Autre",
      BUS_SHELTER_GOOD_CONDITION: "Abribus - Bon état",
      BUS_SHELTER_TRASH_ACCUMULATION: "Abribus - Accumulation de déchets",
      BUS_SHELTER_VANDALIZED_DAMAGED: "Abribus - Vandalisé/Endommagé",
      CONCRETE_BARRIER_DAMAGE: "Dommage à la barrière en béton",
      CONCRETE_OPEN_TOP_LITTER_DAMAGED_VANDALIZED:
        "Poubelle ouverte en béton - Endommagée/Vandalisée",
      CONCRETE_OPEN_TOP_LITTER_FULL: "Poubelle ouverte en béton - Pleine",
      CONCRETE_OPEN_TOP_LITTER_REGULAR: "Poubelle ouverte en béton - Ordinaire",
      CURVE_SIGN_WITH_ADVISORY_SPEED_TAB:
        "Panneau de courbe avec conseil de vitesse",
      DO_NOT_ENTER: "Sens interdit",
      DUAL_STREAM: "Collecte sélective",
      FLAGGED_SET_OUT: "Signalé pour sortie",
      GARBAGE_BIN_LARGE: "Poubelle - Grande",
      GARBAGE_BIN_MEDIUM: "Poubelle - Moyenne",
      GARBAGE_BIN_SMALL: "Poubelle - Petite",
      GARBAGE_PAD: "Aire de déchets",
      GRAFFITI_HATE_RACIST: "Graffiti - Haine/Raciste",
      GREEN_CART_LARGE_SIZE_CHOOSE_BINS_:
        "Bac vert - Grande Taille (Choisir # bacs)",
      GREEN_CART_MEDIUM_SIZE_CHOOSE_BINS_:
        "Bac vert - Taille moyenne (Choisir # bacs)",
      GREEN_CART_SMALL_SIZE_MIHI_CHOOSE_BINS_:
        "Bac vert - Petite Taille (Choisir # bacs)",
      GUARDRAIL_MARKER_NORMAL: "Balise de sécurité - Normal",
      HEAVY_UNDERGROWTH_18_INCHES_: "Sous-bois dense (45 cm)",
      LEAF_CONTAINERS: "Conteneurs pour feuilles",
      LITTER_JUBILEE_DAMAGED_VANDALIZED:
        "Poubelle Jubilee - Endommagée/Vandalisée",
      LITTER_JUBILEE_FULL: "Poubelle Jubilee - Pleine",
      LITTER_JUBILEE_RECYCLING: "Poubelle Jubilee - Recyclage",
      LITTER_JUBILEE_REGULAR: "Poubelle Jubilee - Ordinaire",
      LOAD_RESTRICTED_BRIDGE: "Pont à charge limitée",
      LOW_BRIDGE: "Pont bas",
      LOW_BRIDGE_AHEAD: "Pont bas à venir",
      ONE_WAY: "Sens unique",
      OPEN_DOME: "Dôme ouvert",
      PAPER_BAG_CHOOSE_BAGS_: "Sac en papier (Choisir # sacs)",
      PATIO_COMMERCIAL_ACTIVITY: "Activité commerciale sur patio",
      RECYCLING_BAG_BLUE_CHOOSE_BAGS_: "Sac de recyclage bleu (Choisir # sacs)",
      REFLECTOR_MISSING: "Réflecteur manquant",
      REFLECTOR_OBSCURED: "Réflecteur obscurci",
      REPAIRED_DEFECTS_CRACK: "Défauts réparés - Fissure",
      REPAIRED_DEFECTS_PAVEMENT: "Défauts réparés - Chaussée",
      ROAD_PLOWING_REQUIRED: "Déneigement de route requis",
      ROAD_SALT_REQUIRED: "Épandage de sel sur route requis",
      SCHOOL_ZONE_SPEED_LIMIT: "Limite de vitesse en zone scolaire",
      SHARED_BIKE_STATION: "Station de vélo partagé",
      SHOPPING_CART: "Chariot de magasinage",
      SIDEWALK_PLOWING_REQUIRED: "Déneigement de trottoir requis",
      SIDEWALK_SALT_REQUIRED: "Épandage de sel sur trottoir requis",
      SOLAR_POWERED_SIGN_FIXED: "Panneau solaire - Fixe",
      SOLAR_POWERED_SIGN_MOBILE: "Panneau solaire - Mobile",
      STOP_AHEAD_NEW: "Arrêt à venir, Nouveau",
      SURFACE_DISCONTINUITIES_DEPRECATED:
        "Discontinuités de surface -- obsolète",
      TRANSIT_LITTER_BINS: "Poubelles de transport en commun",
      TRASH_ACCUMULATION_INTERSECTION: "Accumulation de déchets - Intersection",
      TWIST_TOP: "Couvercle à torsion",
      VEHICLE_BLOCKING_BICYCLE_LANE: "Véhicule obstruant la piste cyclable",
      VEHICLE_BLOCKING_ROAD: "Véhicule obstruant la route",
      WIRE_BASKET: "Panier en fil",
      WRONG_WAY: "Mauvais sens",
      YELLOW_BIN: "Bac jaune",
      YIELD_AHEAD: "Cédez le passage à venir",
      YIELD_AHEAD_NEW: "Cédez le passage à venir, Nouveau",
      ZUM_BIN: "Bac Zum",
    },
  },

  ja: {
    [TABLE_HEADERS]: {
      OPENED: "開いた",
      SERVICE_BY: "下記の日付までに修正",
      DTS: "サービスまでの日数",
      CATEGORY: "カテゴリー",
      TYPE: "タイプ",
      SEVERITY: "重大度",
      STATUS: "ステータス",
      REGION: "領域",
      WORK_ORDER_ID: "作業指示書 ID",
      WORK_ORDER: "作業指示",
      ASSIGNED_TO: "に割り当てられた",
      ASSIGN_TO: "に割り当てられた",
      LANE: "LANE",
      INSPECTED_ON: "検査日",
      INSPECTED_BY: "の検査を受けた",
      UPON_COMPLETION_SUBMIT_TO: "完了時に送信先",
    },
    [W_O_STATUS]: {
      OPENED: "開いた",
      CLOSED: "閉まっている",
      IGNORED: "無視",
    },
    [HEADING]: {
      N: "北",
      E: "東",
      S: "南",
      W: "西",
      NE: "北東",
      SE: "南東",
      NW: "北西",
      SW: "南西",
      // for EGIS
      1: "1",
      2: "2",
    },
    [CATEGORY]: {
      BARRIER: "バリア",
      BRIDGE: "橋",
      DRAINAGE: "排水",
      OTHER_ADD_NOTES_: "その他 (メモを追加)",
      ROAD_ACCIDENT: "交通事故",
      ROAD_CONDITION_CHANGE: "道路状況の変化",
      ROADS_STANDARD: "道路規格",
      ROADWAY: "車道",
      SIDEWALK: "歩道",
      SIGN: "標識",
      WEATHER: "天気",
    },
    [W_O_TYPE]: {
      ABANDONED_APPLIANCE: "放棄された家電製品",
      CABLE_POST_GUIDRAIL: "ケーブル&ポストガイドレール",
      CHECKERBOARD: "チェッカーボード",
      EDGE_CRACKING_MEDIUM_SEVERITY: "エッジ亀裂 - 中程度の深刻度",
      GENERAL: "一般",
      GRAFFITI_NORMAL: "落書き - ノーマル",
      GUARDRAIL_DAMAGED: "ガードレールの破損",
      OTHER_ADD_NOTE_: "その他（注記を追加）",
      PAVEMENT_MARKING: "舗装標示",
      POTHOLE_PAVED_SHOULDER: "ポットホール舗装路肩",
      REFLECTOR_DAMAGED: "リフレクター - 破損",
      ROAD_MARKINGS_DEFECT: "道路標識の欠損",
      SHOPPING_CARTS: "ショッピングカート",
      SHOVING_MEDIUM_SEVERITY: "突き飛ばし - 中程度の重大度",
      SIGNAGE_ILLEGIBLE: "標識が判読できない",
      SIGNAGE_MISSING: "標識がない",
      STOP: "停止",
      STOP_AHEAD: "この先で停止",
      TRAFFIC_SIGNAL_AHEAD_NEW: "前方に信号機があり、新しい",
      TRASH_ACCUMULATION_ROADWAY: "ゴミの集積 - 車道",
      TRASH_ACCUMULATION_SIDEWALK: "ゴミの集積 - 歩道",
      TWO_WAY_TRAFFIC_AHEAD: "前方は双方向交通",
      WEATHERING_RAVELING_MEDIUM_SEVERITY: "風化/ほつれ - 中程度の深刻度",
      YIELD: "徐行",
      TRANSVERSE_CRACKING: "横割れ",
      LONGITUDINAL_CRACK_WHEEL_TRACK: "縦方向のクラック ホイール トラック",
      LONGITUDINAL_CRACK_OUTSIDE_OF_WHEEL_TRACK:
        "縦方向のクラック - トラックの外側",
      GRID_CRACKS: "グリッドクラック",
      JOINT_CRACKS: "ジョイントクラック",
      CRACKS_FROM_SOIL_MOVEMENT: "土の移動による亀裂",
      NETWORK_CRACKS_WHEEL_TRACK: "ネットワークの亀裂 - 車輪の跡",
      NETWORK_CRACKS_OUTSIDE_OF_WHEEL_TRACK:
        "ネットワーククラック - ホイール トラックの外側",
      CIRCULAR_NETWORK_CRACKS: "循環ネットワークの亀裂",
      ANGLED_CRACK: "斜めクラック",
      OBLIQUE_CRACK: "斜めクラック",
      DEBRIS: "デブリ",
      POTHOLE_PAVED_SURFACE: "ポットホール舗装面",
      POTHOLE_NON_PAVED_SURFACE: "ポットホール 非舗装面",
      SURFACE_DISCONTINUITIES: "表面の不連続",
      DAMAGED_ELECTRICAL_BOX: "損傷した電気ボックス",
      DAMAGED_PEDESTAL: "破損した台座",
      DAMAGED_SIGN: "破損した標識",
      ILLEGIBLE_SIGN: "判読不能な標識",
      MISSING_SIGN: "なくなった標識",
      OBSCURED_SIGN: "隠れた標識",
      IMPROPERLY_ORIENTED_SIGN: "不適切な向きの標識",
      LUMINAIRE: "照明器具",
      OVERGROWN_VEGETATION: "生い茂った植生",
      GUARDRAIL_MARKER_HIDDEN: "ガードレール マーカー - 非表示",
      GUARDRAIL_MARKER_TILT: "ガードレール マーカー - 傾斜",
      CATCH_BASIN_BLOCK: "排水桝ブロック",
      SPEED_SIGNS_RB_1: "速度標識 - Rb-1",
      CABLE_AND_POST_GUARDRAIL: "ケーブルとポストのガードレール",
      RAMP: "ランプ",
      RURAL_SIGN_911: "911 田舎のサイン",
      ANIMAL_PICKUP_DEAD_DEER: "アニマルピックアップ / 鹿の死骸",
      ASPHALT_REPAIR: "アスファルト補修",
      BARE_DRY: "ベア＆ドライ",
      BLVD_CONTRACT_GRASS_CUTTING: "大通りの契約草刈り",
      BLVD_REPAIR_INTERLOCK_GRASS: "大通りの修理 - インターロック/草",
      BRIDGE_DECK_SPALL: "ブリッジ・デッキ・スポール",
      CABLE_GUIDERAIL: "ケーブル ガイドレール",
      CATCH_BASIN: "キャッチ・ベイスン",
      CONCRETE_BARRIER: "コンクリートバリア",
      CRACKS: "クラック",
      CULVERT: "暗渠",
      CURB_AND_GUTTER: "縁石と側溝",
      CURB_SIDEWALK_REPAIR: "縁石・歩道の修理",
      DAMP_WET: "湿った/湿った",
      DITCH_INLET: "排水口",
      FENCE: "フェンス",
      FLOODING: "洪水",
      GRADING_REQUIRED: "採点が必要",
      GRAFFITI: "落書き",
      ILLEGAL_DUMPING: "不法投棄",
      MANHOLE: "マンホール",
      OTHER_ADD_NOTES_: "その他 (メモを追加)",
      POTHOLE_SHOULDER: "ポットホールショルダー",
      REGULATORY_WARNING_SIGN_SEC_11: "規制/警告サイン Sec 11",
      REGULATORY_WARNING_SIGN_SEC_12: "規制/警告サイン Sec 12",
      ROAD_ACCIDENT: "交通事故",
      ROAD_CLOSURE: "通行止め",
      ROAD_ICY: "道路 ICY",
      ROAD_SLUSHY: "道路のぬかるみ",
      ROAD_SNOW_COVERAGE_BY_CM: "CM別道路積雪量",
      ROAD_SNOW_DRIFTING: "道路の雪の漂流",
      ROAD_SNOW_PACKED: "積雪道路",
      SHOES_ON_POWERLINE: "送電線上の靴",
      SHOULDER_DROP_OFF: "ショルダードロップオフ",
      SITE_RESTORATION: "サイトの復元",
      SNOW_ICE_ACCUMULATION: "雪/氷の蓄積",
      STEEL_BEAM_GUIDERAIL: "スチールビームガイドレール",
      STREET_SWEEPING: "通りの掃除",
      WATER_PONDING: "水の池",
      WATER_TRUCK: "給水車",
      WHEEL_TRACK_BARE: "ホイール トラック ベア",
      POLICE_SIGNALISATION: "警察",
      INDICATION_SIGNAGE: "表示サイン",
      LIMITATION_SIGNAGE: "制限標識",
      PROHIBITIONS_SIGNAGE: "禁止標識",
      OBLIGATION_SIGNALING: "義務の通知",
      DIRECTIONAL_SIGNAGE: "案内標識",
      TOURIST_SIGNAGE: "観光標識",
      BICYCLE_LANE_PLOWING_REQUIRED: "自転車レーンの除雪は必要です",
      BICYCLE_LANE_SALT_REQUIRED: "自転車専用レーンに塩分が必要",
      BLACK_GARBAGE_BAG_MULTI_RESIDENCE_CHOOSE_BAGS_:
        "黒のゴミ袋 - 集合住宅 (袋の数を選択)",
      BLACK_GARBAGE_BAG_SINGLE_RESIDENCE_CHOOSE_BAGS_:
        "黒のゴミ袋 - 一戸建て (袋の数を選択してください)",
      BLUE_BIN_LARGE_SIZE_CHOOSE_BINS_: "青いビン - 大サイズ (ビンの数を選択)",
      BLUE_BIN_MEDIUM_SIZE_CHOOSE_BINS_:
        "ブルービン - 中サイズ (ビンの数を選択)",
      BLUE_BIN_SMALL_SIZE_CHOOSE_BINS_:
        "青いビン - 小さいサイズ (ビンの数を選択)",
      BULK_ITEM_SET_OUT_APPLIANCE:
        "バルクアイテムのセットアウト - アプライアンス",
      BULK_ITEM_SET_OUT_FURNITURE_DEPRECATED:
        "バルクアイテムセットアウト - 家具 -- 非推奨",
      BULK_ITEM_SET_OUT_MATTRESS_WITH_BED_BUG_COVER:
        "バルクアイテムセットアウト - トコジラミカバー付きマットレス",
      BULK_ITEM_SET_OUT_MATTRESS_WITHOUT_BED_BUG_COVER:
        "バルクアイテムセットアウト - トコジラミカバーなしのマットレス",
      BULK_ITEM_SET_OUT_OTHER: "バルクアイテムセットアウト - その他",
      BUS_SHELTER_GOOD_CONDITION: "バスシェルター - 状態良好",
      BUS_SHELTER_TRASH_ACCUMULATION: "バスシェルター - ゴミの蓄積",
      BUS_SHELTER_VANDALIZED_DAMAGED: "バスシェルター - 破壊/破損",
      CONCRETE_BARRIER_DAMAGE: "コンクリートバリアの損傷",
      CONCRETE_OPEN_TOP_LITTER_DAMAGED_VANDALIZED:
        "コンクリートのオープントップリター - 破損/破壊",
      CONCRETE_OPEN_TOP_LITTER_FULL: "コンクリートオープントップリター - フル",
      CONCRETE_OPEN_TOP_LITTER_REGULAR:
        "コンクリートオープントップリター - レギュラー",
      CURVE_SIGN_WITH_ADVISORY_SPEED_TAB: "勧告速度タブ付きのカーブ標識",
      DO_NOT_ENTER: "立入禁止",
      DUAL_STREAM: "デュアルストリーム",
      FLAGGED_SET_OUT: "フラグ付き出発",
      GARBAGE_BIN_LARGE: "ゴミ箱 - 大",
      GARBAGE_BIN_MEDIUM: "ゴミ箱 - 中",
      GARBAGE_BIN_SMALL: "ゴミ箱 - 小",
      GARBAGE_PAD: "ゴミパッド",
      GRAFFITI_HATE_RACIST: "落書き - 憎悪/人種差別主義者",
      GREEN_CART_LARGE_SIZE_CHOOSE_BINS_:
        "グリーン カート - L サイズ (ビンの数を選択してください)",
      GREEN_CART_MEDIUM_SIZE_CHOOSE_BINS_:
        "グリーン カート - 中サイズ (ビンの数を選択してください)",
      GREEN_CART_SMALL_SIZE_MIHI_CHOOSE_BINS_:
        "グリーン カート - S サイズ (MiHi) (ビンの数を選択してください)",
      GUARDRAIL_MARKER_NORMAL: "ガードレールマーカー - 通常",
      HEAVY_UNDERGROWTH_18_INCHES_: "厚い下草 (18 インチ)",
      LEAF_CONTAINERS: "葉っぱの容器",
      LITTER_JUBILEE_DAMAGED_VANDALIZED: "リッター ジュビリー - 破損/破壊",
      LITTER_JUBILEE_FULL: "ゴミジュビリー - フル",
      LITTER_JUBILEE_RECYCLING: "ゴミジュビリー - リサイクル",
      LITTER_JUBILEE_REGULAR: "リッタージュビリー - レギュラー",
      LOAD_RESTRICTED_BRIDGE: "負荷制限橋",
      LOW_BRIDGE: "車高制限橋",
      LOW_BRIDGE_AHEAD: "前方の低い橋",
      ONE_WAY: "一方通行",
      OPEN_DOME: "オープンドーム",
      PAPER_BAG_CHOOSE_BAGS_: "紙袋 (袋の数を選択してください)",
      PATIO_COMMERCIAL_ACTIVITY: "パティオの商業活動",
      RECYCLING_BAG_BLUE_CHOOSE_BAGS_:
        "リサイクルバッグ - ブルー (袋の数を選択)",
      REFLECTOR_MISSING: "リフレクター欠品",
      REFLECTOR_OBSCURED: "反射板で覆われた",
      REPAIRED_DEFECTS_CRACK: "修復された欠陥 - 亀裂",
      REPAIRED_DEFECTS_PAVEMENT: "修復された欠陥 - 舗装",
      ROAD_PLOWING_REQUIRED: "道路の除雪作業が必要",
      ROAD_SALT_REQUIRED: "ロードソルトが必要",
      SCHOOL_ZONE_SPEED_LIMIT: "スクールゾーンの速度制限",
      SHARED_BIKE_STATION: "共用自転車ステーション",
      SHOPPING_CART: "ショッピングカート",
      SIDEWALK_PLOWING_REQUIRED: "歩道の除雪は必須",
      SIDEWALK_SALT_REQUIRED: "歩道に塩が必要",
      SOLAR_POWERED_SIGN_FIXED: "太陽光発電サイン - 固定",
      SOLAR_POWERED_SIGN_MOBILE: "太陽光発電サイン - モバイル",
      STOP_AHEAD_NEW: "先に停止、新規",
      SURFACE_DISCONTINUITIES_DEPRECATED: "表面の不連続性 -- 非推奨",
      TRANSIT_LITTER_BINS: "交通機関用ゴミ箱",
      TRASH_ACCUMULATION_INTERSECTION: "ゴミの集積 - 交差点",
      TWIST_TOP: "ツイストトップ",
      VEHICLE_BLOCKING_BICYCLE_LANE: "自転車レーンを妨害する車両",
      VEHICLE_BLOCKING_ROAD: "車両通行止め道路",
      WIRE_BASKET: "ワイヤーバスケット",
      WRONG_WAY: "進入禁止",
      YELLOW_BIN: "イエロービン",
      YIELD_AHEAD: "合流有り",
      YIELD_AHEAD_NEW: "合流有り 新規",
      ZUM_BIN: "ゴミ箱へ",
    },
  },
};
