import { Document, Text, View, StyleSheet } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import PdfPage from "reports/pdfComponents/PdfPage";
import {
  CREATE_LANG_KEY,
  TRANSLATE,
  TranslateDateTimeToLocale,
} from "utils/lang/translate";
import { WORK_ORDER_DATA_FIELDS_COMMON } from "../dataModal/workOrderDataModal";
import { KEYS as WORK_ORDER_FIELD_TRANSLATION_KEYS } from "../WorkorderTable.translate";
import { getRenderSearchParams } from "./dataUtil";

import {
  getAvailableSize,
  getRowStyle,
  ORIENTATIONS,
  PDF_PAGE_STYLE,
  tableHeaderStyle,
  TABLE_STYLES,
  searchParamStyles,
} from "./styles";

const GAP = 4;
const styles = StyleSheet.create({
  page: PDF_PAGE_STYLE,
  headerDate: { textAlign: "right", fontSize: 14 }, // generated date time stamp on top right corner
  docTitle: { textAlign: "center", fontSize: 18 },
  docSubtitle: {
    ...searchParamStyles.rowContainer,
    gap: GAP,
  },
});

const ORIENTATION = ORIENTATIONS.LANDSCAPE;
const PAGE_SIZE = "LETTER";
const { width: availableWidth, height } = getAvailableSize(
  PAGE_SIZE,
  ORIENTATION
);

const TRANSLATION_TYPES = {
  DATE: {
    FULL: "FULL",
    D_DATE: "D_DATE",
    DATE: "DATE",
  },
};

const DEBUG_WITH_COLOR = false;

export const WorkorderPdfSimplifiedView = (props) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { data, searchParams } = props;
  const searchParamChunks = getRenderSearchParams(searchParams, language);

  // the cell could be a bit shorter
  const DTS_CELL_WIDTH = 16;
  const DATE_COLUMN_WIDTH = 90;

  const COLUMN_MARGIN = 2;

  const DATA_FONT_SIZE = 6;
  const TITLE_FONT_SIZE = 8;

  const cellWidth =
    (availableWidth - DTS_CELL_WIDTH - 2 * DATE_COLUMN_WIDTH) / 7;

  const TITLE_STYLE = {
    marginHorizontal: COLUMN_MARGIN,
    fontSize: TITLE_FONT_SIZE,
    backgroundColor: DEBUG_WITH_COLOR && "teal",
  };

  const DATA_STYLE = {
    marginHorizontal: COLUMN_MARGIN,
    fontSize: DATA_FONT_SIZE,
    backgroundColor: DEBUG_WITH_COLOR && "pink",
  };
  const headers0 = [
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.OPEN_DATETIME_LOCAL,
      // label: "Opened",
      label: TRANSLATE(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.OPENED),
      translationType: TRANSLATION_TYPES.DATE.FULL,
      style: {
        ...DATA_STYLE,
        width: DATE_COLUMN_WIDTH,
      },
      titleStyle: {
        ...TITLE_STYLE,
        width: DATE_COLUMN_WIDTH,
      },
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.SERVICE_BY_LOCAL,
      // label: "Service By",
      label: TRANSLATE(
        WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.SERVICE_BY
      ),
      translationType: TRANSLATION_TYPES.DATE.FULL,
      style: {
        ...DATA_STYLE,
        width: DATE_COLUMN_WIDTH,
      },
      titleStyle: {
        ...TITLE_STYLE,
        width: DATE_COLUMN_WIDTH,
      },
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.DTS_LOCAL,
      // label: "DTS",
      label: TRANSLATE(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.DTS),

      style: {
        ...DATA_STYLE,
        width: DTS_CELL_WIDTH,
      },
      titleStyle: {
        ...TITLE_STYLE,
        width: DTS_CELL_WIDTH,
      },
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.CATEGORY,
      // label: "Category",
      label: TRANSLATE(
        WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.CATEGORY
      ),
      translationType: WORK_ORDER_FIELD_TRANSLATION_KEYS.CATEGORY,
      style: {
        ...DATA_STYLE,
        width: cellWidth,
      },
      titleStyle: {
        ...TITLE_STYLE,
        width: cellWidth,
      },
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.W_O_TYPE,
      // label: "Type",
      label: TRANSLATE(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.TYPE),
      style: {
        ...DATA_STYLE,
        width: cellWidth,
      },
      titleStyle: {
        ...TITLE_STYLE,
        width: cellWidth,
      },
      translationType: WORK_ORDER_FIELD_TRANSLATION_KEYS.W_O_TYPE,
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.SEVERITY,
      // label: "Severity",
      label: TRANSLATE(
        WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.SEVERITY
      ),
      style: {
        ...DATA_STYLE,
        width: cellWidth,
      },
      titleStyle: {
        ...TITLE_STYLE,
        width: cellWidth,
      },
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.W_O_STATUS,
      // label: "Status",
      label: TRANSLATE(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.STATUS),
      style: {
        ...DATA_STYLE,
        width: cellWidth,
      },
      titleStyle: {
        ...TITLE_STYLE,
        width: cellWidth,
      },
      translationType: WORK_ORDER_FIELD_TRANSLATION_KEYS.W_O_STATUS,
    },
    {
      // key: WORK_ORDER_DATA_FIELDS_COMMON.REGION,
      key: WORK_ORDER_DATA_FIELDS_COMMON.ROAD_REGION,
      // label: "Region",
      label: TRANSLATE(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.REGION),
      style: {
        ...DATA_STYLE,
        width: cellWidth,
      },
      titleStyle: {
        ...TITLE_STYLE,
        width: cellWidth,
      },
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.W_O_ID,
      // label: "Work Order ID",
      label: TRANSLATE(
        WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.WORK_ORDER_ID
      ),

      style: {
        ...DATA_STYLE,
        width: cellWidth,
      },
      titleStyle: {
        ...TITLE_STYLE,
        width: cellWidth,
      },
    },
    {
      key: WORK_ORDER_DATA_FIELDS_COMMON.ASSIGN_TO,
      // label: "Assigned To",
      label: TRANSLATE(
        WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.ASSIGNED_TO
      ),
      style: {
        ...DATA_STYLE,
        width: cellWidth,
      },
      titleStyle: {
        ...TITLE_STYLE,
        width: cellWidth,
      },
    },
  ];

  // "image_url"
  const { page, headerDate, docTitle, docSubtitle } = styles;
  return (
    <Document layout>
      <PdfPage
        orientation={ORIENTATION}
        style={page}
        size={PAGE_SIZE}
        pagination={true}
      >
        <View fixed>
          <Text style={headerDate}>
            {TranslateDateTimeToLocale(new Date(), "DATE")}
          </Text>
          <Text style={docTitle}>{TRANSLATE("common:WORK_ORDER")}</Text>
          <View style={{ marginVertical: "10" }} />
        </View>
        <View fixed style={tableHeaderStyle}>
          {Object.values(headers0).map(({ label, titleStyle }, index) => (
            <Text key={index} style={titleStyle}>
              {label}
            </Text>
          ))}
        </View>
        {data.map((d, index) => (
          <View key={index} style={getRowStyle(index)}>
            {Object.values(headers0).map(
              ({ key: keyName, style, translationType }, index) => {
                let value = d[keyName];
                if (translationType) {
                  if (
                    Object.values(TRANSLATION_TYPES.DATE).includes(
                      translationType
                    )
                  ) {
                    value = TranslateDateTimeToLocale(value, translationType);
                  } else {
                    const k = translationType[CREATE_LANG_KEY(value)];
                    value = TRANSLATE(k, value);
                  }
                }
                return (
                  <Text style={{ ...style, marginVertical: 2 }} key={index}>
                    {value}
                  </Text>
                );
              }
            )}
          </View>
        ))}
      </PdfPage>
      {/* ))} */}
    </Document>
  );
};
