import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ModalCloseButton from "../../modalCloseButton/ModalCloseButton";
import loadingJif from "./loading_gif.gif";

const useStyles = (maxWidth) =>
  makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    imageDiv: {
      position: "relative",
      boxSizing: "border-box",
      maxWidth: maxWidth,
      display: "inline-block",
      verticalAlign: "middle",
      border: "10px solid #efefef",
      backgroundColor: "#eaeaea",
      transition: ".3s ease",
      boxShadow: `inset 1px 1px 3px rgba(0, 0, 0, .3),
		  1px 1px 2px rgba(0, 0, 0, .2),
		  40px 35px 70px -10px rgba(0, 0, 0, .5),
		  -20px -20px 100px -10px rgba(255, 255, 255, .8)`,
      "& img": {
        maxWidth: maxWidth,
        // width: "100%",
        boxSizing: "border-box",
        border: "10px solid #333",

        boxShadow: `inset 1px 1px 3px rgba(0, 0, 0, 0.3), 
        1px 1px 2px rgba(0, 0, 0, 0.2), 
        40px 35px 70px -10px rgba(0, 0, 0, 0.5), 
        -20px -20px 100px -10px rgba(51, 51, 51, 0.2)`,
      },
    },
  }));

export const ImageModal = (props) => {
  const loadingImageRef = useRef();
  const loadedIamgeRef = useRef();
  const {
    imageSrc,
    open: showModal,
    onClose: handleModalClose,
    maxWidth = 960,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles(maxWidth)();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (typeof handleModalClose === "function") {
      handleModalClose();
    }
  };

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  useEffect(() => {
    const { current: loadedImgEle } = loadedIamgeRef;
    const { current: loadingImgEle } = loadingImageRef;
    if (loadedImgEle) {
      loadedImgEle.style.display = "none";
    }
    if (loadingImgEle) {
      loadingImgEle.style.display = "inline-block";
    }
  }, [loadedIamgeRef, loadingImageRef]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.imageDiv}>
            <ModalCloseButton onClick={handleClose} />
            <img
              ref={loadedIamgeRef}
              src={imageSrc}
              alt="modal"
              style={{ width: "100%", display: "none", maxHeight: 800 }}
              onLoad={() => {
                setIsLoading(false);
                const { current: loadingImgElement } = loadingImageRef;
                if (loadingImgElement) loadingImgElement.style.display = "none";
                const { current: loadedImgEle } = loadedIamgeRef;
                if (loadedImgEle) {
                  if (loadedImgEle) loadedImgEle.style.display = "inline-block";
                }
              }}
            />

            <img
              ref={loadingImageRef}
              src={loadingJif}
              alt="loading iamge gif"
              style={{ width: "100%" }}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
