import DateUtils from "utils/dateUtils/DateUtils";

const SERVICE_BY_LOCAL = "service_by_local";
const OPEN_DATETIME_LOCAL = "open_datetime_local";
const INSPECTED_ON_LOCAL = "inspected_on_local";
const DTS_LOCAL = "dts_local";
export const DEFAULT_VALUE = "N / A";
/**
 * key is existed work order data key, value is newKey attached to work order data, whose value
 * represents **localize** datetime string value
 */
export const LOCAL_MAPPING_FIELDS = {
  service_by: SERVICE_BY_LOCAL,
  open_datetime: OPEN_DATETIME_LOCAL,
  inspected_on: INSPECTED_ON_LOCAL,
};

const LOCAL_FIELDS = {
  OPEN_DATETIME_LOCAL: OPEN_DATETIME_LOCAL,
  INSPECTED_ON_LOCAL: INSPECTED_ON_LOCAL,
  SERVICE_BY_LOCAL: SERVICE_BY_LOCAL,
  DTS_LOCAL,
};

const UTC_FIELDS = {
  OPEN_DATE_UTC: "open_date",
  OPEN_TIME_UTC: "open_time",
  SERVICE_BY_UTC: "service_by",
  OPEN_DATETIME_UTC: "open_datetime",
  INSPECTED_ON_UTC: "inspected_on",
};

const STRING_FIELDS = {
  ADDRESS: "address",
  ASSIGN_TO: "assign_to",
  ATTACHMENTS: "image_urls_completed",
  CLASS: "class",
  CATEGORY: "category",
  DATAPOINT_ID: "datapoint_id",
  DESCRIPTION: "description",
  ID: "id",
  IMAGE_URL: "image_url",
  INSPECTED_BY: "inspected_by",
  ISSUED_BY: "issued_by",
  LABEL_ID: "label_id",
  DTS: "dts",
  LAT: "lat",
  LON: "lon",
  MAP_IMAGE_URL: "map_image_url",
  NOTE: "note",
  REGION: "region",
  ROAD_REGION: "road_region",
  SEVERITY: "severity",
  W_O_ID: "w_o_id",
  W_O_STATUS: "w_o_status",
  W_O_TYPE: "w_o_type",
  HEADING: "heading",
  LANE: "lane",
};
export const STRING_SET_FIELDS = {
  IMAGE_URLS_COMPLETED: "image_urls_completed",
};

export const WORK_ORDER_DATA_FIELDS_COMMON = {
  ...STRING_FIELDS,
  ...UTC_FIELDS,
  ...LOCAL_FIELDS,
};

export const CSV_REPORT_FIELDS = {
  ...STRING_FIELDS,
  ...LOCAL_FIELDS,
};

/**
 * @summary get an array of strings, which would be used inside the **item** field of a graphql query
 * 
 * @description the array is a merged result of the following
  - common fields - shared among all cities
  - utc fields - also shared among all cities, whose value would be localized, by **timezone**
  - city specific fields - these fields are different city by city

 * @param {[{fieldName: string, label: string}]} fieldsConfig
 * @returns {string []}
 */
export const getQueryItems = (fieldsConfig) => {
  // common fields
  const stringFields = Object.values(STRING_FIELDS);
  // common fields
  const timestampFields = Object.values(UTC_FIELDS);

  let commonFields = [...stringFields, ...timestampFields];

  // get city specific fields
  if (fieldsConfig) {
    const cityFields = fieldsConfig.map((v) => v.fieldName);
    commonFields = commonFields.concat(cityFields);
  }

  // removed any duplicate fields
  const s = new Set(commonFields);
  const uniqueFields = [...s];
  return uniqueFields;
};

/**
 * @summary do some localization for data, mostly from UTC timestamp to local timestamp
 *
 * @description the localization doese not change values of the existed fields, but rather, append new
 * fields with corresponding localized values.
 *
 * IE: there is a **open_datetime** field, whose value is a timestamp string in UTC format
 * this field and its value would be intact. However, a new field named **open_datetime_local** would
 * be added to the data, and its value would be the localized version of the UTC time string
 *
 * @param {Object []} data data returned from a graphql query
 * @param {string} regionTimezone timezone of the city
 * @returns
 */
export const localizeData = (data, regionTimezone) => {
  const copy = { ...data };
  for (const key in copy) {
    const value = copy[key];
    if (Object.values(STRING_SET_FIELDS).indexOf(key) > -1) {
      let urls = value || [];

      // string set is initiated as [""] by default, on dynamodb
      urls = urls.filter((url) => url !== "");
      copy[key] = urls;
    }

    if (Object.keys(LOCAL_MAPPING_FIELDS).indexOf(key) > -1) {
      let localTime = value;
      if (value) {
        localTime = DateUtils.momentTimezoneGetyyyyMMddhhmmss(
          copy[key],
          regionTimezone
        );
      }
      const newKey = LOCAL_MAPPING_FIELDS[key];
      copy[newKey] = localTime;
    }

    // determined local dts based on
    /** time in milliseconds of now */
    const t1 = new Date().getTime();

    /** time in milliseconds of service by time (UTC) */
    const t0 = Date.parse(WORK_ORDER_DATA_FIELDS_COMMON.SERVICE_BY);

    const diffMilliSecond = t0 - t1;
    /** diff between now and service day, in millisecond */
    let dtsLocalDay = diffMilliSecond / (1000 * 60 * 60 * 24);

    const localDTSKey = WORK_ORDER_DATA_FIELDS_COMMON.DTS_LOCAL;
    copy[localDTSKey] = copy[WORK_ORDER_DATA_FIELDS_COMMON.DTS];
    if (isNaN(dtsLocalDay)) {
      dtsLocalDay = 0;
    } else {
      /** set dts to 0 if different is less than one day, ie: overdue less than one day or dts less than one day  */
      if (Math.abs(dtsLocalDay) < 1) {
        copy[localDTSKey] = 0;
      } else if (dtsLocalDay < -1) {
        copy[localDTSKey] = -Math.floor(-dtsLocalDay);
      } else {
        copy[localDTSKey] = Math.floor(dtsLocalDay);
      }
    }

    // set up defail value on work order data
    if (value === "" || value === null || value === undefined) {
      copy[key] = DEFAULT_VALUE;
    }
  }

  return copy;
};

export const STATUS_VALUES = {
  OPENED: "Opened",
  CLOSED: "Closed",
  IGNORED: "Ignored",
};
export const ROAD_REGIONS_VALUES = {
  EAST: "EAST",
  SOUTH: "SOUTH",
  NORTH: "NORTH",
  WEST: "WEST",
};

export const WARDS = [
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
  { key: "5", value: "5" },
];

export const STATUS = [
  { key: "Opened", value: STATUS_VALUES.OPENED },
  { key: "Closed", value: STATUS_VALUES.CLOSED },
  { key: "Ignored", value: STATUS_VALUES.IGNORED },
];
export const ROAD_REGIONS = [
  { key: "EAST", value: ROAD_REGIONS_VALUES.EAST },
  { key: "WEST", value: ROAD_REGIONS_VALUES.WEST },
];

/**
 *
 * @param {string []} assignees an array of people's names
 */
export const getAssignees = (assignees) => {
  if (!Array.isArray(assignees) || assignees.length === 0) {
    return [{ key: "", value: "", label: "" }];
  }

  const arr = assignees.map((name) => ({
    key: name,
    value: name,
    label: name,
  }));
  return arr;
};

export function getWorkorderDataSummary(data, locale) {
  const percentageOption = {
    style: "percent",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  const numberFormatterPercent = new Intl.NumberFormat(
    locale,
    percentageOption
  );
  const dataSummary = data.reduce((acc, ele) => {
    const { category, w_o_type: woType, w_o_status: woStatus, dts_local } = ele;

    if (!acc[category]) {
      acc[category] = {};
      acc[category].report = {};
      acc[category].report.items = [];
      acc[category].report.opened = [];
      acc[category].report.closed = [];
      acc[category].report.ignored = [];
      acc[category].report.overDue = [];
      acc[category].report.stillHaveTime = [];
      acc[category].report.inspectedBeforeServiceBy = [];
      acc[category].report.inspectedAfterServiceBy = [];
      acc[category].report.compliance = numberFormatterPercent.format(0);
      acc[category].report.inCompliance = numberFormatterPercent.format(0);
      acc[category].report.completion = numberFormatterPercent.format(0);
      acc[category].report.inCompleition = numberFormatterPercent.format(0);
      acc[category].report.overduePercentage = numberFormatterPercent.format(0);
      acc[category].report.stillHaveTimePercentage =
        numberFormatterPercent.format(0);
    }

    if (!acc[category][woType]) {
      acc[category][woType] = {};
      acc[category][woType].items = [];
      acc[category][woType].report = {};
      acc[category][woType].report.opened = [];
      acc[category][woType].report.ignored = [];
      acc[category][woType].report.closed = [];
      acc[category][woType].report.overDue = [];
      acc[category][woType].report.stillHaveTime = [];
      acc[category][woType].report.inspectedBeforeServiceBy = [];
      acc[category][woType].report.inspectedAfterServiceBy = [];

      // get localize percentage first
      // as these values would be overwritten if necessary
      // as a result not 0 in number, but 0.00% or 0,00% (french)
      acc[category][woType].report.compliance =
        numberFormatterPercent.format(0);
      acc[category][woType].report.inCompliance =
        numberFormatterPercent.format(0);
      acc[category][woType].report.completion =
        numberFormatterPercent.format(0);
      acc[category][woType].report.inCompleition =
        numberFormatterPercent.format(0);
      acc[category][woType].report.overduePercentage =
        numberFormatterPercent.format(0);
      acc[category][woType].report.stillHaveTimePercentage =
        numberFormatterPercent.format(0);
    }

    if (woStatus === "Opened") {
      acc[category][woType].report.opened.push(ele);
      acc[category].report.opened.push(ele);
    } else if (woStatus === "Closed") {
      acc[category][woType].report.closed.push({ ...ele });
      acc[category].report.closed.push({ ...ele });

      const serviceByDate = ele.service_by.split("T")[0];

      //NOTE: it is found that some closed work order has inspected value as null, timestamp DEFAULT to ''
      const inspectedOnDate = ele?.inspected_on?.split("T")[0] || "";

      const serviceOnDateMilli = Date.parse(serviceByDate);
      const inspectedOnDateMilli =
        inspectedOnDate === -1 ? "" : Date.parse(inspectedOnDate);

      if (
        inspectedOnDateMilli > 0 ||
        inspectedOnDate === DEFAULT_VALUE // for integrity, keep work order whose status is "Closed" but inspected_on value is not given
      ) {
        if (serviceOnDateMilli >= inspectedOnDateMilli) {
          acc[category].report.inspectedBeforeServiceBy.push({ ...ele });
          acc[category][woType].report.inspectedBeforeServiceBy.push({
            ...ele,
          });
        } else {
          acc[category].report.inspectedAfterServiceBy.push({ ...ele });
          acc[category][woType].report.inspectedAfterServiceBy.push({ ...ele });
        }
      }

      acc[category][woType].report.compliance =
        acc[category][woType].report.inspectedBeforeServiceBy.length /
        acc[category][woType].report.closed.length;
      acc[category][woType].report.inCompliance =
        1 - acc[category][woType].report.compliance;

      acc[category][woType].report.compliance = numberFormatterPercent.format(
        acc[category][woType].report.compliance
      );

      acc[category][woType].report.inCompliance = numberFormatterPercent.format(
        acc[category][woType].report.inCompliance
      );

      const compliancePercent =
        acc[category].report.inspectedBeforeServiceBy.length /
        acc[category].report.closed.length;
      acc[category].report.compliance =
        numberFormatterPercent.format(compliancePercent);
      acc[category].report.inCompliance = numberFormatterPercent.format(
        1 - compliancePercent
      );
    } else if (woStatus === "Ignored") {
      acc[category][woType].report.ignored.push(ele);
      acc[category].report.ignored.push(ele);
    }
    acc[category][woType].items.push(ele);

    acc[category].report.items.push(ele);

    const typeTotal = acc[category][woType].items.length;
    const typeClosedTotal = acc[category][woType].report.closed.length;

    const typetotalOverdue = acc[category][woType].report.overDue.length;

    const categoryTypeReportCompletion = typeClosedTotal / typeTotal;
    acc[category][woType].report.completion = numberFormatterPercent.format(
      categoryTypeReportCompletion
    );
    acc[category][woType].report.inCompleition = numberFormatterPercent.format(
      1 - categoryTypeReportCompletion
    );

    const categoryTypeReportOverduePercent = typetotalOverdue / typeTotal;
    acc[category][woType].report.overduePercentage =
      numberFormatterPercent.format(categoryTypeReportOverduePercent);
    acc[category][woType].report.stillHaveTimePercentage =
      numberFormatterPercent.format(1 - categoryTypeReportOverduePercent);

    if (dts_local > 0) {
      acc[category].report.stillHaveTime.push(ele);
      acc[category][woType].report.stillHaveTime.push(ele);
    } else {
      acc[category].report.overDue.push(ele);
      acc[category][woType].report.overDue.push(ele);
    }

    const totalOpened = acc[category].report.opened.length;
    const totalClosed = acc[category].report.closed.length;
    const totalIgnored = acc[category].report.ignored.length;

    const totalItems = totalOpened + totalClosed + totalIgnored;
    const totalOverdue = acc[category].report.overDue.length;

    acc[category].report.completion = parseFloat(totalClosed / totalItems);
    acc[category].report.inCompleition = parseFloat(
      1 - acc[category].report.completion
    );
    acc[category].report.overduePercentage = parseFloat(
      totalOverdue / totalItems
    );
    acc[category].report.stillHaveTimePercentage = parseFloat(
      1 - acc[category].report.overduePercentage
    );

    // report percentage
    acc[category].report.completion = numberFormatterPercent.format(
      acc[category].report.completion
    );
    acc[category].report.inCompleition = numberFormatterPercent.format(
      acc[category].report.inCompleition
    );
    acc[category].report.overduePercentage = numberFormatterPercent.format(
      acc[category].report.overduePercentage
    );
    acc[category].report.stillHaveTimePercentage =
      numberFormatterPercent.format(
        acc[category].report.stillHaveTimePercentage
      );
    return acc;
  }, {});

  return dataSummary;
}
