import moment from "moment";

export default class DateUtils {
  /**
   * @summary get date object from given param
   *
   * @param {Date | string | null | undefined} param
   *
   * @returns data object generated by the param, default return current date object
   */
  static getDateObject(param) {
    const temp = new Date(param);
    if (isNaN(temp.getTime())) {
      return new Date();
    }
    return temp;
  }

  static append0(num) {
    const numString = "" + num;
    if (numString.length < 2) {
      return "0" + numString;
    }
    return numString;
  }

  /**
   * @summary determined whether a date is a valid date
   *
   * @param {Date | string} date
   * @returns true if given string or date is a valid date, otherwise, false
   */
  static isValid(date) {
    if (!date) {
      // turns out only date === undefined returns false, while 0 and null return true
      return false;
    }
    const dateObject = new Date(date);
    return !isNaN(dateObject.getTime());
  }

  /**
   * @summary get local date relative to the given date
   *
   * @param {Date} date
   * @param {boolean} isUTCDate give date / string value represents utc time or not
   * @returns local date relative to the givne date
   */
  static getLocalDate(date = new Date(), isUTCDate = false) {
    const temp = new Date(date);
    if (isUTCDate) {
      const hoursDiff = temp.getTimezoneOffset() / 60;
      temp.setHours(temp.getHours() + hoursDiff);
    }
    return temp;
  }

  /**
   * @summary get the yyyy-mm-dd string format, relative to local date time, of the given date object
   *
   * @param {Date | string | null | undefined} date
   * @param {boolean} isUTCDate give date / string value represents utc time or not
   * @returns string in yyyy-mm-dd formate of the given date, relative to local date time
   */
  static getyyyyMMdd(date, isUTCDate = false) {
    const localDate = this.getLocalDate(date, isUTCDate);
    const yyyy = localDate.getFullYear();
    const mm = localDate.getMonth() + 1;
    const dd = localDate.getDate();
    const mmString = this.append0(mm);
    const ddString = this.append0(dd);
    return `${yyyy}-${mmString}-${ddString}`;
  }

  static getTimeFormat24(date, isUTCDate = false) {
    let dateObject;
    if (!date) {
      dateObject = new Date();
    } else if (this.isValid(date)) {
      dateObject = new Date(date);
    }
    dateObject = this.getLocalDate(dateObject, isUTCDate);
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const seconds = dateObject.getSeconds();
    const hourString = this.append0(hours);
    const munteString = this.append0(minutes);
    const secondString = this.append0(seconds);
    return `${hourString}:${munteString}:${secondString}`;
  }
  static getTimeFormat12(date = new Date()) {
    let ampm = "am";
    let hours = date.getHours();

    if (hours === 12) {
      ampm = "pm";
    } else if (hours > 12) {
      ampm = "pm";
      hours %= 12;
    }
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const hourString = this.append0(hours);
    const munteString = this.append0(minutes);
    const secondString = this.append0(seconds);
    return `${hourString}:${munteString}:${secondString} ${ampm}`;
  }

  static getyyyyMMddHHmmss(date = new Date()) {
    let dateObject;
    if (!date) {
      dateObject = new Date();
    } else if (this.isValid(date)) {
      dateObject = new Date(date);
    }
    const datePart = this.getyyyyMMdd(dateObject);
    const timePart = this.getTimeFormat24(dateObject);
    return `${datePart} ${timePart}`;
  }
  static getyyyyMMddhhmmss(date = new Date()) {
    let dateObject;
    if (!date) {
      dateObject = new Date();
    } else if (this.isValid(date)) {
      dateObject = new Date(date);
    }
    const datePart = this.getyyyyMMdd(dateObject);
    const timePart = this.getTimeFormat12(dateObject);
    return `${datePart} ${timePart}`;
  }

  /**
   * @summary get timestamp string in formart yyyy-MM-ddTHH:mm:ss-04:00
   * @param {Date | string | null | undefined} param
   * @returns timestamp for data base yyyy-MM-ddTHH:mm:ss-04:00
   */
  static getDatabaseTimeStamp(param) {
    const date = this.getDateObject(param);

    const localDateString = date.toString();
    // "Wed Oct 20 2021 11:22:30 GMT-0400 (Eastern Daylight Time)"

    const localRelativeToUTCString = localDateString.split("GMT")[0] + "UTC";
    // "Wed Oct 20 2021 11:22:58 UTC"

    const localRelativeToUTCDateObject = new Date(localRelativeToUTCString);
    // 2021-10-20T11:23:38.000Z

    const localISOString = localRelativeToUTCDateObject.toISOString();
    // "2021-10-20T11:24:19.000Z"

    const GMTPart = localDateString.split("GMT")[1];
    // "-0400 (Eastern Daylight Time)"

    const timezoneString = GMTPart.split(" ")[0];
    // "-0400"

    let tzs = timezoneString.substr(0, 3) + ":" + timezoneString.substr(3);
    // "-04:00"

    const temp = localISOString.replace("Z", tzs);
    // "2021-10-20T11:24:19.000-04:00"

    return temp;
  }

  /**
   *
   * @param {string} timeStamp
   * @param {string} timezone
   * @returns
   */
  static momentTimezoneGetyyyyMMddhhmmss(
    timeStamp,
    timezone = "America/Toronto"
  ) {
    return moment.tz(timeStamp, timezone).format("YYYY-MM-DD HH:mm:ss");
  }

  /**
   * @description get timestring in YYYY-MM-DD format, local to given timezone
   * @param {string} timestamp
   * @param {string} timezone
   * @returns  desire timestring
   */
  static momentTimezoneGetyyyyMMdd(
    timestamp = "",
    timezone = "America/Toronto"
  ) {
    if (timestamp) {
      return moment.tz(moment(timestamp), timezone).format("YYYY-MM-DD");
    }
    return moment.tz(moment(), timezone).format("YYYY-MM-DD");
  }

  /**
   * get ISO string format of date object, start of the date, relative to given timezone
   * @param {Date} dateObject
   * @param {string} timezone
   * @returns
   */
  static momentGetUTCStartOfDay(dateObject, timezone = "America/Toronto") {
    return moment
      .tz(moment(dateObject).format("YYYY-MM-DDT00:00:00"), timezone)
      .toJSON();
  }

  /**
   * get ISO string format of date object, end of the date, relative to given timezone
   * @param {Date} dateObject
   * @param {string} timezone
   * @returns
   */
  static momentGetUTCEndOfDay(dateObject, timezone = "America/Toronto") {
    const temp = moment(dateObject);

    const b = temp.format("YYYY-MM-DDT00:00:00");
    const temp1 = moment(b);
    temp1.add(1, "d");
    temp1.subtract(1, "ms");
    return moment
      .tz(temp1.format("YYYY-MM-DDT23:59:59.999"), timezone)
      .toJSON();
  }

  static momentGetUTCDateRange(
    startDate,
    endDate,
    timezone = "America/Toronto"
  ) {
    const startDateUTC = DateUtils.momentGetUTCStartOfDay(startDate, timezone);
    const endDateUTC = DateUtils.momentGetUTCEndOfDay(endDate, timezone);

    return [startDateUTC, endDateUTC];
  }

  /**
   *
   * @param {Date} input
   * @param {string} [locale] default is **"en"**
   * @returns
   */
  static timeAgo(input, locale = "en") {
    const date = input instanceof Date ? input : new Date(input);
    const formatter = new Intl.RelativeTimeFormat(locale);
    const ranges = {
      years: 3600 * 24 * 365,
      months: 3600 * 24 * 30,
      weeks: 3600 * 24 * 7,
      days: 3600 * 24,
      hours: 3600,
      minutes: 60,
      seconds: 1,
    };

    let timeDiff = date.getTime() - Date.now();
    if (timeDiff >= -1000) {
      timeDiff = -1001;
    }

    const secondsElapsed = timeDiff / 1000;
    for (let key in ranges) {
      const delta = secondsElapsed / ranges[key];
      if (ranges[key] < Math.abs(secondsElapsed)) {
        return formatter.format(Math.round(delta), key);
      }
    }
  }
}
