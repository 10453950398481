import { getAWSTOkenAsync } from "hook/useAuth";
import {
  IRIS_NOTIFICATION_RETRIEVE,
  IRIS_NOTIFICATION_UPDATE,
} from "utils/env/urls";



export const NOTIFICATIONS_STATES =
{
  UNOPENED : "unopened",
  DISMISSED : "dismissed",
  READ : "read"
}

/**
 * @typedef {("READ" | "NON_READ" | "ALL")} TypeNotificationStatus
 */

/**
 *
 * @param {string} userName
 * @param {string} startDate // date string
 * @param {string} endDate // date string
 * @param {TypeNotificationStatus} status
 * @returns {Promise<TypeNotification []>}
 */
export const getNotification = async (userName, startDate, endDate, status) => {
  const token = await getAWSTOkenAsync();
  const url = new URL(IRIS_NOTIFICATION_RETRIEVE);
  url.searchParams.append("username", userName);
  url.searchParams.append("notification_type", "web-app");
  if (status !== "ALL") {
    url.searchParams.append("read", status);
  }
  url.searchParams.append("startDate", startDate);
  url.searchParams.append("endDate", endDate);
  url.searchParams.append("sort", "desc");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return new Promise(async (resolve, reject) => {
    let error;
    let nextUrl = url.toString();
    let notifications = [];

    while (nextUrl) {
      const response = await fetch(nextUrl, options).catch((e) => {
        error = e;
      });

      if (error) {
        break;
      } else if (response.ok) {
        const { results, next: nextPageUrl } = await response.json();
        notifications = notifications.concat(results);
        nextUrl = nextPageUrl;
      } else {
        const { status } = response;
        const message = await response.text();
        const errorMessage = `Error ${status}: ${message}`;
        if (status === 401 || status === 403) {
          error = new Error(errorMessage);
        } else if (status === 404) {
          error = new Error(errorMessage);
        } else if (status >= 500) {
          error = new Error(errorMessage);
        } else {
          error = new Error(errorMessage);
        }
        break;
      }
    }
    if (error) {
      reject(error);
    } else {
      resolve(notifications);
    }
  });
};

/**
 *
 * @param {string} userName
 * @param {TypeNotificationStatus} status
 * @returns {Promise<TypeNotification []>}
 */
export const getPassMonthNotification = async (userName, status) => {
  const endTimeUTC = new Date().toJSON();
  const startDateTimeStamp = new Date().getTime() - 1000 * 60 * 60 * 23 * 30;
  const startTimeUTC = new Date(startDateTimeStamp).toJSON();
  return getNotification(userName, startTimeUTC, endTimeUTC, status);
};

/**
 *
 * @param {string} userName
 * @param {number[]} ids
 * @param {TypeNotificationStatus} status
 * @returns
 */
export const updateNotificationStatus = async (userName, ids, status) => {
  const token = await getAWSTOkenAsync();

  const payload = {
    username: userName,
    notification_ids: ids,
    notification_type: "web-app", // required,
    read_status: status
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };

  return new Promise(async (resolve, reject) => {
    let error;
    const response = await fetch(IRIS_NOTIFICATION_UPDATE, options).catch(
      (e) => {
        error = e;
      }
    );
    if (error) {
      // caught error due to fetch API
      reject(error);
    } else if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const { status } = response;
      const message = await response.text();
      const errorMessage = `Error ${status}: ${message}`;
      if (status === 401 || status === 403) {
        error = new Error(errorMessage);
      } else if (status === 404) {
        error = new Error(errorMessage);
      } else if (status >= 500) {
        error = new Error(errorMessage);
      } else {
        error = new Error(errorMessage);
      }
      reject(error);
    }
  });
};
