import { companyLogo } from "assets/assets";
import { Auth } from "aws-amplify";
import { AWS_CHALLENGE_NAMES } from "aws/utils";
import { getQueryItems } from "components/table/dataModal/workOrderDataModal";
import { LOCAL_STORAGE_KEY_REACT_PDF_RENDERER_CITY } from "libConfig/reactPDFRenderer";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PROTECTED_ROUTES } from "routes/data/protectedRoutes";
import { getIrisUser } from "utils/apis/reportAPI/authAPI";
import {
  getPCIReportConfig,
  listDefectTypes,
  loginArcgis,
} from "utils/requests";
import { getWorkorderConfig } from "utils/requests";
import { remapClientName } from "./utils";
import { datadogRum } from "@datadog/browser-rum";

let arcgisToken = {
  token: undefined,
  expire: undefined,
};

/*================================================== get aws token START ================================================== */
let interval;
const FIFITY_FIVE_MINUTES = 55 * 60 * 1000;
let awsToken;

/**
 * trigger or remove an interval which refetches aws access token in every 55 minutes
 *
 * TODO remove this setup and log user out when token is about to expired instead, more details on this
 * ticket
 * https://irisradgroup.atlassian.net/jira/software/c/projects/DT/boards/1/backlog?selectedIssue=DT-1374
 * @param {boolean} stop if true, remove interval, otherwise, set it up
 */
const autoUpdateAWSToken = (stop = false) => {
  // clear previous interval
  if (interval) {
    // console.log("interval is cleared");
    clearInterval(interval);
  }
  if (!stop) {
    const getToken = () => {
      Auth.currentAuthenticatedUser().then((res) => {
        // put into a ref should be good
        const accessToken = res.signInUserSession.accessToken.jwtToken;
        // console.log("AWS_TOKEN is REFRESHED");
        awsToken = accessToken;
      });
    };

    if (!awsToken) {
      getToken();
    }
    interval = setInterval(getToken, FIFITY_FIVE_MINUTES);
    // console.log("interval is setup with value", interval);
  } else {
    // console.log(`stop is ${stop}`);
  }
};

const getDefectCategories = (defectsList) => {
  const categories = new Map();
  defectsList.forEach((defect) => {
    if (defect?.categories) {
      defect?.categories.forEach((category) => {
        if (!categories.get(category.name)) categories.set(category.name, []);
        categories.get(category.name).push(defect);
      });
    }
  });
  return categories;
};

/**
 *
 * @deprecated used getAWSTOkenAsync instead
 */
export const getAWSToken = () => {
  return awsToken;
};

export const getAWSTOkenAsync = async () => {
  const result = await Auth.currentAuthenticatedUser();
  if (result) {
    return result.signInUserSession.accessToken.jwtToken;
  } else {
    return Promise.reject(Error("Error in getting token from Auth"));
  }
};
/*==================================================  get aws token END  ================================================== */

/*================================================== additional setup for datadog START ================================================== */
const setupDatadog = (username) => {
  // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#identify-user-session
  datadogRum.setUser({
    name: username,
    // name: "lucien PC test manual start session replay",
  });

  const sessionReplaySampleRate =
    process.env.REACT_APP_DATADOG_REPLAY_SAMPLE_RATE || 0;
  // reference https://docs.datadoghq.com/real_user_monitoring/browser/
  datadogRum.init({
    // find values for this snippet from
    // UX Monitoring -> setup & configurations
    // find your app and find Edit Application
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: "us5.datadoghq.com",
    service: "iriscity-dashboard",
    sessionSampleRate: 100,
    sessionReplaySampleRate: parseInt(sessionReplaySampleRate),
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
};
/*==================================================  additional setup for datadog END  ================================================== */
/**
 * get city config out of user object
 * @param {TypeIrisUser} user
 *
 * @returns {TypeCityConfig}
 */
const getCityConfig = (user) => {
  /** first group info */
  const groupInfo = user.clients[0];

  const geoInfo = groupInfo.geo_info;
  /**@type {TypeCityConfig} */
  const cityConfig = {};
  cityConfig.name = groupInfo.service.iris_city.name;
  // default client name as city name, will be overwrtten with a human readalbe name if applicable
  cityConfig.clientName = remapClientName(cityConfig.name);
  // write to localStroage so that when rendering with react-pdf/render, for different language,
  // it has access to it, so that it picks up proper text file for rendering text in various language
  // it is found using hook can pass data to the component
  localStorage.setItem(
    LOCAL_STORAGE_KEY_REACT_PDF_RENDERER_CITY,
    cityConfig.name
  );
  cityConfig.degree_preference = geoInfo.temp_unit;
  cityConfig.code = geoInfo.open_weather_id;
  cityConfig.province = geoInfo.province;
  cityConfig.timezone = geoInfo.timezone;
  cityConfig.latitude = geoInfo.lat;
  cityConfig.longitude = geoInfo.lon;
  cityConfig.deviceCount = groupInfo.device_count;
  cityConfig.logo_url = companyLogo;
  return cityConfig;
};

const DEFAULT_AUTH_CONTEXT = {
  /**@type {TypeIrisUser} Iris user object*/
  user: undefined,

  /** temp user used to reset password, if required by Cognito */
  tempUser: undefined,

  /**@type {TypePageConfig} */
  pageConfig: undefined,

  defectTypes: [],
  defectCategories: new Map(),

  /**
   * array of objects about route information for current user
   * @type {TypeRoute[]}
   */
  routes: [],
  loading: false,
  loadingCachedUser: false,
  error: undefined,

  /**
   *
   * @param {string} userName cognito user name
   * @param {string} password coginto password
   * @returns {Promise<TypeIrisUser>}
   */
  login: (userName, password) => { },
  logout: () => { },
  resetTempUser: () => { },
  getArcgisAccessToken: async () => { },
};

const AuthContext = createContext(DEFAULT_AUTH_CONTEXT);

// Export the provider as we need to wrap the entire app with it
// https://dev.to/finiam/predictable-react-authentication-with-the-context-api-g10
export function AuthProvider(props) {
  const { children } = props;
  const [tempUser, setTempUser] = useState();
  /**@type {[user: TypeIrisUser]} */
  const [user, setUser] = useState();

  /**@type {[pageConfig: TypePageConfig]} */
  const [pageConfig, setPageConfig] = useState();
  const [defectTypes, setDefectTypes] = useState();
  const [defectCategories, setDefectCategories] = useState();

  /**@type {[routes: TypeRoute []]} */
  const [routes, setRoutes] = useState([]);
  const [error, setError] = useState();
  // const [queryItems, setQueryItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCachedUser, setLoadingCachedUser] = useState(true);
  // We are using `react-router` for this example,
  // but feel free to omit this or use the
  // router of your choice.
  const location = useLocation();
  const history = useHistory();

  const [workOrderConfigFromAWS, setWorkOrderConfigFromAW] = useState(null);
  const [pciReportConfigFromAWS, setPciReportConfigFromAW] = useState(null);

  useEffect(() => {
    getWorkorderConfig()
      .then((data) => {
        setWorkOrderConfigFromAW(data);
      })
      .catch((error) => {
        console.error(error);
      });
    getPCIReportConfig()
      .then((data) => {
        setPciReportConfigFromAW(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null);
  }, [error, location.pathname]);

  useEffect(() => {
    const loadUser = async () => {
      /**@type {TypeCognitoUser} */
      let user;

      /**@type {TypeIrisUser} */
      let irisUser;

      let error;
      try {
        setLoadingCachedUser(true);
        /**@type {number} */
        user = await Auth.currentAuthenticatedUser();
        if (user) {
          const token = user.signInUserSession.accessToken.jwtToken;
          irisUser = await getIrisUser(token);
          const defectsList = await listDefectTypes(token);
          setDefectTypes(defectsList);
          setDefectCategories(getDefectCategories(defectsList));

          if (irisUser.clients.length === 0) {
            throw new Error(
              `${irisUser.username} has not been assigned to any groups`
            );
          }
        } else {
          // failed to load cached user
        }
      } catch (e) {
        error = e;
      } finally {
        setLoadingCachedUser(false);
        if (error) {
          console.error("error", error);
          setError(error);
        } else if (irisUser) {
          setUser(irisUser);
        }
      }
    };

    loadUser();
  }, []);

  // extra configs, including routes (pages) and configs for each route (page) based on user info
  useEffect(() => {
    if (
      user &&
      workOrderConfigFromAWS !== null &&
      pciReportConfigFromAWS !== null
    ) {
      /**@type {TypePageConfig} */
      const pageConfig = {};

      // set up city config
      const cityConfig = getCityConfig(user);
      pageConfig.city = cityConfig;
      pageConfig.clientId = user.clients[0].id;
      const {
        w_o_profile,
        patrol_report,
        widgets,
        name: clientName,
      } = user.clients[0].service.iris_city;
      // set up dashboard configs (widgets)

      if (widgets) {
        const dashboardConfig = JSON.parse(JSON.stringify(widgets));
        pageConfig.dashboard = dashboardConfig;
      }
      // set up work order config
      if (w_o_profile) {
        /**@type {TypeWorkorderConfig} */
        const workorderConfig = JSON.parse(JSON.stringify(w_o_profile));

        const lowerClientName = clientName.toLowerCase();
        let fieldsConfig = [];

        if (workOrderConfigFromAWS[lowerClientName]) {
          fieldsConfig =
            workOrderConfigFromAWS[lowerClientName][
            "iris_city_dashboard_config"
            ] || [];
          pageConfig.city.logo_url =
            workOrderConfigFromAWS[lowerClientName]["logo_url"] || companyLogo;
        }
        workorderConfig.interested_fields = fieldsConfig;
        const queryItems = getQueryItems(fieldsConfig);
        workorderConfig.queryItems = queryItems;

        // TODO make sure values in search_fields is a subst of queryItems
        const searchFields = w_o_profile.search_fields.filter(
          (ele) => queryItems.indexOf(ele) > -1
        );
        workorderConfig.searchFields = searchFields;
        pageConfig.workorder = workorderConfig;

        const getOptionArray = (value) => ({
          key: value,
          title: value,
        });
        workorderConfig.category =
          workorderConfig.options.categories.map(getOptionArray);
        workorderConfig.w_o_status =
          workorderConfig.options.workorder_status.map(getOptionArray);
        workorderConfig.heading =
          workorderConfig.options.heading.map(getOptionArray);
        const defectStrings = defectTypes.map((defect) => defect.name);
        const defectSet = new Set(defectStrings);
        const defects = [...defectSet].sort();
        workorderConfig.w_o_type = defects.map(getOptionArray);
        workorderConfig.assign_to =
          workorderConfig.assignees.map(getOptionArray);
      }
      // set up patrol report config
      /**@type {TypePatrol_report} */
      if (patrol_report) {
        const patrolReportConfig = JSON.parse(JSON.stringify(patrol_report));
        pageConfig.patrolReport = patrolReportConfig;
      }

      const pciReportConfig = pciReportConfigFromAWS[clientName.toLowerCase()];
      if (pciReportConfig) {
        pageConfig.pciReport = pciReportConfig;
      }

      setPageConfig(pageConfig);

      // set up route objects for current user
      const irisCityServices = user.clients[0].service.iris_city;
      const serviceKeys = Object.keys(irisCityServices).filter((key) => {
        const value = irisCityServices[key];
        if (value) {
          return true;
        }
        return false;
      });

      if (pciReportConfig) {
        serviceKeys.push(PROTECTED_ROUTES.pciPage.key);
      }

      const filteredRoutes = Object.values(PROTECTED_ROUTES).filter(
        (route) => serviceKeys.indexOf(route.key) > -1
      );
      setRoutes(filteredRoutes);
      autoUpdateAWSToken();
      setupDatadog(user.username);
    }

    return () => {
      autoUpdateAWSToken(true);
    };
  }, [user, workOrderConfigFromAWS, pciReportConfigFromAWS]);

  const login = async (userName, password) => {
    /**@type {TypeCognitoUser} */
    let cognitoUser;

    /** @type {TypeIrisUser} */
    let irisUser, tempUser, error;
    try {
      setLoading(true);
      cognitoUser = await Auth.signIn(userName, password);

      if (cognitoUser) {
        // if need to reset password, indicate by Cognito
        const { challengeName } = cognitoUser;
        if (challengeName === AWS_CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED) {
          // in this case, use is required to reset his/her password
          // keep a reference for this user
          tempUser = cognitoUser;
        } else {
          const token = cognitoUser.signInUserSession.accessToken.jwtToken;
          irisUser = await getIrisUser(token);
          const defectsList = await listDefectTypes(token);
          setDefectTypes(defectsList);
          setDefectCategories(getDefectCategories(defectsList));
          if (irisUser.clients.length === 0) {
            throw new Error(
              `${irisUser.username} has not been assigned to any groups`
            );
          }
        }
      }
    } catch (e) {
      error = e;
    } finally {
      setLoading(false);
      if (error) {
        setError(error);
      } else if (tempUser) {
        // reset password modal would show on Login page
        setTempUser(tempUser);
      } else if (irisUser) {
        setUser(irisUser);
      }
    }
  };

  /**
   * Sign out from the app.
   * @description
     1. sign out from Cognito first => local credential would be cleared autmoatically
     2. reset user => clear local state
     3. push user to login page
   */
  const logout = () => {
    Auth.signOut();
    setUser(undefined);
    autoUpdateAWSToken(true);
    history.replace("/login");
  };

  const getArcgisAccessToken = async () => {
    const url = "https://www.arcgis.com/sharing/rest/generateToken";
    const userName = "IRIS_Brantford";
    const password = "irisBrantford7867!";
    const cityName = pageConfig.city.name;
    if (cityName.toLowerCase() !== "brantford") {
      return "invalid_city";
    }

    // debugger;
    // check expiration
    const { token, expire = 0 } = arcgisToken;
    const now = new Date().getTime();
    const diff = now - expire;
    if (token && diff > 10 * 60 * 1000) {
      return token;
    } else {
      const result = await loginArcgis(url, userName, password);
      arcgisToken = result;
      return arcgisToken.token;
    }
  };

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      tempUser,
      user,
      pageConfig,
      defectTypes,
      defectCategories,
      routes,
      loading,
      loadingCachedUser,
      error,
      login,
      logout,
      resetTempUser: () => {
        setTempUser(null);
      },
      getArcgisAccessToken,
    }),
    [tempUser, user, pageConfig, routes, loading, loadingCachedUser, error]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
