import { useCallback, useRef } from "react";
/**
 * @summary custom hook that use to handle debounce behavior of setTimeOut function
 *
 * @param {Function} cb callback function that would be called after delay
 * @param {number} [delay] delay time in milliseconds, default is **1000**
 *
 * @returns {Function}
 */
const useDebounce = (cb, delay = 1000) => {
  const timerRef = useRef();
  const debounceFun = useCallback(
    (...args) => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        cb(...args);
      }, delay);
    },
    [cb, delay]
  );

  return debounceFun;
};

export default useDebounce;
