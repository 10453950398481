import React from "react";
import { useNotification } from "../context/NotificationContext";
// import { Portal, Snackbar, useTheme } from "@mui/material";

import { Portal, Snackbar, useTheme } from "@material-ui/core";
export default function Notification() {
  const {
    notification: { show, message, position, notificationType },
    hideNotification,
  } = useNotification();

  const theme = useTheme();

  let backgroundColor = theme.palette.primary.main;

  if (notificationType === "warning") {
    backgroundColor = theme.palette.warning.main;
  } else if (notificationType === "success") {
    backgroundColor = theme.palette.success.main;
  } else if (notificationType === "error") {
    backgroundColor = theme.palette.error.main;
  }
  return (
    <Portal>
      <Snackbar
        ContentProps={{
          style: {
            backgroundColor: backgroundColor,
            fontSize: "1rem",
            whiteSpace: "pre", // for line break
          },
        }}
        autoHideDuration={6000}
        anchorOrigin={position}
        open={show}
        onClose={hideNotification}
        message={message}
        key={position.vertical + position.horizontal}
      />
    </Portal>
  );
}
