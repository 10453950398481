import React from "react";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/styles";
import { Avatar } from "@material-ui/core";

import MarketingProductIcon from "../../../assets/logos/marketing_product.svg"
import SalesIcon from "../../../assets/logos/sales.svg"
import TechnicalIcon from "../../../assets/logos/technical.svg"

const FONT_SIZE = {
  lg: 48, // width = 40px,
  sm: 29, // width = 24px
};

export const CATEGORY_MARKETING_PRODUCT = "Marketing/Product";
export const CATEGORY_SALES = "Sales";
export const CATEGORY_CS_SUPPORT_TECHNICAL = "CS/Support and Technical";

export const CATEGORIES = [CATEGORY_MARKETING_PRODUCT, CATEGORY_SALES, CATEGORY_CS_SUPPORT_TECHNICAL];

/**
 *
 * @param {[string]} strArr
 */
const getCategory = (strArr) => {
  for (const value of CATEGORIES) {
    if (strArr.indexOf(value) > -1) {
      return value;
    }
  }

  return null;
};


const useStyles = makeStyles({
  imageIcon: {
    display: "flex",
    height: "inherit",
    width: "inherit"
  },
  iconRoot: {
    textAlign: "center"
  }
});

/**
 *
 * @param {object} props
 * @param {[string]} props.tags
 * @param {("sm" | "lg")} props.size
 * @returns
 */
function NotificationIcon(props) {
  const { tags, size } = props;

  const fontSize = FONT_SIZE[size];

  if (!fontSize) {
    throw new Error(
      `in [getNotificationIcon], fontSize = ${size} is not defined`
    );
  }

  const classes = useStyles();
  const category = getCategory(tags);

  let iconSrc = "assets/marketing_product.svg";


  switch (category) {
    case CATEGORY_MARKETING_PRODUCT:
      iconSrc = MarketingProductIcon
      break;

    case CATEGORY_SALES:
      iconSrc = SalesIcon
      break;

    case CATEGORY_CS_SUPPORT_TECHNICAL:
      iconSrc = TechnicalIcon
      break;
    default:
      iconSrc = MarketingProductIcon
      break;
  }

  return (
    <Avatar style={{ backgroundColor: "#FFFFFF", width: fontSize + 5, height: fontSize + 5, border: '2px solid lightgray' }}>
      <Icon classes={{ root: classes.iconRoot }}
        style={{
          fontSize: fontSize,
          backgroundColor: "#FFFFFF",
          textAlign: "center",
        }}
      >
        <img className={classes.imageIcon} src={iconSrc} />
      </Icon>
    </Avatar>
  )

}

export default NotificationIcon;
