import React, { useState } from "react";
import IrisAlertModal from "../components/irisAlertModal/IrisAlertModal";

const WithModal = (WrappedComponent) => {
  const HocComponent = ({ ...props }) => {
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");

    const openModal = (title, message) => {
      setTitle(title);
      setMessage(message);
    };

    const closeModal = () => {
      setTitle("");
      setMessage("");
    };

    return (
      <>
        <IrisAlertModal title={title} message={message} onClose={closeModal} />
        <WrappedComponent
          openModal={openModal}
          closeModal={closeModal}
          {...props}
        />
      </>
    );
  };

  HocComponent.propTypes = {};

  return HocComponent;
};

export default WithModal;
