import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { OPEN_WEATHER_CITY_LIST } from "../utils/env/options";
import { AuthContext } from "../components/AuthContext";
import { HomePageContext } from "../components/MainContext";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "600px",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "scroll",
  },
  settingRow: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  saveButton: {
    marginTop: "30px",
  },
  weatherOptions: {
    width: "215px",
  },
}));

export default function RegisterDevice() {
  const classes = useStyles();
  const { contextPreference, setContextPreference, contextWidgetList } =
    useContext(AuthContext);
  const [moduleList, setModuleList] = useState(contextWidgetList);
  const [selectedCity, setSelectedCity] = useState({
    name: "Burlington",
    province: "Ontario",
    code: 5911592,
  });
  const { setShowPreference } = useContext(HomePageContext);

  useEffect(() => {
    if (contextPreference["open_weather_city_id"]) {
      setSelectedCity(() => {
        return OPEN_WEATHER_CITY_LIST.find(
          (i) => contextPreference["open_weather_city_id"] === i.code
        );
      });
    }
    setModuleList((prevList) => {
      for (let key of Object.keys(prevList)) {
        contextPreference["iriscity_dashboard_layout"].find(
          (e) => e.i === key
        ) !== undefined
          ? (prevList[key].enabled = true)
          : (prevList[key].enabled = false);
      }
      return prevList;
    });
  }, [contextPreference]);

  const onSave = () => {
    let layout = [];
    for (const moduleKey of Object.keys(moduleList)) {
      if (moduleList[moduleKey].enabled === true) {
        let enabledModule = contextPreference["iriscity_dashboard_layout"].find(
          (e) => e.i === moduleKey
        );
        // use exisiting layout if module is already enabled
        if (enabledModule) {
          layout.push(enabledModule);
          // use default layout if module is just enabled
        } else {
          layout.push({ ...moduleList[moduleKey].layout, i: moduleKey });
        }
      }
    }

    contextPreference["iriscity_dashboard_layout"] = layout;
    contextPreference["open_weather_city_id"] = selectedCity.code;
    setContextPreference({ ...contextPreference });
    setShowPreference(false);
  };

  return (
    <div className={classes.root}>
      <DialogTitle>Dashboard Preferences</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>Modules:</DialogContentText>
        {Object.keys(moduleList).map((moduleKey, i) => {
          return (
            <Grid container key={moduleKey} className={classes.settingRow}>
              <Grid item>
                <Typography variant="subtitle2">
                  {moduleList[moduleKey].title}
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={Boolean(moduleList[moduleKey].enabled)}
                      onChange={(event) => {
                        moduleList[moduleKey].enabled =
                          !moduleList[moduleKey].enabled;
                        setModuleList({ ...moduleList });
                      }}
                      color="primary"
                    />
                  }
                  label={<Typography variant="subtitle2">Enable</Typography>}
                />
              </Grid>
            </Grid>
          );
        })}
      </DialogContent>
      <DialogContent dividers>
        <DialogContentText>Widgets:</DialogContentText>
        <Grid container className={classes.settingRow}>
          <Grid item>
            <Typography variant="subtitle2">Current Weather:</Typography>
          </Grid>
          <Grid item>
            <Autocomplete
              options={OPEN_WEATHER_CITY_LIST}
              size="small"
              value={selectedCity}
              onChange={(event, newValue) => {
                setSelectedCity(newValue);
              }}
              getOptionSelected={(p, q) => p.code === q.code}
              className={classes.weatherOptions}
              getOptionLabel={(option) => `${option.name}, ${option.province}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City, Province"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSave}
          variant="contained"
          color="primary"
          className={classes.saveButton}
        >
          Save
        </Button>
      </DialogActions>
    </div>
  );
}
