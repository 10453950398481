import React from "react";
import { Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import LineBreak from "./LineBreak";

const styles = StyleSheet.create({
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },

  marginVertical: {
    marginVertical: 10,
  },

  pageHeader: {
    fontSize: 22,
    fontStyle: "bold",
  },

  pageSubHeader: {
    fontSize: 16,
  },
  subTitle: {
    fontSize: 12,
  },
});

export default function PdfPageHeader({
  logoUrl,
  pageHeader,
  title,
  subtitle,
  reportDate,
}) {
  return (
    <View fixed style={{ marginBottom: 15 }}>
      <View
        style={{
          ...styles.flex,
          ...styles.marginVertical,
          justifyContent: "space-between",
          height: 80,
        }}
      >
        <View>
          <Image
            src={logoUrl}
            alt="image of city of city"
            style={{ width: 130 }}
          />
        </View>
        <View
          style={{
            ...styles.flex,
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Text style={styles.pageHeader}>{pageHeader}</Text>
          <Text style={styles.pageSubHeader}>{title}</Text>
          <Text style={styles.subtitle}>{subtitle}</Text>
        </View>
        <View style={{ alignSelf: "center" }}>
          <Text>{reportDate}</Text>
        </View>
      </View>
      <LineBreak />
    </View>
  );
}
