import useAuth from "hook/useAuth";
import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        user ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: rest.location },
              // state: {from:}
            }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
