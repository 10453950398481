import React from "react";
import { Document, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { data } from "./demoData/data";
import { weatherInfo } from "./demoData/weatherInfo";
import { note } from "./demoData/note";
import { maintenanceInfo } from "./demoData/maintenance";
import PdfPageHeader from "./pdfComponents/PdfPageHeader";
import LineBreak from "./pdfComponents/LineBreak";
import PdfPage from "./pdfComponents/PdfPage";
import {
  getAvailableSize,
  getRowStyle,
  ORIENTATIONS,
  tableHeaderStyle,
  tableRowStyle,
} from "components/table/file/styles";
import { WORK_ORDER_DATA_FIELDS_COMMON } from "components/table/dataModal/workOrderDataModal";
import { CITY_LOGOS } from "assets/assets";

/**
 *
 * function to get a new string that is concatinated by "\n" for every `lengthLimit` of characters so that
 * it could be rendered with proper line break for the Text element from "@react-pdf/renderer"
 *
 * This function is needed as "@react-pdf/renderer" support limited css property, as a result, it
 * could not handle word break properly.
 *
 * @link Valid CSS properties https://react-pdf.org/styling
 *
 *
 * @param {string} str string in format **region_name_in_snake_case-YYYY-label_id**
 * @param {number} lengthLimit number of characters for a line
 * @returns a new string that is concat with "\n" between every lengthLimit characters of the given string
 *
 * @example
 * const result0 = handleWorkorderString("Quinte_West-2024-14370", 22)    // "Quinte_West-2024-14370"
 * const result0 = handleWorkorderString("Saugeen_Shores-2024-14370", 22) // "Saugeen_Shores-2024-14\n370"
 */
const jonsSubstringWithLineBreak = (str, lengthLimit) => {
  if (str.length <= lengthLimit || lengthLimit < 1) {
    return str;
  }
  const subString22 = [];
  let index = 0;

  while (index < str.length) {
    const subStringWIth22Chars = str.substring(index, index + lengthLimit);
    subString22.push(subStringWIth22Chars);
    index += lengthLimit;
  }
  return subString22.join("\n");
};

const { width: availableWidth } = getAvailableSize(
  "LETTER",
  ORIENTATIONS.LANDSCAPE
);

const styles = StyleSheet.create({
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },

  smallFont: {
    fontSize: 10,
  },
  largeFont: {
    fontSize: 14,
  },

  marginVertical: {
    marginVertical: 10,
  },

  tableTitle: {
    marginVertical: 10,
    fontSize: 16,
    fontStyle: "bold",
  },

  pageHeader: {
    fontSize: 25,
  },

  pageSubHeader: {
    fontSize: 18,
  },
  subTitle: {
    fontSize: 12,
  },
});

const width60 = { width: 60 };
const width100 = { width: 100 };
const width120 = { width: 120 };
const widthFull = { flex: 1 };

const RenderTableTitle = ({ titles, fixed = false }) => {
  return (
    <View style={tableHeaderStyle} fixed={fixed}>
      {titles.map(({ content, style }, index) => (
        <View style={style} key={index}>
          <Text>{content}</Text>
        </View>
      ))}
    </View>
  );
};

const RenderPatrolLog = ({ title: tableTitle, data }) => {
  /** titles with content and style (width) */

  // start_time patroller type shift vehicle device weather_date

  const startTimeWidth = 100;
  const patrollerWidth = 120;
  const typeWidth = 80;
  const shiftWidth = 80;
  const vehicleWidth = 100;
  const weatherDateWidth = 100;
  const deviceWidth =
    availableWidth -
    10 -
    startTimeWidth -
    patrollerWidth -
    typeWidth -
    shiftWidth -
    vehicleWidth -
    weatherDateWidth;
  // const routeWidth = 80;
  // const restWidth =
  //   availableWidth - 10 - dateWidth - patrollerWidth - typeWidth - vehicleWidth;
  // routeWidth;

  const startTimeStyle = { width: startTimeWidth, marginVertical: 2 };
  const patrollerStyle = { width: patrollerWidth, marginVertical: 2 };
  const typeStyle = { width: typeWidth, marginVertical: 2 };
  const shiftStyle = { width: shiftWidth, marginVertical: 2 };
  const vehicleStyle = { width: vehicleWidth, marginVertical: 2 };
  const deviceStyle = { width: deviceWidth, marginVertical: 2 };
  const weatherDateStyle = { width: weatherDateWidth, marginVertical: 2 };
  // // const routeStyle = { width: routeWidth };
  // const roadConditionStyle = { width: restWidth };

  const titles = [
    { content: "Start Time", style: startTimeStyle },
    { content: "Patroller", style: patrollerStyle },
    { content: "Type", style: typeStyle },
    { content: "Shift", style: shiftStyle },
    { content: "Vehicle", style: vehicleStyle },
    { content: "Device", style: deviceStyle },
    { content: "Weather Date", style: weatherDateStyle },
    // { content: "Route", style: routeStyle },
    // { content: "Road Condition", style: roadConditionStyle },
    // { content: "Status", style: width60 },
    // { content: "Weather Date", style: width120 },
    // { content: "Note", style: widthFull },

    // agency_id: 10
    // class: 10
    // date: "2021-10-18"
    // from: 10
    // gis_id: 10
    // material: "Steel"c
    // name: "Lucien"
    // note: "Lucien note"
    // patroller: "Lucien"
    // region: "Montreal"
    // route: "CDN"
    // status: "Good"
    // to: null
    // type: "Street"
    // vehicle: "Car #1"
    // weather_date: "2021-10-18"
  ];
  return (
    <View>
      <Text style={styles.tableTitle} fixed>
        {tableTitle}
      </Text>

      <RenderTableTitle titles={titles} fixed={true} />
      {data.map((log, index) => {
        const {
          start_time,
          patroller,
          type,
          shift,
          vehicle,
          device,
          weather_date,
        } = log;
        return (
          <View key={index} style={getRowStyle(index)}>
            <View style={startTimeStyle}>
              <Text>{start_time}</Text>
            </View>
            <View style={patrollerStyle}>
              <Text>{patroller}</Text>
            </View>
            <View style={typeStyle}>
              <Text>{type}</Text>
            </View>
            <View style={shiftStyle}>
              <Text>{shift}</Text>
            </View>
            <View style={vehicleStyle}>
              <Text>{vehicle}</Text>
            </View>
            <View style={deviceStyle}>
              <Text>{device}</Text>
            </View>
            <View style={weatherDateStyle}>
              <Text>{weather_date}</Text>
            </View>
            {/* <View style={routeStyle}>
              <Text>{route}</Text>
            </View> */}
            {/* <View style={roadConditionStyle}>
              <Text>{road_condition}</Text>
            </View> */}
            {/* <View style={width60}>
              <Text>{status}</Text>
            </View>
            <View style={width120}>
              <Text>{weather_date}</Text>
            </View> */}
            {/* <View style={widthFull}>
              <Text>{note}</Text>
            </View> */}
          </View>
        );
      })}

      <LineBreak style={{ marginVertical: 10 }} />
    </View>
  );
};

const RenderWeatherInfo = ({ title: tableTitle, data }) => {
  /** titles with content and style (width) */
  // weather_date patroller road_condition temperature visibility precipitation snow_accumulation

  const weatherDateWidth = 100;
  const patrollerWidth = 100;
  const snowAccumulationWidth = 120; // snowAccumulationWidth
  const temperatureWidth = 100;
  const visibilityWidth = 60;
  const precipitationWidth = 80;
  const roadConditionWidth =
    availableWidth -
    10 -
    weatherDateWidth -
    patrollerWidth -
    snowAccumulationWidth -
    temperatureWidth -
    visibilityWidth -
    precipitationWidth;

  const weatherDateStyle = { width: weatherDateWidth, marginVertical: 2 };
  const patrollerStyle = { width: patrollerWidth, marginVertical: 2 };
  const roadConditionStyle = { width: roadConditionWidth, marginVertical: 2 };
  const temperatureStyle = { width: temperatureWidth, marginVertical: 2 };
  const visibilityStyle = { width: visibilityWidth, marginVertical: 2 };
  const precipitationStyle = { width: precipitationWidth, marginVertical: 2 };
  const snowAccumulationStyle = {
    width: snowAccumulationWidth,
    marginVertical: 2,
  };

  const titles = [
    { content: "Weather Date", style: weatherDateStyle },
    { content: "Patroller", style: patrollerStyle },
    { content: "Road Condition", style: roadConditionStyle },
    { content: "Temperature", style: temperatureStyle },
    { content: "Visibility", style: visibilityStyle },
    { content: "Precipitation", style: precipitationStyle },
    { content: "Snow Accumuation", style: snowAccumulationStyle },
  ];
  return (
    <View>
      <Text style={styles.tableTitle} fixed>
        {tableTitle}
      </Text>

      <RenderTableTitle titles={titles} fixed={true} />
      {data.map((log, index) => {
        const {
          weather_date,
          patroller,
          road_condition,
          temperature,
          visibility,
          precipitation,
          snow_accumulation,
        } = log;

        return (
          <View key={index} style={getRowStyle(index)}>
            <View style={weatherDateStyle}>
              <Text>{weather_date}</Text>
            </View>
            <View style={patrollerStyle}>
              <Text>{patroller}</Text>
            </View>
            <View style={roadConditionStyle}>
              <Text>{road_condition}</Text>
            </View>
            <View style={temperatureStyle}>
              <Text>{temperature}</Text>
            </View>
            <View style={visibilityStyle}>
              <Text>{visibility}</Text>
            </View>
            <View style={precipitationStyle}>
              <Text>{precipitation}</Text>
            </View>
            <View style={snowAccumulationStyle}>
              <Text>{snow_accumulation}</Text>
            </View>
          </View>
        );
      })}

      <LineBreak style={{ marginVertical: 10 }} />
    </View>
  );
};

const RenderNoteInfo = ({ title: tableTitle, data }) => {
  /** titles with content and style (width) */

  const dateWidht = 120;
  const patrollerWidth = 100;
  const statusWidth = 100;
  const categoryWidth = 100;
  const typeWidth = 100;
  const nodeWidth =
    availableWidth -
    10 -
    dateWidht -
    patrollerWidth -
    statusWidth -
    categoryWidth -
    typeWidth;

  const dateStyle = { width: dateWidht };
  const patrollerStyle = { width: patrollerWidth };
  const statusStyle = { width: statusWidth };
  const categoryStyle = { width: categoryWidth };
  const typeStyle = { width: typeWidth };
  const noteStyle = { width: nodeWidth };

  const titles = [
    { content: "Date", style: dateStyle },
    { content: "Patroller", style: patrollerStyle },
    { content: "Status", style: statusStyle },
    { content: "Category", style: categoryStyle },
    { content: "Type", style: typeStyle },
    { content: "Note", style: noteStyle },
  ];
  return (
    <View>
      <Text style={styles.tableTitle}>{tableTitle}</Text>

      <RenderTableTitle titles={titles} />
      {data.map((log, index) => {
        const { date, patroller, status, category, type, note } = log;
        return (
          <View key={index} style={getRowStyle(index)}>
            <View style={dateStyle}>
              <Text>{date}</Text>
            </View>
            <View style={patrollerStyle}>
              <Text>{patroller}</Text>
            </View>
            <View style={statusStyle}>
              <Text>{status}</Text>
            </View>
            <View style={categoryStyle}>
              <Text>{category}</Text>
            </View>
            <View style={typeStyle}>
              <Text>{type}</Text>
            </View>

            <View style={noteStyle}>
              <Text>{note}</Text>
            </View>
          </View>
        );
      })}
      <LineBreak style={{ marginVertical: 10 }} />
    </View>
  );
};
const RenderMaintenanceInfo = ({ title: tableTitle, data }) => {
  const dateWidth = 80;
  const patrollerWidth = 80;
  const categoryWidth = 80;
  const typeWidth = 80;
  const serviceByWidth = 80;
  const statusWidth = 80;
  const noteWidth = 80;
  const workorderWidth =
    availableWidth -
    10 -
    // 5 * 6 -
    dateWidth -
    patrollerWidth -
    categoryWidth -
    typeWidth -
    serviceByWidth -
    statusWidth -
    noteWidth;

  const dateStyle = { width: dateWidth, marginVertical: 2 };
  const patrollerStyle = { width: patrollerWidth, marginVertical: 2 };
  const categoryStyle = { width: categoryWidth, marginVertical: 2 };
  const typeStyle = { width: typeWidth, marginVertical: 2 };
  const serviceStyle = { width: serviceByWidth, marginVertical: 2 };
  const statusStyle = { width: statusWidth, marginVertical: 2 };
  const workorderStyle = { width: workorderWidth, marginVertical: 2 };
  const noteStyle = { width: noteWidth, marginVertical: 2 };
  const titles = [
    { content: "Date", style: dateStyle },
    { content: "Patroller", style: patrollerStyle },
    { content: "Category", style: categoryStyle },
    { content: "Type", style: typeStyle },
    { content: "Service By", style: serviceStyle },
    { content: "Status", style: statusStyle },
    { content: "Work Order #", style: workorderStyle },
    { content: "Note", style: noteStyle },
  ];
  return (
    <View>
      <Text style={styles.tableTitle}>{tableTitle}</Text>

      <RenderTableTitle titles={titles} fixed={true} />
      {data.map((log, index) => {
        const {
          [WORK_ORDER_DATA_FIELDS_COMMON.OPEN_DATETIME_LOCAL]: date,
          issued_by,
          category,
          w_o_type: type,
          service_by_local,
          w_o_status,
          w_o_id: workorder,
          note,
        } = log;

        // 22 is a magic number, it depends on the
        // 1. font-family
        // 2. font-size
        // 3. the column width
        // that we used, if any of those are changed, this number is subjective to be change.
        // I use O for uppercase character while o for lower case character
        //       0 for numbers
        // ie                 "Quinte_West-2024-123467"
        // i Use this pattern "Oooooo_Oooo-0000-000000"
        // to find the best fit number
        let parsedworkOrderStr = jonsSubstringWithLineBreak(workorder, 22);

        return (
          <View key={index} style={getRowStyle(index)}>
            <View style={dateStyle}>
              <Text>{date}</Text>
            </View>
            <View style={patrollerStyle}>
              <Text>{issued_by}</Text>
            </View>
            <View style={categoryStyle}>
              <Text>{category}</Text>
            </View>
            <View style={typeStyle}>
              <Text>{type}</Text>
            </View>
            <View style={serviceStyle}>
              <Text>{service_by_local}</Text>
            </View>
            <View style={statusStyle}>
              <Text>{w_o_status}</Text>
            </View>
            <View style={workorderStyle}>
              <Text>{parsedworkOrderStr}</Text>
            </View>

            <View style={noteStyle}>
              <Text>{note}</Text>
            </View>
          </View>
        );
      })}

      <LineBreak style={{ marginVertical: 10 }} />
    </View>
  );
};
export default function RoadSectionReport({
  cityName,
  sectionInfo = {
    "Road Name": "Napa Valley",
    From: 0,
    To: 886,
    "Agency ID": 3481,
    "GIS ID": 3481,
    Class: 4,
    Material: "HCB",
    Region: "region_name",
    Note: "some note",
  },
  dateRange,
  mapImageSrc = "",
  data,
  workOrderData,
  logo,
}) {
  const {
    startDate = new Date().toISOString(),
    endDate = new Date().toISOString(),
  } = dateRange;

  const reportHeader = (
    <PdfPageHeader
      logoUrl={logo}
      pageHeader={cityName}
      title="Road Section Report"
      subtitle={`From ${startDate} to ${endDate}`}
      reportDate={`Report date: ${new Date().toJSON().split("T")[0]}`}
    />
  );
  return (
    <Document>
      <PdfPage pagination={true}>
        {reportHeader}
        <View style={{ ...styles.flex, height: 240 }}>
          <View style={{ flex: 2 }}>
            {Object.keys(sectionInfo).map((key, index) => (
              <View
                key={index}
                style={{
                  ...styles.flex,
                  justifyContent: "flex-start",
                  marginVertical: 3,
                }}
              >
                <Text style={{ ...width100, fontStyle: "bold" }}>{key}</Text>
                <Text>{sectionInfo[key]}</Text>
              </View>
            ))}
          </View>
          <View style={{ flex: 3 }}>
            <Image
              style={{
                objectFit: "contain",
              }}
              src={mapImageSrc}
              alt="image of city of vaughan"
            />
          </View>
        </View>
        <LineBreak style={{ marginBottom: 20 }} />

        <RenderPatrolLog title="Patrol Log Information" data={data} />
      </PdfPage>
      <PdfPage pagination={true}>
        {reportHeader}
        <RenderWeatherInfo title="Weather Information" data={data} />
      </PdfPage>
      <PdfPage pagination={true}>
        {reportHeader}
        <RenderMaintenanceInfo
          title="Maintenance Information"
          data={workOrderData}
        />
      </PdfPage>
    </Document>
  );
}
