/**
 * build upon Apollo client useLazyQuery return tuples, within 2nd element of the tupple, there some more useful objects and functions.
 *
 * @param {Function} initalFetch asyn function that handle the **FIRST** query.
 * @param {Function} fetchMore async function that handle all **subsequent** queries.
 * @param {string} fieldName query name, from where value is retrieve, ie *data["fieldName"].items*
 * @param {Object} filter filter that used to query data
 * @returns {Promise<Object[]>}
 */
export const queryAll = async (initalFetch, fetchMore, fieldName, filter) => {
  let token,
    error,
    items = [];

  do {
    const fetcher = token === undefined ? initalFetch : fetchMore;
    const { data, error: fetchError } = await fetcher({
      variables: {
        filter,
        nextToken: token,
      },
    });

    if (fetchError) {
      error = fetchError;
      break;
    } else {
      items = items.concat(data[fieldName].items);
    }
    token = data[fieldName].nextToken;
  } while (token);

  if (error) {
    return Promise.reject(error);
  }
  return Promise.resolve(items);
};
