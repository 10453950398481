// sort for dynamodb filter, deliberately make it not too readabl
const DYNAMODB_SESSION_STORAGE_KEY = "ddb_f";

/**
 * function to save customer selected dynamodb filter into the session stroage, with predefined key name
 * @param {DynamonDBFilterType} data
 * @returns
 */
export const setSessionDynamodbFilter = (data) => {
  if (!data) {
    return;
  }
  const stringValue = JSON.stringify(data);
  sessionStorage.setItem(DYNAMODB_SESSION_STORAGE_KEY, stringValue);
};

/**
 * function to get the cahced dynamodbe filter from the session storage with predefined key name.
 * @returns {DynamonDBFilterType | undefined}
 */
export const getSessionDynamodbFilter = () => {
  let cachedValue;
  try {
    cachedValue = JSON.parse(
      sessionStorage.getItem(DYNAMODB_SESSION_STORAGE_KEY)
    );
  } catch (error) {
    if (error instanceof SyntaxError) {
      console.error(
        "SyntaxError in getting cached ddb_f from session Storage: ",
        error
      );
    } else {
      console.error(
        "error in getting cached ddb_f from session Storage: ",
        error
      );
    }
  }

  return cachedValue;
};
