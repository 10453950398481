import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "pages/login/Login";
import HomePage from "../components/Homepage";
import NotFound from "../components/NotFound";
import Module from "../modules/Module";
import PrivateRoute from "../components/PrivateRoute";
import { PROTECTED_ROUTES } from "./data/protectedRoutes";
import useAuth from "hook/useAuth";
export default function AppRoutes() {
  const [routes, setRoutes] = useState(PROTECTED_ROUTES);
  const { routes: serviceRoutes } = useAuth();

  useEffect(() => {
    setRoutes(serviceRoutes);
  }, [serviceRoutes]);

  return (
    // <Router>
    <Switch>
      <PrivateRoute
        exact
        path={Object.values(routes).map((value) => value.path)}
        component={HomePage}
      />

      <Route exact path="/module/:module" component={Module} />
      <Route exact path={["/login", "/"]} component={Login} />
      <Route exact path="/module/:module/:auth/:moduleName" component={Login} />
      <Route exact component={NotFound} />
    </Switch>
    // </Router>
  );
}
