import { KEYS as WORK_ORDER_TABLE_TRANSLATION_KEY_MAP } from "components/table/WorkorderTable.translate";

export const ROOT_KEY = "WORK_ORDER_DATA_TRANSLATION";

export const KEYS = {
  ADDRESS: `${ROOT_KEY}.ADDRESS`,
  ASSIGN_TO: `${ROOT_KEY}.ASSIGN_TO`,
  CATEGORY: `${ROOT_KEY}.CATEGORY`,
  DATAPOINT_ID: `${ROOT_KEY}.DATAPOINT_ID`,
  DESCRIPTION: `${ROOT_KEY}.DESCRIPTION`,
  ID: `${ROOT_KEY}.ID`,
  IMAGE_URL: `${ROOT_KEY}.IMAGE_URL`,
  INSPECTED_BY: `${ROOT_KEY}.INSPECTED_BY`,
  ISSUED_BY: `${ROOT_KEY}.ISSUED_BY`,
  LABEL_ID: `${ROOT_KEY}.LABEL_ID`,
  DTS: `${ROOT_KEY}.DTS`,
  LAT: `${ROOT_KEY}.LAT`,
  LON: `${ROOT_KEY}.LON`,
  MAP_IMAGE_URL: `${ROOT_KEY}.MAP_IMAGE_URL`,
  NOTE: `${ROOT_KEY}.NOTE`,
  REGION: `${ROOT_KEY}.REGION`,
  ROAD_REGION: `${ROOT_KEY}.ROAD_REGION`,
  SEVERITY: `${ROOT_KEY}.SEVERITY`,
  W_O_ID: `${ROOT_KEY}.W_O_ID`,
  W_O_STATUS: `${ROOT_KEY}.W_O_STATUS`,
  W_O_TYPE: `${ROOT_KEY}.W_O_TYPE`,
  OPEN_DATETIME: `${ROOT_KEY}.OPEN_DATETIME`,
  INSPECTED_ON: `${ROOT_KEY}.INSPECTED_ON`,
  SERVICE_BY: `${ROOT_KEY}.SERVICE_BY`,
  OPEN_DATETIME_LOCAL: `${ROOT_KEY}.OPEN_DATETIME_LOCAL`,
  INSPECTED_ON_LOCAL: `${ROOT_KEY}.INSPECTED_ON_LOCAL`,
  SERVICE_BY_LOCAL: `${ROOT_KEY}.SERVICE_BY_LOCAL`,
  ROAD_NAME: `${ROOT_KEY}.ROAD_NAME`,
  CLASS: `${ROOT_KEY}.CLASS`,
  FROM: `${ROOT_KEY}.FROM`,
  TO: `${ROOT_KEY}.TO`,
  ROAD_ID: `${ROOT_KEY}.ROAD_ID`,
  WARD: `${ROOT_KEY}.WARD`,
  ROD_CODIGO: `${ROOT_KEY}.ROD_CODIGO`,
  ORIG_FID: `${ROOT_KEY}.ORIG_FID`,
  ORIG_SEQ: `${ROOT_KEY}.ORIG_SEQ`,
  ORIG_FID_1: `${ROOT_KEY}.ORIG_FID_1`,
  ORIG_SEQ_1: `${ROOT_KEY}.ORIG_SEQ_1`,
  SHAPE_LENG: `${ROOT_KEY}.SHAPE_LENG`,
  KM_MARKERS: `${ROOT_KEY}.KM_MARKERS`,
  ROUTE_NAME: `${ROOT_KEY}.ROUTE_NAME`,
  MILE_MARKE: `${ROOT_KEY}.MILE_MARKE`,
  DISTANCE_M: `${ROOT_KEY}.DISTANCE_M`,
  HEADING: `${ROOT_KEY}.HEADING`,
  LANE: `${ROOT_KEY}.LANE`,
  DATA_POINT_ID: `${ROOT_KEY}.DATA_POINT_ID`,
  MILE_MARKER: `${ROOT_KEY}.MILE_MARKER`,
  STREET_NAME: `${ROOT_KEY}.STREET_NAME`,
  OWNERSHIP: `${ROOT_KEY}.OWNERSHIP`,
  ROUTE_ID: `${ROOT_KEY}.ROUTE_ID`,
  ATTACHMENTS: `${ROOT_KEY}.ATTACHMENTS`,
  OPERATION_AREA: `${ROOT_KEY}.OPERATION_AREA`,
  ASSET_ID: `${ROOT_KEY}.ASSET_ID`,
};
export const WORK_ORDER_DATA_TRANSLATIONS = {
  en: {
    ADDRESS: "Address",
    ASSIGN_TO: "Person Responsible",
    CATEGORY: "Work Order Category",
    DATAPOINT_ID: "Data Point ID",
    DESCRIPTION: "Description",
    ID: "ID",
    IMAGE_URL: "Image URL",
    INSPECTED_BY: "Inspected by",
    ISSUED_BY: "Issued By",
    LABEL_ID: "Label ID",
    DTS: "DTS",
    LAT: "Latitude",
    LON: "Longitude",
    MAP_IMAGE_URL: "Map Image URL",
    NOTE: "Note",
    REGION: "Region",
    ROAD_REGION: "Region Name",
    SEVERITY: "Severity",
    W_O_ID: "Work Order ID Number",
    W_O_STATUS: "Work Order Status",
    W_O_TYPE: "Work Order Type",
    OPEN_DATETIME: "Date Opened is",
    INSPECTED_ON: "Inspected on",
    SERVICE_BY: "Date Serviced by is",

    OPEN_DATETIME_LOCAL: "Opened on",
    INSPECTED_ON_LOCAL: "Inspected on",
    SERVICE_BY_LOCAL: "Service by",

    // vaughan
    ROAD_NAME: "Road Name",
    CLASS: "Class",
    FROM: "From",
    TO: "To",
    ROAD_ID: "Road ID",
    //   ROAD_REGION: "road_region",
    WARD: "Ward Number",
    // SPLICE
    ROD_CODIGO: "Rod Codigo",
    ORIG_FID: "Orig Fid",
    ORIG_SEQ: "Orig Seq",
    ORIG_FID_1: "Orig Fid 1",
    ORIG_SEQ_1: "Orig Seq 1",
    SHAPE_LENG: "Shape Leng",
    KM_MARKERS: "KM Markers",
    // CMTI
    ROUTE_NAME: "Route Name",
    //   ORIG_FID: "orig_fid",
    //   ORIG_SEQ: "orig_seq",
    //   SHAPE_LENG: "shape_leng",
    MILE_MARKE: "Mile Marker",
    // EGIS
    DISTANCE_M: "Distance Marker",
    HEADING: "Heading",
    LANE: "Lane",

    // new from rwo details pannel
    DATA_POINT_ID: "Data Point ID",
    MILE_MARKER: "Mile Marker",
    STREET_NAME: "Street Name",
    OWNERSHIP: "Ownership",
    ROUTE_ID: "Route ID",
    ATTACHMENTS: "Attachments",

    // new since Maple Ridge
    OPERATION_AREA: "Operation Area",
    ASSET_ID: "Asset ID",
  },
  fr: {
    ADDRESS: "Adresse",
    ASSIGN_TO: "La personne responsable",
    CATEGORY: "La Catégorie d'Ordre de Travail",
    DATAPOINT_ID: "ID du Point de Données",
    DESCRIPTION: "Description",
    ID: "ID",
    IMAGE_URL: "URL de l'image",
    INSPECTED_BY: "Inspecté par",
    ISSUED_BY: "Émis Par",
    LABEL_ID: "ID d'Étiquette",
    DTS: "DTS",
    LAT: "Latitude",
    LON: "Longitude",
    MAP_IMAGE_URL: "URL de l'image de la carte",
    NOTE: "Remarque",
    REGION: "Région",
    ROAD_REGION: "Le Nom de la Région",
    SEVERITY: "Gravité",
    W_O_ID: "Le Numéro d'ID du Bon de Travail",
    W_O_STATUS: "État de la Requête de Travail",
    W_O_TYPE: "Type d'Ordre de Travail",
    OPEN_DATETIME: "La Date d'Ouverture",
    INSPECTED_ON: "Inspecté à",
    SERVICE_BY: "Service le",

    OPEN_DATETIME_LOCAL: "Ouvert par",
    INSPECTED_ON_LOCAL: "Inspecté à",
    SERVICE_BY_LOCAL: "Service le",

    // vaughan
    ROAD_NAME: "Nom de Rue",
    CLASS: "Classe",
    FROM: "Depuis",
    TO: "À",
    ROAD_ID: "ID de Route",
    //   ROAD_REGION: "road_region",
    WARD: "Quartier",
    // SPLICE
    ROD_CODIGO: "Rod Codigo",
    ORIG_FID: "Orig Fid",
    ORIG_SEQ: "Orig Seq",
    ORIG_FID_1: "Orig Fid 1",
    ORIG_SEQ_1: "Orig Seq 1",
    SHAPE_LENG: "Shape Leng",
    KM_MARKERS: "KM Markers",
    // CMTI
    ROUTE_NAME: "Numéro de la route",
    //   ORIG_FID: "orig_fid",
    //   ORIG_SEQ: "orig_seq",
    //   SHAPE_LENG: "shape_leng",
    MILE_MARKE: "Mile Marker",
    // EGIS
    DISTANCE_M: "Marqueur de distance",
    HEADING: "Direction",
    LANE: "voie",

    // new
    DATA_POINT_ID: "ID du Point de Données",
    MILE_MARKER: "Mile Marker",
    STREET_NAME: "Nom de rue",
    OWNERSHIP: "Propriété",
    ROUTE_ID: "ID de route",
    ATTACHMENTS: "Pièces Jointes",
    OPERATION_AREA: "Zone d'opération",
    ASSET_ID: "ID de l'actif",
  },
  ja: {
    ADDRESS: "住所",
    ASSIGN_TO: "に割り当てられた",
    CATEGORY: "カテゴリー",
    DATAPOINT_ID: "データ ポイント ID",
    DESCRIPTION: "説明",
    ID: "ID",
    IMAGE_URL: "画像の URL",
    INSPECTED_BY: "の検査を受けた",
    ISSUED_BY: "発行",
    LABEL_ID: "ラベル ID",
    DTS: "サービスまでの日数",
    LAT: "緯度",
    LON: "経度",
    MAP_IMAGE_URL: "マップ画像の URL",
    NOTE: "ノート",
    REGION: "領域",
    ROAD_REGION: "道路地域",
    SEVERITY: "重大度",
    W_O_ID: "作業指示書 ID",
    W_O_STATUS: "作業指示ステータス",
    W_O_TYPE: "作業指示タイプ",
    OPEN_DATETIME: "オープン日",
    INSPECTED_ON: "検査日",
    SERVICE_BY: "下記の日付までに修正",

    OPEN_DATETIME_LOCAL: "オープン日",
    INSPECTED_ON_LOCAL: "検査日",
    SERVICE_BY_LOCAL: "によるサービス",

    // vaughan
    ROAD_NAME: "道路名",
    CLASS: "クラス",
    FROM: "From",
    TO: "To",
    ROAD_ID: "道路番号",
    //   ROAD_REGION: "road_region",
    WARD: "区",
    // SPLICE
    ROD_CODIGO: "ロッド・コディゴ",
    ORIG_FID: "元のFid",
    ORIG_SEQ: "元のシーケンス",
    ORIG_FID_1: "元のFid 1",
    ORIG_SEQ_1: "元のシーケンス 1",
    SHAPE_LENG: "形状の長さ",
    KM_MARKERS: "キロポスト",
    // CMTI
    ROUTE_NAME: "路線名",
    //   ORIG_FID: "orig_fid",
    //   ORIG_SEQ: "orig_seq",
    //   SHAPE_LENG: "shape_leng",
    MILE_MARKE: "マイルマーカー",
    // EGIS
    DISTANCE_M: "キロポスト",
    HEADING: "見出し",
    LANE: "LANE",
    OPERATION_AREA: "操作エリア",
    ASSET_ID: "アセットID",
  },
};

/**
 * Object to faciliate the translation of a work order data.
 * The key matches the key on a work order data
 * the value points to the translation key, which together with the value of
 * the data, ie: data[key], is used to generate the FULL path of the translation key
 * so that the translated value would be applied.
 */
export const TRANSLATION_MAP = {
  category: WORK_ORDER_TABLE_TRANSLATION_KEY_MAP.CATEGORY,
  w_o_status: WORK_ORDER_TABLE_TRANSLATION_KEY_MAP.W_O_STATUS,
  w_o_type: WORK_ORDER_TABLE_TRANSLATION_KEY_MAP.W_O_TYPE,
  open_datetime_local: "Date",
  inspected_on_local: "Date",
  service_by_local: "Date",
};
