import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Switch, Route, NavLink } from "react-router-dom";
import { TOOL_BAR_HEIGHT } from "../utils/theme/constrains";
import useAuth from "hook/useAuth";
import {
  CREATE_LANG_KEY,
  getCommonTranslation,
  TRANSLATE,
} from "utils/lang/translate";
import IrisNotificationBannerContainer from "components/IrisNotoficationBanner/IrisNotificationBannerContainer";
import { Hidden } from "@material-ui/core";
import {
  getPassMonthNotification,
  NOTIFICATIONS_STATES,
  updateNotificationStatus,
} from "utils/apis/notificationAPI";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    // top: 7,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: 55,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  main: {
    marginTop: TOOL_BAR_HEIGHT,
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

export function PageBody({ openDrawer = true, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [notifications, setNotifications] = React.useState([]);

  const { user, routes } = useAuth();

  useEffect(() => {
    setOpen(openDrawer);
  }, [openDrawer]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getPassMonthNotification(user.username, NOTIFICATIONS_STATES.UNOPENED)
      .then((result) => {
        setNotifications(result);
      })
      .catch((error) => {
        console.error(
          `Error in get user [${user.username}] notification: ${error}`
        );
      });
  }, [user.username]);

  const handleUpdateNotificationStatus = (id, status) => {
    const idArr = [id];
    updateNotificationStatus(user.username, idArr, status).catch((error) => {
      console.error("error in updating notification", error);
    });
  }

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List role="navigation">
          {routes.map((route, index) => {
            const { name, path, IconComponent } = route;
            const currentPath = window.location.pathname;
            const isActive = path === currentPath;
            const activeBackground = theme.palette.primary.main;
            return (
              <NavLink
                key={index}
                to={path}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem
                  button
                  style={{
                    background: isActive && activeBackground,
                    color: isActive && "#fff",
                  }}
                >
                  <ListItemIcon
                    style={{ color: isActive && "#fff", minWidth: 40 }}
                  >
                    {IconComponent}
                  </ListItemIcon>
                  <ListItemText>
                    {TRANSLATE("common:" + CREATE_LANG_KEY(name))}
                  </ListItemText>
                </ListItem>
              </NavLink>
            );
          })}
        </List>
      </Drawer>
      <main className={classes.main}>
        <Hidden smDown>
          <IrisNotificationBannerContainer
            maxNotifications={3}
            onNotificationClosed={(id) => handleUpdateNotificationStatus(id, NOTIFICATIONS_STATES.DISMISSED)}
            onNotificationRead={(id) => handleUpdateNotificationStatus(id, NOTIFICATIONS_STATES.READ)}
            notifications={notifications}
            bannerProps={{
              variant: "standard",
              closeIconSize: "medium",
            }}
          />
        </Hidden>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </main>
    </>
  );
}
