// This is where all custom modules are routed
// When you create a new react-based module, add it in the two spots below

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import NotFound from "../components/NotFound";

// list of available modules -- each route must also be defined below
import London_DailyAlerts from "./London_DailyAlerts/London_DailyAlerts";

export default function Module(props) {
  return (
    <div>
      <Switch>
        {/* <PrivateRoute exact path="/module/London_DailyAlerts" component={London_DailyAlerts} /> */}

        {/* <Route exact component={NotFound} /> */}
      </Switch>
    </div>
  );
}
