import React, { useState } from "react";
import PropTypes from "prop-types";
import IrisLoader from "./withLoader/IrisLoader";

const withLoader = (WrappedComponent) => {
  const HocComponent = ({ ...props }) => {
    const [showLoader, setShowLoader] = useState(false);

    return (
      <>
        <IrisLoader open={showLoader} />
        <WrappedComponent {...props} setShowLoader={setShowLoader} />
      </>
    );
  };

  HocComponent.propTypes = {};

  return HocComponent;
};

export default withLoader;
