/**
 * requires
 * 1. lib @mui-ui/core
 * 2. lib @mui-ui/icons
 * 3. font awesome cdn on index.html
 */

import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";

function LoadingButton(props) {
  const {
    isLoading = false,
    children,
    startIcon,
    endIcon,
    disabled,
    ...restProps
  } = props;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  if (startIcon) {
    return (
      <Button
        {...restProps}
        startIcon={
          loading ? <i className="fa fa-circle-o-notch fa-spin" /> : startIcon
        }
        disabled={loading || disabled}
      >
        {children}
      </Button>
    );
  }
  return (
    <Button
      {...restProps}
      endIcon={
        loading ? <i className="fa fa-circle-o-notch fa-spin" /> : endIcon
      }
      disabled={loading || disabled}
    >
      {children}
    </Button>
  );
}

export default LoadingButton;

// demo
// <LoadingButton
// variant="contained"
// color="primary"
// isLoading={isLoading}
// // startIcon={<AcUnitIcon />}
// >
