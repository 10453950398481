import React, { useState, useEffect, useRef } from "react";
import { loadModules, setDefaultOptions } from "esri-loader";
import { WorkorderMapViewPdf } from "components/table/file/WorkorderMapViewPdf";
import checkMark from "assets/images/workorderMapViewIcons/svgrepo_checked.svg";
import IgnoreMark from "assets/images/workorderMapViewIcons/svgrepo_eye_slash.svg";
import openMark from "assets/images/workorderMapViewIcons/svgrepo_warning_error.svg";
import { pdf } from "@react-pdf/renderer";
import {
  KEYS as DATA_POINT_TRANSLATION_KEY_MAP,
  TRANSLATION_MAP,
} from "config/dataTranslations/workOrderData.translation";
import {
  CREATE_LANG_KEY,
  getLocaleDateTime,
  TRANSLATE_ONCE,
} from "utils/lang/translate";
import { useTranslation } from "react-i18next";
import { KEYS as WORK_ORDER_TABLE_TRANSLATION_KEY_MAP } from "components/table/WorkorderTable.translate";

setDefaultOptions({
  version: "4.21",
  css: true,
});

export default function WorkorderMap(props) {
  const { data, mapCenter } = props;
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [mapView, setMapView] = useState(null);
  const downloadButton = useRef(null);

  const GraphicClass = useRef(null);
  const featureLayer = useRef(null);
  const FeatureLayerClass = useRef(null);

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/Graphic",
        "esri/layers/FeatureLayer",
        "esri/widgets/Search",
        "esri/widgets/Expand",
        "esri/widgets/BasemapGallery",
      ],
      { css: true }
    )
      .then(
        async ([
          Map,
          MapView,
          Graphic,
          FeatureLayer,
          Search,
          Expand,
          BasemapGallery,
        ]) => {
          GraphicClass.current = Graphic;
          FeatureLayerClass.current = FeatureLayer;

          const map = new Map({
            basemap: "topo",
          });

          const mapView = new MapView({
            map: map,
            center: mapCenter,
            zoom: 17,
            container: mapRef.current, // dom element
            ui: {
              components: ["attribution"], // remove all default UI except "attribution", [] means remove everything
            },
          });

          const basemapGallery = new BasemapGallery({
            view: mapView,
            container: document.createElement("div"),
          });

          // Create an Expand instance and set the content
          // property to the DOM node of the basemap gallery widget
          // Use an Esri icon font to represent the content inside
          // of the Expand widget

          const bgExpand = new Expand({
            view: mapView,
            content: basemapGallery,
          });

          mapView.ui.add(
            [
              new Search({
                view: mapView,
              }),
              bgExpand,
            ],
            "top-right"
          );
          mapView.ui.add(downloadButton.current, "top-left");
          // mapView.ui.add(document.getElementById("okayNiceTo"), "bottom-right");
          setMap(map);
          setMapView(mapView);
        }
      )
      .catch((error) => {
        alert("Error in loading arcgis modules");
      });
  }, [mapRef]);

  useEffect(() => {
    if (!map || !mapView) return;
    if (!Array.isArray(data)) return;
    if (data.length === 0) return;

    const copy = JSON.parse(JSON.stringify(data));

    copy.forEach((c) => {
      delete c.tableData; // prop added by Material-Table lib
      delete c["__typename"]; // prop added by GraphQl lib

      // remove the following fiels which contains UTC time value
      delete c.inspected_on;
      delete c.open_date;
      delete c.open_datetime;
      delete c.open_time;
      delete c.service_by;
    });

    const translatedData = copy.map((d) => {
      const c = JSON.parse(JSON.stringify(d));
      Object.keys(TRANSLATION_MAP).forEach((key) => {
        const value = TRANSLATION_MAP[key];
        if (value === "Date") {
          const dateValue = c[key];
          if (dateValue === "Invalid date" || !dateValue) {
            // this is for fields, inspected_on and service_by, which might be not determined yet.
            // make it empty so that the default popup tempalte will render properly.
            c[key] = "";
          } else {
            c[key] = getLocaleDateTime(dateValue, language, "FULL");
          }
        } else {
          const k = value[c[key].toUpperCase()];
          c[key] = TRANSLATE_ONCE(k);
        }
      });
      return c;
    });

    const { current: FeatureLayer } = FeatureLayerClass;

    const features = translatedData.map((d) => ({
      geometry: {
        type: "point",
        longitude: d.lon,
        latitude: d.lat,
      },

      attributes: Object.keys(d).reduce((acc, key) => {
        acc[key] = d[key] + "";
        return acc;
      }, {}),
    }));

    /** was there a layer already */
    let wasExist;

    if (featureLayer.current) {
      wasExist = true;
    }
    map.remove(featureLayer.current);

    // https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-FeatureLayer.html
    featureLayer.current = new FeatureLayer({
      source: features,
      objectIdField: "id", // field of a data which continas unique value among all data

      /**
       * https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-FeatureLayer.html#renderer
       * https://developers.arcgis.com/javascript/latest/api-reference/esri-renderers-Renderer.html
       *
       * for custom feature layer, this field has to be defined, otherwise, defaul ui for feature would be shown
       * setting symbol on graphic while creating feature does not help, it must to be defined over here
       */
      //
      renderer: {
        type: "unique-value", // autocasts as new UniqueValueRenderer()
        field: "w_o_status",
        defaultSymbol: { type: "simple-fill" }, // autocasts as new SimpleFillSymbol()
        uniqueValueInfos: [
          {
            // symbol of features with value of "Opened" will be a check mark
            // value: "Closed",
            value: TRANSLATE_ONCE(
              WORK_ORDER_TABLE_TRANSLATION_KEY_MAP.W_O_STATUS.CLOSED
            ),
            symbol: {
              type: "picture-marker", // autocasts as new TextSymbol()
              height: 16,
              width: 16,
              url: checkMark,
            },
            // symbol: iconSymbol,
          },
          {
            // symbol of features with value of "Ignored" will be a eye-slash mark

            // value: "Ignored",
            value: TRANSLATE_ONCE(
              WORK_ORDER_TABLE_TRANSLATION_KEY_MAP.W_O_STATUS.IGNORED
            ),
            symbol: {
              type: "picture-marker", // autocasts as new TextSymbol()
              height: 16,
              width: 16,
              url: IgnoreMark,
            },
          },
          {
            // value: "Opened",
            value: TRANSLATE_ONCE(
              WORK_ORDER_TABLE_TRANSLATION_KEY_MAP.W_O_STATUS.OPENED
            ),

            symbol: {
              type: "picture-marker", // autocasts as new TextSymbol()
              height: 16,
              width: 16,
              url: openMark,
            },
          },
        ],
      },

      /**
       * https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-FeatureLayer.html#fields
       *
       * NOTE: custom feature layer has to include this fields, otherwise, custom popup template has no access to data
       *
       * [{name: fieldName, type: "small-integer"|"integer"|"single"|"double"|"long"|"string"|"date"|"oid"|"geometry"|"blob"|"raster"|"guid"|"global-id"|"xml"}]
       */
      fields: Object.keys(translatedData[0]).map((key) => ({
        name: key,
        type: "string",
      })),
      popupTemplate: {
        // https://developers.arcgis.com/javascript/latest/api-reference/esri-PopupTemplate.html#title
        title: "Address: {address}",
        outFields: ["*"],
        content: [
          {
            type: "fields",
            /**
             * https://developers.arcgis.com/javascript/latest/api-reference/esri-PopupTemplate.html#fieldInfos
             *
             * NOTE: in order to make this work, "fields" must be defined on the feature layer
             *
             * fieldNames must match those defined on "fields" property
             *
             * [{fieldName: fieldName, label: readable_label, format: {digitSeparator: true, places: 0} properties_to_format_number}]
             */
            fieldInfos: Object.keys(translatedData[0]).map((key) => {
              return {
                fieldName: key,
                label: TRANSLATE_ONCE(
                  DATA_POINT_TRANSLATION_KEY_MAP[CREATE_LANG_KEY(key)]
                ),
              };
            }),
          },
        ],
      },

      /**
       *
       * https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-FeatureLayer.html#labelingInfo
       * https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-support-LabelClass.html
       *
       * defined this field if you desire labels to be dispalyed around a feature
       */
      labelingInfo: [
        {
          symbol: {
            type: "text", // autocasts as new TextSymbol()
            color: "#000",
            font: {
              size: 12,
            },
          },
          labelPlacement: "above-center",
          labelExpressionInfo: {
            expression: "$feature.w_o_type",
          },
        },
        {
          symbol: {
            type: "text", // autocasts as new TextSymbol()
            color: "#000",
            font: {
              size: 12,
            },
          },
          labelPlacement: "below-center",
          labelExpressionInfo: {
            expression: "$feature.w_o_status",
          },
          minScale: 1000000,
        },
      ],
    });

    // featureLayer.current.load().then((layer) => {
    //     mapView.extent = layer.fullExtent;
    // });

    featureLayer.current.when(function () {
      // close any opened popup
      mapView.popup.close();
      if (!wasExist) {
        // first layer, zoom to extent
        mapView.extent = featureLayer.current.fullExtent;
      }
    });
    map.add(featureLayer.current);
  }, [data, map, mapView, language]);

  const downloadImage = async () => {
    let pdfUrl;
    try {
      const { dataUrl } = await mapView.takeScreenshot();

      if (!dataUrl) return;

      const pdfView = (
        <WorkorderMapViewPdf
          searchParams={props.searchParams}
          imageUrl={dataUrl}
        />
      );
      const pdfContent = await pdf(pdfView).toBlob();

      pdfUrl = URL.createObjectURL(pdfContent);
    } catch (error) {
      console.error(`error in generating Work Order Map View pdf`, error);
    } finally {
      if (pdfUrl) {
        window.open(pdfUrl);
      } else {
        alert("Error in genearting pdf url");
      }
    }
  };

  return (
    <>
      <div ref={mapRef} style={{ height: 800 }}>
        <div
          // disabled={true}
          ref={downloadButton}
          className="esri-widget--button"
          title="Export to CSV"
          onClick={downloadImage}
        >
          <span className="esri-icon-download"></span>
        </div>
      </div>
    </>
  );
}
