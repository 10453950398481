import React from "react";
import AppRoutes from "./routes/AppRoutes";
import RootProvider from "components/RootProvider";

function App() {
  return (
    <RootProvider>
      <div style={{ marginInline: "auto", maxWidth: 1920 }}>
        <AppRoutes />
      </div>
    </RootProvider>
  );
}

export default App;
