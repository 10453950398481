export const ROOT_KEY = "DYNAMONDDB_FILTER";
const OPERANT = "OPERANT";
const UI = "GENERAL_UI";

export const KEYS = {
  [OPERANT]: {
    BETWEEN: `${ROOT_KEY}.${OPERANT}.BETWEEN`,
    EQUAL: `${ROOT_KEY}.${OPERANT}.EQUAL`,
    NOT_EQUAL_TO: `${ROOT_KEY}.${OPERANT}.NOT_EQUAL_TO`,
    LESS_THAN_OR_EQUAL_TO: `${ROOT_KEY}.${OPERANT}.LESS_THAN_OR_EQUAL_TO`,
    LESS_THAN: `${ROOT_KEY}.${OPERANT}.LESS_THAN`,
    GREATER_THAN_OR_EQUAL_TO: `${ROOT_KEY}.${OPERANT}.GREATER_THAN_OR_EQUAL_TO`,
    GREATER_THAN: `${ROOT_KEY}.${OPERANT}.GREATER_THAN`,
    CONTAINS: `${ROOT_KEY}.${OPERANT}.CONTAINS`,
    NOT_CONTAINS: `${ROOT_KEY}.${OPERANT}.NOT_CONTAINS`,
    BEGINS_WITH: `${ROOT_KEY}.${OPERANT}.BEGINS_WITH`,
    MATCHES: `${ROOT_KEY}.${OPERANT}.MATCHES`,
  },
  [UI]: {
    OPERANT: `${ROOT_KEY}.${UI}.OPERANT`,
    ADD: `${ROOT_KEY}.${UI}.ADD`,
    FILTER: `${ROOT_KEY}.${UI}.FILTER`,
    VALUE0: `${ROOT_KEY}.${UI}.VALUE0`,
    VALUE1: `${ROOT_KEY}.${UI}.VALUE1`,
  },
};

export const DYNAMONDB_FILTER_TRANSLATIONS = {
  en: {
    [OPERANT]: {
      BETWEEN: "Between",
      EQUAL: "On",
      NOT_EQUAL_TO: "not equal to",
      LESS_THAN_OR_EQUAL_TO: "less than or equal to",
      LESS_THAN: "Before",
      GREATER_THAN_OR_EQUAL_TO: "greater than or equal to",
      GREATER_THAN: "After",
      CONTAINS: "contains",
      NOT_CONTAINS: "not contains",
      BEGINS_WITH: "begins with",
      MATCHES: "Is",
    },
    [UI]: {
      OPERANT: "Operant",
      ADD: "Add",
      FILTER: "Filter",
      VALUE0: "Value 1",
      VALUE1: "Value 2",
    },
  },
  fr: {
    [OPERANT]: {
      BETWEEN: "est Entre",
      EQUAL: "est le",
      NOT_EQUAL_TO: "différent de",
      LESS_THAN_OR_EQUAL_TO: "inférieur ou égal à",
      LESS_THAN: "moins que",
      GREATER_THAN_OR_EQUAL_TO: "supérieur ou égal à",
      GREATER_THAN: "est Après",
      CONTAINS: "contient",
      NOT_CONTAINS: "ne contient pas",
      BEGINS_WITH: "commence par",
      MATCHES: "est",
    },
    [UI]: {
      OPERANT: "Opérant",
      ADD: "Ajouter",
      FILTER: "Filtre",
      VALUE0: "Valeur 1",
      VALUE1: "Valeur 2",
    },
  },
  ja: {
    // TODO all missing, from google translate
    [OPERANT]: {
      BETWEEN: "間",
      EQUAL: "同等",
      NOT_EQUAL_TO: "等しくない",
      LESS_THAN_OR_EQUAL_TO: "以下",
      LESS_THAN: "未満",
      GREATER_THAN_OR_EQUAL_TO: "以上",
      GREATER_THAN: "より大きい",
      CONTAINS: "含む",
      NOT_CONTAINS: "含まない",
      BEGINS_WITH: "から始まる",
      MATCHES: "マッチ",
    },
    [UI]: {
      OPERANT: "オペラント",
      ADD: "追加",
      FILTER: "フィルター",
      VALUE0: "値 1",
      VALUE1: "値 2",
    },
  },
};
