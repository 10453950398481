import { Document, Image, Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";

import { PDF_PAGE_SIZES } from "components/table/file/pdfPageSizes";
import { companyLogo } from "assets/assets";
import { WORK_ORDER_DATA_FIELDS_COMMON } from "../dataModal/workOrderDataModal";
import { splitIntoChunks } from "utils/data/utilsObject";
import { CREATE_LANG_KEY, getLocaleDateTime } from "utils/lang/translate";
import { KEYS as WORK_ORDER_FIELD_TRANSLATION_KEYS } from "../WorkorderTable.translate";
import { KEYS as ROW_DETAILS_TRANSLATION_KEYS } from "config/dataTranslations/workOrderData.translation";
import { useTranslation } from "react-i18next";
import { KEYS as REPORT_TRANSLATIONS_MAP } from "reports/translation/report.translation";
import PdfPage from "reports/pdfComponents/PdfPage";

const FONT_SIZES = {
  LG: 18,
  MD: 16,
  DEFAULT: 14,
  SM: 12,
};

const PAGE_SIZE = "LETTER";

const PAGE_ORIENTATION = "landscape";

const PAGE_PADDING_HORIZONTAL = 36;
const PAGE_PADDING_VERTICAL = 24;

const STATIC_HEADER_HEIGHT = 50;

let PAGE_TOTAL_HEIGHT, PAGE_TOTAL_WIDTH;

if (PAGE_ORIENTATION === "landscape") {
  const [height, width] = PDF_PAGE_SIZES[PAGE_SIZE];
  PAGE_TOTAL_HEIGHT = height;
  PAGE_TOTAL_WIDTH = width;
} else {
  const [width, height] = PDF_PAGE_SIZES[PAGE_SIZE];
  PAGE_TOTAL_HEIGHT = height;
  PAGE_TOTAL_WIDTH = width;
}

const AVAILABLE_HEIGHT =
  PAGE_TOTAL_HEIGHT - 2 * PAGE_PADDING_VERTICAL - STATIC_HEADER_HEIGHT;
const AVAILABLE_WIDTH = PAGE_TOTAL_WIDTH - 2 * PAGE_PADDING_HORIZONTAL;

const styles = StyleSheet.create({
  page: {
    paddingVertical: 24,
    paddingHorizontal: 36,
    fontSize: FONT_SIZES.DEFAULT,
  },
  headerDate: { textAlign: "right", fontSize: 14 }, // generated date time stamp on top right corner
  docTitle: { textAlign: "center", fontSize: FONT_SIZES.LG, fontStyle: "bold" },
  docSubtitle: {
    textAlign: "center",
    fontStyle: "italic",
    fontSize: FONT_SIZES.DEFAULT,
    marginTop: 5,
  },
  pageDiv: {
    position: "absolute",
    bottom: 12,
    right: 36,
    fontSize: FONT_SIZES.SM,
  },
  row: {
    fontSize: 12,
    width: 760,
    display: "flex",
    flexDirection: "row",
    marginTop: 8,
  },
  labelCell: {
    width: 150,
    textAlign: "right",
    marginRight: 8,
    fontStyle: "bold",
  },
  valueCell: {
    width: 180,
  },
  // address could be long, fixed length force auto line break
  addressCell: {
    width: 200,
  },
});

export const WorkorderPdfView = (props) => {
  const { data, arcgisToken, interestedFields, logo } = props;

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const LABEL_TRANSLATION_KEYS = ROW_DETAILS_TRANSLATION_KEYS;

  const TRANSLATION_TYPES = {
    DATE: "date",
  };
  const headers0 = [
    [
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.OPEN_DATETIME_LOCAL,
        label: t(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.OPENED),
        translateType: TRANSLATION_TYPES.DATE,
      }, // given
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.SERVICE_BY_LOCAL,
        label: t(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.SERVICE_BY),
        translateType: TRANSLATION_TYPES.DATE,
      }, // open + days to finished
    ],
    [
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.INSPECTED_ON_LOCAL,
        // label: "Inspected On",
        label: t(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.INSPECTED_ON),
        translateType: TRANSLATION_TYPES.DATE,
      }, // date of opened
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.INSPECTED_BY,
        // label: "Inspected By",
        label: t(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.INSPECTED_BY),
        // translateType: TRANSLATION_TYPES.DATE,
      }, // ""
    ],
    [
      // {
      //   fieldName: "upon_completion_submit_to", // tbd
      //   label: "Upon Completion Submit To", // tbd
      // },
      {
        fieldName: "upon_completion_submit_to", // tbd
        label: t(
          WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS
            .UPON_COMPLETION_SUBMIT_TO
        ), // tbd
      },
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.CATEGORY,
        label: t(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.CATEGORY),
        translateType: WORK_ORDER_FIELD_TRANSLATION_KEYS.CATEGORY,
      },
    ],
    [
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.SEVERITY,
        label: t(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.SEVERITY), // // regulation, standard
      },
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.ISSUED_BY,
        label: t(LABEL_TRANSLATION_KEYS.ISSUED_BY),
      },
    ],
    [
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.ASSIGN_TO,
        label: t(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.ASSIGNED_TO),
      },
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.W_O_ID,
        // label: "Work Order ID",
        label: t(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.WORK_ORDER_ID),
      }, // auto generated
    ],
    [
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.W_O_STATUS,
        // label: "Work Order Status",
        label: t(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.STATUS),
        translateType: WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS,
      }, // "opened": default, "closed", "assigned"
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.W_O_TYPE,
        // label: "Work Order Type",
        label: t(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.TYPE),
        translateType: WORK_ORDER_FIELD_TRANSLATION_KEYS.W_O_TYPE,
      }, // defect type label
    ],
    [
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.DATAPOINT_ID,
        label: t(LABEL_TRANSLATION_KEYS.DATA_POINT_ID),
      },
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.DTS_LOCAL,
        label: t(WORK_ORDER_FIELD_TRANSLATION_KEYS.TABLE_HEADERS.DTS),
      }, // diff from service_by to current date
    ],
    [
      { fieldName: WORK_ORDER_DATA_FIELDS_COMMON.ID, label: "ID" }, // auto generated from server
      {
        fieldName: WORK_ORDER_DATA_FIELDS_COMMON.LABEL_ID,
        label: t(LABEL_TRANSLATION_KEYS.LABEL_ID),
      }, // from server
    ],
  ];

  const remapped = interestedFields.map((data) => {
    const { fieldName, label } = data;
    const cL = label.replaceAll(" ", "_").toUpperCase();
    return {
      fieldName,
      label: t(LABEL_TRANSLATION_KEYS[cL]),
    };
  });
  const header1 = splitIntoChunks(remapped, 2);
  const header2 = [
    {
      fieldName: WORK_ORDER_DATA_FIELDS_COMMON.ADDRESS,
      // label: "Address"
      label: t(LABEL_TRANSLATION_KEYS.ADDRESS),
    }, // from shapefile (1st shapefile)
    {
      fieldName: WORK_ORDER_DATA_FIELDS_COMMON.DESCRIPTION,
      // label: "Description",
      label: t(LABEL_TRANSLATION_KEYS.DESCRIPTION),
    },
    {
      fieldName: WORK_ORDER_DATA_FIELDS_COMMON.NOTE,
      label: t(LABEL_TRANSLATION_KEYS.NOTE),
    },
  ];

  const LineBreak = (
    <View
      style={{
        marginVertical: 10,
        height: 2,
        backgroundColor: "grey",
      }}
    />
  );
  // "image_url"
  const {
    page,
    headerDate,
    docTitle,
    docSubtitle,
    pageDiv,
    row,
    labelCell,
    valueCell,
    addressCell,
  } = styles;

  const StaticHeader = (props) => {
    const { w_o_id: workorderid } = props;

    /** smae as region name, used to referenced city logo from `assets/logos/cityLogos` folder */
    const cityName =
      data[0][WORK_ORDER_DATA_FIELDS_COMMON.REGION].toLowerCase();
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          height: 50,
          // backgroundColor: "red",
        }}
      >
        {/* city & company logos */}
        <View
          style={{
            width: 200,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {logo && (
            <View>
              <Image src={logo} style={{ width: 130 }} />
            </View>
          )}
          <View>
            <Image src={companyLogo} style={{ width: 50 }} />
          </View>
        </View>
        <View
          style={{
            // backgroundColor: "lightblue",
            width: `${AVAILABLE_WIDTH - 400}`,
          }}
        >
          <Text style={docTitle}>
            {t(REPORT_TRANSLATIONS_MAP.TITLE.WORK_ORDER)}
          </Text>
          <Text style={docSubtitle}>{`ID: ${workorderid}`}</Text>
        </View>

        <View
          style={{
            width: 200,
            // backgroundColor: "teal"
          }}
        >
          <Text style={headerDate}>
            {getLocaleDateTime(new Date(), language, "DATE")}
          </Text>
        </View>

        {LineBreak}
      </View>
    );
  };

  const renderLabelAndValue = (d) => (fieldPair, fieldPairIndex) => {
    return (
      <View key={fieldPairIndex} style={row}>
        {fieldPair.map((field, fieldIndex) => {
          let value = d[field.fieldName];

          if (field.translateType === TRANSLATION_TYPES.DATE) {
            // value = TranslateTime(value, dateFormatString);
            value = getLocaleDateTime(value, language, "FULL");
          } else if (field.translateType) {
            const translationKey = field.translateType[CREATE_LANG_KEY(value)];
            value = t(translationKey);
          }
          return (
            <View
              key={fieldIndex}
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text style={labelCell}>{field.label}:</Text>
              <Text style={valueCell}>{value}</Text>
            </View>
          );
        })}
      </View>
    );
  };
  return (
    <Document layout>
      {data.map((d, value) => (
        <React.Fragment key={value}>
          <PdfPage style={page} orientation="landscape" size={PAGE_SIZE}>
            <StaticHeader w_o_id={d.w_o_id} />
            {LineBreak}
            <View>
              <View>{headers0.map(renderLabelAndValue(d))}</View>

              {header1.length > 0 && LineBreak}
              <View>{header1.map(renderLabelAndValue(d))}</View>

              {header2.length > 0 && LineBreak}
              {header2.map((field, index) => (
                <View key={index} style={row}>
                  <Text style={labelCell}>{field.label}:</Text>
                  <Text>{d[field.fieldName]}</Text>
                </View>
              ))}
            </View>
            {LineBreak}

            <Text
              style={pageDiv}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </PdfPage>
          {/* render original defect */}
          <PdfPage style={page} orientation="landscape" size={PAGE_SIZE}>
            <StaticHeader w_o_id={d.w_o_id} />
            {LineBreak}
            <View
              style={{
                height: `${AVAILABLE_HEIGHT - 22}`, // total height - heigh of the linebreak component
              }}
            >
              <Image
                src={{
                  uri: d.image_url,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
                style={{
                  objectFit: "contain",
                }}
              />
            </View>
            <Text
              style={pageDiv}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </PdfPage>
          {/* render location (map) view */}
          <PdfPage style={page} orientation="landscape" size={PAGE_SIZE}>
            <StaticHeader w_o_id={d.w_o_id} />
            {LineBreak}
            <View
              style={{
                height: `${AVAILABLE_HEIGHT - 22}`, // total height - heigh of the linebreak component
              }}
            >
              <Image
                src={d.map_image_url}
                style={{
                  objectFit: "contain",
                }}
              />
            </View>
            <Text
              style={pageDiv}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </PdfPage>

          {Array.isArray(d.image_urls_completed) && // this value could be "N / A"
            d.image_urls_completed.map((url) => {
              return (
                <PdfPage
                  style={page}
                  orientation="landscape"
                  size={PAGE_SIZE}
                  key={url}
                >
                  <StaticHeader w_o_id={d.w_o_id} />
                  {LineBreak}
                  <View
                    style={{
                      height: `${AVAILABLE_HEIGHT - 22}`, // total height - heigh of the linebreak component
                    }}
                  >
                    <Image
                      src={`${url}?token=${arcgisToken}`}
                      style={{
                        objectFit: "contain",
                      }}
                    />
                  </View>
                  <Text
                    style={pageDiv}
                    render={({ pageNumber, totalPages }) =>
                      `${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </PdfPage>
              );
            })}
        </React.Fragment>
      ))}
    </Document>
  );
};

export default WorkorderPdfView;
