//https://www.linkedin.com/in/lucien-chu-95b974ba/

/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Feb 05, 2021
 *
 * Description: This is a component design for a user who is interested to have access to the dashboard,
 * by filling the information, including his name, contact email, phone number, linkedin profile link and
 * so on. He could send the administrator who is able to verify his request and decide to make him an
 * account based on the provided information.
 */

import React, { useState } from "react";
import {
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";

import { validateEmail } from "../createNewUser/CreateNewUser";
import { requestNewAccount } from "../../utils/requests";
import styles from "./RequestAccountForm.module.scss";

const DEFAULT_HELPER_TEXT_CONTENT = "This field cannot be empty";

const NAME = "name";
const EMAIL = "email";
const ORGANIZATION = "organization";
const PHONE = "phone";
const JOB = "job";
const LINKEDIN = "linkedin";
const MESSAGE = "message";

const PHONE_NUMBER_AREA_CODE_LENGTH = 3;
const PHONE_NUMBER_MIDDLE_DIGITS_LENGTH = 3;
const PHONE_NUMBER_LAST_DIGITS_LENGTH = 4;

const PHONE_NUMBER_MAX_LENGTH = 10;
const MESSAGE_MIN_LENGTH = 10;
const MESSAGE_MAX_LENGTH = 400;

/**
 * @summary check whether a given string value is a valid linkedin profile.
 *
 * @param {String} profileUrl string value of the url of one's linkedin profile
 */
const validateLinkedinProfile = (profileUrl) => {
  const re = new RegExp(/^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/);
  const result = re.test(String(profileUrl).toLowerCase());
  return result;
};

/**
 * @description This method would extract all digits(s) from a given string and
 * concatinate all of them in to a single string.
 *
 * ie value = "12a34b56c", returns "123456"
 *    value = "abcd", returns "";
 *
 * @param {String} value string value that may or may not contain digit(s).
 *
 * @returns substring of the given value that only contains digit(s) or an empty string if give string contains no numerical substrings.
 */
const getDigitStringFromString = (value) => {
  const digits = value.match(/\d/g);
  if (!digits) {
    return "";
  }
  return digits.join("");
};

/**
 * @description Give a string value, could be mixed with various symbols.
 * 1. get all digits from the string and join them as another string
 * 2. based on the lengthes of areaCode, and the middle digits and end
 * digits, return string in the forms of
 * (areaCodeDigits) middleDigits-lastDigits
 *
 * for example (647)-123-4567 etc.
 *
 *
 *
 * @param value string value
 * @param areaCodeLength
 * @param middleLength
 * @param lastLength
 *
 * @returns a formatted phone number string with "()" and "-"
 */
const getFormattedPhoneNumber = (
  value,
  areaCodeLength,
  middleLength,
  lastLength
) => {
  const numb = getDigitStringFromString(value);
  if (numb === "") {
    return numb;
  }
  let areaCode = numb.substr(0, areaCodeLength);
  let middleThree = numb.substr(areaCodeLength, middleLength);
  let lastFour = numb.substr(areaCodeLength + middleLength, lastLength);
  let phoneNumberString = "";
  if (areaCode.length < 3) {
    // (1
    // (12
    phoneNumberString = `(${areaCode}`;
  } else if (areaCode.length === areaCodeLength && middleThree.length === 0) {
    // (123)
    phoneNumberString = `(${areaCode})-`;
  } else if (middleThree.length > 0 && lastFour.length === 0) {
    // (123)-4
    // (123)-45
    // (123)-456
    phoneNumberString = `(${areaCode})-${middleThree}${
      // (123)-456-
      middleThree.length === middleLength ? "-" : ""
    }`;
  } else if (lastFour.length > 0) {
    // (123)-456-7
    // (123)-456-78
    // (123)-456-789
    // (123)-456-7890
    phoneNumberString = `(${areaCode})-${middleThree}-${lastFour}`;
  }
  return phoneNumberString;
};

export default function RequestAccountForm(props) {
  const { onSubmit } = props;
  const [formData, setFormData] = useState({
    [NAME]: { value: "", helperText: DEFAULT_HELPER_TEXT_CONTENT },
    [EMAIL]: { value: "", helperText: DEFAULT_HELPER_TEXT_CONTENT },
    [ORGANIZATION]: { value: "", helperText: DEFAULT_HELPER_TEXT_CONTENT },
    [PHONE]: { value: "", helperText: DEFAULT_HELPER_TEXT_CONTENT },
    [JOB]: { value: "", helperText: DEFAULT_HELPER_TEXT_CONTENT },
    [LINKEDIN]: { value: "", helperText: "" },
    [MESSAGE]: { value: "", helperText: DEFAULT_HELPER_TEXT_CONTENT },
  });

  /**
   * @summary A callback function passed to the onChange property to TextFields
   *
   * @description As user input values into each text fields, the formData object would be
   * updated based on the input value.
   *
   * Note that: email value would be validate against a regex and phone number would be
   * converted to more standard string
   *
   * @see formData
   *
   * @param {KeyEvent} event
   * @param {String} field key name corresponding to the formData object
   */
  const updateFormData = (event, field) => {
    const value = event.target.value.trim();
    const updatedFormData = { ...formData };
    if (value !== "") {
      if (field === EMAIL) {
        updatedFormData[field] = {
          value: value,
          helperText: validateEmail(value) ? "" : "Email is not valud",
        };
      } else if (field === PHONE) {
        // get the previously formatted and saved phone number string from the state
        const savedPhoneNumberString = formData[field].value;
        let updatedValue = value;
        if (savedPhoneNumberString.length > value.length) {
          // new value's length is less than the saved one, namely,
          // user is removing a character from the savedPhoneNumberString, which was
          // previously formatted.

          // if value's last digit is a number,
          // for instance:
          // savedPhoneNumberString = "(111)-11", before removing "1"
          // value                  = "(111)-1" after remvoing "1"
          // save the value as the udpated value
          if (!isNaN(value[value.length - 1])) {
            updatedFormData[field] = {
              value: value,
              helperText: "",
            };
          } else {
            let subValue = value;
            // value ends with symbols, namely "-", ")", or "(,
            // for instance
            // savedPhoneNumberString = "(111)-1", before removing "1"
            // value                  = "(111)-" after remvoing "1"
            // cut the string (value) from right until it ends with a digit, like this
            // value_to_be_updated    = "(111"
            while (
              subValue.length > 0 &&
              isNaN(subValue[subValue.length - 1])
            ) {
              subValue = subValue.substr(0, subValue.length - 1);
            }

            updatedFormData[field] = {
              value: subValue,
              helperText: subValue === "" ? DEFAULT_HELPER_TEXT_CONTENT : "",
            };
          }
        } else {
          const formattedPhoneNumber = getFormattedPhoneNumber(
            updatedValue,
            PHONE_NUMBER_AREA_CODE_LENGTH,
            PHONE_NUMBER_MIDDLE_DIGITS_LENGTH,
            PHONE_NUMBER_LAST_DIGITS_LENGTH
          );
          updatedFormData[field] = {
            value: formattedPhoneNumber,
            helperText: "",
          };
        }
      } else if (field === LINKEDIN) {
        updatedFormData[field] = {
          value: value,
          helperText: validateLinkedinProfile(value)
            ? ""
            : "Linkedin profile is not valid",
        };
      } else {
        updatedFormData[field] = { value: value, helperText: "" };
      }
    } else {
      updatedFormData[field] = {
        value: value,
        helperText: field === LINKEDIN ? "" : DEFAULT_HELPER_TEXT_CONTENT,
      };
    }

    setFormData(updatedFormData);
  };

  /**
   * @summary Determines whehter or not the submit button should be disabled or not.
   *
   * @description A callback function passed to the disabled property of the submit button. Based on the
   * current state of the formData, this method would determine whehter or not all text fields are filled
   * properly and whehter the submit button should be enabled for the user.
   *
   * NOTE: it should return false, IOT, submit button is enabled, if and only if
   * 1. all text fields' value are not empty;
   * 2. a proper email address is given;
   * 3. a long enough phone number is geven;
   * 4. a valid lnkedin profile url is gven；
   * 5. a long enough message is given
   *
   * @see PHONE_NUMBER_MAX_LENGTH
   * @see MESSAGE_MIN_LENGTH
   *
   * @returns Whether or not the submit button should be disabled.
   */
  const shouldDisabled = () => {
    let shouldDisabledSubmitBtn = false;
    for (const key in formData) {
      if (shouldDisabledSubmitBtn === true) {
        break;
      }
      if (Object.hasOwnProperty.call(formData, key)) {
        const element = formData[key];
        const { value } = element;
        switch (key) {
          case EMAIL:
            shouldDisabledSubmitBtn = !validateEmail(value);
            break;
          case PHONE:
            shouldDisabledSubmitBtn =
              getDigitStringFromString(formData[key].value).length <
              PHONE_NUMBER_MAX_LENGTH;
            break;
          case LINKEDIN:
            shouldDisabledSubmitBtn =
              value.trim() !== "" && !validateLinkedinProfile(value);
            break;
          case MESSAGE:
            shouldDisabledSubmitBtn = value.length <= MESSAGE_MIN_LENGTH;
            break;
          default:
            shouldDisabledSubmitBtn = value === "";
        }
      }
    }
    return shouldDisabledSubmitBtn;
  };

  /**
   * @summary A callback function passed to the onClick propery of the submit button.
   *
   * @param {Event} event click event
   */
  const handleSubmit = (event) => {
    event.preventDefault(formData);
    const submitForm = {};
    Object.keys(formData).forEach((key) => {
      const value = formData[key].value;
      if (value !== "") {
        submitForm[key] = formData[key].value;
      }
    });
    submitForm["subject"] =
      "Iris City Dashboard Request: " + formData.name.value;
    //TODO API need to be verified
    requestNewAccount(submitForm)
      .then((result) => {
        if (result.success === true && onSubmit) {
          onSubmit(true, "");
        }
      })
      .catch((error) => {
        console.error("Error in sending form: " + error.message);
        onSubmit(false, error.message);
      });
  };

  /**
   * @summary A callback function passed to the onClick propery of the cancel button.
   *
   * @param {Event} event click event.
   */
  const handleCancel = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(false, null);
    }
  };

  return (
    <div className={styles.formWrapper}>
      <div>
        <div className={styles.formTitle}>Let's Talk!</div>
        <div className={styles.formParagraph}>
          <p>
            Learn more about our on demand end to end AI-enabled smart city and
            asset reporting technology
          </p>

          <form id="client-form" noValidate className={styles.formBody}>
            <FormGroup>
              <TextField
                autoFocus
                label="Name"
                placeholder="Your name"
                required
                onChange={(event) => updateFormData(event, NAME)}
              />
              <FormHelperText style={{ color: "red" }}>
                {formData[NAME].helperText}
              </FormHelperText>
            </FormGroup>

            <FormGroup>
              <TextField
                label="Email Address"
                placeholder="Email address"
                required
                onChange={(event) => updateFormData(event, EMAIL)}
              />
              <FormHelperText style={{ color: "red" }}>
                {formData[EMAIL].helperText}
              </FormHelperText>
            </FormGroup>

            <FormGroup>
              <TextField
                // label="City"
                label="Municipality/ Organization"
                placeholder="Where do you work?"
                required
                onChange={(event) => updateFormData(event, ORGANIZATION)}
              />
              <FormHelperText style={{ color: "red" }}>
                {formData[ORGANIZATION].helperText}
              </FormHelperText>
            </FormGroup>

            <Grid container justifyContent="space-between">
              <Grid item sm={5}>
                <FormGroup>
                  {/* <TextField
                    label="Company"
                    placeholder="Where do you work"
                    required
                    onChange={(event) => updateFormData(event, "company")}
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {formData["company"].helperText}
                  </FormHelperText>
                </FormGroup> */}
                  <TextField
                    label="Phone"
                    placeholder="eg: (647)-123-4567"
                    // type="number"
                    value={formData[PHONE].value}
                    required
                    onChange={(event) => updateFormData(event, PHONE)}
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {formData[PHONE].helperText}
                  </FormHelperText>
                </FormGroup>
              </Grid>
              <Grid item sm={5}>
                <FormGroup>
                  <TextField
                    label="Position"
                    placeholder="What do you do?"
                    required
                    onChange={(event) => updateFormData(event, JOB)}
                  />
                </FormGroup>
                <FormHelperText style={{ color: "red" }}>
                  {formData[JOB].helperText}
                </FormHelperText>
              </Grid>
            </Grid>
            <FormGroup>
              <TextField
                label="LinkedIn Profile"
                placeholder="eg. https://www.linkedin.com/in/your-name/"
                required
                onChange={(event) => updateFormData(event, LINKEDIN)}
              />
              <FormHelperText style={{ color: "red" }}>
                {formData[LINKEDIN].helperText}
              </FormHelperText>
            </FormGroup>

            <FormGroup>
              <FormLabel style={{ marginBottom: "0.775rem" }}>
                Message
              </FormLabel>
              <TextareaAutosize
                // placeholder="What is the reason for requesting an account from us"
                placeholder="I want to know more."
                required
                maxLength={MESSAGE_MAX_LENGTH}
                minlength={MESSAGE_MIN_LENGTH}
                rowsMax={3}
                onChange={(event) => updateFormData(event, MESSAGE)}
              />
              <FormHelperText style={{ color: "red" }}>
                {formData[MESSAGE].helperText}
              </FormHelperText>
            </FormGroup>

            <div className={styles.formButtonsContainer}>
              <button className={styles.cancelButton} onClick={handleCancel}>
                Cancel
              </button>
              <button
                className={styles.submitButton}
                disabled={shouldDisabled()}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

/**
 * Change Log:
 *
 * Change Date: Feb 05, 2021
 *
 * Description: Compoent is created and documented.
 */

/**
 * Change Log:
 *
 * Change Date: Feb 12, 2021
 *
 * Description:
 * Updated: make linkedin profile input is not mandatory, but if any
 * value was provided, the regex check would still be applied.
 */
