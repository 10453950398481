export const ROOT_KEY = "DASHBOARD_COMPONENT";
const TAB_KEYS = "TAB_KEYS";

export const KEYS = {
  DEVICE_DEPLOYED: `${ROOT_KEY}.DEVICE_DEPLOYED`,
  [TAB_KEYS]: {
    ROUTE_COVERAGE: `${ROOT_KEY}.${TAB_KEYS}.ROUTE_COVERAGE`,
    ROAD_PATROL_REPORT: `${ROOT_KEY}.${TAB_KEYS}.ROAD_PATROL_REPORT`,
    ASSETS_INVENTORY: `${ROOT_KEY}.${TAB_KEYS}.ASSETS_INVENTORY`,
    HEAT_MAP: `${ROOT_KEY}.${TAB_KEYS}.HEAT_MAP`,
    WORK_ORDER: `${ROOT_KEY}.${TAB_KEYS}.WORK_ORDER`,
    COMPLIANCE_DASHBOARD: `${ROOT_KEY}.${TAB_KEYS}.COMPLIANCE_DASHBOARD`,
    ROAD_DEFECT: `${ROOT_KEY}.${TAB_KEYS}.ROAD_DEFECT`,
    WASTE_PARTICIPATION: `${ROOT_KEY}.${TAB_KEYS}.WASTE_PARTICIPATION`,
    CRACK_ALERTS: `${ROOT_KEY}.${TAB_KEYS}.CRACK_ALERTS`,
    DETECTION_MODULE: `${ROOT_KEY}.${TAB_KEYS}.DETECTION_MODULE`,
    PCI: `${ROOT_KEY}.${TAB_KEYS}.PCI`,
    ROW_SIGNS: `${ROOT_KEY}.${TAB_KEYS}.ROW_SIGNS`,
    SEGMENT: `${ROOT_KEY}.${TAB_KEYS}.SEGMENT`,
    WASTE_STATISTICS: `${ROOT_KEY}.${TAB_KEYS}.WASTE_STATISTICS`,
    LITTER_BIN: `${ROOT_KEY}.${TAB_KEYS}.LITTER_BIN`,
    BARRIERS: `${ROOT_KEY}.${TAB_KEYS}.BARRIERS`,
    LITTER_BINS: `${ROOT_KEY}.${TAB_KEYS}.LITTER_BINS`,
    LUMINAIRES: `${ROOT_KEY}.${TAB_KEYS}.LUMINAIRES`,
    PCI_BY_SEGMENT: `${ROOT_KEY}.${TAB_KEYS}.PCI_BY_SEGMENT`,
    PAVEMENT_MARKINGS: `${ROOT_KEY}.${TAB_KEYS}.PAVEMENT_MARKINGS`,
    ROAD_DEFECTS: `${ROOT_KEY}.${TAB_KEYS}.ROAD_DEFECTS`,
    SIGNS: `${ROOT_KEY}.${TAB_KEYS}.SIGNS`,
    COLLECTION_UPDATED_SEGMENTS: `${ROOT_KEY}.${TAB_KEYS}.COLLECTION_UPDATED_SEGMENTS`,
  },
};

export const DASHBOARD_COMPONENT_DICTS = {
  en: {
    DEVICE_DEPLOYED: "Devices Deployed: {{numOfDevices}}",
    [TAB_KEYS]: {
      ROUTE_COVERAGE: "Route Coverage",
      ROAD_PATROL_REPORT: "Road Patrol Report",
      ASSETS_INVENTORY: "Assets",
      HEAT_MAP: "Heat Map",
      WORK_ORDER: "Work Order",
      COMPLIANCE_DASHBOARD: "Compliance Dashboard",
      ROAD_DEFECT: "Road Defect",
      WASTE_PARTICIPATION: "Waste Participation",
      CRACK_ALERTS: "Crack Alerts",
      DETECTION_MODULE: "Detection Module",
      PCI: "PCI",
      ROW_SIGNS: "Row Signs",
      SEGMENT: "Segment",
      WASTE_STATISTICS: "Waste Statistics",
      LITTER_BIN: "Litter Bin",
      BARRIERS: "Barriers",
      LITTER_BINS: "Litter Bins",
      LUMINAIRES: "Luminaires",
      PCI_BY_SEGMENT: "PCI by Segment",
      PAVEMENT_MARKINGS: "Pavement Markings",
      ROAD_DEFECTS: "Road Defects",
      SIGNS: "Signs",
      COLLECTION_UPDATED_SEGMENTS: "Collection Updated Segments",
    },
  },
  fr: {
    DEVICE_DEPLOYED: "Appareils Déployés: {{numOfDevices}}",
    [TAB_KEYS]: {
      ROUTE_COVERAGE: "Couverture de l'Itinéraire",
      ROAD_PATROL_REPORT: "Rapport de patrouille routière",
      ASSETS_INVENTORY: "Patrimoine",
      HEAT_MAP: "Carte Thermique",
      WORK_ORDER: "demande d'intervention ",
      COMPLIANCE_DASHBOARD: "Tableau de Bord de Conformité",
      ROAD_DEFECT: "Défaut de la Route",
      WASTE_PARTICIPATION: "Participation aux Déchets",
      CRACK_ALERTS: "signalement de fissure",
      DETECTION_MODULE: "Module de détection",
      PCI: "PCI",
      ROW_SIGNS: "Signes de Rangée",
      SEGMENT: "Segment",
      WASTE_STATISTICS: "Statistiques sur les déchets",
      LITTER_BIN: "Poubelle à ordures",
      BARRIERS: "Barrières",
      LITTER_BINS: "Poubelle à ordure",
      LUMINAIRES: "Luminaires",
      PCI_BY_SEGMENT: "PCI par segment",
      PAVEMENT_MARKINGS: "Marquage au sol",
      ROAD_DEFECTS: "Défauts de la route",
      SIGNS: "Panneaux",
      COLLECTION_UPDATED_SEGMENTS: "Segments mis à jour de la collection",
    },
  },
  ja: {
    DEVICE_DEPLOYED: "配備されたデバイス: {{numOfDevices}}",
    [TAB_KEYS]: {
      ROUTE_COVERAGE: "対象路線",
      ROAD_PATROL_REPORT: "ロードパトロールレポート",
      ASSETS_INVENTORY: "資産",
      HEAT_MAP: "ヒートマップ",
      WORK_ORDER: "作業指示",
      COMPLIANCE_DASHBOARD: "コンプライアンス ダッシュボード",
      ROAD_DEFECT: "道路の欠陥",
      WASTE_PARTICIPATION: "廃棄物への参加",
      CRACK_ALERTS: "クラックアラート",
      DETECTION_MODULE: "検出モジュール",
      PCI: "PCI",
      ROW_SIGNS: "行標識",
      SEGMENT: "セグメント",
      WASTE_STATISTICS: "廃棄物統計",
      LITTER_BIN: "ゴミ箱",
      BARRIERS: "Barriers", // missing from file
      LITTER_BINS: "ゴミ箱",
      LUMINAIRES: "Luminaires", // missing from file
      PCI_BY_SEGMENT: "PCI by Segment", // missing from file
      PAVEMENT_MARKINGS: "Pavement Markings", // missing from file
      ROAD_DEFECTS: "Road Defects", // missing from file
      SIGNS: "Signs", // missing from file
      COLLECTION_UPDATED_SEGMENTS: "コレクションの更新されたセグメント",
    },
  },
};
