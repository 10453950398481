import React, { useState, useEffect, useRef } from "react";
import { loadModules, setDefaultOptions } from "esri-loader";
import { WorkorderMapViewPdf } from "components/table/file/WorkorderMapViewPdf";

import { pdf } from "@react-pdf/renderer";

import { useTranslation } from "react-i18next";
import useAuth from "hook/useAuth";
import {
  getAllStandardFeatuers,
  getFeatuerLayerMetaData,
} from "utils/requests";
import { nexcoPCITemplateCSSV } from "./pciTemplates/nexcoPCITemplateCSSV";
import NexcoPCIReport from "reports/nexco/NexcoPCIReport";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Button } from "@material-ui/core";
setDefaultOptions({
  version: "4.21",
  css: true,
});

const QUERY_RADIUS = 1000;
const QUERY_DISTANCE_UNIT = "meters"; // "feet"|"miles"|"nautical-miles"|"us-nautical-miles"|"meters"|"kilometers"

export default function PCIPage(props) {
  const { data, mapCenter } = props;
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [mapView, setMapView] = useState(null);
  const downloadButton = useRef(null);

  const GraphicClass = useRef(null);
  const FeatureLayerClass = useRef(null);

  const [queriedFeatures, setQueriedFeatures] = useState([]);

  const {
    pageConfig: {
      pciReport: {
        layer_url,
        feature_layer_query_url,
        feature_attributes,
        pci_report_config_csv,
      },
    },
  } = useAuth();

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/Graphic",
        "esri/layers/FeatureLayer",
        "esri/widgets/Search",
        "esri/widgets/Expand",
        "esri/widgets/BasemapGallery",
      ],
      { css: true }
    )
      .then(
        async ([
          Map,
          MapView,
          Graphic,
          FeatureLayer,
          Search,
          Expand,
          BasemapGallery,
        ]) => {
          GraphicClass.current = Graphic;
          FeatureLayerClass.current = FeatureLayer;

          const map = new Map({
            basemap: "topo",
          });

          const mapView = new MapView({
            map: map,
            center: mapCenter,
            zoom: 17,
            container: mapRef.current, // dom element
            ui: {
              components: ["attribution"], // remove all default UI except "attribution", [] means remove everything
            },
            popup: {
              defaultPopupTemplateEnabled: true,
            },
          });

          const basemapGallery = new BasemapGallery({
            view: mapView,
            container: document.createElement("div"),
          });

          // Create an Expand instance and set the content
          // property to the DOM node of the basemap gallery widget
          // Use an Esri icon font to represent the content inside
          // of the Expand widget

          const bgExpand = new Expand({
            view: mapView,
            content: basemapGallery,
          });

          mapView.ui.add(
            [
              new Search({
                view: mapView,
              }),
              bgExpand,
            ],
            "top-right"
          );
          mapView.ui.add(downloadButton.current, "top-left");
          // mapView.ui.add(document.getElementById("okayNiceTo"), "bottom-right");
          setMap(map);
          setMapView(mapView);

          const featureLayer = new FeatureLayer({
            url: layer_url,
            objectIdField: "id", // field of a data which continas unique value among all data
          });

          featureLayer.when(function () {
            mapView.extent = featureLayer.fullExtent;
            const center = [139.25198414, 37.94507254]; // hard coded center as the extent of the map seems off
            mapView
              .goTo({
                center,
                zoom: 11,
              })
              .catch(function (error) {
                console.error(error);
              });
          });

          /*================================================== set up down load PCI pdf START ================================================== */
          mapView.popup.actions.push({
            title: "PDFへのエクスポート", // Export PDF
            id: "action-export-pdf",
            className: "esri-icon-download", // https://developers.arcgis.com/javascript/latest/esri-icon-font/
          });

          async function callBackFn() {
            // in this case the view zooms out two LODs on each click
            const data = queriedFeatures.reduce((acc, ele) => {
              const temp = JSON.parse(JSON.stringify(ele.attributes));
              for (const key in temp) {
                if (Object.hasOwnProperty.call(temp, key)) {
                  const element = temp[key];
                  // round number to 2 decimal point
                  if (!isNaN(element) && !(element % 1 === 0)) {
                    const newValue = Math.round(element * 100) / 100;
                    temp[key] = newValue;
                  }
                }
              }
              acc.push(temp);
              return acc;
            }, []);

            const pdfContentView = <NexcoPCIReport data={[data]} />;

            try {
              const pdfContent = await pdf(pdfContentView).toBlob();
              window.open(URL.createObjectURL(pdfContent), "_blank");
            } catch (error) {
              alert("Error in converting pdf content");
              console.error("Error in converting pdf content", error);
            } finally {
            }
          }

          // This event fires for each click on any action
          mapView.popup.on("trigger-action", function (event) {
            // If the zoom-out action is clicked, fire the callBackFn() function
            if (event.action.id === "action-export-pdf") {
              callBackFn();
            }
          });
          /*==================================================  set up down load PCI pdf END  ================================================== */

          /*================================================== handle when map is clicked START ================================================== */
          /**
           * 1. a point grapic will be displayed on map
           * 2. base on that point, query 1000 meters around the feature layer
           * 3. dispay the following if there is any feature returns
           *    1. a circle graphic which covers the queried features
           *    2. a popup with a built in table, for user to browse and navigate quick to a feature
           */

          let queriedFeatures;
          //create graphic for mouse point click
          const clickedPointGraphic = new Graphic({
            symbol: {
              type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
              color: [0, 0, 139],
              outline: {
                color: [255, 255, 255],
                width: 1.5,
              },
            },
          });

          // indicate (circle out) queried features
          const rangeGraphic = new Graphic({
            symbol: {
              type: "simple-fill", // autocasts as new SimpleFillSymbol()
              color: [0, 0, 0, 0.2],
              outline: {
                // autocasts as new SimpleLineSymbol()
                color: [255, 255, 255],
                width: 1,
              },
            },
          });

          function queryFeatures(screenPoint) {
            const point = mapView.toMap(screenPoint);
            featureLayer
              .queryFeatures({
                geometry: point,
                distance: QUERY_RADIUS,
                units: QUERY_DISTANCE_UNIT,
                spatialRelationship: "intersects",
                returnGeometry: false,
                returnQueryGeometry: true, // If true, the query geometry will be returned with the query results.
                outFields: ["*"],
              })
              .then((featureSet) => {
                // set graphic location to mouse pointer and add to mapview
                clickedPointGraphic.geometry = point;
                mapView.graphics.add(clickedPointGraphic);
                const { features } = featureSet;
                // open popup of query result
                if (features?.length) {
                  queriedFeatures = features;
                  mapView.popup.open({
                    location: point,
                    features: queriedFeatures,
                  });
                }
                if (featureSet.queryGeometry) {
                  rangeGraphic.geometry = featureSet.queryGeometry;
                  mapView.graphics.add(rangeGraphic);
                }
              });
          }

          mapView.on("click", (event) => {
            // remove previously added point graphic
            mapView.graphics.remove(clickedPointGraphic);
            if (mapView.graphics.includes(rangeGraphic)) {
              mapView.graphics.remove(rangeGraphic);
            }
            queryFeatures(event);
          });
          /*==================================================  handle when map is clicked END  ================================================== */
          map.add(featureLayer);
        }
      )
      .catch((error) => {
        alert("Error in loading arcgis modules");
      });
  }, [mapRef]);

  useEffect(() => {
    getFeatuerLayerMetaData(layer_url)
      .then((metaData) => {
        let maxCount = 0;
        const { standardMaxRecordCount, maxRecordCount } = metaData;
        if (standardMaxRecordCount) {
          maxCount = 3400;
        } else {
          maxCount = maxRecordCount;
        }

        getAllStandardFeatuers(
          feature_layer_query_url,
          maxCount,
          feature_attributes
        )
          .then((result) => {
            setQueriedFeatures(result);
          })
          .catch((e) => console.error("error in getting feature data", e));
      })
      .catch((e) => console.error("error in getting layer's meta data", e));
  }, [layer_url, feature_layer_query_url, feature_attributes]);

  const downloadImage = async () => {
    let pdfUrl;
    try {
      const { dataUrl } = await mapView.takeScreenshot();

      if (!dataUrl) return;

      const pdfView = (
        <WorkorderMapViewPdf
          searchParams={props.searchParams}
          imageUrl={dataUrl}
        />
      );
      const pdfContent = await pdf(pdfView).toBlob();

      pdfUrl = URL.createObjectURL(pdfContent);
    } catch (error) {
      console.error(`error in generating Work Order Map View pdf`, error);
    } finally {
      if (pdfUrl) {
        window.open(pdfUrl);
      } else {
        alert("Error in genearting pdf url");
      }
    }
  };
  const downlowPCIData = () => {
    if (queriedFeatures.length > 0) {
      nexcoPCITemplateCSSV(queriedFeatures, pci_report_config_csv);
    }
  };
  return (
    <>
      <div ref={mapRef} style={{ height: "90vh" }}>
        <Button
          ref={downloadButton}
          variant="contained"
          style={{
            display: queriedFeatures.length <= 0 ? "none" : "inline-flex",
          }}
          color="primary"
          startIcon={<GetAppIcon />}
          title="すべてのデータをエクセルにエクスポート"
          onClick={downlowPCIData}
        >
          ダウンロード
        </Button>
      </div>
    </>
  );
}
