export const TITLE_TEMPLATE = {
  番號: {
    width: 25,
    title: "番號",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "index",
  },
  "上下 区分": {
    width: 25,
    title: "上下 区分",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "w_o_type",
  },
  "ＩＣ ＪＣＴ区間": {
    width: 80,
    title: "ＩＣ ＪＣＴ区間",
    render_sub_titles: true,
    subs: [
      {
        data_prefix: undefined,
        data_suffix: undefined,
        title: "自",
        data_key: "km_markers",
      },
      {
        data_prefix: undefined,
        data_suffix: undefined,
        title: "至",
        data_key: "km_markers",
      },
    ],
  },
  "施 工 区 分": {
    width: 75,
    title: "施 工 区 分",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "shi_gong_qu_fen",
  },
  "施 工 区 間 測 点": {
    width: 50,
    title: "施 工 区 間 測 点",
    render_sub_titles: true,
    subs: [
      {
        title: "KP",
        data_prefix: undefined,
        data_suffix: undefined,
        data_key: "km_markers",
      },
    ],
  },
  " 轍 ": {
    width: 20,
    title: "轍 ",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "zhe",
  },
  "概 要": {
    width: 80,
    title: "概 要",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: undefined,
    render_sub_titles: false,
    subs: [
      {
        title: undefined,
        data_prefix: "W",
        data_suffix: undefined,
        data_key: "gai_yao_w",
      },
      {
        title: undefined,
        data_prefix: undefined,
        data_suffix: "L",
        data_key: "gai_yao_l",
      },
    ],
  },
  "数 量 (㎡)": {
    width: 50,
    title: "数 量 (㎡)",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "shuliang",
  },
  "備 考": {
    width: 50,
    title: "備 考",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "bei_kao",
  },
  "規 制": {
    width: 50,
    title: "規 制",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "gui_ze",
  },
  "㎡": {
    width: 50,
    title: "㎡",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "m_sqrt",
  },
};
export const PCI_TITLE_TEMPLATE = {
  番號: {
    width: 25,
    title: "番號",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "FID",
  },
  "上下 区分": {
    width: 25,
    title: "上下 区分",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "direction",
  },
  "ＩＣ ＪＣＴ区間": {
    width: 80,
    title: "ＩＣ ＪＣＴ区間",
    render_sub_titles: true,
    subs: [
      {
        data_prefix: undefined,
        data_suffix: undefined,
        title: "自",
        data_key: "KM_Marker",
      },
      {
        data_prefix: undefined,
        data_suffix: undefined,
        title: "至",
        data_key: "KM_Marker",
      },
    ],
  },
  "施 工 区 分": {
    width: 75,
    title: "施 工 区 分",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "shi_gong_qu_fen",
  },
  "施 工 区 間 測 点": {
    width: 50,
    title: "施 工 区 間 測 点",
    render_sub_titles: true,
    subs: [
      {
        title: "KP",
        data_prefix: undefined,
        data_suffix: undefined,
        data_key: "KM_Marker",
      },
    ],
  },
  " 轍 ": {
    width: 20,
    title: "轍 ",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "zhe",
  },
  "概 要": {
    width: 80,
    title: "概 要",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: undefined,
    render_sub_titles: false,
    subs: [
      {
        title: undefined,
        data_prefix: "W",
        data_suffix: undefined,
        data_key: "LB_Defect_",
      },
      {
        title: undefined,
        data_prefix: undefined,
        data_suffix: "L",
        data_key: "DP_Defect_",
      },
    ],
  },
  "数 量 (㎡)": {
    width: 50,
    title: "数 量 (㎡)",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "LB_Defec00",
  },
  "備 考": {
    width: 50,
    title: "備 考",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "bei_kao",
  },
  "規 制": {
    width: 50,
    title: "規 制",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "gui_ze",
  },
  "㎡": {
    width: 50,
    title: "㎡",
    data_prefix: undefined,
    data_suffix: undefined,
    data_key: "m_sqrt",
  },
};
