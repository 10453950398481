import { StyleSheet } from "@react-pdf/renderer";

export const DEFAULTS = {
  FONT_SIZE: 8,
  FLEX: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "space-around",
  },
  BORDER: "1px solid black",
};
export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 45,
    paddingHorizontal: 35,
    fontSize: DEFAULTS.FONT_SIZE,
  },

  headerRow: {
    ...DEFAULTS.FLEX,
    border: "1px solid black",
    minHeight: 20,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    minHeight: 20,
    border: "1px solid black",
    justifyContent: "space-around",
    textAlign: "center",
  },
  cellText: {
    flex: 1,
    fontSize: 6,
  },
  cellTextRow: {
    fontSize: 6,
  },

  singelCellView: {
    alignItems: "center",
    borderLeft: "1px solid black",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
  },
});
