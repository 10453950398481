export const ROOT_KEY = "WEATHER_COMPONENT";

export const KEYS = {
  WEATHER_TIME: `${ROOT_KEY}.WEATHER_TIME`,
};

export const WEATHER_COMPONENT_TRANSLATIONS = {
  en: {
    WEATHER_TIME: "As of {{time}}",
  },
  fr: {
    WEATHER_TIME: "temps météorologique {{time}}",
  },
  ja: {
    WEATHER_TIME: "{{time}} 現在",
  },
};
