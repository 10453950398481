import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import welcomeImg from "assets/WelcomeIMG.png";
import IrisCityLogo from "assets/IrisCityLogo.png";
import irisLogo from "assets/irisLogo.png";
import { resetPassword } from "utils/requests";
import ResetPassword from "components/restPasswordComponent/ResetPassword";
import withModal from "HOC/withModal";
import { USER_NAME } from "utils/env/localStorageKeys";
import LoadingButton from "irisUI/loadingButton/LoadingButton";
import esriLogo from "assets/esriLogo.png";
import geotabLogo from "assets/geotabLogo.jpg";
import IrisModal from "components/userPreference/IrisModal";
import RequestAccountForm from "components/requestAccountForm/RequestAccountForm";
import TermsOfUse from "components/termsOfUser/TermsOfUse";
import { PROTECTED_ROUTES } from "routes/data/protectedRoutes";
import pkg from "../../../package.json";

import { ResetPasswordModal } from "components/modals/ResetPassordModal";
import Spinner from "irisUI/spinner/Spinner";
import useAuth from "hook/useAuth";
import { useNotification } from "irisUI/global/notification/context/NotificationContext";
import { getCommonTranslation } from "utils/lang/translate";

const PASSWORD_MIN_LENGTH = 6;
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  loginPanel: {
    height: "100%",
    position: "relative",
    padding: "10px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  loginTextFieldsAndButton: {
    margin: "2rem 0",
    flexGrow: 1,
    "@media screen and (max-width: 960px)": {
      margin: 0,
    },
  },
  loginFormFooter: {
    "& a": {
      textDecoration: "none",
      margin: "0.375rem 0",
      color: "currentColor",
      fontSize: "1.15rem",
      fontWeight: 500,
      "&:hover": {
        color: "#c1151f",
      },
    },
  },
  loginForm: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    margin: "auto 0",
    width: "55%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > *": {
      textAlign: "center",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& > *": {
        width: "100%",
      },
    },
  },

  loginFooter: {
    maxHeight: "20vh",
    position: "absolute",
    bottom: 0,
    textAlign: "center",
  },
  welcomeImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  irisLogo: {
    position: "absolute",
    width: "120px",
    top: "1.5%",
    right: "1.5%",
  },
  loginLogo: {
    objectFit: "contain",
    width: "70%",
  },
  imagePanel: {
    height: "100%",
    position: "relative",
  },

  inputText: {
    // margin: "0.375rem 0",
    fontSize: "1.15rem",
    textAlign: "center",
  },

  loginButton: {
    border: "none",
    marginTop: "0.375rem",
    padding: "0.375rem",
    borderRadius: "3px",
    fontWeight: "bold",
    fontSize: "1.15rem",
    backgroundColor: "#ffffff",
    "&:focus": {
      outline: "none",
    },

    "&:enabled": {
      backgroundColor: "#c1151f",
      color: "#ffffff",
    },
    "&:hover": {
      cursor: "pointer",
      "&:enabled": {
        backgroundColor: "#A80006",
        // color: "#ffffff",
      },
    },
  },

  createAccountButton: {
    border: "none",
    marginTop: "0.375rem",
    padding: "0.375rem",
    borderRadius: "3px",
    fontWeight: 600,
    fontSize: "1.15rem",
    backgroundColor: "#ffffff",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      cursor: "pointer",
      color: "#c1151f",
    },
  },

  cooperatorLogo: {
    width: "80%",
    // "@media screen and (max-width: 960px)": {
    //   width: "80%",
    // },
    "@media screen and (max-width: 600px)": {
      width: "40%",
    },
  },
}));

function Login(props) {
  const { history } = props;
  const { openModal } = props;
  const classes = useStyles();

  const [username, setUsername] = useState(
    localStorage.getItem(USER_NAME) || ""
  );
  const [password, setPassword] = useState("");
  const [userProfileId, setUserProfileId] = useState(-1);
  const [token, setToken] = useState("");

  // state(s) for request new account form
  const [isShowRequestAccountForm, setIsShowRequestAccountForm] =
    useState(false);

  // state(s) for terms of use popup
  const [isShowTermsOfUse, setIsShowTermsOfUse] = useState(false);

  const [resetPasswordInfo, setResetPasswordInfo] = useState({
    message: "",
    open: false,
    severity: "success",
    position: "top-center",
  });

  const [isResetPassword, setIsResetPassword] = useState(false);
  const [shouldDisableLogin, setShouldDisableLogin] = useState(true);

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const { login, user, tempUser, resetTempUser, loadingCachedUser, error } =
    useAuth();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (user) {
      const from = history.location.state?.from;

      if (from) {
        const { pathname, search } = from;
        if (pathname) {
          history.replace(pathname + search);
        }
      } else {
        history.replace(PROTECTED_ROUTES.dashboardPage.path);
      }
    }
  }, [user, history]);
  const handleLogin = async () => {
    // do nothing if password is too short
    // this prevent login process is executed when user press "Enter" on the pass world field
    if (shouldDisableLogin && !props.match.params.auth) {
      return;
    }

    login(username, password);
  };

  const onGetNewPassword = (password) => {
    // call reset password
    resetPassword({ profile_id: userProfileId, new_password: password }, token)
      .then((result) => {
        openModal(
          "Login",
          "Password is reset successfully, please login again"
        );
      })
      .catch((error) => {
        openModal(
          "Error",
          `Error in reset password, please try later.\nError: ${error.message}`
        );
      })
      .finally(() => {
        setUserProfileId(-1);
        setToken("");
        setPassword("");
      });
  };

  useEffect(() => {
    const result = !(
      username.trim().length !== 0 &&
      password.trim().length >= PASSWORD_MIN_LENGTH
    );
    setShouldDisableLogin(result);
  }, [username, password, setShouldDisableLogin]);

  // listen to updates of error object and show error message to user via notification
  useEffect(() => {
    if (error) {
      // Amplify error when loading cached user failed
      if (error === "The user is not authenticated") return;
      showNotification(error.message || "UNKNOWN ERROR", "top-right", "error");
    }
  }, [error, showNotification]);

  const onRequestFormSend = (isSuccess, errorMessage) => {
    // dismiss form modal
    setIsShowRequestAccountForm(false);

    // show informative message
    if (isSuccess) {
      // setIsShowRequestAccountForm(false);
      openModal(
        "Thanks for your interest!",
        "Your request has been sent successfully, we would come back to you shortly."
      );
    } else if (errorMessage) {
      openModal(
        "Please try later",
        "Something goes wrong, your request was not sent. " + errorMessage
      );
    }
  };

  if (loadingCachedUser) {
    return <Spinner />;
  }

  return (
    <>
      <Grid container className={classes.root}>
        <ResetPassword
          isOpen={isResetPassword}
          userName={username}
          onClose={() => setIsResetPassword(false)}
          onSubmit={onGetNewPassword}
        />
        <IrisModal
          open={isShowRequestAccountForm}
          onClose={() => setIsShowRequestAccountForm(false)}
        >
          <RequestAccountForm onSubmit={onRequestFormSend} />
        </IrisModal>
        <IrisModal
          open={isShowTermsOfUse}
          onClose={() => setIsShowTermsOfUse(false)}
        >
          <TermsOfUse />
        </IrisModal>
        <Grid container item sm={6} className={classes.loginPanel}>
          <div className={classes.loginForm}>
            <div className={classes.logoAndTitles}>
              <img
                src={IrisCityLogo}
                className={classes.loginLogo}
                alt="irisCITY™"
              />
              <Typography variant="body1">AI Powered GIS Dashboard</Typography>
              <Typography variant="body2">Version {pkg.version}</Typography>
            </div>
            <div className={classes.loginTextFieldsAndButton}>
              <TextField
                disabled={isLoggingIn}
                autoFocus
                value={username}
                margin="normal"
                // placeholder={t("common:USER_NAME")}
                placeholder={getCommonTranslation("user name")}
                onChange={(event) => setUsername(event.target.value)}
                InputProps={{
                  classes: {
                    input: classes.inputText,
                  },
                }}
              ></TextField>
              <TextField
                margin="normal"
                type="password"
                placeholder={getCommonTranslation("password")}
                disabled={isLoggingIn}
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                InputProps={{
                  classes: {
                    input: classes.inputText,
                  },
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />

              <LoadingButton
                style={{ width: 140, marginLeft: "auto" }}
                onClick={handleLogin}
                variant="contained"
                color="primary"
                isLoading={isLoggingIn}
                disabled={
                  !(
                    username.trim().length !== 0 &&
                    password.trim().length >= PASSWORD_MIN_LENGTH
                  )
                }
              >
                {getCommonTranslation("login")}
              </LoadingButton>
            </div>

            <div className={classes.loginFormFooter}>
              <a
                href="#request-account-form"
                onClick={() => setIsShowRequestAccountForm(true)}
              >
                {getCommonTranslation("create account")}
              </a>
              <a
                href="https://www.irisradgroup.com/"
                target="_blank"
                rel="noreferrer"
              >
                {getCommonTranslation("about us")}
              </a>
              <a href="#terms-of-use" onClick={() => setIsShowTermsOfUse(true)}>
                {getCommonTranslation("terms of use")}
              </a>
            </div>
          </div>
          {/* <div className = {classes.loginPanelFooter}>

          </div> */}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.loginFooter}
          >
            <Grid item md={4} sm={7}>
              <a href="https://www.esri.com" target="_blank" rel="noreferrer">
                <img
                  className={classes.cooperatorLogo}
                  src={esriLogo}
                  alt="esri home page"
                  // style={{ width: "80%" }}
                />
              </a>
            </Grid>
            <Grid item md={4} sm={7}>
              <a
                href="https://www.geotab.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.cooperatorLogo}
                  src={geotabLogo}
                  alt="geotab home page"
                  // style={{ maxWidth: "80%" }}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Grid
            container
            item
            sm={6}
            alignItems="center"
            direction="column"
            className={classes.imagePanel}
          >
            <img src={irisLogo} className={classes.irisLogo} alt="IRIS" />
            <img
              src={welcomeImg}
              className={classes.welcomeImg}
              alt="Welcome to IrisGo Admin Console"
            />
          </Grid>
        </Hidden>
      </Grid>
      <ResetPasswordModal
        open={tempUser !== null && tempUser !== undefined}
        // open={true}
        tempCognitoUser={tempUser}
        onClose={() => {
          setPassword("");
          resetTempUser();
        }}
        updateRestPasswordInfo={setResetPasswordInfo}
      />
    </>
  );
}
export default withModal(Login);
