import React from "react";
import { Document, View, Text, Image } from "@react-pdf/renderer";
import PdfPage from "./pdfComponents/PdfPage";
import PdfPageHeader from "./pdfComponents/PdfPageHeader";
import { complianceSummary } from "./demoData/complianceSummary";
import LineBreak from "./pdfComponents/LineBreak";
import { getRowStyle, tableHeaderStyle } from "components/table/file/styles";

export default function PatrolComplianceSummary({
  data: summaryData,
  dateRange,
  imageUrl = "https://surveymonkey-assets.s3.amazonaws.com/survey/164524031/aee40dac-27bf-4cb9-9668-89aae37b459b.jpg",
}) {
  const {
    startDate = new Date().toISOString(),
    endDate = new Date().toISOString(),
  } = dateRange;
  // const data = complianceSummary;

  // maintenance_class: 2,
  // selection_count: 14,
  // patrol_count: 35,
  // compliant_count: 4,
  // non_compliant_count: 10,

  //   "maintenance_class": 10,
  // "section": 10
  // "patrol": 10,
  // "compliant": 10,
  // "non_compliant": 10,,

  // "region": "Montreal",
  // "date": "2021-10-14",

  const dataWithUniqueKey = summaryData.reduce((acc, ele) => {
    const key = ele.date + " - " + ele.maintenance_class;

    if (!acc[key]) {
      acc[key] = { ...ele };
    }
    return acc;
  }, {});

  const sortedData = Object.keys(dataWithUniqueKey)
    .sort()
    .map((k) => ({ ...dataWithUniqueKey[k] }));

  return (
    <Document>
      <PdfPage pagination={true}>
        <PdfPageHeader
          logoUrl="https://surveymonkey-assets.s3.amazonaws.com/survey/164524031/aee40dac-27bf-4cb9-9668-89aae37b459b.jpg"
          pageHeader="City of Vaughan"
          title="Compliance Summary"
          subtitle={`From ${startDate} to ${endDate}`}
          reportDate={`Report date: ${new Date().toJSON().split("T")[0]}`}
        />
        <View style={tableHeaderStyle} fixed>
          <Text style={{ width: 120, textAlign: "center" }}>Date</Text>
          <Text style={{ width: 120, textAlign: "center" }}>Class</Text>
          {/* <Text style={{ width: 120, textAlign: "center" }}>Patrol Count</Text> */}
          <Text style={{ width: 120, textAlign: "center" }}>Section Count</Text>
          <Text style={{ width: 120, textAlign: "center" }}>%Compliant</Text>
          <Text style={{ width: 120, textAlign: "center" }}>
            %Non Compliant
          </Text>
        </View>

        {sortedData.map((d, index) => {
          // {
          //   "compliant": 100,
          //   "date": "2021-12-15",
          //   "id": "44395465-3faf-442a-a81d-40cad89c2902",
          //   "maintenance_class": 6,
          //   "non_compliant": 0,
          //   "patrol": 10,
          //   "region": "",
          //   "section": 139
          // },
          const { date, maintenance_class, section, compliant, non_compliant } =
            d;
          let bgStyle = { marginVertical: 2, textAlign: "center", width: 120 };
          // if (index % 2 === 0) {
          //   bgStyle = { backgroundColor: "#e3e3e3" };
          // }
          return (
            <View
              key={index}
              style={getRowStyle(index)}
              // style={{
              //   display: "flex",
              //   justifyContent: "center",
              //   flexDirection: "row",
              // }}
            >
              <Text style={bgStyle}>{date}</Text>
              <Text style={bgStyle}>{maintenance_class}</Text>
              <Text style={bgStyle}>{section}</Text>
              <Text style={bgStyle}>{`${compliant}%`}</Text>
              <Text style={bgStyle}>
                {/* {`${non_compliant.toFixed(2)}%`} */}

                {`${non_compliant}%`}
              </Text>
              {/* <Text
                style={{
                  ...bgStyle,
                  marginVertical: 2,
                  width: 120,
                  textAlign: "center",
                }}
              >
                {(
                  (compliant_count * 100) /
                  (compliant_count + non_compliant_count)
                ).toFixed(2)}
              </Text> */}
            </View>
          );
        })}

        {/* <View
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            marginBottom: 5,
            marginTop: 3,
          }}
        >
          <Text
            style={{
              width: 120,
              textAlign: "center",
              fontStyle: "bold",
            }}
          >
            Total
          </Text>
          <Text style={{ width: 120, textAlign: "center" }}>
            {a.total.selection_count}
          </Text>
          <Text style={{ width: 120, textAlign: "center" }}>
            {a.total.patrol_count}
          </Text>
          <Text style={{ width: 120, textAlign: "center" }}>
            {a.total.compliant_count}
          </Text>
          <Text style={{ width: 120, textAlign: "center" }}>
            {a.total.non_compliant_count}
          </Text>
          <Text style={{ width: 120, textAlign: "center" }}>
            {(
              (a.total.compliant_count /
                (a.total.compliant_count + a.total.non_compliant_count)) *
              100
            ).toFixed(2)}
          </Text>
        </View> */}

        <LineBreak />
        <View style={{ marginHorizontal: "auto", width: 500 }}>
          <Image src={imageUrl} />
        </View>
        <LineBreak />
      </PdfPage>
    </Document>
  );
}
